@font-face {
  font-family: wow-bold;
  src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
}
@font-face {
  font-family: wow-regular;
  src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
    format("otf");
}

@import '~video-react/styles/scss/video-react';
@font-face {
    font-family: wow-medium;
    src: url('../fonts/poppins/Poppins-Medium.otf');
  }  
  @font-face {
    font-family: wow-regular;
    src: url('../fonts/poppins/Poppins-Regular.otf');
  }  
  @font-face {
    font-family: wow-black;
    src: url('../fonts/poppins/Poppins-Black.otf');
  }  
  @font-face {
    font-family: wow-holiday-bold;
    src: url('../fonts/poppins/Poppins-Bold.otf');
  }  
      @font-face {
    font-family: wow-boldItalic;
    src: url('../fonts/poppins/Poppins-BoldItalic.otf');
  } 
  @font-face {
    font-family: wow-semiBold;
    src: url('../fonts/poppins/Poppins-SemiBold.otf');
  } 
  @font-face {
    font-family: wow-italic;
    src: url('../fonts/poppins/Poppins-Italic.otf');
  }
  @font-face {
    font-family: wow-thin;
    src: url('../fonts/poppins/Poppins-Thin.otf');
}





@import 'node_modules/react-modal-video/scss/modal-video.scss';


















  ol.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -12px !important;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
    margin-top: 46px;
}


















  .text-box-inside-background-image-of-new-design {
    position: relative !important;
    top: 0% !important;
    left: 0% !important;
    transform: translate(0%, 0%) !important;
}








@keyframes forwarding-in-expand-forward-top-carousel-heading {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
            transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes forwarding-in-expand-forward-top-carousel-heading {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-700px) translateY(-500px);
            transform: translateZ(-700px) translateY(-500px);
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    -webkit-transform: translateZ(0) translateY(0);
            transform: translateZ(0) translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes focus-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-800px);
            transform: translateZ(-800px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes focus-in-expand-fwd {
  0% {
    letter-spacing: -0.5em;
    -webkit-transform: translateZ(-800px);
            transform: translateZ(-800px);
    -webkit-filter: blur(12px);
            filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateZ(0);
            transform: translateZ(0);
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}












.carousel-caption-holiday-home{
  background-color: rgba(0,0,0,.5);
  // right: 15%;
  // bottom: 20px;
  // left: 15%;
  // z-index: 10;
  // padding-top: 20px;
  // padding-bottom: 20px;
  // color: #fff;
}



.carousel-caption-holiday-home{
  h3{
    animation: forwarding-in-expand-forward-top-carousel-heading 1.2s cubic-bezier(.215,.61,.355,1) both !important;
    -webkit-animation: forwarding-in-expand-forward-top-carousel-heading 1.2s cubic-bezier(.215,.61,.355,1) both ;
  }
  
  @media(max-width:7000px) and (min-width:400px){
    h3{
      font-size: 1.75rem;
      font-family: wow-bold !important;
      text-transform: uppercase;
      color: #fff !important;
    }
  }
  @media(max-width:400px) and (min-width:0px){
    h3{
      font-family: wow-medium;
      text-transform:uppercase;
      font-size: 22px;
      color: #fff !important;
    }
  }

  p{
    font-size: 14px;
    font-family: wow-regular;


    @media(max-width:700px){
      display: none;
    }
    
  }

  p{
    -webkit-animation: focus-in-expand-fwd 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: focus-in-expand-fwd 1.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }
}






// media queries for header slider home images in pc tab and mobile

@media(max-width:7000px) and (min-width:991px){
  .home-slider-pics-in-tabletview-holiday{
    display: none;
  }
  .home-slider-pics-in-mobileview-holiday{
    display: none;
  }
  // .home-slider-pics-in-desktopview-holiday{
  //   margin-top: 30px;
  // }
  .home-page-slider-item-tb1{
    display: none;
  }
  .home-page-slider-item-mb1{
    display: none;
  }
  
}
@media(max-width:991px) and (min-width:575px){
.home-slider-pics-in-desktopview-holiday{
  display: none;
}
.home-slider-pics-in-mobileview-holiday{
  display: none;
}
.home-page-slider-item-pc1{
  display: none;
}
.home-page-slider-item-mb1{
  display: none;
}

}
@media(max-width:575px) and (min-width:0px){
  .home-slider-pics-in-desktopview-holiday{
    display: none;
  }
  .home-slider-pics-in-tabletview-holiday{
    display: none;
  }
  .home-slider-pics-in-mobileview-holiday img{
  }
  .home-slider-pics-in-mobileview-holiday{
   /*  margin-top: 10px; */
  }
  .home-page-slider-item-pc1{
    display: none;
  }
  .home-page-slider-item-tb1{
    display: none;
  }
  .home-page-slider-item-mb1 img{
  }
  .home-page-slider-item-mb1{
  
  }
}








.text-box-inside-background-image-of-new-design {
 
  text-align: center;
  padding-left: 10%;
  margin-bottom: 30px;
}

.carousel-in-wow-holiday-card {
  margin-bottom: 20px;
  /* border-bottom: 25px solid #f87370;
    border-right: 25px solid #f87370;
    border-left: 25px solid #f87370; */
}

.heading-primary-in-wow-holiday-cards{
  /* color: #fff; */
  text-transform: uppercase;
  backface-visibility: hidden;
}

.heading-primary-main {
  display: block;
  font-size: 50px;
  font-weight: 700;
  font-family: wow-holiday-bold;
  letter-spacing: 5px;
  animation-name: moveInleft;
  animation-duration: 2s;
  color: black !important;
  background: -webkit-gradient(linear,left top,right top,color-stop(0,transparent),color-stop(0%,#f87370),color-stop(25%,#f87370),color-stop(75%,transparent));
  display: inline-block;
  background-size: 100% 5px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  
}

.heading-primary-sub {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
  letter-spacing: 1.4px;
  animation-name: moveInright;
  animation-duration: 1s;
  margin-top: 10px;
}

.button-in-new-design {
  width: 30%;
  height: 50px;
  margin-left: 30%;
  margin-top: 16px !important;
  animation-name: moveInup;
  animation-duration: 1s;

  /* 
    animation-delay: 2s; 
    */
}

.images-in-carousel-in-wow-holiday {
  height: 100%;
  max-height: 500px;
  width: 100%;
  max-width: 700px;
  opacity: 1;
  
}

.box-around-image {
  margin-top: 5%;
}

.centered-text-cards-locations-holidaycards {
  position: absolute;
  font-size: 24px;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 5%);
  font-weight: bold;
  color: #fff !important;
  opacity: 1;
  background-color: #0000008a;
}






.box-around-image:hover {
  transition: transform ease-out;
  animation-name: moveInup;
  animation-duration: 0.7s;
  transform: translate3d(0, 0, 0);
}

.images-in-carousel-in-wow-holiday:nth-child(even):hover {
  transform: rotate(225deg) translate3d(40px, 130px, 0);
}

@keyframes moveInup {
  0% {
    opacity: 1;
    transform: translate(0, 10px);
  }
  80% {
    transform: translate(0, -10px);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}


@keyframes moveInupcarouselplaces {
  0% {
    opacity: 1;
    transform: translate(0, 15px);
  }
  80% {
    transform: translate(0 , -10px);
  }
  100% {
    opacity: 1;
    transform: translateY(1px);
  }
}

@media (max-width: 700px) {
  .heading-primary-main {
    display: block;
    font-size: 22px ;
    font-weight: 400;
    font-family: wow-bold;
    letter-spacing: 2px;
    animation-name: moveInleft;
    animation-duration: 1s;
    margin-top: 15px;
    padding-bottom: 5px;
  }

  .heading-primary-sub {
    display: block;
    font-size: 14px;
    font-weight: 400;
    color: #aaaaaa;
    letter-spacing: 1px;
    animation-name: moveInright;
    animation-duration: 1s;
    margin-top: 10px;
  }
}

/* wow holiday cards  slider css  */

.carousel-card-inside-slider-of-wow-holiday {
  height: 100%;
}

.carousel-item-card-inside-slider-of-wow-holiday {
  height: 100%;
  max-height: 900px;
  /* padding: 3%; */
}

.card-inside-wow-holiday-slider {
  height: 400px;

  width: 20rem;
}

.card-img-top-inside-wow-holiday-slider {
  height: 100%;
  max-height: 140px;
  width: 100%;
  max-width: 300px;
}

.date-card-inside-wow-holiday-slider {
  font-size: 15px;
  font-weight: bolder;
  /* text-align: left; */
  margin-top: 90px;
}

.carousel-inside-link-card-inside-wow-holiday-slider {
  color: #1036fc;
  font-family: wow-bold;
  font-size: 18px;
  text-align: right !important;
  justify-content: right;
}

.card-for-practice {
  height: 380px;
  border: 1px transparent black;
  width: auto;
  cursor: pointer;
  
}

.card-for-practice:hover {
  transition: transform ease-out;
  animation-name: moveInup;
  animation-duration: 1s;
  transform: translate3d(0, 0, 0);
}

.card-img-top-for-practice {
  height: 100%;
  max-height: 160px;
  width: 100%;
  max-width: 321px;
}

.date-card-for-practice {
  font-size: 12px;
  font-weight: bolder;
  /* text-align: left; */
  margin-top: 35px;
}

.carousel-inside-link-card-for-practice {
  color: #1036fc;
  font-family: wow-bold;
  font-size: 18px;
  text-align: right !important;
  justify-content: right;
}

/* .card-border{
            border: 1px solid black;
        } */

.card-text-for-practice {
  line-height: 1.3;
  font-size: 14px;
  font-weight: 500;
  width: 82%;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 38px;
  overflow: hidden;
}

.star_box {
  margin-top: 6px !important;
}

.t_gray {
  color: #888;
}

.t12-wow {
  font-size: 12px !important;
}

.ratings-section-holiday-cards {
  color: #f5a623;
}

.t_main {
  color: #f5a623;
}

.ratings-section-holiday-cards i {
  color: #f5a623 !important;
  float: none;
  position: relative;
  top: 0.5px;
}

/* [class^="klook-icon-"], [class*=" klook-icon-"] {
            font-family: "klookfont";
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;
            color: #d5d5d5;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        } */

/* .t14 {
            font-size: 14px !important;
        }


        .u_t_gray_9-wow {
            color: #999999;
        }


        .act_card ul .star_box>span:first-child {
            padding-right: 4px;
        } */

.t14 {
  font-size: 14px !important;
}

.u_t_gray_9-wow {
  color: #999999;
}

.star_box > span:first-child {
  padding-right: 4px;
}

.price {
  height: 51px;
}
.del_price {
  margin-top: 6% !important;
}

.black-wow {
  color: #999999 !important;
}

.price .g_right {
  float: none;
  display: block;
}

.price .g_right {
  position: relative;
  top: 5px;
  font-size: 12px;
}

.price .notice {
  color: #24b985;
  font-weight: bold;
}

@media (max-width: 767px) {
  /* .card-for-practice {
    height: 400px;
    border: 1px transparent black;
    width: 29rem;
  } */

  .carousel-item-card-inside-slider-of-wow-holiday {
    height: 100%;
    max-height: 1600px;
    /* padding: 3%; */
  }
}
















.join-us-our-container {
  background-image: url(/IMAGES/city-images/slider-card-images/slider-wow-holiday.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0%;
  position: relative;
  width: 100%;
  padding-bottom: 27%;
  margin-top: 20px;
  bottom: 0;
}

.join-us-our-content {
  font-size: 120%;
  color: #fff;
}

.join-us-our-content {
  display: -moz-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 10px;
  right: 30px;
}

.join-us-our-team-heading {
    font-family: wow-bold !important;
  font-size: 46px !important;
  color: #ffffff;
  text-align: left !important;
  margin-left: -25px !important;
  
  line-height: 1.2 !important;
}

h1 {
    font-weight: 700 !important;
    margin: 0 0 10px !important;
}


.join-us-our-team-heading{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

/* .btn-primary {
    background-color: #f87370 !important;
} */

/* .btn-primary {
    color: #fff !important;
    background-color: #007bff !important;
    border-color: 1px #007bff !important;
} */


/* .btn-primary:hover {
    color: #fff !important;
    background-color: #0069d9 !important;
    border-color: #0062cc !important;
} */

/* .button-in-wow-holiday-in-join:hover {
    color: #212529;
    text-decoration: none;
} */


.button-in-wow-holiday-in-join {
    display: inline-block !important;
    font-weight: 400 !important;
    /* color: #212529; */
    text-align: center !important;
    vertical-align: middle !important;
    /* -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none; */
    user-select: none !important;
    /* background-color: transparent; */
    border: 1px solid transparent !important;
    padding: .375rem .75rem !important;
    /* font-size: 1rem; */
    line-height: 1.5 !important;
    border-radius: .25rem !important;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

/* [type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
} */


.new-btn {
    position: relative !important;
    display: inline-block;
    cursor: pointer;
    outline: none !important;
    border: 0;
    vertical-align: middle;
    text-decoration: none !important;
    background: transparent !important;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}

.new-btn, select {
    text-transform: none !important;
}


.new-btn {
    -webkit-appearance: button ;
    /* -webkit-writing-mode: horizontal-tb !important; */
    text-rendering: auto !important;
    color: buttontext;
    letter-spacing: normal !important;
    word-spacing: normal !important;
    text-transform: none;
    text-indent: 0px !important;
    text-shadow: none !important;
    display: inline-block;
    text-align: center;
    align-items: flex-start !important;
    cursor: default;
    background-color: buttonface;
    box-sizing: border-box;
    margin: 0em;
    font: 400 13.3333px Arial !important;
    padding: 1px 6px ;
    border-width: 2px ;
    border-style: outset;
    border-color: buttonface;
    border-image: initial;
}


/* .margin-in-button{
    margin-left: 330px !important;
} */
#chalpe{
    background-color: #f87370 !important;
    border-color: #ffffff !important;
    color: #fff !important;
}

#chalpe:hover {
  color: #fff !important;
  background-color: #ebaaa9 !important;
  border-color: #0062cc !important;
}

.button-in-wow-holiday-in-join {
    margin: .375rem ;
    color: inherit;
    text-transform: uppercase;
    word-wrap: break-word;
    white-space: normal;
    cursor: pointer;
    border: 0;
    border-radius: .125rem;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    padding: .84rem 2.14rem;
    font-size: .81rem;
}



























@media (max-width: 6000px) and (min-width: 700px) {
.jhkjbhjgkjhjnbhjk {
    margin-left: -27px !important;
}
.join_us_buttons {
    margin-left: 0 !important;
    margin-top: 5px !important;
    font-size: 20px !important;
    font-family: wow-regular !important;
    margin-right: 17px !important; 
   

}
.join-us-our-team-heading {
  font-family: wow-bold !important;
  font-size: 40px !important;
  color: #ffffff;
  text-align: left !important;
  margin-left: -25px !important;
  line-height: 1.2 !important;

}


}



@media (max-width: 7000px) and (min-width: 700px) {
  .home-our-story {
    padding: 2% 5%;
  }
}







@media (max-width: 700px) and (min-width: 0) {
.join-us-our-container {
  background-image: url(/IMAGES/city-images/slider-card-images/slider-wow-holiday.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: relative;
  width: 100%;
  height: 190px;
  padding-bottom: 27%;
  margin-top: 20px;
  bottom: 0;
}

.join-us-our-content {
  font-size: 120% !important;
  color: #fff;
}

.join-us-our-content {
  display: -moz-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60px;
  right: 30px;
}

.join-us-our-team-heading {
    font-family: wow-bold !important;
  font-size: 19px !important;
  color: #ffffff;
  text-align: left !important;
  margin-left: 0px !important;
  
  line-height: 1.2 !important;
}

h1 {
    font-weight: 500 !important;
    margin: 0 0 10px !important;
}


.join-us-our-team-heading{
    display: block;
    font-size: 2em;
    margin-block-start: 0.67em;
    margin-block-end: 0.67em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}


.join_us_buttons {
  margin-left: 0 !important;
  margin-top: 5px !important;
  font-size: 10px !important;
  font-family: wow-regular;
  margin-right: 17px !important; 
 

}



.button-in-wow-holiday-in-join {
  margin: .175rem ;
  color: inherit;
  text-transform: uppercase;
  word-wrap: break-word;
  white-space: normal;
  cursor: pointer;
  border: 0;
  border-radius: .125rem;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
  transition: color 0.15s ease-in-out,background-color 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
  padding: .24rem 1.14rem !important;
  font-size: .31rem !important;
}


.new-btn, select {
  text-transform: none !important;
}


.new-btn {
  -webkit-appearance: button ;
  /* -webkit-writing-mode: horizontal-tb !important; */
  text-rendering: auto !important;
  color: buttontext;
  letter-spacing: normal !important;
  word-spacing: normal !important;
  text-transform: none;
  text-indent: 0px !important;
  text-shadow: none !important;
  display: inline-block;
  text-align: center;
  align-items: flex-start !important;
  cursor: default;
  background-color: buttonface;
  box-sizing: border-box;
  margin: 0em;
  font: 200 10.3333px Arial !important;
  padding: 1px 6px !important ;
  border-width: 0px ;
  border-style: outset;
  border-color: buttonface;
  border-image: initial;
}

.button-in-wow-holiday-in-join {
  display: inline-block !important;
  font-weight: 400 !important;
  /* color: #212529; */
  text-align: center !important;
  vertical-align: middle !important;
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; */
  user-select: none !important;
  /* background-color: transparent; */
  border: 1px solid transparent !important;
  padding: .175rem .75rem !important;
  /* font-size: 1rem; */
  line-height: 1.5 !important;
  border-radius: .25rem !important;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out !important;
}

/* [type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button;
} */


.new-btn {
  position: relative !important;
  display: inline-block;
  cursor: pointer;
  outline: none !important;
  border: 0;
  vertical-align: middle;
  text-decoration: none !important;
  background: transparent !important;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
}

.card-for-practice {
  height: 380px;
  border: 1px transparent black;
  width: 27rem;
  padding: 1%;
}
.card-img-top-for-practice {
  height: 100%;
  max-height: 160px;
  width: 100%;
  max-width: 300px;
}


}
















// home page grid section



$bacgroundgradiend : #f3f3f3;

.gradient-back-travel-blogscard14-home-page{
    background-color: $bacgroundgradiend;
    padding: 50px 0;
    margin-bottom: 1rem;
    
}


.our-story-main-araea-headings12-ser-home-page {
  -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(50%,#f87370),color-stop(50%,transparent));
  -webkit-border-image: -webkit-linear-gradient(left,#f87370 50%,transparent 0);
  border-image: -webkit-gradient(linear,left top,right top,color-stop(50%,#f87370),color-stop(50%,transparent));
  border-image: linear-gradient(90deg,#f87370 50%,transparent 0);
  border-image-slice: 1;
  font-size: 16px;
  text-transform: uppercase!important;
  font-family: wow-medium;
}
 .our-story-main-araea-headings12-ser-home-page {
  border-bottom: 4px solid #f87370;
  color: #565656;
  padding-bottom: 5px;
  margin-bottom: 20px;
}


/* gradient gird section */



.padding-in-travel-blogscard14-grid-text-home-page{
  padding: 4rem 0;
}


.travel-blogscard14-page-araea-sub-headings110-home-page {
  font-size: 24px;
}

.travel-blogscard14-page-araea-sub-headings110-home-page {
  // border-bottom: 4px solid #f87370;
  // -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
  // -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
  // border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
  // border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
  // border-image-slice: 1;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  background-image: linear-gradient(to right,#f87270a8 , #ff615e);
  letter-spacing: 2px;
  transition: all .2s;
  font-family: wow-bold;
  // padding-bottom: 5px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.travel-blogscard14-page-page-text-home-page {
  font-size: 14px;
  font-family: wow-regular;
  margin-bottom: .5rem;
}
















































/* carousel-for-mobile */

.news-header-text{
  font-size: 50px;
  font-family: wow-bold;
  text-align: center;
  margin-top: 50px;
  
  padding-bottom: 5px;  
  /* bottom padding should be higher to make up for pseudo border height  */
background: linear-gradient(90deg, transparent 20%, #1036fc 20%, #1036fc 80%, transparent 60%);
background-size: 100%  5px;
background-position: 0% 100%;
background-repeat: no-repeat;
text-transform: uppercase;
display: inline-block;
color: #565656;
  
}





.carousel-inside-text{
  color: #565656;
  font-family: wow-regular;
  font-size: 20px;
  text-align: left;
  
}







@media (min-width:992px){

  .carousel-inside-text{
      color: #565656;
      font-weight: bolder;
      font-size: 20px;
      text-align: left;
      
  }

  .carousel-inside-link{
      color: #1036fc;
      font-family: wow-bold;
      font-size: 18px;
      text-align: right !important;
      justify-content: right;
      margin-left: 300px;
      
  }


  .card{
      height: 420px;
      
      width: 26rem;
      
  
  }

 




  .carousel-inside-link-card{
      color: #1036fc;
      font-family: wow-bold;
      font-size: 18px;
      text-align: right !important;
      justify-content: right;
      
      
  }

  .image-in-carousel-news{
      height: 100%;
      width: 100% ;
  }

  .date{
      font-size: 15px;
      font-weight: bolder;
      text-align: left;
      margin-top: 100px;
  }

  .date-card{
      font-size: 15px;
      font-weight: bolder;
      text-align: left;
      margin-top: 75px;
  }

  .carousel-news{
      height: 400px;
  
  }
  .carousel-inside-text-header{
      padding: 40px;
      color: #1036fc;
      font-weight: bold;
      font-size: 22px;
     
      
  }
  
.carousel-border{
  border-right: 25px solid #1036fc;
  border-bottom: 25px solid #1036fc;
  border-left: 25px solid #1036fc;
}
ol.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -42px;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
  margin-top: 46px;
}
}
@media (max-width:1130px) and (min-width:600px){

.carousel-news{
  height: 260px;

} 

.carousel-inside-link{
  color: #1036fc;
  font-weight: bolder;
  font-size: 18px;
  
  

  
}

.card{
  height: 420px;
  
  width: 26rem;
  

}


.image-in-carousel-news{
  height: 100%;
  max-height: 270px;

  width: 100% ;
}

.date-card{
  font-size: 15px;
  font-weight: bolder;
  text-align: left;
  margin-top: 15px;
}



.carousel-inside-text-header{
  padding: 1px;
  color: #1036fc;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
 
  
}
.date{
  font-size: 15px;
  font-weight: bolder;
  text-align: left;
  margin-top: 30px;
  
  
}

.carousel-border{
  border-right: 25px solid #1036fc;
  border-bottom: 25px solid #1036fc;
  border-left: 25px solid #1036fc;
}
}
@media (max-width:600px) and (min-width:0px){
  
.carousel-news{
  max-height: 500px;
  height: 100%;

}

.carousel-inside-link{
  color: #1036fc;
  font-weight: bold;
  font-size: 18px;
  text-align: right !important;
  justify-content: right;
  
  
}

.carousel-inside-text-header{
  padding: 1px;
  color: #1036fc;
  font-weight: bold;
  font-size: 16px;
  text-align: left;
 
  
}

.image-in-carousel-news{
  height: auto;
  width: 100% ;
}


/* .carousel-border{
   border-right: 6px solid #1036fc;
  border-bottom: 6px solid #1036fc;
  border-left: 6px solid #1036fc; 
} */
.date-card{
  font-size: 15px;
  font-weight: bolder;
  text-align: left;
  margin-top: 20px;
  
}
}









.carousel-news {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

/* ul{
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
} */

.cards{
  height: 320px !important;
  width: 28rem !important;
}

.carousel-news button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  box-shadow: none;
  -webkit-transition: background .5s;
  transition: background .5s;
  border: 2px solid grey;
  padding: 0;
  margin: 0 6px 0 0;
  outline: 0;
  cursor: pointer;
}

.card-img-top{
  height: 100%;
  max-height: 200px;
  width: 100%;
  max-width: 350px;
}

.card{
  height: 420px;
  
  width: 26rem;
  

}

.carousel-inner > .item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -moz-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}


.carousel-cards{
  border: 1px solid transparent;
  transition-property: all;
  transition-delay: 5s;

}




@media (max-width: 992px){
  .carousel-display-in-pc-view{
    display: none;
  }
  .text-box-inside-background-image-of-new-design{
    text-align: left !important;
    padding: 0 !important;
    /* margin-top: 12px; */
    margin-bottom: 25px;
}

}






















@media (max-width: 7000px) and (min-width: 500px){
.our-story-main-araea-headings12-inno {
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(30%,#f87370),color-stop(30%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 30%,transparent );
    border-image: -webkit-gradient(linear,left top,right top,color-stop(30%,#f87370),color-stop(30%,transparent));
    border-image: linear-gradient(90deg,#f87370 35%,transparent 0%);
    border-image-slice: 1;
    font-size: 26px;
    color: #000000;
    font-family: wow-bold;
    padding-bottom: 5px;
    margin-bottom: 20px;
    text-transform: uppercase!important;
}
}


.our-story-main-araea-headings12-inno-con {
  font-size: 16px;
  font-family: wow-medium;
  color: #565656;
}

.story-content{
  padding: 0rem 0 2rem 0;
}




/* video tag */

@media (max-width: 7000px) and (min-width: 700px){
.video_container_home {
    position: relative;
    margin-top: 20px;
    
}

.text_on_video_ifram {
  // background-color: rgba(0,0,0,.5);
  height: 100%;
  width: 100%;
  padding: 6px 10px;
  color: #fff;
  font-family: wow-regular;
  background: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
  padding: 20px 20px 10px;
  border-radius: 10px;
}

.text_on_video_ifram {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
}

.text_on_video_ifram_heading {
  color: #fff;
  font-family: wow-bold;
  font-weight: 700;
  font-size: 22px;
  line-height: 1.5rem;
  text-align: center !important;
}

.text_on_video_ifram_main_heading {
  color: #fff;
  font-family: wow-bold;
  margin-top: 9% !important;
  // transform: translateY(-50%);
  font-weight: 700;
  font-size: 44px;
}
/* h1, h2, h4 {
  text-align: center;
} */

.text_btn_area1548 {
  font-size: 18px;
  font-family: wow-regular;
  color: #fff;
  text-align: center !important;
}

.play_video_area {
  font-size: 60px;
  margin-right: 20px;
  margin-top: 0px !important;
  cursor: pointer;
  display: flex;
  justify-content: center !important;
  flex-direction: row;
}



}

.verification_title-modal-videotag {
  color: #565656;
  font-family: wow-bold;
  font-size: 22px;
  line-height: 1.5;
}



// video scss

.video_container_home{
  background-image: url(/IMAGES/city-images/city-home-images/videotag-img3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% !important;
    position: relative;
    width: 100%;
    padding-bottom: 33%;
    margin-top: 0px;
    bottom: 0;
    margin-bottom: 60px;
}







@media (max-width: 700px){

  .video_container_home {
    position: relative;
}

  .text_on_video_ifram {
    background-color: rgba(0,0,0,.5);
    padding: 20px 20px 10px;
    border-radius: 10px;
  }
  
  // .text_on_video_ifram {
  //   position: absolute;
  //   bottom: 30px;
  //   left: 5%;
  // }
  // .text_on_video_ifram_heading {
  //   color: #fff;
  //   font-family: wow-bold;
  //   font-weight: 100;
  //   font-size: 18px;
  //   line-height: 1px;
  // }
  // .text_btn_area1548 {
  //   font-size: 16px;
  //   font-family: wow-regular;
  //   color: #fff;
  // }

  .text_on_video_ifram {
    // background-color: rgba(0,0,0,.5);
    height: 100%;
    width: 100%;
    padding: 6px 10px;
    color: #fff;
    font-family: wow-regular;
    background: linear-gradient(to bottom, rgba(0,0,0,0.5), rgba(0,0,0,0));
    padding: 10px 10px 10px;
    border-radius: 10px;
  }
  
  .text_on_video_ifram {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
  
  .text_on_video_ifram_heading {
    color: #fff;
    font-family: wow-bold;
    font-weight: 700;
    font-size: 16px;
    text-align: center !important;
    line-height: 1;
  }
  
  .text_on_video_ifram_main_heading {
    color: #fff;
    font-family: wow-bold;
    margin-top: 2% !important;
    // transform: translateY(-50%);
    font-weight: 700;
    font-size:20px;
    line-height: 1;
  }
  /* h1, h2, h4 {
    text-align: center;
  } */
  
  .text_btn_area1548 {
    font-size: 16px;
    font-family: wow-regular;
    color: #fff;
    text-align: center !important;
  }
  
  .play_video_area {
    font-size: 30px;
    margin-right: 20px;
    margin-top: 0px !important;
    cursor: pointer;
    display: flex;
    justify-content: center !important;
    flex-direction: row;
  }


  .video_container_home{
  background-image: url(/IMAGES/city-images/city-home-images/videotag-img3.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% !important;
    position: relative;
    width: 100%;
    padding-bottom: 45%;
    margin-top: 0px;
    bottom: 0;
    margin-bottom: 60px;
}
  
  
  



}










// 2nd video

.m_bg_dark {
  z-index: 10000;

  #video {
    max-width: 90%;
    max-height: 90%;
}

.m_bg_dark video {
  width: 80%;
  height: 80%;
}
.g_v_c_mid {
  position: absolute;
}

.g_v_c_mid {
  top: 50%;
  left: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
  transform: translate3d(-50%, -50%, 0);
  right: 0\9;
  left: 0\9;
  margin: 0 auto\9;
}

video {
  outline: none;
  display: inline-block;
}


}

.m_bg_dark {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.6);
  top: 0;
  left: 0;
  display: none;
  z-index: 1000;
}

.m_bg_dark .t30-close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #fff;
  font-size: 22px;
  cursor: pointer;
  opacity: .5;
  opacity: 1;
}

[class^="t30-close-icon"], [class*=" t30-close-icon"] {
  font-family: "wow-bold";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #d5d5d5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



 .m_bg .t30-icon-play {
  color: #fff;
  font-size: 70px;
  cursor: pointer;
  opacity: 1;
  display: inline-block;
  margin-top: 20px;
}

.t30-icon-play:before {
  content: "\E0A0";
}















// brands area


.partners.brands {
  margin-bottom: 30px;
}

.brands h2 {
  margin-bottom: 8px;
  line-height: 40px;
  font-weight: 500;
  font-family: wow-bold;
}

.t32 {
  font-size: 32px;
}

.t_gray {
  color: #888;
}
// .t16 {
//   font-size: 16px;
// }

.partner_logo_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -34px;
}

.partner_logo_box .partner_icon_scale {
  margin-right: 60px;
  margin-bottom: 20px;
}

.partner_icon_scale-two {
  margin-right: 60px;
  margin-bottom: 20px;
}

.brands i {
  -webkit-filter: grayscale(100%) !important;
  filter: grayscale(100%) !important;
}

.brands i:hover {
  -webkit-filter: grayscale(0%) !important;
  filter: grayscale(0%) !important;
}



@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
.i_shangrila_en {
    background-image: url(/IMAGES/sprites-index-icon@2x.png);
    background-size: 165px 1533px;
}
}

.i_shangrila_en {
  display: inline-block;
  background-image: url(/IMAGES/sprites-index-icon.png);
  background-position: 0px -1411px;
  width: 106px;
  height: 53px;
}
[class^="i_"] {
  display: inline-block;
  vertical-align: top;
}


@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
.i_airasia_en {
    background-image: url(/IMAGES/sprites-index-icon@2x.png);
    background-size: 165px 1533px;
}
}

.i_airasia_en {
  display: inline-block;
  background-image: url(/IMAGES/sprites-index-icon.png);
  background-position: 0px -583px;
  width: 118px;
  height: 31px;
}



@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
.i_big {
    background-image: url(/IMAGES/sprites-index-icon@2x.png);
    background-size: 165px 1533px;
}
}
.i_big {
    display: inline-block;
    background-image: url(/IMAGES/sprites-index-icon.png);
    background-position: 0px -478px;
    width: 97px;
    height: 29px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
  .i_disney {
      background-image: url(/IMAGES/emirates-logo-brands.png);
      background-size: 165px 1533px;
  }
  }
  .i_disney {
      display: inline-block;
      background-image: url(/IMAGES/emirates-logo-brands.png);
      background-position: 0px -478px;
      width: 97px;
      height: 34px;
}


// @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi){
//   .i_disney-two {
//       background-image: url(/IMAGES/emirates-logo-brands.png);
//       background-size: 165px 1533px;
//       background-repeat: no-repeat;
//   }
//   }
//   .i_disney-two {
//       // display: inline-block;
//       background-image: url(/IMAGES/emirates-logo-brands.png);
//       background-repeat: no-repeat;
//       // padding-top: 20%;
//       width: 150px;
//       height: 70px;
// }


// .brands-two i {
//   -webkit-filter: grayscale(100%) !important;
//   filter: grayscale(100%) !important;
// }

// .brands-two i:hover {
//   -webkit-filter: grayscale(0%) !important;
// }


























// .f_hidden {
//   display: none;
// }



// part 2 cards top cities

 .m_justify_list_wowdestinations:first-child {
  margin-right: calc(100% - (373px + 764px));
}

.bg-image-card-topcities{
  display: 'block';
  background-image: url(/IMAGES/city-images/city-home-images/top-cities/3.jpg);
  background-repeat: no-repeat;
}

.a_sd_move_wowdestinations:hover {
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}

.m_justify_list_wowdestinations:first-child {
  margin-right: calc(100% - (373px + 764px)) !important;
}
  .theme {
  width: 740px;
  height: 352px;
}

.padding_in_a_sd_move_wowdestinations{
  padding-right: 0px !important;
}

 .m_justify_list_wowdestinations {
  margin-bottom: 20px;



 .m_justify_list_wowdestinations {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  display: inline-block;
  vertical-align: top;
  overflow: hidden;
  line-height: 1;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  box-sizing: border-box;
}

.m_bg {
  position: relative;
}

.m_radius_box {
  border-radius: 2px;
  overflow: hidden;
}
 .a_sd_move_wowdestinations {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 -1px 3px 0 rgba(0, 0, 0, 0.08);
}



.m_bg {
  background-repeat: no-repeat;
  background-size: cover;
  // height: 100%;
  // width: 100%;
  background-position: center center;
  background-color: #e0e0e0;
  overflow: hidden;
}

// .m_bg {
//   height: 500px;
// }

.m_bg_op2 {
  position: absolute;
}

.m_bg_op2 {
  background-color: rgba(66,66,66,0.25);
}

.m_bg_op5, .m_bg_op2 {
  top: 0;
  left: 0;
  display: inline-block;
  content: "";
  width: 100%;
  height: 100%;
}

 .t_v_mid_box-topcities-img-inside {
  position: relative;
}


.t_v_mid_box-topcities-img-inside {
  display: table;
  width: 100%;
  height: 100%;
}


.t_v_mid_box-topcities-img-inside .t_v_mid {
  display: table-cell;
  vertical-align: middle;
  font-size: 0;
}

.t_mid-text-img-inside {
  text-align: center;
  font-family: wow-bold;
}

  .m_justify_list_wowdestinations:nth-child(1) p {
  margin: 20px;
  line-height: 24px;
}

.t_white {
  color: #ffffff;
}
.t30 {
  font-size: 30px;
}

.t_white {
  color: #ffffff;
}
.t16 {
  font-size: 17px;
  font-family: wow-regular;
}

 .viewMore {
  height: 32px;
  line-height: 32px;
  padding: 0 32px;
  border: 1px solid #fff;
  border-radius: 2px;
  font-weight: 500;
  display: inline-block;
}

.viewMore:hover {
  height: 32px;
  line-height: 32px;
  color: #000 !important;
  padding: 0 32px;
  border: 1px solid #fff;
  border-radius: 2px;
  font-weight: 500;
  display: inline-block;
  background-color: #fff;
}


}

.card-for-section-topcities-holiday{
  display: block;


  .images-in-carousel-in-wow-holiday-2 {
    width: 100%;
    max-width: 100%;
    height: auto;
    border-radius: 0px;
    
  }
  
  
  ul li:first-child {
    padding-top: 0px;
  }
  
  
  

  .card-for-multicarousel-places-holiday:hover {
    transition: transform ease-in;
    animation-name: moveInupcarouselplaces;
    animation-duration: .8s;
  }
  
  .card {
    font-weight: 400;
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.02);
    width: 357px !important;
    height: 352px;

    .card-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 1rem;
  }

    .card-title {
      margin-bottom: 0.75rem;
      color: #666666;
      font-family: wow-bold;
    }

    .card .card-body .card-text {
      font-size: .9rem;
      font-weight: 400;
      color: #747373;
  }

  .card-text {
      line-height: 1.3;
      font-size: 14px !important;
      font-weight: 500;
      font-family: wow-medium;
      text-align: left;
      display: inline-block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      .latest_price {
        font-size: 14px;
        color: #000 !important;
        justify-content: end !important;
    }

    .klook-fast_box{
      display: flex !important;
      justify-content: flex-end !important;
    }

    .price {
      height: 101px !important;
  }

   .g_right {
    float: none;
    display: block;
  }
  
   .g_right {
    top: 5px;
    font-size: 12px;
  }
  
  .notice {
    color: #24b985;
    font-weight: bold;
  }

  }

    .fa-medkit{
      padding-right: .3rem;
    }

    .list-group-item {
      position: relative;
      display: block;
      color: #aaaaaa;
      font-size: .9rem;
      padding: .75rem 1.25rem;
      background-color: #fff;
      border: 0px solid rgba(0,0,0,.125);
  }

    .star_box {
      margin-top: 0px !important;
    }


    .location-name-multi-carousel-places-holiday{
      position: absolute;
      top: 0px;
      height: 20%;
      width: 100%;
      padding: 8px 12px;
      color: #fff;
      font-family: wow-medium;
      background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0));
    }

    .fa-map-marker-alt{
      padding-right: 5px;
    }
  }


}












































// // top things to do section part 2 images

// .cols-section-image-thumb-section-topthings{
//   padding-right: 1px !important;
//   padding-left: 0px !important;

// }

// .link-class-img-top-things-to-do{
//   width: 100%;
// }
// .image-thumb-topthings:active, .image-thumb-topthings:focus,
//  .image-thumb-topthings:hover, .image-thumb-topthings:visited {
//   text-decoration: none;
//   color: #fff;
// }

// @media screen and (min-width: 48em){
// .image-thumb-topthings {
//     font-size: 1.125rem;
//     line-height: 1.375rem;
// }
// }
// .image-thumb-topthings {
//     box-sizing: content-box;
//     font-weight: 600;
//     font-size: .875rem;
//     text-align: center;
//     padding-top: 43.3333%;
//     padding-bottom: .9375rem;
//     height: auto;
//     line-height: 1.125rem;
//     color: #fff;
//     -webkit-filter: saturate(100%);
//     -moz-filter: saturate(100%);
//     -o-filter: saturate(100%);
//     -ms-filter: saturate(100%);
//     filter: saturate(100%);
// }
// .image-thumb-topthings {
//     background-size: cover;
// }

// .image-thumb-section-topthings h3 {
//   // height: 100%;
//   // width: 100%;
//   position: absolute;
//   color: #fff;
//   top: 80%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-size: 100% 100%;
//   background-position: 0 0;
//   overflow: none;
//   text-align: center;

// }


// .img-top-things-to-do, .link-class-img-top-things-to-do{
//   webkit-filter: brightness(50%) saturate(20%);
//   -moz-filter: brightness(50%) saturate(20%);
//   -o-filter: brightness(50%) saturate(20%);
//   -ms-filter: brightness(50%) saturate(20%);
//   filter: brightness(50%) saturate(20%);
//   transition: filter 160ms linear,-webkit-filter 160ms linear,-moz-filter 160ms linear,-o-filter 160ms linear,-ms-filter 160ms linear;
//   cursor: pointer;
// }

// .img-top-things-to-do, .link-class-img-top-things-to-do{
//   -webkit-filter: brightness(70%) saturate(20%);
//   -moz-filter: brightness(70%) saturate(20%);
//   -o-filter: brightness(70%) saturate(20%);
//   -ms-filter: brightness(70%) saturate(20%);
//   filter: brightness(70%) saturate(20%);
// }

// .img-top-things-to-do:hover, .link-class-img-top-things-to-do:hover{
//   // -webkit-filter: grayscale(0%) !important;
//   // filter: grayscale(0%) !important;
//   -webkit-filter: brightness(100%);
//     -moz-filter: brightness(100%);
//     -o-filter: brightness(100%);
//     -ms-filter: brightness(100%);
//     filter: brightness(100%);
// }

// .topthings-button:hover{
//   background-color: #f87370 !important;
//   font-size: 24px !important;
// }

// .topthings-button Button{
//   text-align: center !important;
//   width: auto !important;
// }

// .topthings-button{
//   width: 100%;
//   text-align: center !important;
// }


















video {
  max-width: 100%;
  width: 100%;
  height: auto;
}

video {
  object-fit: contain;
}






















.sada{
  width: 40vh;
  height: 45vh;
 
}


@media (min-width: 992px){

.card-info-about-holiday-blog{
  width: 104%;
  
  height: 210%;


  box-shadow: 6px 7px 3px #00000048;
  -moz-border-radius:25px;
  -webkit-border-radius:25px;
  border-radius: 9px;
 
  
}

.home-blog-card-on-hover:hover {
  transition: transform ease-in;
  animation-name: moveInup;
  animation-duration: 0.7s;
  transform: translate3d(0, 0, 0, 0);
}

/* .on-hover:hover{
  opacity: 0;
} */


.card-info-about-holiday-blog-position{
  position: relative;
  
}




.text_on_images_ifram_card {
  /* background-color: rgba(0,0,0,.1); */
  padding: 0px 3px;
  /* border-radius: 3px; */
  width: 100%;
  
}

.text_on_images_ifram_card {
  position: absolute;
  bottom: 5px;
  left: 0%;
}

.text_on_images_ifram_card_heading {
  color: #ffffff;
  font-family: wow-bold;
  font-weight: 900;
  font-size: 16px;
  text-align: center;
  padding: 0 10px;
  background: rgba(0, 0, 0, 0.404);
  /* line-height: 5px; */
}

.text_btn_images_ifram_card_area1548 {
  
  font-family: wow-regular;
  color: #ffffff;
  display: block;
  background: transparent;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  
    
    
}


.play_video_area_images_ifram_card {
  font-size: 12px;
  margin-right: 0px;
  margin-top: 7px !important;
  padding: 0 10px;
  background: rgba(0, 0, 0, 0.521);
  border-radius: 10px;
  
  /* cursor: pointer; */
}














}


.card-info-about-holiday-blog-position{
  position: relative;
}









@media (max-width: 992px) and (min-width:700px){

  /* .practice12345{
    width: 119%;
   
    height: 100%;
  }
  
  
  .practice12345679{
    width: 100%;
    max-height: 600px !important;
    height: 100% !important;
  } */
  .card-info-about-holiday-blog{
    width: 110%;
    
    height: 100%;
  
  
    box-shadow: 10px 10px 5px #00000048;
    -moz-border-radius:25px;
    -webkit-border-radius:25px;
    border-radius: 12px;
   
    
  }



  .card-info-about-holiday-blog-position{
    position: relative;
    
  }
  
  
  
  
  .text_on_images_ifram_card {
    /* background-color: rgba(0,0,0,.1); */
    padding: 0px 3px;
    /* border-radius: 3px; */
    width: 100%;
    
  }
  
  .text_on_images_ifram_card {
    position: absolute;
    bottom: 5px;
    left: 3%;
  }
  
  .text_on_images_ifram_card_heading {
    color: #ffffff;
    font-family: wow-bold;
    font-weight: 900;
    font-size: 14px;
    text-align: center;
    padding: 0 10px;
    background: rgba(0, 0, 0, 0.154);
    /* line-height: 5px; */
  }
  
  .text_btn_images_ifram_card_area1548 {
    
    font-family: wow-regular;
    color: #ffffff;
    display: block;
    background: transparent;
    -webkit-font-smoothing: antialiased;
    text-align: center;
    
      
      
  }
  
  
  .play_video_area_images_ifram_card {
    font-size: 10px;
    margin-right: 0px;
    margin-top: 7px !important;
    padding: 0 0px;
    background: rgba(0, 0, 0, 0.527);
    border-radius: 10px;
    
    /* cursor: pointer; */
  }







  }








  @media (max-width:700px){

    /* .practice12345{
      width: 119%;
     
      height: 100%;
    }
    
    
    .practice12345679{
      width: 100%;
      max-height: 600px !important;
      height: 100% !important;
    } */
    .card-info-about-holiday-blog{
      width: 100%;
      
      /* height: 100%; */
    
    
      box-shadow: 10px 10px 5px #00000048;
      -moz-border-radius:25px;
      -webkit-border-radius:25px;
      border-radius: 12px;
      
     
      
    }
  
  
  
    .card-info-about-holiday-blog-position{
      position: relative;
      left: 1rem  !important;
      margin-bottom: 1.5rem;
    }
    
    
    
    
    .text_on_images_ifram_card {
      /* background-color: rgba(0,0,0,.1); */
      padding: 0px 3px;
      /* border-radius: 3px; */
      width: 95%;
      
    }
    
    .text_on_images_ifram_card {
      position: absolute;
      bottom: 5px;
      left: 3%;
    }
    
    .text_on_images_ifram_card_heading {
      color: #ffffff;
      font-family: wow-bold;
      font-weight: 900;
      font-size: 18px;
      text-align: center;
      padding: 0 10px;
      background: rgba(0, 0, 0, 0.154);
      /* line-height: 5px; */
    }
    
    .text_btn_images_ifram_card_area1548 {
      
      font-family: wow-regular;
      color: #ffffff;
      display: block;
      background: transparent;
      -webkit-font-smoothing: antialiased;
      text-align: center;
      
        
        
    }
    
    
    .play_video_area_images_ifram_card {
      font-size: 12px;
      margin-right: 0px;
      margin-top: 7px !important;
      padding: 0 0px;
      background: rgba(0, 0, 0, 0.527);
      border-radius: 10px;
      
      /* cursor: pointer; */
    }
  
  
  
  
  
  
  
    }






















.box-around-image-2 {
  margin-top: 5%;
  cursor: pointer;
}

.box-around-image-2:hover {
  transition: transform ease-out;
  animation-name: moveInup;
  animation-duration: 0.7s;
  transform: translate3d(0, 0, 0);
}

.images-in-carousel-in-wow-holiday-2 {
  width: 100%;
  height: auto;
  border-radius: 7px;
  
}

.centered-text-cards-locations-holidaycards-2 {
  font-size: 20px;
  font-family: wow-bold;
  color: #565656 !important;
}


.new-cards-wow-holiday-places{
  display: flex;
}

.holiday-cards-hot-places-icons{
  font-size: 20px;
  color: #F87370 !important;
  margin-right: 9px;
  margin-left: 9px;
  margin-top: 5px;

}


.country-name-holiday-cards-places{
  font-size: 20px;
  font-family: wow-medium;
  color: #F87370 !important;
}



































































/* what makes us differeny */

/* .l-section {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.wpb_row {
  position: relative;
  margin: 0 auto;
}

.l-section{
  border-color: #ebebeb;
}

.l-section:before {
  content: '';
  display: table;
}


@media (max-width: 1024px){
.l-section.height_large>.l-section-h {
    padding: 4rem 0;
}

}




.l-section-h {
  max-width: 1200px;
}

.l-section-h {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.i-cf:before, .i-cf:after {
  content: ' ';
  display: table;
}

.g-cols.type_default {
  margin: 0 -1.5rem;
}

.vc_row {
  position: relative;
}

.g-cols {
  display: flex;
  flex-wrap: wrap;
}

.wpb_column {
  position: relative;
}

.vc_column_container{
  border-color: #ebebeb;
}

@media (min-width: 768px){
.vc_col-sm-12 {
    width: 100%;
}

}

.vc_column_container {
  display: flex;
  flex-direction: column;
}

.g-cols.type_default>div>.vc_column-inner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.vc_column-inner {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.vc_column-inner{
  border-color: #ebebeb;
}

.vc_column-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.l-canvas {
  color: #666666;
}

.wpb_wrapper h2:last-child{
  margin-bottom: 0;
}

.wpb_wrapper h2:first-child{
  padding-top: 0;
}

.wpb_wrapper h2 {
  color: #000000;
  font-family: wow-bold;
    font-weight: 300;
  color: #f87370;
    font-size: 32px;
    line-height: 1.4;
    letter-spacing: 0em;
    margin: 0 0 1.5rem;
}

.w-separator.size_medium:empty {
  height: 3rem;
}

.w-separator:not(.with_content), .w-separator.with_content .w-separator-h {
  position: relative;
}

.w-separator {
  clear: both;
  overflow: hidden;
  line-height: 0;
  height: 1.5rem;
}

.g-cols.type_default {
  margin: 0 -1.5rem;
}


@media (min-width: 768px){
.vc_col-sm-6 {
    width: 50%;
}

}

.wpb_wrapper h3:first-child {
  padding-top: 0;
}

.wpb_wrapper h3 {
  font-family: wow-medium;
    font-weight: 300;
    font-size: 26px;
    line-height: 1.4;
    letter-spacing: 0em;
    margin-bottom: 1.5rem;
    margin: 0 0 1.5rem;
}

.wpb_wrapper h5:last-child {
  margin-bottom: 0;
}

.wpb_wrapper h5 {
  color: #000000;
  font-family: wow-regular;
    font-weight: 300;
    font-size: 20px;
    line-height: 1.4;
    letter-spacing: 0em;
    margin: 0 0 1.5rem;
}

.w-separator.size_medium.with_line {
  height: 6rem;
}

.w-separator.color_border {
  color: #ebebeb;
}

.w-separator.with_content {
  line-height: 1.3;
}

.w-separator.color_primary{
  color: #000000;
}

.w-separator.with_content {
  line-height: 1.3;
}

.w-separator.width_30 {
  width: 30%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.w-separator.with_content .w-separator-h {
  position: relative;
}

.w-separator-h {
  display: inline-flex;
  align-items: center;
  height: inherit;
  border-color: inherit !important;
  color: inherit;
}

.w-separator-h:before {
  right: 100%;
  margin-right: 0.6em;
}

.w-separator-h:after {
  left: 100%;
  margin-left: 0.6em;
}

.w-separator-h:before, .w-separator-h:after {
  content: '';
  position: absolute;
  top: 50%;
  height: 0;
  width: 200rem;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: inherit;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
} */




























.slick-slider-main-class{
  padding-top: 1%;
  margin-bottom: 4%;
  
  .card-for-multicarousel-places-holiday:hover {
    transition: transform ease-in;
    animation-name: moveInupcarouselplaces;
    animation-duration: .8s;
  }
  
  .card {
    font-weight: 400;
    border: 0;
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.02);

    .card-title {
      margin-bottom: .75rem;
      color: #666666;
      font-family: wow-bold;
    }

    .card .card-body .card-text {
      font-size: .9rem;
      font-weight: 400;
      color: #747373;
  }

  .card-text {
      line-height: 1.3;
      font-size: 14px;
      font-weight: 500;
      font-family: wow-regular;
      text-align: left;
      display: inline-block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
  }

    .fa-medkit{
      padding-right: .3rem;
    }

    .list-group-item {
      position: relative;
      display: block;
      color: #aaaaaa;
      font-size: .9rem;
      padding: .75rem 1.25rem;
      background-color: #fff;
      border: 0px solid rgba(0,0,0,.125);
  }

    .star_box {
      margin-top: 0px !important;
    }


    .location-name-multi-carousel-places-holiday{
      position: absolute;
      top: 0px;
      height: 20%;
      width: 100%;
      padding: 6px 10px;
      color: #fff;
      font-family: wow-regular;
      background: linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0));
    }

    .fa-map-marker-alt{
      padding-right: 5px;
    }
    
}


.card:focus {
  font-weight: 400;
  border: 0;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px;
}





.box-around-image-2-slider-slick {
  padding: 5%;
  cursor: pointer;
}

.box-around-image-2-slider-slick:hover {
  transition: transform ease-out;
  animation-name: moveInup;
  animation-duration: 0.7s;
  transform: translate3d(0, 0, 0);
}

.slick-prev:before, .slick-next:before {
  font-family: 'slick';
  font-size: 30px;
  line-height: 1;
  opacity: .75;
  color: #f87370;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev, .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 20px;
  height: 20px;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-dots li.slick-active button:before {
  opacity: .175;
  color: black !important;
}
.slick-dots li button:before {
  font-family: 'slick';
  font-size: 12px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: '•';
  text-align: center;
  opacity: .25;
  color: black !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next {
  right: -30px;
}

.slick-prev {
left: -30px;
}


.images-in-carousel-in-wow-holiday-2 {
  width: 100%;
  height: auto;
  border-radius: 0px;
  
}

.border-multicarousel-places{
  border: 1px solid inherit;
  background-color: #f5f5f5;
  position: relative;
  height: 172px;
  padding: 0 12px 12px 12px;
  line-height: 1.2;
  box-sizing: border-box;
  border-top: none;
}

ul li:first-child {
  padding-top: 0px;
}

.title {
  font-size: 14px;
  line-height: 1.3;
  color: #333;
  font-family: wow-regular !important;
}

ul .title {
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  text-align: left;
  display: inline-block;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  max-height: 38px;
  overflow: hidden;
}


.price-multi-carousel-places {
  height: 51px;
  margin-left: -203px !important;
}
.del_price {
  margin-top: 6% !important;
}

.black-wow {
  color: #999999 !important;
}

.price-multi-carousel-places .g_right {
  float: none;
  display: block;
}

.price-multi-carousel-places .g_right {
  top: 5px;
  font-size: 12px;
}

.price-multi-carousel-places .notice {
  color: #24b985;
  font-weight: bold;
}


}



 



@media(max-width:700px){
  .slick-slider-main-class{
    padding-top: 1%;
    margin-bottom: 4%;
  
  
  .box-around-image-2-slider-slick {
    padding: 5%;
    cursor: pointer;
  }
  
  .box-around-image-2-slider-slick:hover {
    transition: transform ease-out;
    animation-name: moveInup;
    animation-duration: 0.7s;
    transform: translate3d(0, 0, 0);
  }
  
  .slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 25px;
    line-height: 1;
    opacity: .75;
    color: #000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  
  .slick-dots li.slick-active button:before {
    opacity: .75;
    color: black;
  }
  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 12px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: black;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-next {
    right: -10px;
}

.slick-prev {
  left: -10px;
}
  
  
  .images-in-carousel-in-wow-holiday-2 {
    width: 100%;
    height: auto;
    border-radius: 0px;
    
  }
  
  .border-multicarousel-places{
    border: 1px solid inherit;
    background-color: #f5f5f5;
    position: relative;
    height: 172px;
    padding: 0 12px 12px 12px;
    line-height: 1.2;
    box-sizing: border-box;
    border-top: none;
  }
  
  ul li:first-child {
    padding-top: 8px;
  }
  
  .title {
    font-size: 14px;
    line-height: 1.3;
    color: #333;
    font-family: wow-regular !important;
  }
  
  ul .title {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    text-align: left;
    display: inline-block;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    max-height: 38px;
    overflow: hidden;
  }
  
  
  
  
  }

}





.message {
  white-space: wrap;
  text-shadow: 0 1px 0 #fff3b8;
  text-align: center !important;
  padding-left: 500px;
  padding-right: 10px;
}








@media(max-width:700px){
  .slick-slider-main-class{
    padding-top: 1%;
    margin-bottom: 4%;
    
    .card-for-multicarousel-places-holiday:hover {
      transition: transform ease-in;
      animation-name: moveInupcarouselplaces;
      animation-duration: .8s;
    }
    
    .card {
      font-weight: 400;
      border: 0;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.02);
      width: 20rem !important;
      height: 32rem;
}
.slick-initialized .slick-slide {
  display: flex !important;
  justify-content: center;
}
}

}












//  setion performed at last classes media queries


@media(max-width:700px){
  .theme {
    width: auto;
    height: 352px;
    background-position: -190px;
}

.m_justify_list_wowdestinations:first-child {
  margin-right: 0px !important;
}

.card-for-section-topcities-holiday .card {
  font-weight: 400;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.02);
  width: 20.5rem !important;
  height: 352px;
  margin-bottom: .5rem;
  justify-content: center;
  display: flex;
}

.seo_link_maing_top_cities_section_suggested {
  padding: 30px 0 !important;
}

.m_justify_list_wowdestinations .t30 {
  font-size: 25px;
}

.m_justify_list_wowdestinations .t16 {
  font-size: 14px;
  font-family: wow-regular;
}

.card-for-section-topcities-holiday {
  justify-content: center;
  display: flex;
}




}





























@media(max-width:1025px) and (min-width: 770px){
  .m_justify_list_wowdestinations:first-child {
    margin-right: calc(10% - (3px + 4px)) !important;
}

.slick-slider-main-class .card {
  font-weight: 400;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.02);
  width: 20.5rem !important;
}

.theme {
  width: 620px;
  height: 352px;
}

.card-for-section-topcities-holiday .card {
  font-weight: 400;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.02);
  width: 300px !important;
  height: 352px;
}
}

@media(max-width:770px) and (min-width: 560px){
  .m_justify_list_wowdestinations:first-child {
    margin-right: calc(10% - (3px + 4px)) !important;
}

.slick-slider-main-class .card {
  font-weight: 400;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.02);
  width: 20.5rem !important;
}

.theme {
  width: 470px;
  height: 352px;
}

.card-for-section-topcities-holiday .card {
  font-weight: 400;
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.02);
  width: 230px !important;
  height: 352px;
}

.card-for-section-topcities-holiday .card .card-text {
  line-height: 1.2;
  font-size: 14px !important;
  font-weight: 500;
  font-family: wow-medium;
  text-align: left;
  display: inline-block;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 1.6rem;
}

.star_box > span:first-child {
  padding-right: 0px !important;
}

.t14 {
  font-size: 11px !important;
}

.card-for-section-topcities-holiday .card .card-text .latest_price {
  font-size: 12px;
  color: #000 !important;
  justify-content: end !important;
}


}





@media(max-width:500px){
  .slick-initialized .slick-slide {
    display: flex !important;
    justify-content: center !important;
}
}


































.swiper-slide-animate-video {
  position: relative;
  width: 100%;
  height: 480px;
  overflow: hidden;
  background-color: #e9e9e9;
}