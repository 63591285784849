@font-face {
  font-family: wow-bold;
  src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
}
@font-face {
  font-family: wow-regular;
  src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
    format("otf");
}

@font-face {
  font-family: wow-medium;
  src: url("../fonts/poppins/Poppins-Medium.otf");
}
@font-face {
  font-family: wow-regular;
  src: url("../fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: wow-black;
  src: url("../fonts/poppins/Poppins-Black.otf");
}
@font-face {
  font-family: wow-holiday-bold;
  src: url("../fonts/poppins/Poppins-Bold.otf");
}
@font-face {
  font-family: wow-boldItalic;
  src: url("../fonts/poppins/Poppins-BoldItalic.otf");
}
@font-face {
  font-family: wow-semiBold;
  src: url("../fonts/poppins/Poppins-SemiBold.otf");
}
@font-face {
  font-family: wow-italic;
  src: url("../fonts/poppins/Poppins-Italic.otf");
}
@font-face {
  font-family: wow-thin;
  src: url("../fonts/poppins/Poppins-Thin.otf");
}





















/* header image */

.td-post-header-holder-AirportTaxis {
    position: relative;
    min-height: 100px;
    background-color: #444;
    margin-bottom: 36px;
    overflow: hidden;
}


 .td-image-gradient-AirportTaxis:before {
    z-index: 1;
}

.td-image-gradient-AirportTaxis:before {
    bottom: 0;
    content: "";
    display: block;
    height: 80%;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.2)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.15) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
}

.td-post-featured-image-AirportTaxis {
    position: relative;
    visibility: visible !important;
}

.td-post-featured-image-AirportTaxis img {
    margin-bottom: 0;
}

.td-post-featured-image-AirportTaxis img {
    display: block;
    width: 100%;
    height: auto;
}

.td-animation-stack-type0-2-AirportTaxis {
    opacity: 1 !important;
    transition: opacity 0.3s;
    transition-timing-function: cubic-bezier(0.39, 0.76, 0.51, 0.56);
}


.td-post-title-AirportTaxis {
    position: absolute;
    bottom: 0;
    padding: 0 0px 9px 250px;
    width: 80%;
    z-index: 1;
}


.td-post-title-AirportTaxis .td-category-AirportTaxis {
    margin-bottom: 5px;
}

.td-category-AirportTaxis {
    list-style: none;
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1;
}

.td-category-AirportTaxis li {
    display: inline-block;
    margin: 0 5px 5px 0;
    line-height: 1;
}

.td-post-title-AirportTaxis ul{
    padding-inline-start: 0px !important;
}


.td-category-AirportTaxis a {
    border-color: var(--darkblue)!important;
    background-color: var(--darkblue);
}


.td-category-AirportTaxis a {
    color: #fff;
    background-color: #222222;
    padding: 3px 6px 4px 6px;
    white-space: nowrap;
    display: inline-block;
}

.post .td-category-AirportTaxis a {
    text-transform: none;
}


 .entry-title-AirportTaxis{
    font-family: wow-bold;
    font-weight: 700;
}

.entry-title-AirportTaxis {
    font-size: 32px;
    line-height: 38px;
    font-weight: 500;
    margin-bottom: 14px;
    margin-top: 0;
    color: #fff;
}

.entry-title-two-AirportTaxis{
    font-family: wow-medium;
    font-weight: 500;
}

.entry-title-two-AirportTaxis {
    font-size: 24px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 14px;
    margin-top: 0;
    color: rgb(190, 186, 186);
}

.entry-category-AirportTaxis{
    font-size: 12px;
    line-height: 34px;
    font-family: wow-regular;

}


header .td-module-meta-info {
    margin-bottom: 16px;
}

.td-module-meta-info {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 11px;
    margin-bottom: 7px;
    line-height: 1;
    min-height: 17px;
}


 .td-post-title-AirportTaxis .td-post-date-AirportTaxis, .td-post-title-AirportTaxis .td-post-comments a, .td-post-title-AirportTaxis .td-post-views-AirportTaxis {
    color: #fff;
}

header .td-post-date-AirportTaxis {
    margin-left: 4px;
    color: #444;
}

.td-post-date-AirportTaxis {
    color: #aaa;
    display: inline-block;
    position: relative;
    top: 2px;
}

header .td-module-meta-info-AirportTaxis {
    margin-bottom: 16px;
}

.td-module-meta-info-AirportTaxis {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 11px;
    margin-bottom: 7px;
    line-height: 1;
    min-height: 17px;
}

.td-post-date-AirportTaxis .entry-date-AirportTaxis {
    text-transform: none;
}

.td-post-title-AirportTaxis .td-post-views-AirportTaxis {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    margin-right: 0;
}

.td-post-template-3 .td-post-title-AirportTaxis .td-post-comments, .td-post-template-3 .td-post-title-AirportTaxis .td-post-views-AirportTaxis {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    margin-right: 0;
}

 .view-icon-views-AirportTaxis {
    line-height: 17px;
    font-size: 14px;
    margin-right: 5px;
}






header .td-post-views-AirportTaxis span {
    line-height: 15px;
    vertical-align: top;
}










































/* media queries */

@media(max-width:1125px) and (min-width:700px){
  .td-post-title-AirportTaxis {
      position: absolute;
      bottom: 0;
      padding: 0 0px 9px 20px;
      width: 100%;
      z-index: 1;
  }
  .entry-title-AirportTaxis{
      font-family: wow-bold;
      font-weight: 700;
  }
  
  .entry-title-AirportTaxis {
      font-size: 22px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 14px;
      margin-top: 0;
      color: #fff;
  }
  
  .entry-title-two-AirportTaxis{
      font-family: wow-medium;
      font-weight: 500;
  }
  
  .entry-title-two-AirportTaxis {
      font-size: 15px;
      line-height: 15px;
      font-weight: 500;
      margin-bottom: 14px;
      margin-top: 0;
      color: rgb(190, 186, 186);
  }
  .padding-in-travel-blogscard15-grid-text {
      padding: 0rem 0;
  }
  
  .travel-blogscard15-page-araea-sub-headings110 {
      border-bottom: 4px solid #f87370;
      border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
      border-image-slice: 1;
      font-family: wow-bold;
      color: #565656;
      padding-bottom: 5px;
      margin-bottom: 5px;
      text-transform: uppercase;
  }
  
  .travel-blogscard15-page-araea-sub-headings110 {
      font-size: 18px;
  }
  
  .travel-blogscard15-page-page-text {
      font-size: 13px;
      font-family: wow-regular;
  }
  

  .heading-secondary-help-community-section-wow-blogscard15 {
      font-size: 2rem;
      font-weight: 700;
      margin-top: 2rem;
      font-family: wow-bold;
      display: inline-block;
      letter-spacing: 2px;
      padding-bottom: 5px;
      transition: all .2s;
      background: -webkit-gradient(linear, left top, right top, color-stop(25%, transparent), color-stop(25%, #f87370), color-stop(75%, #f87370), color-stop(75%, transparent));
      display: inline-block;
      background-size: 100% 5px;
      background-position: 0 100%;
      background-repeat: no-repeat;
  }



  









  /* gradient gird section */





.padding-in-travel-blogscard15-grid-text{
  padding: 0rem 0;
}


.travel-blogscard15-page-araea-sub-headings110 {
  font-size: 16px;
}

.travel-blogscard15-page-araea-sub-headings110 {
  border-bottom: 4px solid #f87370;
  -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
  -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
  border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
  border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
  border-image-slice: 1;
  font-family: wow-bold;
  color: #565656;
  padding-bottom: 5px;
  margin-bottom: 5px;
  text-transform: uppercase;
}

.travel-blogscard15-page-page-text {
  font-size: 11px;
  font-family: wow-regular;
  margin-bottom: .5rem;
}





  
}
  
  
@media(max-width:700px){
    .td-post-title-AirportTaxis {
      position: absolute;
      bottom: 0;
      padding: 0 0px 9px 0px;
      width: 100%;
      z-index: 1;
}
  
  
  .entry-title-AirportTaxis{
      font-family: wow-bold;
      font-weight: 700;
  }
  
  .entry-title-AirportTaxis {
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 3px;
      margin-top: 0;
      color: #fff;
  }
  
  header .td-module-meta-info-AirportTaxis {
      margin-bottom: 0px;
  }
  
  
  .entry-title-two-AirportTaxis{
      font-family: wow-medium;
      font-weight: 500;
  }
  
  .entry-title-two-AirportTaxis {
      font-size: 12px;
      line-height: 10px;
      font-weight: 500;
      margin-bottom: 5px;
      margin-top: 0;
      color: rgb(190, 186, 186);
  }
  .padding-in-travel-blogscard15-grid-text {
      padding: 0rem 0;
  }
  
  .entry-category-AirportTaxis{
      display: none !important; 
  
  }

  .heading-secondary-help-community-section-wow-blogscard15 {
      font-size: 1.6rem;
      font-weight: 700;
      margin-top: 2rem;
      font-family: wow-bold;
      display: inline-block;
      letter-spacing: 2px;
      transition: all .2s;
      background: -webkit-gradient(linear, left top, right top, color-stop(25%, transparent), color-stop(25%, #f87370), color-stop(75%, #f87370), color-stop(75%, transparent));
      display: inline-block;
      background-size: 100% 5px;
      background-position: 0 100%;
      background-repeat: no-repeat;
  }

  .heading-secondary-section-wow-AirportTaxis {
      font-size: 1.4rem;
      font-weight: 700;
      margin-top: 2rem;
      font-family: wow-bold;
      display: inline-block;
      background-image: linear-gradient(to right, #565656, #565656b4);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      letter-spacing: 2px;
      transition: all .2s;
  }

  .heading_secondary_uk_section_wow_blogscard15 {
      font-size: 2rem;
      font-weight: 700;
      margin-top: 1rem;
      font-family: wow-bold;
      display: inline-block;
      letter-spacing: 2px;
      transition: all .2s;
      background: -webkit-gradient(linear, left top, right top, color-stop(25%, transparent), color-stop(25%, #f87370), color-stop(75%, #f87370), color-stop(75%, transparent));
      display: inline-block;
      background-size: 100% 5px;
      background-position: 0 100%;
      background-repeat: no-repeat;
      padding-bottom: 5px;
  }
  
  .accordion .card-header .card-title {
      padding-right: 0px;
      font-weight: 700;
      white-space: normal;
      text-align: left;
  }
  
  
}


















































// small grid of text icons airport taxis

.wow-airporttaxi--bg{
    background: #f5f5f5;
    margin-top: -3rem !important;
}

.wow-airporttaxi--4 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 2rem !important;
    margin-bottom: 1rem !important;
    }
    
    .user-icon-airporttaxi-wow-holiday{
      font-size: 32px;
      color: #f87370;
      font-weight: 700;
    }
      
    
    @media (min-width: 992px){
    .wow-airporttaxi--4 {
      grid-template: auto/repeat(4,minmax(0,1fr));
      display: flex;
    }
    
    }
    
    
    .l-best-nz__tile {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    }
    
    .wow-airporttaxi--5>* {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    }
    
    .l-best-nz__tile-icon {
    width: 175px;
    margin-bottom: 25px;
    }
    
    .lazy-load-image-background .opacity {
    opacity: 1;
    -webkit-transition: opacity .3s;
    transition: opacity .3s;
    }
    
    .lazy-load-image-background .opacity {
    background-image: none!important;
    opacity: 0;
    }
    
    .l-best-nz__tile-icon .lazy-img-wrapper {
    width: 100%;
    }
    
    img {
    max-width: 100%;
    border-style: none;
    vertical-align: middle;
    }
    .subTitle-wow-airporttaxi {
    margin-bottom: 5px;
    font-family: wow-bold;
    color: #565656;
    }
    
    .subTitle-text-wow-airporttaxi{
    font-family: wow-regular;
    
}























.u-margin-bottom-second-heading-wow-airporttaxi{
    margin-bottom: 1rem;
  }


  .heading_secondary_uk_section_wow_airporttaxi{
    font-size: 2.8rem;
    font-weight: 700;
    margin-top: 3.5rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87370 , #f872708c);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent; 
    letter-spacing: 2px;
    transition: all .2s;
    // background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    // display: inline-block;
    // background-size: 100% 5px;
    // background-position: 0 100%;
    // background-repeat: no-repeat;
    padding-bottom: 5px;
  
  
    
}







































// home page grid section



$bacgroundgradiend : #f3f3f3;

.gradient-back-travel-airporttaxi-wowholiday{
    background-color: $bacgroundgradiend;
    padding: 50px 0;
    margin: 1rem 0;
    
}


.our-story-main-araea-headings12-ser-wowholiday {
  -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(50%,#f87370),color-stop(50%,transparent));
  -webkit-border-image: -webkit-linear-gradient(left,#f87370 50%,transparent 0);
  border-image: -webkit-gradient(linear,left top,right top,color-stop(50%,#f87370),color-stop(50%,transparent));
  border-image: linear-gradient(90deg,#f87370 50%,transparent 0);
  border-image-slice: 1;
  font-size: 14px;
  text-transform: uppercase!important;
  font-family: wow-medium;
}
 .our-story-main-araea-headings12-ser-wowholiday {
  border-bottom: 4px solid #f87370;
  color: #565656;
  padding-bottom: 5px;
  margin-bottom: 20px;
}


/* gradient gird section */



.padding-in-travel-airporttaxi-grid-text-wowholiday{
  padding: 4rem 0;
}


.travel-airporttaxi-page-araea-sub-headings110-wowholiday {
  font-size: 24px;
}

.travel-airporttaxi-page-araea-sub-headings110-wowholiday {
  // border-bottom: 4px solid #f87370;
  // -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
  // -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
  // border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
  // border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
  // border-image-slice: 1;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  background-image: linear-gradient(to right,#f87270a8 , #ff615e);
  letter-spacing: 2px;
  transition: all .2s;
  font-family: wow-bold;
  // padding-bottom: 5px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.travel-airporttaxi-page-page-text-wowholiday {
  font-size: 14px;
  font-family: wow-regular;
  margin-bottom: .5rem;
}





































// Media Area

.media-section-airporttaxi-holiday{
    margin: 2rem;


.landing-page__section-taxi-airportTaxis-img {
    width: 420px !important;
}



.heading-text-media-airporttaxi-holiday{
    // border-bottom: 4px solid #f87370;
  // -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
  // -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
  // border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
  // border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
  // border-image-slice: 1;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  display: inline-block;
  background-image: linear-gradient(to right,#f87270 , #ff615e);
  letter-spacing: 2px;
  transition: all .2s;
  font-family: wow-bold;
  // padding-bottom: 5px;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-size: 18px;
  margin-bottom: .5rem;
}

.paragraph-text-media-airporttaxi-holiday{
  transition: all .2s;
  font-family: wow-regular;
  // padding-bottom: 5px;
  margin-bottom: 15px;
  text-transform: uppercase;
  margin-bottom: .5rem;
}



}







// booking airport taxis

// icons section



.bui-list__icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}






// image section 

@media (min-width: 992px){
.bui-grid>[class*='bui-grid__column'] {
    padding: 32px 16px 0 16px;
}
}

@media (min-width: 992px){
.bui-grid>[class*='bui-grid__column'] {
    padding: 32px 16px 0 16px;
}
}


@media (min-width: 992px){
.bui-grid__column-6\@large, .bui-grid__column-1\/2\@large {
    -ms-flex-preferred-size: 50%;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
    width: 50%;
    max-width: 50%;
}
}

.taxi-airportTaxis-illustration-wrapper {
    margin: 24px auto 0;
    width: 420px;
    position: relative;
}

.taxi-airportTaxis-speech {
    top: 0;
    left: 0;
    width: auto !important;
}

.taxi-airportTaxis-speech{
    position: absolute;
    width: 130px;
    text-align: center;
}

.taxi-airportTaxis-speech-bubble {
    background: #f87370;
    border-radius: 4px;
    padding: 8px;
    position: relative;
}

.taxi-airportTaxis-speech-bubble::after {
    border-right: 10px solid transparent;
    border-top: 10px solid #f87370;
    bottom: -10px;
    content: "";
    position: absolute;
    left: 10px;
}

.taxi-airportTaxis-book {
    top: 145px;
    left: 70px;
}

.taxi-airportTaxis-book{
    position: absolute;
    width: 130px;
    text-align: center;
}

.taxi-airportTaxis-confirmation {
    top: 145px;
    left: 240px;
}

.taxi-airportTaxis-confirmation {
    position: absolute;
    width: 130px;
    text-align: center;
}

.taxi-airportTaxis-driver {
    top: 250px;
    left: 240px;
}

.taxi-airportTaxis-driver {
    position: absolute;
    width: 130px;
    text-align: center;
}


.taxi-airportTaxis-arrive {
    top: 250px;
    left: 60px;
}

 .taxi-airportTaxis-arrive {
    position: absolute;
    width: 180px;
    text-align: center;
}

.taxi-airportTaxis-enjoy {
    top: 417px;
    left: -45px;
}

.taxi-airportTaxis-enjoy {
    position: absolute;
    width: 130px;
    text-align: center;
}

.landing-page__section-taxi-airportTaxis .landing-page__section-taxi-airportTaxis-img {
    width: 420px;
}

.landing-page__section-taxi-airportTaxis-img {
    width: 415px;
    height: 415px;
  }



.text-in-h3-airportTaxi h3{
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

@media (max-width: 7000px) and (min-width: 800px){
  .airportTaxi-banner-top-co {
      width: 56ch;
  }
  }

// .blblbl{
//   background-color: #656565;
//   border-radius: 50%;
//   padding: 5px;
// }


.airportTaxi-wow-holiday-home-our-story {
  padding: 2% 7%;
}

.airportTaxi-wow-holiday-our-team-heading {
  font-family: wow-bold ;
font-size: 56px ;
color: #ffffff;
text-align: left ;
margin-left: -25px ;
letter-spacing: 15px;
width: 100%;
line-height: 1.2 !important;
}

@media (max-width:  1125px) and (min-width: 700px){
.airportTaxi-wow-holiday-our-team-heading {
  font-family: wow-bold !important;
  font-size: 50px !important;
  color: #ffffff;
  text-align: left !important;
  margin-left: -25px !important;
  letter-spacing: 15px;
  width: 100% !important;
  line-height: 1.2 !important;
}
}

@media (max-width: 700px){

  .airportTaxi-wow-holiday-our-team-heading {
    font-family: wow-bold !important;
font-size: 24px !important;
color: #ffffff;
text-align: left !important;
margin-left: -50px !important;
letter-spacing: 5px;
width: 100%;
line-height: 1.2 !important;
  }

  .text-in-h3-airportTaxi{
    font-size: 12px !important;
}
.airportTaxi-banner-top-co{
  display: none !important;
}

.landing-page__section-taxi-airportTaxis-img {
  width: 315px;
  height: 315px;
}





// text writtenm in how taxi  book image


.taxi-airportTaxis-book {
  top: 105px;
  left: 20px;
}

.taxi-airportTaxis-book{
  position: absolute;
  width: 130px;
  text-align: center;
}

.taxi-airportTaxis-confirmation {
  top: 105px;
  left: 150px;
}

.taxi-airportTaxis-confirmation {
  position: absolute;
  width: 130px;
  text-align: center;
}

.taxi-airportTaxis-driver {
  top: 190px;
  left: 150px;
}


.taxi-airportTaxis-driver {
  position: absolute;
  width: 130px;
  text-align: center;
}


.taxi-airportTaxis-arrive {
  top: 170px;
  left: 20px;
}

.taxi-airportTaxis-arrive {
  position: absolute;
  width: 130px;
  text-align: center;
}

.taxi-airportTaxis-enjoy {
  top: 317px;
  left: -25px;
}

.taxi-airportTaxis-illustration-wrapper {
  margin: 24px auto 0;
  width: 320px;
  position: relative;
}

}












// last button section

.button-group-section-airporttaxi-holiday{
    text-align: left;
  
    Button:hover, Button:visited{
      background-color: #f87370 !important;
      color: #fff;
      border: 1px solid rgba(219, 219, 219, 0.5);
    }
  
    Button:focus{
      outline: 0px auto black !important;
      // background-color: rgb(86, 86, 236) !important;
    }
  
    Button:active, Button:focus-within{
      outline: 0px auto black !important;
    }
  
    .selected-button{
      background-color: #f87370 !important;
      color: #fff;
    }
    
    .saaaadaselected-button{
      background-color: #f87370 !important;
      color: #fff;
    }
    
  }
  
  
  
  @media(max-width:700px){
    .button-group-section-airporttaxi-holiday{
      text-align: center;
      .MuiButtonGroup-root {
        display: flex !important;
        border-radius: 4px;
    }
  
    .MuiButton-label {
      width: 100%;
      font-size: 8px !important;
      display: inherit;
      align-items: inherit;
      justify-content: inherit;
  }
  
    }  
  
}
  
  
  
  
  
  
  
  
  
  .topthings-button:hover{
    font-size: 24px !important;
  
    Button{
      background-color: #f87370 !important;
      text-align: center !important;
    }
}
  
  
  .topthings-button{
    font-size: 24px !important;
  
    Button{
      text-align: center !important;
      width: auto !important;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
  
  .topthings-button{
    width: 100%;
    text-align: center !important;
}




.cols-section-image-thumb-section-airporttaxi{
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.cols-section-bg-color-section-airporttaxi{
  background-color: #ffffff;
  padding: 2rem 2rem;
  margin: 1rem 0;

div{
  padding: .4rem;

  Button{
    text-align: center !important;
    }
}

}

























// cars section of wow available

.heading-primary-in-wow-holiday-cards{
  /* color: #fff; */
  text-transform: uppercase;
  backface-visibility: hidden;
}

@media (max-width: 992px){
  .text-box-inside-background-image-of-new-design{
    text-align: left !important;
    padding: 0 !important;
    /* margin-top: 12px; */
    margin-bottom: 25px;
}

}

.text-box-inside-background-image-of-new-design {
  position: relative !important;
  top: 0% !important;
  left: 0% !important;
  transform: translate(0%, 0%) !important;
}

.text-box-inside-background-image-of-new-design {
 
  text-align: center;
  padding-left: 10%;
  margin-bottom: 30px;
}

.heading-primary-main-airportaaxi {
  display: block;
  font-size: 50px;
  font-weight: 700;
  font-family: wow-holiday-bold;
  letter-spacing: 5px;
  animation-name: moveInleft;
  animation-duration: 2s;
  color: black !important;
  background: -webkit-gradient(linear,left top,right top,color-stop(0,transparent),color-stop(0%,#f87370),color-stop(25%,#f87370),color-stop(75%,transparent));
  display: inline-block;
  background-size: 100% 5px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  text-align: center !important;
}

.heading-primary-sub-airportaaxi {
  display: block;
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
  letter-spacing: 1.4px;
  animation-name: moveInright;
  animation-duration: 1s;
  margin-top: 10px;
}




@media (max-width: 700px) {
  .heading-primary-main-airportaaxi {
    display: block;
    font-size: 22px !important;
    font-weight: 400;
    font-family: wow-bold;
    letter-spacing: 0px;
    animation-name: moveInleft;
    animation-duration: 1s;
    margin-top: 15px;
    padding-bottom: 5px;
    background: -webkit-gradient(linear,left top,right top,color-stop(0,transparent),color-stop(20%,#f87370),color-stop(55%,#f87370),color-stop(95%,transparent));
  display: inline-block;
  background-size: 100% 5px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  }

  .heading-primary-sub-airportaaxi {
    display: block;
    font-size: 11px;
    font-weight: 400;
    color: #aaaaaa;
    letter-spacing: 1px;
    animation-name: moveInright;
    animation-duration: 1s;
    margin-top: 10px;
  }
}



.cars-section-airport-taxi-holiday-grid{
  @media(max-width:700px){
  .margin-in-row-airporttaxi-abovecars-area{
    margin-right: 0px;
    margin-left: 0px;
    text-align: center !important;
  }
}
  .heading-text-cars-section-airport-taxi-holiday-grid{
    display: block;
    font-size: 22px ;
    font-weight: 400;
    font-family: wow-bold;
    letter-spacing: 2px;
    animation-name: moveInleft;
    animation-duration: 1s;
    margin-top: 15px;
    padding-bottom: 5px;
    text-align: center !important;
    color:  #f87370;
}
  .figure {
    text-align: center;

    .figure-caption {
      font-size: 18px;
      text-align: center;
      font-family: wow-bold;
      color: rgb(86, 86, 86);
  }
}
}

















// ??????????????????????/////////////@at-root
// 
// 
//                                         airports transfer made easy section
// 
// 




.bg-class-airporttaxi-transfer-section{
  background-color: #ffc1c18e;
  padding: 3rem 0;
  margin: 3rem 0;
}
















.pfakosjdpasd{
.code-box-demo {
  text-align: center;
}
.ant-layout-header,
.ant-layout-footer {
  background: #7dbcea;
  color: #fff;
}
[data-theme="dark"] .ant-layout-header {
  background: #6aa0c7;
}
[data-theme="dark"] .ant-layout-footer {
  background: #6aa0c7;
}
.ant-layout-footer {
  line-height: 1.5;
}
.ant-layout-sider {
  background: #3ba0e9;
  color: #fff;
  line-height: 120px;
}
[data-theme="dark"] .ant-layout-sider {
  background: #3499ec;
}
.ant-layout-content {
  background: rgba(16, 142, 233, 1);
  color: #fff;
  min-height: 120px;
  line-height: 120px;
}
[data-theme="dark"] .ant-layout-content {
  background: #107bcb;
}
> .code-box-demo > .ant-layout + .ant-layout {
  margin-top: 48px;
}

}