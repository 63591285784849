@font-face {
  font-family: wow-bold;
  src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
}
@font-face {
  font-family: wow-regular;
  src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
    format("otf");
}

@font-face {
  font-family: wow-medium;
  src: url("../fonts/poppins/Poppins-Medium.otf");
}
@font-face {
  font-family: wow-regular;
  src: url("../fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: wow-black;
  src: url("../fonts/poppins/Poppins-Black.otf");
}
@font-face {
  font-family: wow-holiday-bold;
  src: url("../fonts/poppins/Poppins-Bold.otf");
}
@font-face {
  font-family: wow-boldItalic;
  src: url("../fonts/poppins/Poppins-BoldItalic.otf");
}
@font-face {
  font-family: wow-semiBold;
  src: url("../fonts/poppins/Poppins-SemiBold.otf");
}
@font-face {
  font-family: wow-italic;
  src: url("../fonts/poppins/Poppins-Italic.otf");
}
@font-face {
  font-family: wow-thin;
  src: url("../fonts/poppins/Poppins-Thin.otf");
}


























.help-page-top-container {
  background-image: linear-gradient(to left bottom, #e0caca00, #0000009f), url(/IMAGES/city-images/wow-help/helpbg1.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80%;
  position: relative;
  width: 100%;
  padding-bottom: 38%;
}

.container-help-center-wow-holiday {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 992px){
.container-help-center-wow-holiday {
    max-width: 960px;
}

}


@media (max-width: 7000px) and (min-width: 600px){
.help-page-top-content h2 {
    margin: 0 0 16px;
    font-family: wow-bold;
    text-transform: uppercase;
    font-size: 40px;
    line-height: 1.2;
    color: #ffffff;
}

.help-page-top-content p {
    margin: 0 0 16px;
    font-family: wow-medium;
    font-size: 30px;
}
.help-page-top-content p {
    margin: 0 0 16px;
    font-family: wow-medium;
    font-size: 30px;
}


}


@media (max-width: 600px) and (min-width: 0px){
  .help-page-top-content h2 {
      margin: 0 0 6px;
      font-family: wow-bold;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 1.2;
      color: #ffffff;
  }

  .help-page-top-content p {
    margin: 0 0 16px;
    font-family: wow-medium;
    font-size: 16px;
}
.help-page-top-content p {
    margin: 0 0 16px;
    font-family: wow-medium;
    font-size: 16px;
}
}


.help-page-top-content p:last-of-type {
    margin-bottom: 0;
}


.help-page-search-area {
    max-width: 350px;
    min-width: 200px;
    display: inline;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}
.rbt {
    outline: none;
}

.form-control-help-center-wow-holiday {
    border: 1px solid #d3d3d3;
    font-family: wow-regular;
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057 !important;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    overflow: visible;
}






.help-page-top-content {
    font-size: 120%;
    color: #fff;
}

.help-page-top-content {
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 30px;
    right: 30px;
}

.input-hint-help-holiday {
  left: 0px;
  pointer-events: none;
  position: absolute;
  top: 0px;
  display: inline-block;
}

.input-box-help-holiday-bg-image {
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.35);
  box-sizing: content-box;
  width: 3px;
  border-style: solid;
  border-width: 1px;
  font-size: 16px;
  line-height: 24px;
  margin: 0px;
  padding: 6px 12px;
}

.help-bg-image-holiday-div {
  height: 0px;
  left: 0px;
  overflow: scroll;
  position: absolute;
  top: 0px;
  visibility: hidden;
  white-space: pre;
  font-size: 16px;
  font-family: wow-medium;
  font-weight: 400;
  font-style: normal;
  letter-spacing: normal;
  text-transform: none;
}


.sr-only-help-center {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    margin: -1px;
    border: 0;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}


.help-icon-search {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.help-icon-search:before {
    content: "\f002";
}

















.help_center1 {
  margin-top: 0px;
  margin-bottom: 50px;
}


.help_center {
  padding: 50px 0;
  /* text-align: left; */
  font-family: wow-bold;
  font-size: 32px;
  color: transparent;
  background: linear-gradient(to right, #f87370 , #f87270a8) ;
  -webkit-background-clip: text;
  margin-bottom: -.5rem !important;
  font-weight: 500;
  line-height: 1.2;
}

@media(max-width:700px){
  .help_center {
    padding: 30px 0;
    /* text-align: left; */
    font-family: wow-bold;
    font-size: 20px !important;
    color: transparent;
    background: linear-gradient(to right, #f87370 , #f87270a8) ;
    -webkit-background-clip: text;
    margin-bottom: -.5rem !important;
    font-weight: 500;
    line-height: 1.2;
}


  }



  .jss8-help-center {
    height: auto;
    display: flex;
    flex-grow: 1;
    background-color: #fff;
}

.jss2 {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}


.jss9-help-center {
    border-right: 1px solid rgba(0, 0, 0, 0.178);
}

.MuiTabs-vertical-help-center {
    flex-direction: column !important;
}

.MuiTabs-root-help-center {
    display: flex;
    overflow: hidden;
    min-height: 48px;
    /* max-height: 208px; */
    -webkit-overflow-scrolling: touch;
}

.MuiTabs-scrollable-help-center {
  /* overflow-y: scroll ; */
  scrollbar-width: none;
}

.MuiTabs-scroller-help-center {
    flex: 1 1 auto;
    display: inline-block;
    position: relative;
    white-space: nowrap;
}

.MuiTabs-flexContainerVertical-help-center {
    flex-direction: column !important;
}

.MuiTabs-flexContainer-help-center {
    display: flex;
}

.MuiTabs-scroller-help-center {
    white-space: nowrap ;
}


.MuiButtonBase-root-help-center {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}




@media (min-width: 600px){
  .MuiTab-root-help-center {
      font-size: 0.8125rem;
      min-width: 160px !important;
  }
  
  }
  
  
  .MuiTab-root-help-center {
      padding: 6px 12px !important;
      overflow: hidden;
      position: relative;
      font-size: 0.875rem;
      max-width: 264px;
      min-width: 72px;
      box-sizing: border-box;
      min-height: 48px;
      text-align: center;
      flex-shrink: 0;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.75;
      white-space: normal;
      letter-spacing: 0.02857em !important;
      text-transform: uppercase;
  }

  .MuiTab-textColorInherit-help-center {
    color: inherit !important;
    opacity: 0.7 !important;
}


li.terms-and-conditions-tab-name-help-center {
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: inherit;
  margin-bottom: .7rem !important;
 
}

.terms-and-conditions-tab-name-help-center {
  font-family: wow-bold!important;
  text-align: left!important;
  font-size: 18px!important;
  color: #565656 !important;
}

button .MuiTab-textColorInherit-help-center:visited{
  color: red !important;
  opacity: 1 !important;
}



.MuiTab-wrapper-help-center {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}


.MuiTab-wrapper-help-center {
  align-items: flex-start!important;
  justify-content: flex-start!important;
}


.icons-in-help-center-muitab-heading{
  font-size: 16px;
  color: #f87370;
  padding-right: .2rem;
}


.MuiTouchRipple-root-help-center {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
}







.jss34-helpcenter {
  /* width: 100%; */
  height: 48px !important;
  height: 2px;
  position: absolute !important;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.jss36-helpcenter {
  background-color: #f87370!important;
}

.jss37-helpcenter {
  right: 0;
  width: 2px;
  height: 100%;
}





.MuiTypography-root-helpcenter {
  margin: 0;
}

@media (max-width: 7000px) and (min-width: 500px){
  .termsTabs-helpcenter {
      display: flex;
      justify-content: center;
      width: 80%;
  }
  }

  .MuiTypography-body1-helpcenter {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.jss69-helpcenter {
  padding: 10px !important;
}


.helpcenter_detail h4 {
  padding: 0 10px 3px 0;
  margin-bottom: 1rem;
  color: #565656;
  border-bottom: 4px solid #f87370;
  -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(20%,#f87370),color-stop(20%,transparent));
  -webkit-border-image: -webkit-linear-gradient(left,#f87370 20%,transparent 0);
  border-image: -webkit-gradient(linear,left top,right top,color-stop(20%,#f87370),color-stop(20%,transparent));
  border-image: linear-gradient(90deg,#f87370 20%,transparent 0);
  border-image-slice: 1;
  font-size: 22px;
  font-family: wow-bold;
  text-transform: uppercase!important;
  text-align: left;
  font-weight: 500;
  line-height: 1.2;
}


.on-focus-button-helpcenter:focus {
  outline: -webkit-focus-ring-color auto 1px ;
}

.on-focus-button-helpcenter:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.on-focus-button-helpcenter:focus{
  opacity: 1 !important;
}

/* .on-focus-button-helpcenter:active{
  opacity: 1 !important;
  color: #f87370 !important;
} */















/* services features safety */


.services-box-helpcenter{
  background-color: #fff;
  font-size: 2.2rem;
  padding: 1.8rem;
  height: 100%;
  text-align: center;
  border-radius: 4px;
  /* box-shadow: 0 .3rem .7rem #000000; */
  transition: transform .3s;
  margin-top: 0rem;
  
  

}

.service-box-help-center-margin{
  margin-bottom: 1.5rem;

}





.lg-icon-helpcenter, 
.circle-icon-helpcenter {
    width: 90px !important;
    height: 90px !important;
    line-height: 85px !important;
    font-size: 50px !important;
    margin-bottom: 1rem ;
   
}


.primary-icon-filled-helpcenter {
    background: #f87370;
    color: #fff;
border: none;
}

.circle-icon-helpcenter{
    border-radius: 50%;
}


.header-heading1-inside-grid-wow-help-center-features-box{
  font-size: 1.2rem !important;
  font-weight: 700;
  text-transform: uppercase;
  font-family: wow-bold;
  color: #000000;
  /* background-image: linear-gradient(to right,#000000 , #0000009c);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block; */
}


.feature__box-text-wow-help-center{
       font-size: 1.1rem !important;
       font-family: wow-regular;
       color: #000000;
       text-align: left;
       margin-top: 1rem;
    

}






.link-button-text-wow-help-center-holiday:link,
.link-button-text-wow-help-center-holiday:visited{
    font-size: 1.1rem;
    color: #565656 !important;
    text-decoration: none;
    border-bottom: 1px solid #000000 !important;
    padding: 0px;
    font-family: wow-regular;
    left: 35%;


}




.link-button-text-wow-help-center-holiday:hover{
    background-color: #f87370 !important;
    color: #ffffff !important;
    box-shadow: 0 1rem 2rem #000000;
    transform: translateY(-3px);
    padding: 4px;

}

.link-button-text-wow-help-center-holiday:active {
    box-shadow: 0 .5rem 1rem #000000;
    transform: translateY(3px);
    padding: 4px;


}


.border-line-wow-help-icons{
  border-left: 4px solid #f87370;
}







































