@font-face {
  font-family: wow-bold;
  src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
}
@font-face {
  font-family: wow-regular;
  src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
    format("otf");
}


@font-face {
    font-family: wow-medium;
    src: url('../fonts/poppins/Poppins-Medium.otf');
  }  
  @font-face {
    font-family: wow-regular;
    src: url('../fonts/poppins/Poppins-Regular.otf');
  }  
  @font-face {
    font-family: wow-black;
    src: url('../fonts/poppins/Poppins-Black.otf');
  }  
  @font-face {
    font-family: wow-holiday-bold;
    src: url('../fonts/poppins/Poppins-Bold.otf');
  }  
      @font-face {
    font-family: wow-boldItalic;
    src: url('../fonts/poppins/Poppins-BoldItalic.otf');
  } 
  @font-face {
    font-family: wow-semiBold;
    src: url('../fonts/poppins/Poppins-SemiBold.otf');
  } 
  @font-face {
    font-family: wow-italic;
    src: url('../fonts/poppins/Poppins-Italic.otf');
  }
  @font-face {
    font-family: wow-thin;
    src: url('../fonts/poppins/Poppins-Thin.otf');
  }










  *, :after, :before {
    box-sizing: border-box;
}








.borderline-bw-history-holiday-cards-inspiration{
  border: 1px solid #f8727094;
  margin-bottom: 35px;

}







.background-image-inholiday-inspiration-cols{

  background-image: url(/IMAGES/city-images/aboutus/background-image.jpg);
  /* -webkit-clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%);
clip-path: polygon(0% 20%, 60% 20%, 60% 0%, 100% 50%, 60% 100%, 60% 80%, 0% 80%); */

  padding: 35px 0;
  margin-top: 20px;
}













.holiday-inspiration-wow-holiday-our-container {
    background-image: url(/IMAGES/city-images/holiday-inspiration/bg-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% !important;
    position: relative;
    width: 100%;
    padding-bottom: 32%;
  margin-top: 0px;
  bottom: 0;
    margin-bottom: 20px;
    /* background-attachment: fixed; */
    
    
  }




  .holiday-inspiration-our-content {
    font-size: 120% !important;
    color: #fff;
    background-color: rgba(0,0,0,0.25);
    width: 100%;
    left: 0 !important;
    
    top: 0;
    height: 100%;
    
  }
  
  .holiday-inspiration-our-content {
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 60px;
    right: 30px;
  }



  .holiday-inspiration-wow-holiday-home-our-story {
    padding: 2% 21%;
    margin-top: 13rem;
  }

  .holiday-inspiration-wow-holiday-our-team-heading {
    font-family: wow-bold !important;
  font-size: 52px !important;
  color: #ffffff;
  text-align: center !important;
  font-weight: 700;
  text-shadow: 2px 2px 0 rgba(0,0,0,.6);
  animation-name: moveInleft !important;
  animation-duration: 1s;
  font-style: italic !important;
  margin-left: -65px !important;
  letter-spacing: 3px;
  line-height: 1 !important;
}

  .holiday-inspiration-wow-holiday-our-team-heading-below-text{
    font-family: wow-regular !important;
    font-size: 21px !important;
    color: #ffffff;
    text-align: center !important;
    font-weight: 300;
    padding: 10px 190px;
    text-shadow: 2px 2px 0 rgba(0,0,0,.5);
    margin-left: -65px !important;
    letter-spacing: 1px;
    line-height: 1.2 !important;
    animation-name: moveInright;
    animation-duration: 1s;
}



















  /* cols text */


  .heading-primary-in-wow-holiday-inspiration-inside-cols{
    /* color: #fff; */
    
    backface-visibility: hidden;
  }


  .text-box-inside-background-image-of-new-design-inspiration-inside-cols {
    position: relative !important;
    top: 0% !important;
    left: 0% !important;
    transform: translate(0%, 0%) !important;
}



.text-box-inside-background-image-of-new-design-inspiration-inside-cols {
 
  text-align: left;
  padding-left: 0%;
  margin: 20px 0;
}


.heading-primary-main-inspiration-inside-cols {
    display: block;
    font-size: 27px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: wow-holiday-bold;
    
    animation-name: moveInleft;
    animation-duration: 1s;
    color: #565656 !important;
    margin-bottom: 20px;
    margin-left: 0 !important;
    line-height: 1.5;
    
  }

  .border-line-inspiration-inside-cols-headings{
    /* border-bottom: 4px solid #f87370; */
    /* border-image: linear-gradient(70deg,#f87370 65%,transparent 0); */
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    
    /* border-image-slice: 11; */

  }


  .heading-primary-sub-inspiration-inside-cols {
    display: block !important;
    
    font-size: 19px;
    font-weight: 400;
    color: #aaaaaa;
    letter-spacing: 1.4px;
    animation-name: moveInright;
    animation-duration: 1s;
    /* margin-top: 10px; */
    
  }



.image-in-inspirations-cols{
    -webkit-box-shadow: 19px 20px 16px 1px rgba(9,9,10,0.7);
-moz-box-shadow: 19px 20px 16px 1px rgba(9,9,10,0.7);
box-shadow: 19px 20px 16px 1px rgba(9,9,10,0.7);
border-radius: 10px;
}




.padding-in-images{
    padding: 0%;
    
}

.class-around-text-inpiration-cols{
    
    
    
    transition: box-shadow .2s ease-in-out,-webkit-box-shadow .2s ease-in-out;
    border-radius:21px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 2px rgba(0,0,0,.1);
    color: #333;
    font-size: 1.4rem;
    border: 1px solid #f87370;
    width: auto;
    height: auto;
    /* height: 100%; */
    margin: 45px 0 0 0;
    padding: 10px !important;
    display: block !important;
    
}


/* .background-image-in-cols-inspiration{

} */

 .bg-displaced {
    
    top: 50px;
    right: 0;
    bottom: 50px;
    left: 0;
    z-index: 0;
}


















@media (min-width: 700px) and (max-width: 995px){

  .class-around-text-inpiration-cols{
    
    
    
    transition: box-shadow .2s ease-in-out,-webkit-box-shadow .2s ease-in-out;
    border-radius:21px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 2px rgba(0,0,0,.1);
    color: #333;
    font-size: 1.4rem;
    border: 1px solid #f87370;
    width: auto;
    height: auto;
    /* height: 100%; */
    margin: 18px 0 0 0;
    padding: 1px !important;
    display: block !important;
    
}




.heading-primary-main-inspiration-inside-cols {
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: wow-holiday-bold;
  
  animation-name: moveInleft;
  animation-duration: 1s;
  color: #565656 !important;
  margin-bottom: 10px;
  margin-left: 0 !important;
  line-height: 1.5;
  
}


.heading-primary-sub-inspiration-inside-cols {
  display: block !important;
  
  font-size: 13px;
  font-weight: 400;
  color: #aaaaaa;
  letter-spacing: 0px;
  animation-name: moveInright;
  animation-duration: 1s;
  /* margin-top: 10px; */
  
}




}



















.practice-routing{
  width: 5%;
  display: flex;
  padding: 22px;
  background-color: #f87370;
  color: #fff;
  text-align: center !important;
  /* margin-left: 5%; */
  margin-bottom: 52px;

}

.assasa{
  display: flex;
  justify-content: space-evenly;
}

























@media (max-width:1126px) and (min-width:700px){

  .holiday-inspiration-wow-holiday-home-our-story {
    padding: 1% 2% 0% 15%;
    margin-top: 10rem;
  }


  .holiday-inspiration-wow-holiday-our-container {
    background-image: url(/IMAGES/city-images/holiday-inspiration/bg-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% !important;
    position: relative;
    width: 100%;
    padding-bottom: 35%;
  margin-top: 0px;
  bottom: 0;
    margin-bottom: 15px;
    
    
  }

  .holiday-inspiration-wow-holiday-our-team-heading {
    font-family: wow-bold !important;
  font-size: 46px !important;
  color: #ffffff;
  text-align: center !important;
  font-weight: 700;
  text-shadow: 2px 2px 0 rgba(0,0,0,.6);

  font-style: italic !important;
  margin-left: -65px !important;
  letter-spacing: 3px;
  line-height: 1 !important;
  }

  .holiday-inspiration-wow-holiday-our-team-heading-below-text{
    font-family: wow-regular !important;
    font-size: 20px !important;
    color: #ffffff;
    text-align: center !important;
    font-weight: 300;
    padding: 0px 80px;
    text-shadow: 2px 2px 0 rgba(0,0,0,.5);
    margin-left: -65px !important;
    letter-spacing: 0px;
    line-height: 1.2 !important;

  }








}








.culture-cards-holiday-inspiration:hover{ 
  -webkit-box-shadow: -6px -38px 65px -12px rgba(86,86,86,1);
  -moz-box-shadow: -6px -38px 65px -12px rgba(86,86,86,1);
  box-shadow: -6px -38px 65px -12px rgba(86,86,86,1);

}














































/* cards code */



.topParent {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 100%;
  margin-bottom: 0px;
  list-style: none;
}


.topParent-smallcards {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 105%;
  height: 100%;
  margin-bottom:0px;
  list-style: none;
}







.innerParent {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  list-style: none;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  border: 1px solid rgba(102, 102, 102, 0.2);
  border-radius: 3px;
  margin: 10px;
  overflow: hidden;
  margin-left: 0;
}
.innerLink {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  list-style: none;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  margin: 10px;
  border-radius: 3px;
  background-color: transparent;
  color: transparent;
  margin-left: 0;
}
.innerLink span {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  list-style: none;
  color: transparent;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  padding: 0;
}
.cText {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  list-style: none;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
  padding: 0 20px;
}
.cImage {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px !important;
  line-height: 1.42857 !important;
  list-style: none;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  position: absolute !important;
  bottom: 0;
  z-index: 1 !important;
  height: 1 !important;
  width: 100% !important;
}
.InspirationTop-group__card-image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.cImage img {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px !important;
  line-height: 1.42857 !important;
  list-style: none !important;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased !important;
  box-sizing: border-box !important;
  border: 0;
  vertical-align: middle !important;
  width: 100% !important;
}
.pTitle {
  line-height: 1.42857;
  list-style: none;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: #666;
  font-family: HelveticaNeue-Bold, "Helvetica Bold", "Arial Black",
    sans-serif;
  font-size: 10px !important;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin: 0 !important;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
}




.pTitle-smallcards {
  line-height: 0.42857;
  list-style: none;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: #666;
  font-family: HelveticaNeue-Bold, "Helvetica Bold", "Arial Black",
    sans-serif;
  font-size: 10px !important;
  letter-spacing: 4px;
  text-transform: uppercase;
  margin: 0 !important;
  text-align: left;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  text-align: center;
}






.mainTitle {
  list-style: none;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: EmiratesSM, helvetica, arial, sans-serif !important;
  letter-spacing: 0.5px !important;
  font-weight: 400 !important;
  box-sizing: border-box;
  color: #333;
  padding-bottom: 42px !important;
  position: relative;
  font-size: 50px !important;
  line-height: 50px !important;
  margin: 20px 0 0 20px;
}

.mainTitle-smallcards {
  list-style: none;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  font-family: EmiratesSM, helvetica, arial, sans-serif !important;
  letter-spacing: 0.1px !important;
  font-weight: 400 !important;
  box-sizing: border-box;
  color: #333;
  padding-bottom: 29px !important;
  position: relative;
  /* text-align: center; */
  font-size: 22px !important;
  line-height: 20px !important;
  margin: 20px 0 0 20px;
}





.InspirationTop-group__card h3:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 20px !important;
  background-color: #c60c30;
  height: 2px;
  width: 60px;
}
.postTitle {
  line-height: 1.42857;
  list-style: none;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: #333;
  font-family: HelveticaNeue-Light, Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  margin: 0 !important;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
}




.postTitle-smallcards {
  line-height: 0.12857;
  list-style: none;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  color: #333;
  font-family: HelveticaNeue-Light, Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  margin: 0 !important;
  font-size: 14px;
  padding-left: 20px;
  text-align: center;
  padding-right: 20px;
  
}



.ul-class-holiday-inspiration-big-cards {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  list-style: none;
  width: 100%;
  list-style-type: none;
  display: table;
  table-layout: fixed;
  position: relative;
  margin: 0;
  padding: 0;
}
.InspirationTop-group__card:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.InspirationTop-group__card-image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
}
.InspirationTop-group__card h3:after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 20px;
  background-color: #c60c30;
  height: 2px;
  width: 60px;
}
.wrapper-inspiratin-topCards{
  color: #333;
font-family: Arial,Helvetica,sans-serif;
font-size: 14px;
line-height: 1.42857;
margin: 0;
padding: 0;
-webkit-font-smoothing: antialiased;
box-sizing: border-box;
}
.group{
  color: #333;
font-family: Arial,Helvetica,sans-serif;
font-size: 14px;
line-height: 1.42857;
margin: 0;
padding: 0;
-webkit-font-smoothing: antialiased;
box-sizing: border-box;
position: relative;


}
.gCon{
  color: #333;
font-family: Arial,Helvetica,sans-serif;
font-size: 14px;
line-height: 1.42857;
padding: 0;
-webkit-font-smoothing: antialiased;
box-sizing: border-box;
margin: 0 auto;
position: relative;
z-index: 2;
}













@media (max-width: 700px){

  .holiday-inspiration-wow-holiday-our-container {
    background-image: url(/IMAGES/city-images/holiday-inspiration/bg-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% !important;
    position: relative;
    width: 100%;
    padding-bottom: 47%;
  margin-top: 0px;
  bottom: 0;
    margin-bottom: 10px;
    background-attachment: scroll;
    
    
  }

  .holiday-inspiration-wow-holiday-home-our-story {
    padding: 1% 1%;
    margin-top: 4rem;
  }


  .holiday-inspiration-wow-holiday-our-team-heading {
    font-family: wow-bold !important;
  font-size: 28px !important;
  color: #ffffff;
  text-align: center !important;
  font-weight: 700;
  text-shadow: 2px 2px 0 rgba(0,0,0,.6);
  font-style: italic !important;
  margin-left: -5px !important;
  letter-spacing: 1px;
  line-height: 1 !important;
  }
  .holiday-inspiration-wow-holiday-our-team-heading-below-text{
    font-family: wow-regular !important;
    font-size: 13px !important;
    color: #ffffff;
    text-align: center !important;
    font-weight: 300;
    padding: 10px 45px;
    text-shadow: 2px 2px 0 rgba(0,0,0,.5);
    margin-left: -5px !important;
    letter-spacing: 0px;
    line-height: 1.2 !important;
    

  }


  .class-around-text-inpiration-cols{
    
    
    
    transition: box-shadow .2s ease-in-out,-webkit-box-shadow .2s ease-in-out;
    border-radius:21px;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 2px rgba(0,0,0,.1);
    color: #333;
    font-size: 1.4rem;
    border: 1px solid #fff;
    width: auto;
    height: auto;
    /* height: 100%; */
    margin: 45px 0 0 0;
    padding: 0px !important;
    display: block !important;
    margin-bottom: 10px;
    
}

.heading-primary-main-inspiration-inside-cols {
  display: block;
  font-size: 17px;
  text-transform: uppercase;
  font-weight: 900;
  font-family: wow-holiday-bold;
  
  animation-name: moveInleft;
  animation-duration: 1s;
  color: #565656 !important;
  margin-bottom: 15px;
  margin-left: 0 !important;
  line-height: 1.5;
  
}
.heading-primary-sub-inspiration-inside-cols {
  display: block !important;
  
  font-size: 14px;
  font-weight: 400;
  color: #aaaaaa;
  letter-spacing: 0.4px;
  animation-name: moveInright;
  animation-duration: 1s;
  /* margin-top: 10px; */
  
}


.image-in-inspirations-cols{
  -webkit-box-shadow: none;
-moz-box-shadow: none;
box-shadow: none;
border-radius: 10px;
}

.padding-in-images{
  padding: 5%;
  
}

.first-history-card{
  display: none;
}

/* 
.InspirationTop-group__card-image:before {
  display: block;
  content: "";
  width: 100%;
  padding-top: 100%;
} */


.topParent-smallcards {
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 1.42857;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 0px;
  list-style: none;
}










}























