@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }



















/* carousel image banner */

.carousel-community-guidline {
    position: relative;
    width: 100%;
}


.slide-community-guidline {
    height: auto!important;
}

.slide-community-guidline {
    width: 100%;
    height: auto;
    overflow: hidden;
}





.carousel-indicators-community-guidline {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}


ol.carousel-indicators-community-guidline {
    margin-top: 0;
    margin-bottom: 1rem;
}

.carousel-indicators-community-guidline .active-community-guidline {
    opacity: 1;
}


.carousel-indicators-community-guidline li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
    color: #fff !important;
}





.carousel-inner-community-guidline {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.carousel-item-community-guidline.active-carousel-inner-community-guidline {
    display: block;
}


.carousel-inner-community-guidline::after {
    display: block;
    clear: both;
    content: "";
}



.carousel-item-community-guidline {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: -webkit-transform .6s ease-in-out;
    transition: transform .6s ease-in-out;
    transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
}



.w-100-community-guidline {
    width: 100%!important;
}
.d-block-community-guidline {
    display: block!important;
}


.image-carousel-community-guidline img{
    vertical-align: middle;
    border-style: none;
}



.home_slider_text_area123456l-community-guidline {
    background-color: rgba(0,0,0,.5);
}

.home_slider_text_area123456l-community-guidline h3 {
    -webkit-animation: tracking-in-expand-fwd-top 1.2s cubic-bezier(.215,.61,.355,1) both;
    animation: tracking-in-expand-fwd-top 1.2s cubic-bezier(.215,.61,.355,1) both;
}


@media (max-width: 7000px) and (min-width: 400px){
    .home_slider_text_area123456l-community-guidline h3, .offices-heading-text {
        text-transform: uppercase;
        font-family: wow-bold;
    }
    
    .about_fdkng12154-community-guidline {
        font-size: 24px;
        font-family: wow-bold;
        color: #fff;
    }
    
    .about_fdkng-community-guidline{
        font-size: 16px;
    
    }
    
    .home_slider_text_area123456l-community-guidline p {
        font-family: wow-regular;
        margin-bottom: 1rem;
        margin-top: 0;
    }
}

.carousel-caption-community-guidline {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center;
}




.about_fdkng12154-community-guidline h3{
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}



.carousel-control-prev-community-guidline {
    left: 0;
}

.carousel-control-next-community-guidline, .carousel-control-prev-community-guidline {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 5;
    transition: opacity .15s ease;
}


.sr-only-community-guidline {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
    margin: -1px;
}

.carousel-control-next-community-guidline {
    right: 0;
}



/* media queries for slider */


@media(max-width:700px){
    .w-100-community-guidline {
        width: 100%!important;
        height: 33vh;
    }


    .about_fdkng12154-community-guidline {
        font-size: 15px;
        font-family: wow-bold;
        margin-bottom: 0rem;
        font-weight: 100;
        line-height: 1;
    }

    .about_fdkng-community-guidline{
        display: none;
    
    }
}


@media(max-width:1120px) and (min-width:700px){
    .w-100-community-guidline {
        width: 100%!important;
        
    }


    .about_fdkng12154-community-guidline {
        font-size: 18px;
        font-family: wow-bold;
        margin-bottom: 0rem;
        font-weight: 100;
        line-height: 1.2;
    }

    .about_fdkng-community-guidline{
        font-size: 13px;
        padding: 0 2rem;
        margin-bottom: 0 !important;
    
    }
}







































.wow_community_guidline {
    padding: 50px 0 0 0;
    /* text-align: left; */
    font-family: wow-bold;
    margin-bottom: 14px;
    font-size: 42px;
    background: linear-gradient(90deg, transparent 20%, #f87370 20%, #f87370 80%, transparent 60%);
  background-size: 100%  5px;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  text-transform: uppercase;
  display: inline-block;
  color: #565656;
  padding-bottom: 5px; 
    line-height: 1.2;
  }






.wow_community-guideline-heading-text{
    font-family: wow-regular;
    font-size: 16px;
  
}




.covid-new-top-area-third-area-contetn-community-guideline{
    margin-bottom: 5rem;
}
















.covid-new-top-area-third-area-contetn p, .covid-new-top-area-third-area-contetn ul li {
    font-size: 16px;
    font-family: wow-regular;
}

.covid-new-top-area-third-area-contetn ul li{
    list-style-type: disc !important;
}


.covid-new-top-area-third-area-contetn p{
margin-top: 0;
    margin-bottom: 1rem;
}


.covid-new-top-area-third-area-contetn h3 {
    font-size: 26px;
    font-family: wow-bold;
}





















@media(max-width:700px){
    .wow_community_guidline {
        padding: 30px 0 0 0;
        /* text-align: left; */
        font-family: wow-bold;
        margin-bottom: 0px;
        font-size: 24px;
        background: linear-gradient(90deg, transparent 20%, #f87370 20%, #f87370 80%, transparent 60%);
      background-size: 100%  5px;
      background-position: 0% 100%;
      background-repeat: no-repeat;
      text-transform: uppercase;
      display: inline-block;
      color: #565656;
      padding-bottom: 5px; 
        line-height: 1.2;
      }
    
    
    
    
    
    
    .wow_community-guideline-heading-text{
        font-family: wow-regular;
        font-size: 14px;
      
    }

    .covid-new-top-area-third-area-contetn p, .covid-new-top-area-third-area-contetn ul li {
        font-size: 14px;
        font-family: wow-regular;
    }


    .covid-new-top-area-third-area-contetn h3 {
        font-size: 22px;
        font-family: wow-bold;
    }
}

























