@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }




























.jss34-termsconditions {
    /* width: 100%; */
    height: 48px !important;
    height: 2px;
    position: absolute !important;
    transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.jss36-termsconditions {
    background-color: #f87370 !important;
}

.jss37-termsconditions {
    left: 0;
    width: 2px;
    height: 100% ;
}


.icons-in-term-condition-heading{
    font-size: 16px;
    color: #f87370;
    padding-right: .2rem;
}



































@media screen and (max-height: 450px) {
  .sidenav-termscondition {
    padding-top: 15px;
  }


  .sidenav-termscondition a {
    font-size: 18px;
  }
}


@media (min-width: 700px) {
.sidenav-termscondition-mobile-view{
  display: none !important;
}
}

@media (max-width: 700px) {
.sidenav-termscondition-mobile-view{
  display: "";
}
}


@media (max-width: 700px) {
.muitab-in-pc-view-terms-conditions{
display: none !important;
}
}














@media (max-width: 700px) {

.sidenav-termscondition {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgb(15, 15, 15);
  overflow-x: hidden;
  transition: 0.4s;
  padding-top: 60px;
}

.sidenav-termscondition button {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181 ;
  display: block;
  transition: 0.3s;
}

.sidenav-termscondition button:hover {
  color: #f1f1f1;
}

.sidenav-termscondition .closebtn-termscondition {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.closebtn-termscondition {
  color: ivory !important;
}





.MuiButtonBase-root {
color: inherit;
border: 0;
cursor: pointer;
margin: 0;
display: inline-flex;
outline: 0;
padding: 0;
position: relative;
align-items: center;
user-select: none;
border-radius: 0;
vertical-align: middle;
-moz-appearance: none;
justify-content: center;
text-decoration: none;
background-color: transparent;
-webkit-appearance: none;
-webkit-tap-highlight-color: transparent;
}

.terms-and-conditions-tab-name {
font-family: wow-regular!important;
text-align: left!important;
font-size: 20px!important;
color: #fff !important;
}

li.terms-and-conditions-tab-name {
display: list-item;
text-align: -webkit-match-parent;
list-style: inherit;
font-size: 13px!important;
margin-bottom: .7rem !important;
color: #000000 !important;

}


.MuiTab-root {
font-size: 1.8125rem;
min-width: 160px !important;
}

.MuiTab-root {
padding: 6px 8px !important;
padding-left: 1rem !important;
overflow: auto;
position: relative;
font-size: 0.875rem;
max-width: 264px;
min-width: 72px;
box-sizing: border-box;
min-height: 60px;
text-align: center;
flex-shrink: 0;
font-family: "Roboto", "Helvetica", "Arial", sans-serif;
font-weight: 500;
line-height: 1.75;
white-space: normal;
letter-spacing: 0.00857em !important;
text-transform: uppercase;
}

.MuiTab-textColorInherit {
opacity: 0.8 !important;
}

.termscondition-menu-button-border{
border-bottom: 5px solid #e7e7e7;
}

.termscondition-menu-button{
font-size: 24px;
font-family: wow-medium;
cursor: pointer;
margin-left: 1rem;
}
}