.invoice-le-view_area-customerlist{
    padding: 50px;

    .invoice-customerlist-div-body-le{
        display: flex;
        justify-content: space-between;
        margin: 24px auto;
        width: 900px;
        box-shadow: 0 2px 8px rgba(0,0,0,.2);

        .invoice-customerlist-body-inside {
            font-size: 14px;
            position: relative;
            padding: 25px;
            min-height: 1024px;
            width: 100%;
            box-sizing: border-box;
            border: 1px solid transparent;
            background: #fff;
            display: flex;
            flex-direction: column;
        }
    
        .invoice-customerlist-body-inside{
            font-family: 'Open Sans', sans-serif;
        }
    
        .customerlist-template__divider--full-width .le-divider-top-customerlist {
            margin-left: -16px;
            margin-right: -16px;
        }

        .le-divider-top-customerlist {
            display: block;
            height: 0;
            padding: 0;
            border: none;
            border-bottom: 1px solid #ffffff;
            overflow: hidden;
            margin: 24px auto;
        }


       
.description_of_card_of_customerlist {
    display: flex;
    justify-content: center;
    margin-bottom: 70px;
    font-size: 600;
    color: #ffffff;

    
@media (max-width: 7000px) and (min-width: 1120px){
    .description_of_card_of_customerlist_area {
        width: 100%;
    }
    }
}


.customerlist-template__items-table {
    margin-top: 26px;
    margin-right: -16px;
    margin-left: -16px;

    .le-table-customerlist {
        font-size: 14px;

        .le-table-customerlist__header .le-table-customerlist__row:last-child {
            border-color: #b2c2cd;
            
        }

        .le-table-customerlist__header .le-table-customerlist__row {
            border-width: 2px;
        }

        .le-table-customerlist__header .le-table-customerlist__cell{
            text-transform: inherit;
            font-weight: 600;
            padding-top: 20px;
            padding-bottom: 20px;
            color: #fff;
        }

        .le-table-customerlist__header .le-table-customerlist__cell--amount {
            text-transform: inherit;
            font-weight: 600;
            padding-top: 20px;
            padding-bottom: 20px;
            color: #000000;
            font-family: 'Open Sans', sans-serif;
        }

        .le-table-customerlist__header .le-table-customerlist__row-behind-table:last-child {
            border-color: #b2c2cd;
        }
    
        .le-table-customerlist__header .le-table-customerlist__row-behind-table {
            border-width: 2px;
        }

        .le-table-customerlist__row {
            border-bottom: inherit;
            
        }

        .le-table-customerlist__body .le-table-customerlist__row:last-child {
            border-color: #d4dde3;
        }

        .le-table-customerlist__row-behind-table:last-child {
            border-color: #d4dde3;
        }
    
    
        .le-table-customerlist__body .le-table-customerlist__row-behind-table:last-child {
            border-color: #d4dde3;
        }

        .le-table-customerlist__cell--amount {
            padding-left: 15px;
            padding-right: 15px;
            width: 125px;
            text-align: center;
            vertical-align: center;
            white-space: pre-line;
        }

        .le-table-customerlist__cell--amount {
            font-size: 16px;

            
        }


        .le-table-customerlist__cell:first-child {
            text-align: right;
        }

        .le-table-customerlist__cell{
            padding-top: 0;
            padding-bottom: 0;
        }

        .le-table-customerlist__cell:first-child {
            padding-left: 10px;
        }

        .le-table-customerlist__cell {
            
            vertical-align: top;
            white-space: pre-line;
            word-break: break-word;
        }

        .le-table-customerlist__cell {
            font-size: 14px;
        }

        .le-text--emphasized-customerlist {
            font-family: wow-regular;
            font-size: 14.5px;
            color: #000000;
        }

        

// .class-table-particular-customerlist{
//     width: 500px !important;
// }



    }

    .le-table-customerlist{
        width: 100%;
        border-collapse: collapse;

       
    }

    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }
}




.customerlist-template__items-table .no-items-message-customerlist {
    text-align: center;
    margin-left: 10px !important;
    padding: 10px;

}



.accountant-heading-customerlist{
    font-size: 26px;
    font-family: 'Open Sans', sans-serif;
    color: #565656;
    font-weight: bold;
    

}


.customerlist-heading-area{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0 7px;

    .customerlist-heading{
    font-size: 22px;
    font-family: 'Open Sans', sans-serif;
    color: #565656;
    font-weight: bold;
    margin-top: 10px;

    }

    #dropdown-basic-button{
        padding: 8px 25px;
        border-radius: 10px;
        cursor: pointer !important;

      
        
    }
    
    .dropdown-toggle::after {
        display: inline-block;
        margin-left: .955em;
        vertical-align: .255em;
        content: "";
        color: #000000 !important;
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }
    

}




.le-invoice-customerlist-filter-button{
    border-radius: 25px !important;
    margin-top: 15px !important;
}



    
}
}





// media queries 

@media(max-width:1015px) and (min-width:700px){
    .invoice-le-view_area-customerlist .invoice-customerlist-div-body-le {
        display: flex;
        justify-content: space-between;
        margin: 24px auto;
        width: 630px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    }

    .invoice-le-view_area-customerlist {
        padding: 20px;
    }

    .invoice-le-view_area-customerlist .invoice-customerlist-div-body-le .invoice-customerlist-body-inside {
        font-size: 14px;
        position: relative;
        padding: 15px;
        min-height: 1024px;
        width: 100%;
        box-sizing: border-box;
        border: 1px solid transparent;
        background: #fff;
        display: flex;
        flex-direction: column;
    }
}




@media(max-width:700px){
    .invoice-le-view_area-customerlist {
        padding: 10px;
    }
}