@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }





















 .td-post-header-holder {
    position: relative;
    min-height: 300px;
    background-color: #444;
    margin-bottom: 36px;
    overflow: hidden;
    margin-top: 36px;
}


 .td-image-gradient:before {
    z-index: 1;
}

.td-image-gradient:before {
    bottom: 0;
    content: "";
    display: block;
    height: 50%;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.8)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
}

.td-post-featured-image {
    position: relative;
    visibility: visible !important;
}

.td-post-featured-image img {
    margin-bottom: 0;
}

.td-post-featured-image img {
    display: block;
    width: 100%;
    height: auto;
}

.td-animation-stack-type0-2 {
    opacity: 1 !important;
    transition: opacity 0.3s;
    transition-timing-function: cubic-bezier(0.39, 0.76, 0.51, 0.56);
}


.td-post-title {
    position: absolute;
    bottom: 0;
    padding: 0 30px 9px 30px;
    width: 80%;
    z-index: 1;
}


.td-post-title .td-category {
    margin-bottom: 5px;
}

.td-category {
    list-style: none;
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1;
}

.td-category li {
    display: inline-block;
    margin: 0 5px 5px 0;
    line-height: 1;
}

.td-post-title ul{
    padding-inline-start: 0px !important;
}


.td-category a {
    border-color: var(--darkblue)!important;
    background-color: var(--darkblue);
}


.td-category a {
    color: #fff;
    background-color: #222222;
    padding: 3px 6px 4px 6px;
    white-space: nowrap;
    display: inline-block;
}

.post .td-category a {
    text-transform: none;
}


 .entry-title-newscard1{
    font-family: gyg-regular-webfont;
    font-weight: 700;
}

.entry-title-newscard1 {
    font-size: 44px;
    line-height: 54px;
    font-weight: 500;
    margin-bottom: 14px;
    margin-top: 0;
    color: #fff;
}





 .td-post-title .td-post-date, .td-post-title .td-post-comments a, .td-post-title .td-post-views {
    color: #fff;
}

header .td-post-date {
    margin-left: 4px;
    color: #444;
    float: left;
}

.td-post-date {
    color: #aaa;
    display: inline-block;
    position: relative;
    top: 2px;
}


.td-post-date .entry-date {
    text-transform: none;
}

.td-post-title .td-post-views {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    margin-right: 0;
}

.td-post-template-3 .td-post-title .td-post-comments, .td-post-template-3 .td-post-title .td-post-views {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    margin-right: 0;
}

header .view-icon-views-newscard1 {
    position: relative;
    line-height: 17px;
    font-size: 14px;
    margin-right: 5px;
}

[class*="td-icon-"] {
    line-height: 1;
    text-align: center;
    display: inline-block;
}

.view-icon-views-newscard1:before {
    content: '\e805';
}

[class^="td-icon-"]:before, [class*=" td-icon-"]:before {
    font-family: 'newspaper';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

header .td-post-views span {
    line-height: 15px;
    vertical-align: top;
}







/* media queries for header image */

@media(max-width:700px){
    .entry-title-newscard1{
        font-family: wow-regular;
        font-weight: 700;
    }
    
    .entry-title-newscard1 {
        font-size: 16px;
        line-height: 1.5rem;
        font-weight: 500;
        margin-bottom: 14px;
        margin-top: 0;
        color: #fff;
    }

    .td-post-header-holder {
        position: relative;
        min-height: 0px;
        background-color: #444;
        margin-bottom: 36px;
        overflow: hidden;
        margin-top: 36px;
    }

    .td-category {
        display: none;
    }

    .td-post-title {
        position: absolute;
        bottom: 0;
        padding: 0 0px 0px 10px;
        width: 80%;
        z-index: 1;
    }

    
}



























/* description news cards */

.description_of_card_of_newsroom {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 5rem;
    font-size: 600;
    color: #8d8c8c;
}

@media (max-width: 7000px) and (min-width: 500px){
.description_of_card_of_newsroom_area {
    width: 75%;
}
}

.blogs-description-area-of-main--text {
    font-size: 14px;
    font-family: wow-regular;
    color: #565656;
    margin-top: 0;
    margin-bottom: 1rem;
}

.blogs-description-area-of-main-headings {
    font-size: 24px;
    font-family: wow-bold;
    color: #565656;
    text-align: left;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}


.description_of_card_of_newsroom_bottom_area {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
}

.description_of_card_of_newsroom_bottom_area_previous {
    cursor: pointer;
}

.description_of_card_of_newsroom_bottom_area_previous h5 {
    color: #565656;
    font-family: wow-bold;
    font-size: 1.25rem;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}


.description_of_card_of_newsroom_bottom_area_previous p {
    color: #565656;
    font-family: wow-regular;
    margin-top: 0;
    margin-bottom: 1rem;
}

.description_of_card_of_newsroom_bottom_area_next {
    text-align: right;
    cursor: pointer;
}

.description_of_card_of_newsroom_bottom_area_next h3{
    font-size: 1.75rem;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.description_of_card_of_newsroom_bottom_area_next h5 {
    color: #565656;
    font-family: wow-bold;
    font-size: 1.25rem;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}


.description_of_card_of_newsroom_bottom_area_next p {
    color: #565656;
    font-family: wow-regular;
    margin-top: 0;
    margin-bottom: 1rem;
}







.wow-news-card1-work-page-top-image{
    margin: 2rem 0 1rem 0;
  }










