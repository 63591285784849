@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }











  .header-image-in-new-design-transport{
    /* width: 100% !important;
    
    max-height: 400px !important; */
    background-image: linear-gradient(to bottom, #00000000, #00000062), url('/IMAGES/city-images/wow-transport/bg-image2.jpg');
    background-size: cover;
    background-position: center;
    
    /* clip-path: polygon(0 0,100% 0, 100% 420px,0 100%); */
    position: relative;



    /* background-repeat: no-repeat; */
    
    background-position: 23% !important;
    
    width: 100%;
    padding-bottom: 33% !important;
  
  
    margin-bottom: 20px;
    background-attachment: fixed;
   
   
    
   

}





.logo-inside-header-of-new-design-page-transport{
    position: absolute;
    top: 30px;
    left: 30px;

    
}

.logo-inside-header-of-new-design-page--wow-transport{
    position: absolute;
    top: 30px !important;
    right: 30px !important;
    text-align: right !important; 
}


.image-logo-inside-header-of-new-design-page-wow-transport{
    height: 50px;
    text-align: right;
}


.image-logo-inside-header-of-new-design-page-transport{
    height: 60px;
    
}



.text-box-inside-background-image-of-new-design-transport{
    position: absolute;
    top: 50% !important;
    left: 50%;
    transform: translate(-50%, -50%);

}


.heading-primary-transport{
    color: #fff;
    text-transform: uppercase;
    backface-visibility: hidden;
    

}


.heading-primary-main-transport{
    display: block;
    text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.815);
    font-family: wow-medium;
    font-size: 60px;
    font-weight: 400;
    letter-spacing: 20px;
    animation-name: moveInleft;
    animation-duration: 1s;

}




.heading-primary-sub-transport{
    display: block;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 13.4px;
    animation-name: moveInright;
    animation-duration: 1s;
    text-shadow: 2px 2px 0 rgba(0,0,0,.4);


}



.holiday-transport-wow-holiday-our-team-heading-below-text{
    font-family: wow-regular !important;
    font-size: 21px !important;
    color: #ffffff;
    text-align: center !important;
    font-weight: 300;
    padding: 5px 120px;
    text-shadow: 2px 2px 0 rgba(0,0,0,.5);
    margin-left: -35px !important;
    letter-spacing: 1px;
    line-height: 1 !important;

  }





















.border-line-bw-transport-images{
    border-bottom: 1px solid #5656569f;
    margin-bottom:1.5rem ;
}


.border-line-bw-transport-images-three{
    border-bottom: 1px solid #5656569f;
    padding: 2rem 0;

}

.border-line-bw-transport-images-three-buttons{
    border-bottom: 1px solid #5656569f;
    padding: 2rem 0;
    margin-bottom: 2rem;
}

.border-line-bw-transport-images-three-buttons-below{
    border-bottom: 3px solid #aaaaaa93;
    
    margin-bottom: 4rem;

}

.border-line-bw-team-members{
    border-bottom: 3px solid #f87270ad;
    
    margin: 2rem 0 5rem 0;

}



/* media query for border lines */


@media (max-width:700px){

.border-line-bw-transport-images{
    border-bottom: 1px solid #5656569f;
    margin-bottom:1rem ;
    display: none;
}


.border-line-bw-transport-images-three{
    border-bottom: 1px solid #5656569f;
    padding: 2rem 0;
    display: none;

}

.border-line-bw-transport-images-three-buttons{
    border-bottom: 1px solid #5656569f;
    padding: 2rem 0;
    margin-bottom: 2rem;
    display: none;
}

.border-line-bw-transport-images-three-buttons-below{
    border-bottom: 3px solid #aaaaaa93;
    
    margin-bottom: 4rem;

}

.border-line-bw-team-members{
    border-bottom: 3px solid #f87270ad;
    
    margin: 2rem 0 5rem 0;
    display: none;

}


}

















































  .u-margin-bottom-second-heading-transport{
    margin-bottom: 2rem;
}


.heading-secondary-section-transport-holiday{
    font-size: 3.5rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1.7rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: #f87370;
    letter-spacing: 2px;
    transition: all .2s;
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;


    
}

.image-tourism-wow-transport{
   margin-top: -2rem;
   margin-bottom: 1.7rem;
}


.header-inside-grid-wow-transport{
    font-size: 1.6rem !important;
    font-weight: 700;
    text-transform: uppercase;
    font-family: wow-holiday-bold;
    color: #565656;
    text-align: center;
    /* background-image: linear-gradient(to right,#000000 , #0000009c);
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block; */
}

.header-inside-grid-wow-transport-onetwo{
    font-size: 1.4rem !important;
    font-weight: 700;
    text-transform: uppercase;
    font-family: wow-holiday-bold;
    color: #565656;
    text-align: center;

}



.header-context-inside-grid-wow-transport{
    font-size: 1.4rem !important;
    font-family: wow-regular;
    color: #000000;
    text-align: center;
    
}

.header-context-inside-grid-wow-transport:not(:last-child){
    margin-bottom: 1rem;
    
}

.header-context-inside-grid-wow-transport-center{
    font-size: 1.3rem !important;
    font-family: wow-regular;
    color: #000000;
    text-align: center;
    margin-bottom: 4rem !important;
}

.header-context-inside-grid-wow-transport-center:not(:last-child){
    margin-bottom: 1rem;
    
}


.fas{
    text-align: center;
}


.jet-transport{
    font-size: 96px;
    text-align: center !important;
    color: #f87370;
    margin-bottom: 1rem;
}


































.holiday-transport-our-team-wow-holiday-our-container {
    background-image: url(/IMAGES/city-images/wow-transport/our-team/our-team.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% !important;
    position: relative;
    width: 100%;
    padding-bottom: 35%;
  margin-top: 0px;
  bottom: 0;
    margin-bottom: 5rem;
    
    
  }


  .holiday-transport-our-team-our-content {
    font-size: 120% !important;
    color: #fff;
    background-color: rgba(0,0,0,0.25);
    width: 100%;
    left: 0 !important;
    
    top: 0;
    height: 100%;
    
  }
  
  .holiday-transport-our-team-our-content {
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 60px;
    right: 30px;
  }


  .holiday-transport-our-team-wow-holiday-home-our-story {
    padding: 2% 21%;
    margin-top: 8rem;
  }


  .holiday-transport-our-team-wow-holiday-our-team-heading {
    font-family: wow-bold !important;
  font-size: 52px !important;
  color: #ffffff;
  text-align: center !important;
  font-weight: 700;
  text-shadow: 2px 2px 0 rgba(0,0,0,.6);

  font-style: italic !important;
  margin-left: -65px !important;
  letter-spacing: 5px;
  line-height: 1 !important;
  }











/* professional team context */

.professional-team-heading-transport-holiday{
    padding: 5rem 0rem;
    font-size: 2.3rem;
    font-weight: 700;
    font-family: wow-bold;
    color: #000000;

    

}

.professional-team-context-transport-holiday{
    padding: 0rem 0rem 0 0;
    font-size: 1.3rem;
    font-weight: 500;
    font-family: wow-regular;
    color: #565656;

}






/* meet the team ceo */




.meet-the-team-ceo-transport-holiday-image{
    width: 100%;
    transform: translateX(-1.6rem) scale(1.4);
    backface-visibility: hidden !important;
    transition: all .3s;
    text-align: center !important;
    
    
}

.meet-the-team-ceo-transport__shape{
    width: 35%;
    
    height: 17rem;
    shape-outside: circle(50% at 50% 50%);
    -webkit-shape-outside: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    -webkit-clip-path: circle(50% at 50% 50%);
    position: relative;
    margin-bottom: 5.2rem;
    
    top: -9% !important;
    left: 50%;
    transform: translate(-50%, 25%);
}


.meet-the-team-ceo-transport__image-caption{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    color: #fff;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-align: center;
    opacity: 0;
    transition: all .5s;
    backface-visibility: hidden;

}

.meet-the-team-ceo-transport__shape:hover .meet-the-team-ceo-transport__image-caption{
    opacity: 1;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
}


.meet-the-team-ceo-transport__shape:hover .meet-the-team-ceo-transport-holiday-image{
    transform: translateX(-1.6rem) scale(1);

    backface-visibility: hidden !important; 
    
}


.content-meet-team-ceo-transport-holiday{
    text-align: center;
}

.occupation-of-team-member-ceo-transport-holiday{
    
    margin-top: 3rem;
    font-size: 3rem;
    color: #fff;
    display: inline-block;
    background-color: #f87270c5;
    padding: 3px;
}


.text-wriiten-of-team-member-ceo-transport-holiday{
    margin-top: 3.5rem;
    font-size: 1.2rem;
    color: #565656;
    padding: 0 1.5rem;

}

.social-media-button-in-wow-holidays-transport-team-ceo{
    
    font-size: 3rem;
    display: flex;
    
    /* left: 0%; */
    transform: translate(35%, 0%);
    /* justify-content: space-between; */
    

}

.social-media-button-in-wow-holidays-transport-team-ceo-center{
    text-align: center !important;
}


.social-media-button-in-wow-holidays-transport-team-icon-ceo{
    color: #f87270c5;
    /* background-color: #f87270c5; */
    /* display: inline-block; */
    margin-left: 2rem;
    padding: 3px;

}

.margin-in-team-and-ceo-transport-holiday{
margin-bottom: 4rem;
}




/* meet the team ceo media queries */


@media(max-width:700px){



.meet-the-team-ceo-transport-holiday-image{
    width: 90%;
    transform: translateX(.6rem) scale(1.4);
    backface-visibility: hidden !important;
    transition: all .3s;
    text-align: center !important;
    
    
}

.meet-the-team-ceo-transport__shape{
    width: 92%;
    
    height: 17rem;
    shape-outside: circle(50% at 50% 50%);
    -webkit-shape-outside: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    -webkit-clip-path: circle(50% at 50% 50%);
    position: relative;
    margin-bottom: 3.2rem;
    
    top: -7%;
    left: 2%;
    transform: translate(0%, 25%);
}


.meet-the-team-ceo-transport__image-caption{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    color: #fff;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-align: center;
    opacity: 0;
    transition: all .5s;
    backface-visibility: hidden;

}

.meet-the-team-ceo-transport__shape:hover .meet-the-team-ceo-transport__image-caption{
    opacity: 1;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
}


.meet-the-team-ceo-transport__shape:hover .meet-the-team-ceo-transport-holiday-image{
    transform: translateX(-1.6rem) scale(1);

    backface-visibility: hidden !important; 
    
}


.content-meet-team-ceo-transport-holiday{
    text-align: center;
}

.occupation-of-team-member-ceo-transport-holiday{
    
    margin-top: 3rem;
    font-size: 3rem;
    color: #fff;
    display: inline-block;
    background-color: #f87270c5;
    padding: 3px;
}


/* .text-wriiten-of-team-member-ceo-transport-holiday{
    margin-top: 3.5rem;
    font-size: 1.2rem;
    color: #565656;
    padding: 0 1.5rem;

} */

.social-media-button-in-wow-holidays-transport-team-ceo{
    
    font-size: 3rem;
    display: flex;
    
    /* left: 0%; */
    transform: translate(-8%, 0%);
    justify-content: space-evenly;
    

}

.social-media-button-in-wow-holidays-transport-team-ceo-center{
    text-align: center !important;
}


.social-media-button-in-wow-holidays-transport-team-icon-ceo{
    color: #f87270c5;
    /* background-color: #f87270c5; */
    /* display: inline-block; */
    margin-left: 2rem;
    padding: 3px;

}

.margin-in-team-and-ceo-transport-holiday{
margin-bottom: 3rem;
}


}







































/* meet the team */



.meet-the-team-transport-holiday-image{
    width: 100%;
    transform: translateX(-1.6rem) scale(1.4);
    backface-visibility: hidden !important;
    transition: all .3s;
    
    
}




.meet-the-team-transport__shape{
    width: 100%;
    height: 18rem;
    shape-outside: circle(50% at 50% 50%);
    -webkit-shape-outside: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    -webkit-clip-path: circle(50% at 50% 50%);
    position: relative;
    margin-bottom: 2rem;
}


.meet-the-team-transport__image-caption{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    color: #fff;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-align: center;
    opacity: 0;
    transition: all .5s;
    backface-visibility: hidden;

}

.meet-the-team-transport__shape:hover .meet-the-team-transport__image-caption{
    opacity: 1;
    transform: translate(-50%, -50%);
    background-color: #000000b6;
    
    backface-visibility: hidden;
}


.meet-the-team-transport__shape:hover .meet-the-team-transport-holiday-image{
    transform: translateX(-1.6rem) scale(1);
    /* filter: blur(4px) brightness(60%) ; */
    backface-visibility: hidden !important; 
    
}


.content-meet-team-transport-holiday{
    text-align: center;
}

.occupation-of-team-member-transport-holiday{
    
    margin-top: 1rem;
    font-size: 3rem;
    color: #fff;
    background-color: #f87270;
}


.text-wriiten-of-team-member-transport-holiday{
    margin-top: 3.5rem;
    font-size: 1.2rem;
    color: #565656;
    padding: 0 1.5rem;

}

.social-media-button-in-wow-holidays-transport-team{

    margin-top: 1rem;
    text-align: left !important;
    font-size: 3rem;
    /* clip-path: circle(50% at 50% 50%);
    -webkit-clip-path: circle(50% at 50% 50%); */
    display: flex;
    justify-content: space-between;
    

}


.social-media-button-in-wow-holidays-transport-team-icon{
    color: #f87270c5;
    /* background-color: #f87270c5; */
    display: inline-block;
    margin-left: 2rem;
    padding: 3px;

}




/* member 1 team */

.meet-the-team-transport-holiday-image-member1{
    width: 100%;
    transform: translateX(4.7rem) scale(1.4);
    backface-visibility: hidden !important;
    transition: all .3s;
    
    
}


.meet-the-team-transport__shape:hover .meet-the-team-transport-holiday-image-member1{
    transform: translateX(.6rem) scale(1);
    backface-visibility: hidden !important; 
    
}




/* media queries for our team section */
@media(max-width:700px){



    .holiday-transport-our-team-wow-holiday-our-container {
        background-image: url(/IMAGES/city-images/wow-transport/our-team/our-team.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0% !important;
        position: relative;
        width: 100%;
        padding-bottom: 50%;
      margin-top: 0px;
      bottom: 0;
        margin-bottom: 1rem;
        background-attachment: scroll;
        
        
      }
    
    
      .holiday-transport-our-team-our-content {
        font-size: 120% !important;
        color: #fff;
        background-color: rgba(0,0,0,0.25);
        width: 100%;
        left: 0 !important;
        
        top: 0;
        height: 100%;
        
      }
      
      .holiday-transport-our-team-our-content {
        display: -moz-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 60px;
        right: 30px;
      }
    
    
      .holiday-transport-our-team-wow-holiday-home-our-story {
        padding: 1% 1%;
        margin-top: 2rem;
      }
    
    
      .holiday-transport-our-team-wow-holiday-our-team-heading {
        font-family: wow-bold !important;
      font-size: 42px !important;
      color: #ffffff;
      text-align: center !important;
      font-weight: 700;
      text-shadow: 2px 2px 0 rgba(0,0,0,.6);
    
      font-style: italic !important;
      margin-left: -65px !important;
      letter-spacing: 5px;
      line-height: 1 !important;
      }
    
    
    
    
    
    
    
    
    
    
    
    /* professional team context */
    
    .professional-team-heading-transport-holiday{
        padding: 5rem 0rem;
        font-size: 2.3rem;
        font-weight: 700;
        font-family: wow-bold;
        color: #000000;
    
        
    
    }
    
    .professional-team-context-transport-holiday{
        padding: 0rem 0rem 0 0;
        font-size: 1.3rem;
        font-weight: 500;
        font-family: wow-regular;
        color: #565656;
    
    }
    

    .meet-the-team-transport__shape{
        width: 77%;
        height: 18rem;
        shape-outside: circle(50% at 50% 50%);
        -webkit-shape-outside: circle(50% at 50% 50%);
        clip-path: circle(50% at 50% 50%);
        -webkit-clip-path: circle(50% at 50% 50%);
        position: relative;
        margin-bottom: 2rem;
        left: 6%;
        margin-right:0 !important ;
        
    }
    

    .meet-the-team-transport-holiday-image-member1{
        width: 85%;
        transform: translateX(4.7rem) scale(1.4);
        backface-visibility: hidden !important;
        transition: all .3s;
    }
    
    
    .meet-the-team-transport__shape:hover .meet-the-team-transport-holiday-image-member1{
        transform: translateX(.6rem) scale(1);
        backface-visibility: hidden !important; 
        
    }

    .occupation-of-team-member-transport-holiday{
    
        margin-top: 1rem;
        font-size: 3rem;
        color: #fff;
        background-color: #f87270;
    }
    
    .text-wriiten-of-team-member-transport-holiday{
        margin-top: 2.5rem;
        font-size: 1.4rem;
        color: #565656;
        padding: 0 .5rem;
    
    }
    
    .social-media-button-in-wow-holidays-transport-team{
    
        margin-top: 1rem;
        text-align: left !important;
        font-size: 3rem;
        /* clip-path: circle(50% at 50% 50%);
        -webkit-clip-path: circle(50% at 50% 50%); */
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 2rem;
    
    }
    
    
    .social-media-button-in-wow-holidays-transport-team-icon{
        color: #f87270c5;
        /* background-color: #f87270c5; */
        display: inline-block;
        margin-left: -2rem;
        padding: 3px;
    
    }
    
    
    

    
 
    
  
   
    


}



































.heading-our-team-section-transport-holiday{
    font-size: 4.5rem;
    font-weight: 700;
    margin-top: -2rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: #f87370;
    letter-spacing: 2px;
    transition: all .2s;
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;


    
}

.heading-meet-the-team-section-transport-holiday{
    font-size: 3.8rem;
    font-weight: 700;
    margin-top: 5rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: #f87370;
    letter-spacing: 2px;
    transition: all .2s;
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;

}














.three-overlapperd-images-transport-below-features-holiday{
    position: relative !important;
}



  
.composition-transport{

    
    margin-top: 48rem;
    
    }
    
    
    .composition__photo-transport{
        width: 55%;
        box-shadow:0 1.5rem 4rem #0000002c ;
        position: absolute;
        border-radius: 2px;
        z-index: 10;
        transition: all .2s;
        outline-offset: 1.2rem;
        margin-top: 8rem;
    }
    
    
    
    .composition__photo-transport:hover{
        outline: 1rem solid #f87370;
        transform: scale(1.05) translate(-0.5rem);
        box-shadow: 0 2.5rem 4rem #00000077;
        z-index: 20;
    }
    
    
    
    
    .composition__photo--p1-transport{
        left: 0;
        top: -3rem;
    
    }
    
    .composition__photo--p2-transport{
        right: 0;
        top: 2rem;
        
    }
    
    .composition__photo--p3-transport{
        left: 20%;
        top:10rem;
    
        
    }
    
    
    
    
    
    
    .composition-transport:hover .composition__photo-transport:not(:hover){
        transform: scale(.95);
        
    }
    




    /* media queries for pics */
    @media (max-width:700px){
        .composition-transport{

    
            margin-top: 30rem;
            
            }


            .composition__photo-transport{
                width: 65%;
                box-shadow:0 1.5rem 4rem #0000002c ;
                position: absolute;
                border-radius: 2px;
                z-index: 10;
                transition: all .2s;
                outline-offset: 1.2rem;
                margin-top: 8rem;
            }
            
            
            
            .composition__photo-transport:hover{
                outline: 1rem solid #f87370;
                transform: scale(1.05) translate(-0.5rem);
                box-shadow: 0 2.5rem 4rem #00000077;
                z-index: 20;
            }
            
            
            
            
            .composition__photo--p1-transport{
                left: 1rem;
                top: -4rem;
            
            }
            
            .composition__photo--p2-transport{
                right: 1rem;
                top: 0rem;
                
            }
            
            .composition__photo--p3-transport{
                left: 15%;
                top:5rem;
            
                
            }





    }
    



























    /* feature box in transport */







    .feature-box-in-wow-transport-holiday{
        background-color: #f87370;
        font-size: 5.2rem;
        padding: 1.8rem;
        text-align: center;
        border-radius: 4px;
        box-shadow: 0 1.7rem 3rem #000000;
        transition: transform .3s;
        margin-top: 3rem;
        
        
    
    }
    
    
    .feature-box-in-wow-transport-holiday:hover{
        transform: translateY(-1.5rem) scale(1.01) ;
    
    }
    
    
    
    
    .section-features-nature-wow-transport-holiday > *{
        transform: skewY(4deg);
    }
    
    
    
    .feature-box__icon-wow-transport{
        font-size: 6rem;
        margin-bottom: .5rem;
        display: inline-block;
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0.623), #fff);
        -webkit-background-clip: text;
        color: transparent;
    }
    
    
    .header-inside-grid-wow-transport-features-box{
        font-size: 1.4rem !important;
        font-weight: 700;
        text-transform: uppercase;
        font-family: wow-holiday-bold;
        color: #fff;
        /* background-image: linear-gradient(to right,#000000 , #0000009c);
        -webkit-background-clip: text;
        color: transparent;
        display: inline-block; */
    }
    
    
    .feature__box-text-wow-transport{
            font-size: 1.3rem !important;
            font-family: wow-regular;
            color: #fff;
         
    
    }









    
















    Button.button-featured-holiday-transport{
        width: 28%;
        background-color: #f87370 !important;
        color: #fff !important;
        font-size: 22px !important;
        padding: 0 !important;
        border-radius: 10px !important;
        margin-right: 3rem;
        border-color: #f87370 !important;
        outline-color: #f87370 !important;
        margin-bottom: 4rem !important; 
    
      }
    
    
      .button-featured-holiday-transport:hover{
        /* background-color: #fff !important; */
        color: white !important;
      }

      /* media quries for button */

      @media (max-width:700px){
        Button.button-featured-holiday-transport{
            width: 30%;
            background-color: #f87370 !important;
            color: #fff !important;
            font-size: 12px !important;
            padding: 0 !important;
            border-radius: 10px !important;
            margin-right: 1rem;
            border-color: #f87370 !important;
            outline-color: #f87370 !important;
            margin-bottom: 4rem !important; 
        
          }
        
        
          .button-featured-holiday-transport:hover{
            /* background-color: #fff !important; */
            color: white !important;
          }

      }











   




/* our team heading */

.our-team-heading-transport{
    font-size: 32px;
    color: #565656;
    font-weight: 700;
    font-family: wow-bold;
    padding: 2rem 0;
}
.button-section-team-transport-holiday{
    border-bottom: 1px solid grey;
    padding: 0 !important;
    margin: 0 !important;
    display: flex;
    justify-content: space-between;
    text-align: center !important;

    
}




.button-text-wow-transport-grid-grid-section-pictures:link,
.button-text-wow-transport-grid-grid-section-pictures:visited{
    font-size: 2.5rem;
    color: #565656 !important;
    display: inline-block ;
    text-decoration: none;
    
    padding: 2px;
    font-family: wow-regular;
    margin-right: .5rem;


}




.button-text-wow-transport-grid-grid-section-pictures:hover{
    background-color: #f87370 !important;
    color: #ffffff !important; 
    box-shadow: 0 1rem 2rem #000000;
     transform: translateY(3px);
    border-bottom: 2px solid #f87370 !important;
    padding: 4px;
    display: inline-block ;

}

.button-text-wow-transport-grid-grid-section-pictures:active {
    box-shadow: 0 .5rem 1rem #000000;
    transform: translateY(3px);
    padding: 4px;
    color: #000000 !important;
    background-color: blue !important;


}


/* .button-text-wow-transport-grid-grid-section-pictures.active {
    color: blue !important;
    background-color: blue !important;


} */





/* team content */


.border-right-column-transport-team{
    border-right: 0px solid #5656566b;
    
}

.header-in-our-team-transport{
    margin: 2rem 0;
    font-size: 36px;
    color:  transparent;
    background-image: linear-gradient(to bottom left, #f87370, #f87370);
    -webkit-background-clip: text;
    font-weight: 700;
    font-family: wow-bold;
    border-bottom: 2px solid #f87370;
    display: inline-block;
    border-radius: 10px;

}

.header-context-in-our-team-transport{
    font-weight: 500;
    font-size: 1.4rem;
    font-family: wow-regular ;
    color: #565656e1;
}
















.composition-team-transport{

    position: relative;
    
    
    
    }
    
    
    .composition__photo-team-transport{
        width: 65%;
        box-shadow:0 1.5rem 4rem #0000002c ;
        position: absolute;
        border-radius: 2px;
        z-index: 10;
        transition: all .2s;
        outline-offset: 1.2rem;
    }
    
    
    
    .composition__photo-team-transport:hover{
        outline: 1rem solid #f87370;
        transform: scale(1.05) translate(-0.5rem);
        box-shadow: 0 2.5rem 4rem #00000077;
        z-index: 20;
    }
    
    
    
    
    .composition__photo--p1-team-transport{
        left: 1rem;
        top: 0.3rem;
    
    }
    
    .composition__photo--p2-team-transport{
        right: -4rem;
        top: 4rem;
        
    }
    
    .composition__photo--p3-team-transport{
        left: 15%;
        top: 9rem;
    
        
    }
    
    
    
    
    
    
    .composition-team-transport:hover .composition__photo-team-transport:not(:hover){
        transform: scale(.95);
        
    }








    /* media-queries for pics in team transport*/


    @media(max-width:700px){
        

.composition-team-transport{

    position: relative;
    margin-bottom: 18rem;
    
    
    
    }
    
    
    .composition__photo-team-transport{
        width: 70%;
        box-shadow:0 1.5rem 4rem #0000002c ;
        position: absolute;
        border-radius: 2px;
        z-index: 10;
        transition: all .2s;
        outline-offset: 1.2rem;
    }
    
    
    
    .composition__photo-team-transport:hover{
        outline: 1rem solid #f87370;
        transform: scale(1.05) translate(-0.5rem);
        box-shadow: 0 2.5rem 4rem #00000077;
        z-index: 20;
    }
    
    
    
    
    .composition__photo--p1-team-transport{
        left: 1rem;
        top: 2rem;
    
    }
    
    .composition__photo--p2-team-transport{
        right: 1rem;
        top: 4.5rem;
        
    }
    
    .composition__photo--p3-team-transport{
        left: 15%;
        top: 8rem;
    
        
    }
    
    
    
    
    
    
    .composition-team-transport:hover .composition__photo-team-transport:not(:hover){
        transform: scale(.95);
        
    }
    }

















































    @media(max-width:700px){
        

  .header-image-in-new-design-transport{
    /* width: 100% !important;
    
    max-height: 400px !important; */
    background-image: linear-gradient(to bottom, #00000000, #00000062), url('/IMAGES/city-images/wow-transport/bg-image2.jpg');
    background-size: cover;
    background-position: center;
    
    /* clip-path: polygon(0 0,100% 0, 100% 420px,0 100%); */
    position: relative;



    /* background-repeat: no-repeat; */
    
    background-position: 23% !important;
    
    width: 100%;
    padding-bottom: 50% !important;
  
  
    margin-bottom: 20px;
    background-attachment: scroll;
   
   
    
   

}





.logo-inside-header-of-new-design-page-transport{
    position: absolute;
    top: 10px;
    left: 10px;

    
}

.logo-inside-header-of-new-design-page--wow-transport{
    position: absolute;
    top: 10px !important;
    right: 10px !important;
    text-align: right !important; 
}


.image-logo-inside-header-of-new-design-page-wow-transport{
    height: 30px;
    text-align: right;
}


.image-logo-inside-header-of-new-design-page-transport{
    height: 40px;
    
}



.text-box-inside-background-image-of-new-design-transport{
    position: absolute;
    top: 63% !important;
    left: 50%;
    transform: translate(-50%, -50%);

}


.heading-primary-transport{
    color: #fff;
    text-transform: uppercase;
    backface-visibility: hidden;
    

}


.heading-primary-main-transport{
    display: block;
    text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.815);
    
    font-size: 22px;
    font-weight: 400;
    letter-spacing:1px;
    animation-name: moveInleft;
    animation-duration: 1s;

}




.heading-primary-sub-transport{
    display: none;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 6.4px;
    animation-name: moveInright;
    animation-duration: 1s;
    text-shadow: 2px 2px 0 rgba(0,0,0,.4);


}



.holiday-transport-wow-holiday-our-team-heading-below-text{
    font-family: wow-regular !important;
    font-size: 12px !important;
    color: #ffffff;
    text-align: center !important;
    font-weight: 300;
    padding: 1px 30px;
    text-shadow: 2px 2px 0 rgba(0,0,0,.5);
    margin-left: -15px !important;
    letter-spacing: 0px;
    line-height: 1 !important;
    display: none;

  }


  .image-tourism-wow-transport{
    margin-top: -2rem;
    margin-bottom: 1.7rem;
    width: 100%;
 }







 /* header image below content */

 .heading-secondary-section-transport-holiday{
    font-size: 2rem;
    font-weight: 700;
    margin-top: 1rem;
    margin-bottom: 1.7rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: #f87370;
    letter-spacing: 1px;
    transition: all .2s;
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;


    
}

.u-margin-bottom-second-heading-transport{
    margin-bottom: 3rem;
}

.feature-box-in-wow-transport-holiday{
    background-color: #f87370;
    font-size: 5.2rem;
    padding: 1.8rem;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 1rem 1.5rem #000000;
    transition: transform .3s;
    margin: 3rem 0;
    
    

}


.heading-meet-the-team-section-transport-holiday{
    font-size: 3rem;
    font-weight: 700;
    margin-top: 5rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: #f87370;
    letter-spacing: 2px;
    transition: all .2s;
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;

}




    }










    .text-written-below-we-offer-transport-holiday{
        font-size: 26px;
        font-family: wow-regular;
        padding: 0 20rem;
        color: #565656;
        margin-top: 2rem;

    }










































/* transport and serives content */






/* bg image */

    .holiday-transport-our-services-wow-holiday-our-container {
        background-image: url(/IMAGES/city-images/wow-transport/transportservices/bg-image-transport.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0% !important;
        position: relative;
        width: 100%;
        padding-bottom: 35%;
      margin-top: 0px;
      bottom: 0;
        margin-bottom: 5rem;
        
        
      }
    
    
      .holiday-transport-our-services-our-content {
        font-size: 120% !important;
        color: #fff;
        background-color: rgba(0,0,0,0.25);
        width: 100%;
        left: 0 !important;
        
        top: 0;
        height: 100%;
        
      }
      
      .holiday-transport-our-services-our-content {
        display: -moz-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 60px;
        right: 30px;
      }
    
    
      .holiday-transport-our-services-wow-holiday-home-our-story {
        padding: 2% 21%;
        margin-top: 8rem;
      }
    
    
      .holiday-transport-our-services-wow-holiday-our-team-heading {
        font-family: wow-bold !important;
      font-size: 52px !important;
      color: #ffffff;
      text-align: center !important;
      font-weight: 700;
      text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.466);
    
      font-style: italic !important;
      margin-left: -65px !important;
      letter-spacing: 5px;
      line-height: 1 !important;
      }
    



      .heading-secondary-section-we-offer-transport-holiday{
        font-size: 3.5rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87270a8 , #ff615e);
        -webkit-background-clip: text;
        color: #f87370;
        letter-spacing: 2px;
        transition: all .2s;
        background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
        display: inline-block;
        background-size: 100% 5px;
        background-position: 0 100%;
        background-repeat: no-repeat;
    
    
        
    }
    .u-margin-bottom-second-heading-transport-we-offer{
        margin-bottom: .7rem;
    }
    

      

      /* services features box */


      .services-box-transport{
        background-color: #fff;
        font-size: 5.2rem;
        padding: 1.8rem;
        text-align: center;
        border-radius: 4px;
        /* box-shadow: 0 .3rem .7rem #000000; */
        transition: transform .3s;
        margin-top: 0rem;
        
        
    
    }
    /* services icons */


    .lg-icon, 
    .circle-icon {
        width: 90px !important;
        height: 90px !important;
        line-height: 85px !important;
        font-size: 50px !important;
        margin-bottom: 1rem ;
       
    }


    .primary-icon-filled {
        background: #f87370;
        color: #fff;
    border: none;
    }

    .circle-icon{
        border-radius: 50%;
    }
    
    
    /* .feature-box-in-wow-transport-our-services-holiday:hover{
        transform: translateY(-1.5rem) scale(1.01) ;
    
    } */
    
    
    
    
    .feature-box__icon-wow-transport-our-services{
        font-size: 6rem;
        margin-bottom: .5rem;
        display: inline-block;
        background-image: linear-gradient(to right, #000000, #000000bb);
        -webkit-background-clip: text;
        color: transparent;
    }
    
    
    .header-inside-grid-wow-transport-our-services-features-box{
        font-size: 1.4rem !important;
        font-weight: 700;
        text-transform: uppercase;
        font-family: wow-holiday-bold;
        color: #000000;
        /* background-image: linear-gradient(to right,#000000 , #0000009c);
        -webkit-background-clip: text;
        color: transparent;
        display: inline-block; */
    }
    
    
    .feature__box-text-wow-transport-our-services{
            font-size: 1.3rem !important;
            font-family: wow-regular;
            color: #000000;
         
    
    }

















    .section-transport-services-wow-holiday {
        display: block;
    }


    .fadeInLeft {
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
    }




    @keyframes fadeInLeft{
        0%{
            opacity: 0;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }

        100%{
            opacity: 1;
    -webkit-transform: none;
    transform: none;
        }
    }

    .bg-contrast-variant-1 {
        background: #f87270;
        color: #FFFFFF;
    }


    .box-lg_left, .box-lg_body {
        display: table-cell;
    }


    .bus-image-transport-service-wow-holiday{
        max-width: none;
    }



    .bus-image-transport-service-wow-holiday {
        display: inline-block;
        height: auto;
        vertical-align: middle;
    border-style: none;
    }

    .box-lg_body {
        vertical-align: middle;
        padding: 40px 40px;
    }

    .text-default-transport-services-holiday {
        color: #fff;
        font-size: 25px;
        line-height: 1.433333;
        text-transform: uppercase;
        font-weight: 700;
        font-family: wow-holiday-bold;
        margin-bottom: 0.5rem;

    }



    .text-primary-transport-services-holiday{
        color: #1c5e6d !important;
        font-weight: 400;
        font-family: wow-regular;
        font-size: 25px;
        line-height: 1.433333;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }


     .text-paragrapgh-primary-transport-services-holiday{
        margin-top: 16px;
        line-height: 1.4;
        font-size: 19px;
    }




    /* media queries bus  */

    @media(max-width:700px){
         .section-transport-services-wow-holiday {
        display: none;
    }


    .fadeInLeft {
        -webkit-animation-name: fadeInLeft;
        animation-name: fadeInLeft;
    }




    @keyframes fadeInLeft{
        0%{
            opacity: 0;
            -webkit-transform: translate3d(-100%, 0, 0);
            transform: translate3d(-100%, 0, 0);
        }

        100%{
            opacity: 1;
    -webkit-transform: none;
    transform: none;
        }
    }

    .bg-contrast-variant-1 {
        background: #f87270;
        color: #FFFFFF;
    }


    .box-lg_left, .box-lg_body {
        display: table-cell;
    }


    .bus-image-transport-service-wow-holiday{
        max-width: none;
    }



    .bus-image-transport-service-wow-holiday {
        display: inline-block;
        height: auto;
        vertical-align: middle;
    border-style: none;
    }

    .box-lg_body {
        vertical-align: middle;
        padding: 40px 40px;
    }

    .text-default-transport-services-holiday {
        color: #fff;
        font-size: 25px;
        line-height: 1.433333;
        text-transform: uppercase;
        font-weight: 700;
        font-family: wow-holiday-bold;
        margin-bottom: 0.5rem;

    }



    .text-primary-transport-services-holiday{
        color: #1c5e6d !important;
        font-weight: 400;
        font-family: wow-regular;
        font-size: 25px;
        line-height: 1.433333;
        text-transform: uppercase;
        margin-bottom: 0.5rem;
    }


     .text-paragrapgh-primary-transport-services-holiday{
        margin-top: 16px;
        line-height: 1.4;
        font-size: 19px;
    }
    }




    @media (min-width: 768px){
.text-md-left {
    text-align: left !important;
}

    }
















    .heading-secondary-section-our-we-services-transport-holiday{
        font-size: 3.5rem;
        font-weight: 700;
        margin-top: 2rem;
        margin-bottom: 5rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87270a8 , #ff615e);
        -webkit-background-clip: text;
        color: #f87370;
        letter-spacing: 2px;
        transition: all .2s;
        background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
        display: inline-block;
        background-size: 100% 5px;
        background-position: 0 100%;
        background-repeat: no-repeat;
    
    
        
    }


































    .section-md-bottom-90 {
        padding-bottom: 90px;
    }

    .section-md-top-transportservices {
        padding-top: 65px;
    }


    .section-skew {
        position: relative;
    }


    .bg-dark-blue {
        background: #f87370;
        fill: #f87370;
    }

    .context-dark{
        color: #fff;
    }

  
    

    .section-skew-wide  .skew-block {
        width: 62.24%;
    }
    .section-skew .skew-block {
        display: block;
    }

   

    .section-skew .skew-block {
        position: absolute;
        background: inherit;
        top: -28px;
        right: -1%;
        /* width: 39.8%; */
        height: 30px;
        bottom: 28px !important;
        /* -webkit-transform: skew(-34deg); */
        transform: skew(-34deg);
    }


    .bg-white {
        background: #fff;
        fill: #fff;
    }


    .text-md-left {
        text-align: left;
    }





    /* .shell-transport-holiday, .shell-wide {
        max-width: 170px;
    } */



    .services-overview-heading-transport-holiday{
        color: #fff;
        font-size: 36px;
        font-family: wow-bold;
        margin-bottom: 21px;
     line-height: 1.33333;
     text-transform: uppercase;
     letter-spacing: 0.04em;
        }
 
        hr.bg-primary {
         background: #fff !important;
         fill: #fff !important;
     }

     .hr-md-left-0 {
        margin-left: 0 !important;
    }

    hr.divider-lg {
        width: 80px;
        height: 5px;
    }
    hr {
        box-sizing: content-box;
    }

   hr.divider {
        /* width: 40px;
        height: 2px; */
        padding: 0;
        margin: 21px auto 21px;
        background: #b7b7b7;
        border: none;
    }


    .offset-top-50 {
        margin-top: 50px;
    }


    .range-xs-center {
        -ms-flex-pack: center;
        justify-content: center;
    }


    .range {
        /* display: -ms-flexbox; */
        display: flex !important;
        /* -ms-flex: 0 1 auto; */
        flex: 0 1 auto !important;
        /* -ms-flex-direction: row; */
        flex-direction: row !important;
        /* -ms-flex-wrap: wrap; */
        flex-wrap: wrap !important;
    }

    .range {
        margin-left: -15px;
        margin-right: -15px;
    }

    .range > .cell-md-6 {
        -ms-flex-preferred-size: 50%;
        flex-basis: 50%;
        max-width: 50%;
    }


    .range > [class*='cell'] {
       
        flex: 0 0 auto;
        /* -ms-flex-preferred-size: 100%; */
    }


    [class*="cell-"] {
        padding-left: 15px;
        padding-right: 15px;
    }


    .unit-md-horizontal {
        /* -ms-flex-direction: row; */
        flex-direction: row !important;
    }

    .unit {
        /* display: -ms-flexbox; */
        display: flex;
        /* -ms-flex: 0 1 100%; */
        flex: 0 1 100%;
        -ms-flex-direction: column;
        flex-direction: column;
    }


    .unit-left {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        max-width: 100%;
    }

    .unit-md-horizontal > .unit-left + .unit-body {
        padding-top: 0;
        padding-left: 20px;
    }

    .text-bold{
        font-weight: 700;
    }


    .headings-inside-shell-transport-holiday {
        line-height: 1.36364;
        font-size: 22px !important;
        margin-bottom: 10px;
        font-family: "Hind", Helvetica, Arial, sans-serif;
        letter-spacing: 0.02em;
    }


    .text-inside-shell-our-services-transport-holiday{
        margin-top: 10px;
        margin: 0 0 12px;
    }








    /* media queries section overview */

    @media(max-width:700px){
        .section-md-bottom-90 {
            padding-bottom: 90px;
        }
    
        .section-md-top-transportservices {
            padding-top: 55px;
        }
    
    
        .section-skew {
            position: relative;
        }
    
    
        .bg-dark-blue {
            background: #f87370;
            fill: #f87370;
        }
    
        .context-dark{
            color: #fff;
        }
    
      
        
    
        .section-skew-wide  .skew-block {
            width: 0;
        }
        .section-skew .skew-block {
            display: block;
        }
    
       
    
        .section-skew .skew-block {
            position: absolute;
            background: inherit;
            top: -28px;
            right: -1%;
            /* width: 39.8%; */
            height: 30px;
            bottom: 28px !important;
            /* -webkit-transform: skew(-34deg); */
            transform: skew(-34deg);
        }
    
    
        .bg-white {
            background: #fff;
            fill: #fff;
        }
    
    
        .text-md-left {
            text-align: left;
        }
    
    
    
    
    
        /* .shell-transport-holiday, .shell-wide {
            max-width: 170px;
        } */
    
    
    
        .services-overview-heading-transport-holiday{
            color: #fff;
            font-size: 28px;
            font-family: wow-bold;
            margin-bottom: 21px;
         line-height: 1.33333;
         text-transform: uppercase;
         letter-spacing: 0.04em;
            }
     
            hr.bg-primary {
             background: #fff !important;
             fill: #fff !important;
         }
    
         .hr-md-left-0 {
            margin-left: 0 !important;
        }
    
        hr.divider-lg {
            width: 140px;
            height: 5px;
        }
        hr {
            box-sizing: content-box;
        }
    
       hr.divider {
            /* width: 40px;
            height: 2px; */
            padding: 0;
            margin: 21px auto 21px;
            background: #b7b7b7;
            border: none;
        }
    
    
        .offset-top-50 {
            margin-top: 50px;
        }
    
    
        .range-xs-center {
            -ms-flex-pack: center;
            justify-content: center;
        }
    
    
        .range {
            /* display: -ms-flexbox; */
            display: flex !important;
            /* -ms-flex: 0 1 auto; */
            flex: 0 1 auto !important;
            /* -ms-flex-direction: row; */
            flex-direction: column !important;
            /* -ms-flex-wrap: wrap; */
            flex-wrap: wrap !important;
        }
    
        .range {
            margin-left: -15px;
            margin-right: -15px;
        }
    
        .range > .cell-md-6 {
            -ms-flex-preferred-size: 90%;
            flex-basis: 90%;
            max-width: 90%;
        }
    
    
        .range > [class*='cell'] {
           
            flex: 0 0 auto;
            /* -ms-flex-preferred-size: 100%; */
        }
    
    
        [class*="cell-"] {
            padding-left: 15px;
            padding-right: 0px;
        }
    
    
        .unit-md-horizontal {
            /* -ms-flex-direction: row; */
            flex-direction: row !important;
        }
    
        .unit {
            /* display: -ms-flexbox; */
            display: flex;
            /* -ms-flex: 0 1 100%; */
            flex: 0 1 100%;
            -ms-flex-direction: column;
            flex-direction: column;
        }
    
    
        .unit-left {
            -ms-flex: 0 0 auto;
            flex: 0 0 auto;
            max-width: 100%;
        }
    
        .unit-md-horizontal > .unit-left + .unit-body {
            padding-top: 0;
            padding-left: 20px;
        }
    
        .text-bold{
            font-weight: 700;
        }
    
    
        .headings-inside-shell-transport-holiday {
            line-height: 1.36364;
            font-size: 22px !important;
            margin-bottom: 10px;
            font-family: "Hind", Helvetica, Arial, sans-serif;
            letter-spacing: 0.02em;
            margin-top: 0.6rem;
        }
    
    
        .text-inside-shell-our-services-transport-holiday{
            margin-top: 10px;
            margin: 0 0 12px;
        }









        .section-skew-wide  .skew-block {
            width: 0;
        }
        .section-skew .skew-block {
            display: none;
        }
    
       
    
        .section-skew .skew-block {
            position: absolute;
            background: inherit;
            top: -28px;
            right: -1%;
            /* width: 39.8%; */
            height: 30px;
            bottom: 28px !important;
            /* -webkit-transform: skew(-34deg); */
            transform: skew(-34deg);
        }
    
    }


































































/* portion of transport in-guidance transport services page */

.heading1-transport-section-transport-services-holiday{
    font-size: 3.5rem;
    font-weight: 700;
    margin-top: 2rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270 , #ff615e7a);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;
    /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat; */


    
}
.u-margin-bottom-second-heading-transport-we-offer{
    margin-bottom: .7rem;
}




/* section 1 row column */


.three-overlapperd-images-heading1-transport-section-holiday{
    position: relative !important;
}



  
.composition-transport-heading1-transport-section{

    
    margin-top: 0rem;
    
    }
    
    
    .composition__photo-transport-heading1-transport-section{
        width: 70%;
        box-shadow:0 1.5rem 4rem #0000002c ;
        position: absolute;
        border-radius: 2px;
        z-index: 10;
        transition: all .2s;
        outline-offset: 1.2rem;
        margin-top: 8rem;
    } 
    
    
    
    .composition__photo-transport-heading1-transport-section:hover{
        outline: 1rem solid #f87370;
        transform: scale(1.05) translate(-0.5rem);
        box-shadow: 0 2.5rem 4rem #00000077;
        z-index: 20;
    }
    
    
    
    
    .composition__photo--p1-transport-heading1-transport-section{
        left: -2rem;
        top: -5rem;
    
    }
    
    .composition__photo--p2-transport-heading1-transport-section{
        right: 0;
        top: -2rem;
        
    }
    
    .composition__photo--p3-transport-heading1-transport-section{
        left: 15%;
        top: 4rem;
    
        
    }
    
    
    
    
    
    
    .composition-transport-heading1-transport-section:hover .composition__photo-transport-heading1-transport-section:not(:hover){
        transform: scale(.95);
        
    }




    .context-written-in-heading1-transport-section{
        padding: 5rem 2rem;

    }

    .context-heading-in-heading1-transport-section{
        font-size: 22px;
        font-family: wow-bold;
        margin-bottom: 1rem;
        display: inline-block;
        background-image: linear-gradient(to right,#f87270 , #ff615e7a);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 1px;
        
    }

    .context-text-written-in-heading1-transport-section{
        padding: .6rem 2rem;
        font-size: 14px;
        font-family: wow-regular;
        color: #565656;

    }




    /* portion of transport in-guidance transport services page media queries*/
@media(max-width:700px){
.heading1-transport-section-transport-services-holiday{
    font-size: 3rem;
    font-weight: 700;
    margin-top: 2rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270 , #ff615e7a);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 0px;
    transition: all .2s;
    /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat; */


    
}
.u-margin-bottom-second-heading-transport-we-offer{
    margin-bottom: .7rem;
}




/* section 1 row column */


.three-overlapperd-images-heading1-transport-section-holiday{
    position: relative !important;
}



  
.composition-transport-heading1-transport-section{

    
    margin-top: 25rem;
    
    }
    
    
    .composition__photo-transport-heading1-transport-section{
        width: 55%;
        box-shadow:0 1.5rem 4rem #0000002c ;
        position: absolute;
        border-radius: 2px;
        z-index: 10;
        transition: all .2s;
        outline-offset: 1.2rem;
        margin-top: 8rem;
    }
    
    
    
    .composition__photo-transport-heading1-transport-section:hover{
        outline: 1rem solid #f87370;
        transform: scale(1.05) translate(-0.5rem);
        box-shadow: 0 2.5rem 4rem #00000077;
        z-index: 20;
    }
    
    
    
    
    .composition__photo--p1-transport-heading1-transport-section{
        left: 2rem;
        top: -5rem;
    
    }
    
    .composition__photo--p2-transport-heading1-transport-section{
        right: 1rem;
        top: -2rem;
        
    }
    
    .composition__photo--p3-transport-heading1-transport-section{
        left: 15%;
        top: 2rem;
    
        
    }
    
    
    
    
    
    
    .composition-transport-heading1-transport-section:hover .composition__photo-transport-heading1-transport-section:not(:hover){
        transform: scale(.95);
        
    }




    .context-written-in-heading1-transport-section{
        padding: 1rem 2rem;

    }

    .context-heading-in-heading1-transport-section{
        font-size: 22px;
        font-family: wow-bold;
        margin-bottom: 1rem;
        display: inline-block;
        background-image: linear-gradient(to right,#f87270 , #ff615e7a);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 1px;
        
    }

    .context-text-written-in-heading1-transport-section{
        padding: .6rem 2rem;
        font-size: 14px;
        font-family: wow-regular;
        color: #565656;

    }
}








































@media(max-width:700px){

    .text-written-below-we-offer-transport-holiday{
        font-size: 20px;
        font-family: wow-regular;
        padding: 0 2rem;
        color: #565656;
        margin-top: 2rem;

    }


    .holiday-transport-our-services-wow-holiday-our-container {
        background-image: url(/IMAGES/city-images/wow-transport/transportservices/bg-image-transport.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0% !important;
        position: relative;
        width: 100%;
        padding-bottom: 58%;
      margin-top: 0px;
      bottom: 0;
        margin-bottom: 5rem;
        background-position: 70%;
        
        
        
      }
    
    
      .holiday-transport-our-services-our-content {
        font-size: 120% !important;
        color: #fff;
        background-color: rgba(0,0,0,0.25);
        width: 100%;
        left: 0 !important;
        
        top: 0;
        height: 100%;
        
      }
      
      .holiday-transport-our-services-our-content {
        display: -moz-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 60px;
        right: 30px;
      }
    
    
      .holiday-transport-our-services-wow-holiday-home-our-story {
        padding: 1% 11%;
        margin-top: 9rem;
      }
    
    
      .holiday-transport-our-services-wow-holiday-our-team-heading {
        font-family: wow-bold !important;
      font-size: 26px !important;
      color: #ffffff;
      text-align: center !important;
      font-weight: 700;
      text-shadow: 2px 2px 0 rgba(0, 0, 0, 0.466);
    
      font-style: italic !important;
      margin-left: -35px !important;
      letter-spacing: 5px;
      line-height: 1 !important;
      }






      .heading-secondary-section-we-offer-transport-holiday{
        font-size: 3rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87270a8 , #ff615e);
        -webkit-background-clip: text;
        color: #f87370;
        letter-spacing: 2px;
        transition: all .2s;
        background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
        display: inline-block;
        background-size: 100% 5px;
        background-position: 0 100%;
        background-repeat: no-repeat;
    
    
        
    }
    .u-margin-bottom-second-heading-transport-we-offer{
        margin-bottom: .7rem;
    }











}

































