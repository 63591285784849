@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }























/* header image */

.holiday-privacy-policy-wow-holiday-our-container {
    background-image: url(/IMAGES/city-images/wow-privacy/privacy-header.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% !important;
    position: relative;
    width: 100%;
    padding-bottom: 32%;
  margin-top: 0px;
  bottom: 0;
    margin-bottom: 10px;
    background-attachment: fixed;
    
    
    
  }


  .holiday-privacy-policy-our-content {
    font-size: 120% !important;
    color: #fff;
    background-color: rgba(0,0,0,0.25);
    width: 100%;
    left: 0 !important;
    
    top: 0;
    height: 100%;
    
  }
  
  .holiday-privacy-policy-our-content {
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 60px;
    right: 30px;
  }

  .holiday-privacy-policy-wow-holiday-home-our-story {
    padding: 2% 11% !important;
    margin-top: 2rem;
  }

  .holiday-privacy-policy-wow-holiday-our-team-heading {
    font-family: wow-bold !important;
  font-size: 52px !important;
  color: #ffffff;
  text-align: center !important;
  font-weight: 700;
  text-shadow: 5px 5px 0 rgba(0, 0, 0, 0.747);

  font-style: italic !important;
  margin-left: 0px !important;
  letter-spacing: 3px;
  line-height: 1 !important;
  }





  /* header image media queries*/
  @media(max-width:700px){

.holiday-privacy-policy-wow-holiday-our-container {
    background-image: url(/IMAGES/city-images/holiday-inspiration/slider1.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% !important;
    position: relative;
    width: 100%;
    padding-bottom: 55%;
  margin-top: 0px;
  bottom: 0;
    margin-bottom: 0px;
    background-attachment: scroll;
    
    
    
  }


  .holiday-privacy-policy-our-content {
    font-size: 120% !important;
    color: #fff;
    background-color: rgba(0,0,0,0.25);
    width: 100%;
    left: 0 !important;
    
    top: 0;
    height: 100%;
    
  }
  
  .holiday-privacy-policy-our-content {
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0px;
    right: 30px;
  }

  .holiday-privacy-policy-wow-holiday-home-our-story {
    padding: 1% 1%;
    margin-top: 0rem;
  }

  .holiday-privacy-policy-wow-holiday-our-team-heading {
    font-family: wow-bold !important;
  font-size: 32px !important;
  color: #ffffff;
  text-align: center !important;
  font-weight: 700;
  text-shadow: 2px 2px 0 rgba(0,0,0,.6);

  font-style: italic !important;
  margin-left: -5px !important;
  letter-spacing: 3px;
  line-height: 1 !important;
  }



  .privacy_and_policy {
    padding: 30px 0;
    /* text-align: left; */
    font-family: wow-bold;
    font-size: 24px !important;
    color: transparent;
    background: linear-gradient(to right, #f87370 , #f87270a8) ;
    margin-bottom: -.5rem !important;
    font-weight: 500;
    line-height: 1.2;
}


  }




















.privacy_and_policy1 {
    margin-top: 0px;
    margin-bottom: 50px;
}


.privacy_and_policy {
    padding: 50px 0;
    /* text-align: left; */
    font-family: wow-bold;
    font-size: 32px;
    color: transparent;
    background: linear-gradient(to right, #f87370 , #f87270a8) ;
    -webkit-background-clip: text;
    margin-bottom: -.5rem !important;
    font-weight: 500;
    line-height: 1.2;
}



.jss8 {
    height: auto;
    display: flex;
    flex-grow: 1;
    background-color: #fff;
}

.jss2 {
    border-right: 1px solid rgba(0, 0, 0, 0.12);
}


.jss9 {
    border-right: 1px solid rgba(0, 0, 0, 0.178);
}

.MuiTabs-vertical {
    flex-direction: column !important;
}

.MuiTabs-root {
    display: flex;
    overflow: hidden;
    min-height: 48px;
    /* max-height: 208px; */
    -webkit-overflow-scrolling: touch;
}

.MuiTabs-scrollable {
    /* overflow-y: scroll ; */
    scrollbar-width: none;
}

.MuiTabs-scroller {
    flex: 1 1 auto;
    display: inline-block;
    position: relative;
    white-space: nowrap;
}

.MuiTabs-flexContainerVertical {
    flex-direction: column !important;
}

.MuiTabs-flexContainer {
    display: flex;
}

.MuiTabs-scroller {
    white-space: nowrap ;
}


[type=button]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

.MuiTab-textColorInherit {
    color: inherit !important;
    opacity: 0.7 !important;
}

@media (min-width: 700px){
.MuiTab-root {
    font-size: 0.8125rem;
    min-width: 160px !important;
}


.MuiTab-root-button {
    font-size: 0.8125rem;
    min-width: 160px !important;
}

}


.MuiTab-root {
    padding: 6px 12px !important;
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;
    max-width: 264px;
    min-width: 72px;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em !important;
    text-transform: uppercase;
}

.MuiButtonBase-root {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.terms-and-conditions-tab-name {
    font-family: wow-medium!important;
    text-align: left!important;
    font-size: 14px!important;
}

.MuiTab-wrapper {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.MuiTab-wrapper {
    align-items: flex-start!important;
    justify-content: flex-start!important;
}

.MuiTab-wrapper {
    text-transform: none!important;
}

.MuiTouchRipple-root {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
}


.jss37-pivacypolicy {
    left: 0;
    width: 2px;
    height: 100%;
}

.jss34-pivacypolicy {
   /* width: 100%; */
   height: 48px !important;
   height: 2px;
   position: absolute !important;
   transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.jss36-pivacypolicy {
    background-color: #f87370!important;
}




:focus {
    outline: -webkit-focus-ring-color auto 1px ;
}

.on-focus-button-termcondition:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color;
}

/* 

.terms-and-conditions-tab-name:focus{
    color: rgb(189, 3, 3) !important;
}


.terms-and-conditions-tab-name:active{
    color: rgb(189, 3, 3) !important;
}

.terms-and-conditions-tab-name:visited{
    color: rgb(189, 3, 3) !important;
} */



/* 
.active-privacy-policy{
    background-color: black !important;
    color: #fff !important;
} */









/* paragraphs   */


.MuiTypography-body1 {
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
}

.MuiTypography-root {
    margin: 0;
}

@media (max-width: 7000px) and (min-width: 500px){
.helpTabs-avaiablel, .termsTabs {
    display: flex;
    justify-content: center;
    width: 80%;
}
}

.jss69 {
    padding: 20px !important;
}


.termsandcondtions_detail h4 {
    padding: 0 10px 0 0;
    margin-bottom: -2px;
    color: #565656;
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(20%,#f87370),color-stop(20%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 20%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(20%,#f87370),color-stop(20%,transparent));
    border-image: linear-gradient(90deg,#f87370 20%,transparent 0);
    border-image-slice: 1;
    font-size: 22px;
    font-family: wow-bold;
    text-transform: uppercase!important;
    text-align: left;
    font-weight: 500;
    line-height: 1.2;
}

.termsandcondtions_detail h3 {
  padding: 0 10px 0 0;
  margin-bottom: -2px;
  color: #565656;
  /* border-bottom: 4px solid #f87370;
  -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(20%,#f87370),color-stop(20%,transparent));
  -webkit-border-image: -webkit-linear-gradient(left,#f87370 20%,transparent 0);
  border-image: -webkit-gradient(linear,left top,right top,color-stop(20%,#f87370),color-stop(20%,transparent));
  border-image: linear-gradient(90deg,#f87370 20%,transparent 0);
  border-image-slice: 1; */
  font-size: 16px;
  font-family: wow-bold;
  text-transform: uppercase!important;
  text-align: left;
  font-weight: 500;
  line-height: 1.2;
}


.sub_details p {
    padding-top: 10px;
    font-family: wow-regular;
    font-size: 14px;
    margin-bottom: 1rem;
    text-align: justify;
}





ol.ordered-list-in-privacy-policy{
    margin-top: 0;
    margin-bottom: .1rem !important;
    list-style-type: disc !important;
    padding: 1rem 2rem !important;
    
    
   
}

li.terms-and-conditions-tab-name {
    display: list-item;
    text-align: -webkit-match-parent;
    list-style: inherit;
    margin-bottom: .1rem !important;
    
   
}













.right-of-user{
    display: flex;
    justify-content: space-between;
    
}

#icon-user, #icon-users{
    color: #f87370;

}

#icon-user2, #icon-users2{
    color: #f87370;

}

#icon-user3, #icon-users3{
    color: #f87370;

}





































.jss37-pivacypolicy-button {
    right: 0;
    width: 2px;
    height: 100%;
}

.jss34-pivacypolicy-button {
   /* width: 100%; */
   height: 48px !important;
   height: 2px;
   position: absolute !important;
   transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.jss36-pivacypolicy-button {
    background-color: #000000!important;
}

.MuiButtonBase-root-button {
    color: inherit;
    border: 0;
    cursor: pointer;
    margin: 0;
    display: inline-flex;
    outline: 0;
    padding: 0;
    position: relative;
    align-items: center;
    user-select: none;
    border-radius: 0;
    vertical-align: middle;
    -moz-appearance: none;
    justify-content: center;
    text-decoration: none;
    background-color: transparent;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}


.MuiTab-root-button {
    padding: 6px 12px !important;
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;
    max-width: 264px;
    min-width: 72px;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.02857em !important;
    text-transform: uppercase;
}


Button.MuiTab-textColorInherit-button {
    color: inherit !important;
    opacity: 1 !important;
    background-color: #f87370 !important;
}
.terms-and-conditions-tab-name-button {
    font-family: wow-regular!important;
    text-align: left!important;
    font-size: 14px!important;
}
/* 
.MuiTab-wrapper-button {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}


.MuiTab-wrapper-button {
    align-items: flex-start!important;
    justify-content: flex-start!important;
}

.MuiTab-wrapper-button {
    text-transform: none!important;
} */

.icons-in-term-condition-heading-button{
    font-size: 16px;
    color: inherit;
    padding-right: .3rem;
}

.MuiTouchRipple-root-button {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    position: absolute;
    border-radius: inherit;
    pointer-events: none;
}

















































.sidenav {
    height: 0;
    width: 100%;
    position: relative;
    z-index: 1;
    top: 0;
    background-color: #111;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: 0.5s;
    padding-bottom: 60px;
    padding-top: 10px;
  }
  
  .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav a:hover {
    color: #f1f1f1;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0 ;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }
  
  @media screen and (max-height: 450px) {
    .sidenav {
      padding-top: 15px;
    }
    .sidenav a {
      font-size: 18px;
    }
  }
  
  
  .open-nav-help{
    color: #fff;
    padding: 28px 8px 8px 32px !important;
    margin-top: 12rem !important;
  }



















  
  
  @media screen and (max-height: 450px) {
    .sidenav-privacy-policy {
      padding-top: 15px;
    }


    .sidenav-privacy-policy a {
      font-size: 18px;
    }
  }


  @media (min-width: 700px) {
  .sidenav-privacy-policy-mobile-view{
    display: none !important;
  }
}

@media (max-width: 700px) {
  .sidenav-privacy-policy-mobile-view{
    display: "";
  }
}


@media (max-width: 700px) {
.muitab-in-mobile-view-privacy-policy{
  display: none !important;
}
}














@media (max-width: 700px) {

  .sidenav-privacy-policy {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(15, 15, 15);
    overflow-x: hidden;
    transition: 0.4s;
    padding-top: 60px;
  }
  
  .sidenav-privacy-policy button {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181 ;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav-privacy-policy button:hover {
    color: #f1f1f1;
  }
  
  .sidenav-privacy-policy .closebtn-privacypolicy {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .closebtn-privacypolicy {
    color: ivory !important;
  }





.MuiButtonBase-root {
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.terms-and-conditions-tab-name {
  font-family: wow-regular!important;
  text-align: left!important;
  font-size: 20px!important;
  color: #fff !important;
}

li.terms-and-conditions-tab-name {
  display: list-item;
  text-align: -webkit-match-parent;
  list-style: inherit;
  font-size: 13px!important;
  margin-bottom: .7rem !important;
  color: #000000 !important;
 
}


.MuiTab-root {
  font-size: 1.8125rem;
  min-width: 160px !important;
}

.MuiTab-root {
  padding: 6px 8px !important;
  padding-left: 1rem !important;
  overflow: auto;
  position: relative;
  font-size: 0.875rem;
  max-width: 264px;
  min-width: 72px;
  box-sizing: border-box;
  min-height: 60px;
  text-align: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.00857em !important;
  text-transform: uppercase;
}

.MuiTab-textColorInherit {
  opacity: 0.8 !important;
}

.privacy-menu-button-border{
  border-bottom: 5px solid #e7e7e7;
}

.privacy-menu-button{
  font-size: 28px;
  font-family: wow-medium;
  cursor: pointer;
  margin-left: 1rem;
}
}