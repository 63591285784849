@font-face {
    font-family: wow-bold;
    src: url('https://wowets.com/static/media/Poppins-Bold.otf') format(opentype) ;
  }  
@font-face {
    font-family: wow-regular;
    src: url('https://wowets.com/static/media/Poppins-Regular.de2dd933.otf' )  format('otf');
  }   


@media (min-width: 992px){
    .carousel-in-mobile-view-wow-holiday{
        display: none;
    }
}
    
   @media (min-width:700px) and (max-width:1125px){
    .heading-primary-main {
        display: block;
        font-size: 30px;
        font-weight: 400;
        letter-spacing: 5px;
        animation-name: moveInleft;
        animation-duration: 1s;
        margin-top: 25px;
    }  
}


@media (max-width:700px){
    .centered-in-mobile-view-holidaycards {
        position: absolute;
        font-size: 24px;
        bottom: 10%;
        left: 50%;
        transform: translate(-50%, 5%);
        font-weight: bold;
        color: #fff !important;
        background-color: #0000008a;
    }

    .images-in-carousel-in-wow-holiday{
        position: relative;

    }

    
  }


       