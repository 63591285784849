@font-face {
  font-family: wow-bold;
  src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
}
@font-face {
  font-family: wow-regular;
  src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
    format("otf");
}


@font-face {
    font-family: wow-medium;
    src: url('../fonts/poppins/Poppins-Medium.otf');
  }  
  @font-face {
    font-family: wow-regular;
    src: url('../fonts/poppins/Poppins-Regular.otf');
  }  
  @font-face {
    font-family: wow-black;
    src: url('../fonts/poppins/Poppins-Black.otf');
  }  
  @font-face {
    font-family: wow-holiday-bold;
    src: url('../fonts/poppins/Poppins-Bold.otf');
  }  
      @font-face {
    font-family: wow-boldItalic;
    src: url('../fonts/poppins/Poppins-BoldItalic.otf');
  } 
  @font-face {
    font-family: wow-semiBold;
    src: url('../fonts/poppins/Poppins-SemiBold.otf');
  } 
  @font-face {
    font-family: wow-italic;
    src: url('../fonts/poppins/Poppins-Italic.otf');
  }
  @font-face {
    font-family: wow-thin;
    src: url('../fonts/poppins/Poppins-Thin.otf');
  }

























  /* header image */

.holiday-blog-post-wow-holiday-our-container {
  background-image: url(/IMAGES/city-images/wow-blog/blog-cover.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0% !important;
  position: relative;
  width: 100%;
  padding-bottom: 35%;
margin-top: 0px;
bottom: 0;
  margin-bottom: 10px;
  background-attachment: fixed;
  
  
  
}


.holiday-blog-post-our-content {
  font-size: 120% !important;
  color: #fff;
  background-color: rgba(0,0,0,0.25);
  width: 100%;
  left: 0 !important;
  
  top: 0;
  height: 100%;
  
}

.holiday-blog-post-our-content {
  display: -moz-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60px;
  right: 30px;
}

.holiday-blog-post-wow-holiday-home-our-story {
  padding: 2% 11% !important;
  margin-top: 2rem;
}

.holiday-blog-post-wow-holiday-our-team-heading {
  font-family: wow-bold !important;
font-size: 52px !important;
color: #ffffff;
text-align: center !important;
font-weight: 700;
text-shadow: 5px 5px 0 rgba(0, 0, 0, 0.747);

font-style: italic !important;
margin-left: 0px !important;
letter-spacing: 3px;
line-height: 1 !important;
}





/* header image media queries*/
@media(max-width:700px){

.holiday-blog-post-wow-holiday-our-container {
  background-image: url(/IMAGES/city-images/holiday-inspiration/slider1.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% !important;
  position: relative;
  width: 100%;
  padding-bottom: 55%;
margin-top: 0px;
bottom: 0;
  margin-bottom: 0px;
  background-attachment: scroll;
  
  
  
}


.holiday-blog-post-our-content {
  font-size: 120% !important;
  color: #fff;
  background-color: rgba(0,0,0,0.25);
  width: 100%;
  left: 0 !important;
  
  top: 0;
  height: 100%;
  
}

.holiday-blog-post-our-content {
  display: -moz-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0px;
  right: 30px;
}

.holiday-blog-post-wow-holiday-home-our-story {
  padding: 1% 1%;
  margin-top: 0rem;
}

.holiday-blog-post-wow-holiday-our-team-heading {
  font-family: wow-bold !important;
font-size: 32px !important;
color: #ffffff;
text-align: center !important;
font-weight: 700;
text-shadow: 2px 2px 0 rgba(0,0,0,.6);

font-style: italic !important;
margin-left: -5px !important;
letter-spacing: 3px;
line-height: 1 !important;
}



}



.conten-icon-wow-blog{
display: flex;
justify-content: space-between;
width: 18%;
margin-top: -2rem;
}


.conten-icon-blog{
font-size: 28px;
color: #565656;
cursor: pointer;
}














.main-heading-wow-blog{
margin-bottom: 50px;

}


.blog_post1 {
  margin-top: 0px;
  margin-bottom: 50px;
}


.blog_post {
  padding: 50px 0;
  /* text-align: left; */
  font-family: wow-bold;
  font-size: 32px;
  color: transparent;
  background: linear-gradient(to right, #f87370 , #f87270a8) ;
  -webkit-background-clip: text;
  margin-bottom: -.5rem !important;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
}









/* blogs area heading */



.blogs-description-area-of-sub-headings {
font-size: 18px;
font-family: wow-medium;
color: #565656;
text-align: left;
font-weight: 500;
  line-height: 1.2;
  margin-bottom: 30px;
}


.slider-icon-blog-wow{
display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  margin-right: .3em ;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}





















.MuiTabs-root-blog {
min-height: 200px;
max-height: 458px;
overflow-x: hidden !important;
overflow-y: scroll;
}

.recent_blogs_area_col {
background-color: #f8f8f8;
}


.jss9-blog {
border-right: 1px solid rgba(0, 0, 0, 0.178);
}

.MuiTabs-vertical-blog {
flex-direction: column !important;
}


.MuiTabs-scrollable-blog {
overflow-y: scroll !important;
/* scrollbar-width: none ; */
margin-left: 0px;
margin-bottom: 25px;


}










/* Track */
.MuiTabs-scrollable-blog::-webkit-scrollbar-track {
box-shadow: inset 0 0 5px grey; 
border-radius: 10px;
}


/* width */
.MuiTabs-scrollable-blog::-webkit-scrollbar 
{
width: 13px;
background: #ffffff; 
}

/* Handle */
.MuiTabs-scrollable-blog::-webkit-scrollbar-thumb {
background: #aaaaaa; 
border-radius: 10px;
}

/* Handle on hover */
.MuiTabs-scrollable-blog::-webkit-scrollbar-thumb:hover {
background: #aaaaaa94; 
}

/* buttons */
/* .MuiTabs-scrollable-blog::-webkit-scrollbar-button{
background: blue; 

} */

/* .MuiTabs-scrollable-blog::-webkit-scrollbar-button:single-button {
background-color: #bbbbbb;
display: block;
border-style: solid;
height: 13px;
width: 16px;
margin: 1rem 0;
}

.MuiTabs-scrollable-blog::-webkit-scrollbar-button:single-button:vertical:decrement {
border-width: 0;
background-color: #f8f8f8;
border-color: none;
}

.MuiTabs-scrollable-blog::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
border-color: transparent transparent #777777 transparent;

} */
/* Down */
/* .MuiTabs-scrollable-blog::-webkit-scrollbar-button:single-button:vertical:increment {
border-width: 8px 8px 0 8px;
border-color: #555555 transparent transparent transparent;
margin-bottom: 23px !important;
background-color: #f8f8f8;
margin-top: 10px;
}

.MuiTabs-scrollable-blog::-webkit-scrollbar-button:vertical:single-button:increment:hover {
border-color: #000000 transparent transparent transparent;
} */


.MuiTabs-scrollable-blog::-webkit-scrollbar-thumb {
border-radius: 10px;
-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
outline: 1px solid slategrey;
}










.MuiTabs-scroller-blog {
flex: 1 1 auto;
display: inline-block;
position: relative;
white-space: wrap;
}

.MuiTabs-scroller-blog {
white-space: wrap ;
}


.text-written-date{
color: #f87370;
font-weight: 700;
}


.date-card-for-blogs {
font-size: 12px;
display: flex;
justify-content: space-between;
font-weight: bolder;
text-align: left !important;
margin-top: 5px;
}






.recent_list_detail_items45 {
margin-top: 0px;
margin-right: 15px !important;
border-radius: 5px;
background-color: #fff;
padding: 10px;

}


.media-blog {
display: -ms-flexbox;
display: flex;
-ms-flex-align: start;
align-items: flex-start;
}












.MuiTab-wrapper-blog {
width: 100%;
display: inline-flex;
flex-direction: column;
justify-content: center;
}




.MuiTab-wrapper-blog {
text-transform: none!important;
}




.heading-of-blog-muitab-holiday{
font-size: 1.2rem;
font-family: wow-bold;
color: #f87370;
text-align: center !important;
}







.wow-blog-text-for-practice {
line-height: 1.2;
font-size: 14px;
font-weight: 500;
width: 100%;
padding: 20px 0;
text-align: left;
display: inline-block;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 5;
-webkit-box-orient: vertical;
max-height: 98px;
overflow: hidden;
}

Button.button-wow-blog-read-more{
background-color: #f87370 !important;
color: #fff !important;
font-size: 10px;

}

.button-wow-blog-read-more{
background-color: #f87370 !important;
color: #fff !important;
padding: 7px;
font-size: 14px;

}


.image-blog-tab-wow-holiday{
padding: 15px 0;
}






.offset-top-10 {
margin-top: 10px;
}

.text-bold{
font-weight: 700;
}

.text-primary-wow-blog-image {
color: #f87370 !important;
line-height: 1.4;
  font-size: 20px !important;
  transition: .3s all ease;
text-decoration: none;
}


.offset-top-15 {
margin-top: 15px;
}

.list-inline-date-written-behind-image-blog-holiday {
margin-left: -6px;
margin-right: -6px;
padding-left: 0;
  list-style: none;
}

.list-inline-date-written-behind-image-blog-holiday > li {
padding-left: 6px;
padding-right: 6px;
display: inline-block;
}

.post-meta .xxs-icon {
font-size: 18px;
}

.text-middle-blog-holiday {
vertical-align: middle;
}

.text-gray {
color: #565656;
}

.xxs-icon {
font-size: 18px;
line-height: 18px;
}

.blog-holiday-behind-image-icon {
position: relative;
display: inline-block;
font-weight: 400;
font-size: 36px;
line-height: 42px;
font-style: normal;
}

.inset-left-5 {
padding-left: 10px;
}

.inset-left-10 {
padding-left: 10px;
}











.famous-blog-heading-wow-blog{
margin-bottom: 20px;

}


.famous-heading-blog_post {
  padding: 50px 0;
  /* text-align: left; */
  font-family: wow-bold;
  font-size: 32px;
  color: transparent;
  background: linear-gradient(to right, #f87370 , #f8727085) ;
  -webkit-background-clip: text;
  margin-bottom: -.5rem !important;
  font-weight: 500;
  line-height: 1.2;
  text-transform: uppercase;
}








.button-text-wow-blog-card-holiday:link,
.button-text-wow-blog-card-holiday:visited{
  font-size: 1.2rem;
  color: #f87370 !important;
  display: inline-block ;
  text-decoration: none;
  border-bottom: 1px solid #f87370 !important;
  padding: 0px;
  font-family: wow-regular;
  bottom: 10px;
  position: absolute;
  left: 35%;


}




.button-text-wow-blog-card-holiday:hover{
  background-color: #f87370 !important;
  color: #ffffff !important;
  box-shadow: 0 1rem 2rem #000000;
  transform: translateY(-3px);
  padding: 4px;

}

.button-text-wow-blog-card-holiday:active {
  box-shadow: 0 .5rem 1rem #000000;
  transform: translateY(3px);
  padding: 4px;


}









.button-text-wow-blog-card-holiday-muitab:link,
.button-text-wow-blog-card-holiday-muitab:visited{
  font-size: .95rem;
  color: #f87370 !important;
  display: inline-block ;
  text-decoration: none;
  padding: 5px;
  font-family: wow-bold;
  margin-left: 30%;
  margin-top: 5%;


}




.button-text-wow-blog-card-holiday-muitab:hover{
  background-color: #f87370 !important;
  color: #ffffff !important;
  box-shadow: 0 1rem 2rem #000000;
  transform: translateY(-2px);

}

.button-text-wow-blog-card-holiday-muitab:active {
  box-shadow: 0 .5rem 1rem #000000;
  transform: translateY(2px);
  padding: 4px;


}
















/* card blog */





      .card-for-practice-blog-holiday {
        height: 410px;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0,0,0,.125);
        border-radius: .25rem;
        width: auto;
        border-bottom: 4px solid #f87370;

        .fa-user{
          margin-right: .5rem;
        }
        
      }
      
      .card-for-practice-blog-holiday:hover {
        -webkit-box-shadow: 0px -3px 11px 1px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px -3px 11px 1px rgba(0,0,0,0.75);
        box-shadow: 0 0 10px #777;
      }       

      .card-img-top-for-practice-blog-holiday {
        height: auto;
        
        width: 100%;
      }


      .card-text-for-practice-blog-holiday {
        line-height: 1.3;
        font-size: 18px;
        font-family: wow-bold;
        font-weight: 500;
        padding: 10px 10px;
        text-align: left;
        display: inline-block;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        color: #565656;
        -webkit-box-orient: vertical;
        max-height: 60px;
        overflow: hidden;
      }


      .t_gray-blog-holiday {
        color: #888;
      }
      
      .t12-wow-blog-holiday {
        font-size: 12px !important;
      }

      .star_box-blog-holiday {
        margin-top: 6px !important;
      }


      .m_star_v2-blog-holiday {
        color: #aaaaaa;
      }
    
      
      .m_star_v2-blog-holiday i {
        color: #aaaaaa !important;
        position: relative;
        padding-left: 10px;
        
      }


      .t14-blog-holiday {
        font-size: 14px !important;
        font-family: wow-regular;
        margin-right: .3rem;
        
      }


      .u_t_gray_9-wow-blog-holiday {
        color: #999999;
        
        
      }
      

      .black-wow-blog-holiday {
        color: #999999 !important;
      }


      .text-inside-blog-wow-holiday-card{
        padding: 7px 12px;
        font-size: 14px ;
        font-family: wow-regular;
        


      }


      .margin-in-rows-cards-blog-holiday{
        margin-bottom: 2rem;
      }




      Button.button-blog-cards-wow-holiday{
        background-color: #dbdbdb !important;
        color: #000000 !important;
        font-size: 14px;
      
      }
















      @media(max-width:1130px) and (min-width:720px){
        .MuiTabs-root-blog {
          min-height: 200px;
          max-height: 388px;
          overflow-x: hidden !important;
          overflow-y: scroll;
        }

        Button.button-wow-blog-read-more{
          background-color: #f87370 !important;
          color: #fff !important;
          font-size: 9px;
          width: 100%;
        
        }

        .card-text-for-practice-blog-holiday {
          line-height: 1.3;
          font-size: 16px;
          font-family: wow-bold;
          font-weight: 500;
          padding: 10px 10px;
          text-align: left;
          display: inline-block;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          color: #565656;
          -webkit-box-orient: vertical;
          /* max-height: 38px; */
          overflow: hidden;
        }


        .card-for-practice-blog-holiday {
          height: 400px;
          word-wrap: break-word;
          background-color: #fff;
          background-clip: border-box;
          border: 1px solid rgba(0,0,0,.125);
          border-radius: .25rem;
          width: auto;
          border-bottom: 4px solid #f87370;
          
        }


        .button-text-wow-blog-card-holiday:link,
.button-text-wow-blog-card-holiday:visited{
  font-size: 1.1rem;
  color: #f87370 !important;
  display: inline-block ;
  text-decoration: none;
  border-bottom: 1px solid #f87370 !important;
  padding: 0px;
  font-family: wow-regular;
  bottom: 15px;
  position: absolute;
  left: 28%;


}

       





      }





