@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }













.dropdown{
    content: "";
}


.dropdown-link{
    color: #565656;
    padding-top: -40px;
    padding-bottom: -40px;
    font-family: wow-regular;

cursor: pointer;
text-align:center;
margin-left:20px;
  }









  

 /* a.meanmenu-reveal {
    width: 22px;
    height: 22px;
    padding: 13px 13px 11px;
    top: 0;
    right: 0;
    cursor: pointer !important;
    color: #fff !important;
    text-decoration: none;
    font-size: 16px;
    text-indent: -9999em;
    line-height: 22px;
    font-size: 1px;
    display: block;
    font-family: Arial,Helvetica,sans-serif !important;
    font-weight: 700 !important;
    float: right;
} */














  /* .navbar-dark .navbar-toggler {
    color: rgba(255,255,255,.5);
    border-color: rgba(255,255,255,.1);
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}

.navbar-toggler button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}

.navbar-toggler-icon{
    background: url('/IMAGES/city-images/navbar-toggler.png') no-repeat !important ;
    color: white !important;
}

.navbar-toggler-icon {
    display: inline-block !important;
    width: 1.5em !important;
    height: 1.5em !important;
    vertical-align: middle !important;
    content: "" !important;
    background: no-repeat center center;
    background-size: 100% 100%;
} */
 














/* 2nd header below header section navbar */

@media (max-width: 1130px) and (min-width: 776px){

    .mr-auto {
        text-align: center !important;
        padding-left: 5px !important;
        width: 100% !important;
    }

    /* .text-written-in-navbar-of-wow-holiday{
        color: #fff;
        font-size: 25px !important;
        font-family: wow-holiday-bold;
    } */
    
    .text-written-in-navbar-of-wow-holiday:hover{
        color: #fff;
        font-size: 21px !important;
        font-family: wow-holiday-bold;
    }

   
      
     





}


@media (max-width: 990px) and (min-width: 716px){
    .home-icon{
        color: #f87370;
        font-size: 32px !important;
        margin-top: 5px;
    }

    .text-written-in-navbar-of-wow-holiday{
        
        font-size: 35px !important;
        font-family: wow-holiday-bold;
        color: #f87370 !important;
      }
      .text-written-in-navbar-of-wow-holiday-practices{
        font-size: 35px !important;
        font-family: wow-holiday-bold;
    }



}






























.border-line-in-wow-holidays{
    border-bottom: 1px solid black;

}

.header-section-wow-holidays-design{
    background-color: #F87370;
}



.width-of-navbar-in-wow-holiday{
    width: 100%;
    

}


.navbar__top, nav.navbar {
    padding-left: 30px !important;
    padding-right: 30px!important;
}

.navbar__top {
    font-size: 14px;
    line-height: 3;

    @media(max-width:700px){
        line-height: 2;
        }
}

.bg-color--primary{

    background-color: #f87370;

}


.text-white .mr-2  {
    color: #fff!important;
    
}

.user-icon-in-nav-wow-holiday{
    color: #f87370;
}



.header-links-wow-holiday:hover{
    color: #565656 ;
    opacity: 1;
}

.icon-on-hover:hover{
    color: #fff;
    opacity: 1;
}

.header-links-wow-holiday:hover{
    color: #fff;
    opacity: 1;
}










.mr-1{
    color: #fff!important;
}

.py-2 {
    padding-bottom: .5rem!important;
}

.py-2 {
    padding-top: 0rem !important;
}

.navbar__top a {
    font-family: wow-medium;
    font-size: 11px;
    font-weight: 600;
    line-height: 2;
    letter-spacing: 0.08px;
    color: #fff;

    @media (max-width: 1049px) and (min-width:747px){
        font-family: wow-medium;
        font-size: 9px;
    }

    @media(max-width:700px){
    font-family: wow-medium;
    font-size: 12px;
    font-weight: 600;
    line-height: 1.5;
    letter-spacing: 0.08px;
    color: #fff;
    }
}


.mr-n2, .mx-n2 {
    margin-right: -.5rem!important;
}

.pl-0, .px-0 {
    padding-left: 0 !important;
}

.mb-0, .my-0 {
    margin-bottom: 0!important;

    @media(max-width:700px){
        margin-top: 0 !important;
    }
}

.list-inline {
    list-style: none;
}

/* .align-items{
    text-align: center !important;
    justify-items: center !important;
    justify-content: center !important;
} */

.logo-imgage-in-wow-holiday-page{
    height: 80px;
    padding-left: 10%;
    
}

.social-media-button-in-wow-holidays-page:hover{
    height: 3px;
    opacity: 1;
    color: white;
}

.color-of-nav-bar-in-wow-holiday{
    /* background: transparent !important; */
    background-color: #F87370 !important;
    border-top: 3px solid #f87370;
    margin-top: 0.1%;
    opacity: 1;
    
}

.home-in-nav{
    color: black !important;
}

// .home-slider-in-wow-holiday-below-nav{
//     height: auto ;
//     max-height: 100% !important;

    // .blockx{
    //     height:100%;
         
        
    //     width: 100%;
    //   }
    
    //   .blocky{
    //     height:100%;
    //      max-height: 100%; 
    //     max-width: 100%;
    //     width: 100%;
    //     color: blue;
      
    //   } 
    
    //   .blockz{
    //     height:100%;
    //      max-height: 100%; 
    //     max-width: 100%;
    //     width: 100%;
    //   } 
//    
// }

.home-slider-in-wow-holiday-below-nav-mainClass{
.carousel-fade .carousel-item {
    position: relative;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block !important;
    opacity: 0;
    z-index: 0;
    transition: transform 0ms ease-in-out, opacity 0.8s ease-out;
}

.carousel-caption {
    bottom: 30px;
}


// .slide{
//     height: auto !important;
//   }

//   .carousel {
//     position: relative;
//     width: 100%;
// }

// .slide {
//     overflow: hidden;
// }


}


/* .slider_back-ani{
  -webkit-animation: kenburns-bottom-left 10s ease-out both;
	        animation: kenburns-bottom-left 10s ease-out both;
} */
/* ----------------------------------------------
 * Generated by Animista on 2019-11-8 10:19:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-bottom-left
 * ----------------------------------------
 */
 @-webkit-keyframes kenburns-bottom-left {
    0% {
      -webkit-transform: scale(1) translate(0, 0);
              transform: scale(1) translate(0, 0);
      -webkit-transform-origin: 16% 84%;
              transform-origin: 16% 84%;
    }
    100% {
      -webkit-transform: scale(1.25) translate(-20px, 15px);
              transform: scale(1.25) translate(-20px, 15px);
      -webkit-transform-origin: left bottom;
              transform-origin: left bottom;
    }
  }
  @keyframes kenburns-bottom-left {
    0% {
      -webkit-transform: scale(1) translate(0, 0);
              transform: scale(1) translate(0, 0);
      -webkit-transform-origin: 16% 84%;
              transform-origin: 16% 84%;
    }
    100% {
      -webkit-transform: scale(1.25) translate(-20px, 15px);
              transform: scale(1.25) translate(-20px, 15px);
      -webkit-transform-origin: left bottom;
              transform-origin: left bottom;
    }
  }





  /* icons in nav bar and content written */

/* .home-icon{
    color: #fff;
    font-size: 23px;
    margin-top: 5px;
} */

/* .text-written-in-navbar-of-wow-holiday{
    color: #fff;
    font-size: 23px;
    font-family: wow-holiday-bold;
} */

/* .text-written-in-navbar-of-wow-holiday:hover{
    color: #fff;
    font-size: 23px;
    font-family: wow-holiday-bold;
} */



















/* about us */

.bg-displaced-wrap .bg-displaced-body {
    position: relative;
    z-index: 1;
}

.bg-displaced-wrap {
    position: relative;
    z-index: 0;
}

section {
    display: block;
}

.shell, .shell-wide {
    min-width: 320px;
    max-width: 480px;
}

.shell, .shell-wide, .shell-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.box-cart {
    padding: 70px 25px !important;
}



.bg-ebony-clay {
    background: #f87370;
    fill: #2b2f40;
}

.context-dark, .bg-gray-base, .bg-gray-darker, .bg-gray-dark, .bg-mine-shaft, .bg-teak, .bg-cod-gray, .bg-ebony-clay {
    color: #9b9b9b;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block;
}

.bg-ebony-clay {
    color: #666c84;
}

.image-in-about-us-of-wow-holiday {
    max-width: 100%;
    height: auto;
}

.image-in-about-us-of-wow-holiday {
    vertical-align: middle;
    max-width: 100%;
    height: auto;
    border: 0;
}

.box-cart {
    padding: 70px 25px;
}

.bg-displaced-wrap .bg-displaced {
    position: absolute;
    top: 50px;
    right: 0;
    bottom: 50px;
    left: 0;
    z-index: 0;
}

.bg-image {
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
}

.blockquote-complex.blockquote-complex-inverse:before {
    border-color: #fff !important;
    
}


*:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.contact-us-button a {
    border: 1px #fff solid;
    padding: 12px;
    margin: 0px 5px;
}

 .bg-ebony-clay a {
    color: #fff;
}


.contact-in-wow-holiday-about-us {
    transition: all 0.3s ease 0s;
    text-decoration: none;
}

.contact-in-wow-holiday-about-us {
    display: inline-block;
    text-decoration: none;
    transition: .33s all ease-out;
}

.contact-in-wow-holiday-about-us {
    background-color: transparent;
}

 .bg-ebony-clay h2 {
    color: #fff;
}

blockquote p:last-child, blockquote ul:last-child, blockquote ol:last-child {
    margin-bottom: 0;
}

.text-written-in-about-us-in-wow-holiday {
    text-align: justify;
    margin: 0 0 20px;
    color: #fff;
}

.text-written-in-about-us-in-wow-holiday {
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

blockquote {
    font: inherit;
    padding: 0;
    margin: 0;
    border: 0;
}

blockquote {
    padding: 12px 24px !important;
    margin: 0 0 24px;
    font-size: 17.5px;
    /* border-left: 5px solid #f9f9f9; */
}

.blockquote-complex {
    position: relative;
}

.blockquote-complex:before, .blockquote-complex:after {
    content: '';
    position: absolute;
    width: 54px;
    height: 54px;
    border-style: solid;
    /* border-color: #000; */
}

.blockquote-complex:before {
    top: 0;
    left: 0;
    border-width: 1px 0 0 1px;
}

.blockquote-complex.blockquote-complex-inverse:after {
    border-color: #fff;
}

.blockquote-complex:after {
    right: 0;
    bottom: 0;
    border-width: 0 1px 1px 0;
}

.blockquote-complex {
    padding: 26px 60px 26px 63px !important;
}










@media (min-width: 992px){
.shell, .shell-wide {
    width: 100%;
    max-width: 1140px;
}

.box-cart {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row-reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 120px 55px 90px 70px !important;
}

.box-cart .box-cart-image {
    display: block !important;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}

.box-cart .box-cart-body {
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding-right: 45px;
}


}













@media (max-width: 991px) and (min-width: 701px){
    .shell, .shell-wide {
        max-width: 920px;
    }
    
    .box-cart {
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
        flex-direction: row-reverse;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        padding: 60px 15px 20px 30px !important;
    }
    
    .box-cart .box-cart-image {
        display: block !important;
        -webkit-flex-shrink: 0;
        -ms-flex-negative: 0;
        flex-shrink: 0;
    }
    
    .box-cart .box-cart-body {
        -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
        flex-grow: 1;
        padding-right: 25px;
    }

    blockquote {
        padding: 6px 14px !important;
        margin: 0 0 0 !important;
        font-size: 13.5px;
        /* border-left: 5px solid #f9f9f9; */
    }

    .blockquote-complex {
        padding: 26px 20px 26px 23px !important;
    }
}





















@media (max-width: 700px){
.shell, .shell-wide {
    width: 100%;
    /* max-width: 1100px; */
    height: 100% !important;
    max-height: 900px !important;
    /* overflow: hidden; */
    /* display: flex;
    flex-wrap: nowrap; */
}
.box-cart {
    padding: 10px 0 10px 0 !important;
}







h3 + *, .h3 + * {
    margin-top: 36px;
}

.height-of-about-us-section-in-wow-holiday{
    height: 100% !important;
    max-height: 900px !important;
}

.image-in-about-us-of-wow-holiday{
    display: none;
}

.blockquote-complex:after {
    right: 0;
    bottom: 0;
    /* border-width: 0 1px 1px 0; */
    display: none;
}

.blockquote-complex:before {
    top: 0;
    left: 0;
    border-width: 1px 0 0 1px;
    display: none;
}

.blockquote-complex {
    padding: 26px 20px 26px 13px !important;
}

.contact-in-wow-holiday-about-us{
    margin-left: 60px !important;
    margin-bottom: 30px !important;
}

blockquote {
    padding: 6px 14px !important;
    margin: 0 0 0 !important;
    font-size: 13.5px;
    /* border-left: 5px solid #f9f9f9; */
}


}

































/* corona strip news */



@keyframes marquee{
    0% {
        transform: translateX(0);
    }
    
    100% {
        transform: translateX(-100%);
    }
    
    }
    
    
    @keyframes swap{
        0%, 50% {
            left: 0;
        }
        
        50.01%, 100% {
            left: 111%;
        }
        
        }
    
    
    
    
    
    
    
    
    .breaking-news-heading-strip-holiday-class {
        background-color: #f7615e;
        border-bottom: 3px solid rgb(255, 145, 145);
        color: #fff;
        line-height: 40px;
        z-index: 1000;
        height: 40px;
    }
    
    
    .marquee-heading-strip-holiday {
        width: 100%;
        line-height: 40px;
        height: 40px;
        margin: 0 auto;
        overflow: hidden;
        white-space: nowrap !important;
    }
    
    
    .breaking-news-class p, .marquee-heading-strip-holiday {
        font-size: 16px;
        font-family: wow-regular;
    }
    
    .marquee-content-heading-strip-holiday {
        display: inline-block;
        -webkit-animation: marquee 50s linear infinite;
        animation: marquee 50s linear infinite;
    }
    
    
    .item-collection-1 {
        position: relative;
        left: 0;
        -webkit-animation: swap 50s linear infinite;
        animation: swap 50s linear infinite;
    }
    
    .link-in-corona-news-strip a {
        color: #007bff;
        text-decoration: none;
        background-color: transparent;
    }
    
    .link-in-corona-news-strip a:hover {
        color: #0056b3;
        text-decoration: underline !important;
    }































.d-md-flex {
    display: flex ;
    padding-left: 3rem !important;
    padding-right: 3rem !important;
}



@media(max-width: 790px){
    .px-5 {
        padding-left: 0rem!important;
    }
    .display-in-mobile-view-topheader {
        display: block !important;
    
    }
    .display-in-mobile-view-topheader {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }
    .Header-top-main-area-wow-holiday{
    ul {
        padding-inline-start: 0px;
    }

    .main-header-Menu-Wrap-holiday li a {
        display: block;
        font-size: 12px !important;
        font-weight: 400;
        color: #fff;
        letter-spacing: 0;
        padding: 5px 5px !important;
        margin-top: 1px;
        margin-bottom: 0px;
        cursor: pointer;
    }
}

.navbar__top ul{
    text-align: center !important;
    margin-bottom: 0rem;
}
}


@media (max-width: 767px){
.logo-imgage-in-wow-holiday-page{
    height: 100%;
    /* margin-left: 170%; */
    width: 100%;
    max-width: 60px;
}

// .home-slider-in-wow-holiday-below-nav{
//     height: 38vh !important;
   
// }


/* .navbar-toggler{
    color: #fff !important;
    background-color: black !important;
} */

/* .navbar-toggler  .navbar-toggler-icon {
    width: 22px !important;
    height: 22px !important;
    padding: 13px 13px 11px !important;
    top: 0;
    right: 0;
    cursor: pointer !important;
    color: #fff !important;
    text-decoration: none;
    font-size: 16px !important;
    text-indent: -9999em !important;
    line-height: 22px !important;
    font-size: 1px ;
    display: block !important;
    font-family: Arial,Helvetica,sans-serif !important;
    font-weight: 700 !important;
    float: right !important;
} */

/* .mean-container a.meanmenu-reveal span {
    display: block;
    background: #fff;
    height: 3px;
    margin-top: 3px;
} */

.navbar-dark .navbar-toggler {
    color: rgba(255,255,255,.5);
    border-color: rgba(255,255,255,.1);
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button;
}

.navbar-toggler button {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    text-decoration: none;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;
}




}


/* @media (min-width: 892px){

.navbar-expand-lg {
    flex-flow: row nowrap !important;
    justify-content: flex-start !important;
}
} */



@media (max-width: 957px){

    a.meanmenu-reveal {
        width: 72px !important;
        height: 42px !important;
        padding: 7px 10px 7px !important;
        top: 0 !important;
        right: 0;
        cursor: pointer !important;
        color: #fff !important;
        text-decoration: none !important;
        font-size: 16px;
        text-indent: -9999em;
        line-height: 22px !important;
        font-size: 1px;
        display: block !important;
        font-family: Arial,Helvetica,sans-serif !important;
        font-weight: 700 !important;
        float: right !important;
    }
    
    a.meanmenu-reveal {
        display: none;
    }
    
     a.meanmenu-reveal span {
        display: block !important;
        background: #fff !important;
        height: 3px !important;
        margin-top: 5px !important;
        width: 100% !important;
    }
    
    .mean-container .mean-bar, .mean-container .mean-bar * {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box !important;
    }

    .mean-container .mean-bar {
        width: 90% !important;
    }

    .mean-container .mean-bar, .mean-container .mean-bar * {
        -webkit-box-sizing: content-box;
        -moz-box-sizing: content-box;
        box-sizing: content-box !important;
    }

    .mean-container .mean-bar {
        background: transparent none repeat scroll 0 0 !important;
        float: left !important;
        min-height: 42px !important;
        padding: 4px 0 0 !important;
        position: relative !important;
        width: 100%;
        z-index: 999999 !important;
    
    }

    .mean-container .mean-bar::after {
        color: #ffffff !important;
        content: "" !important;
        font-size: 21px !important;
        left: 5% !important;
        position: absolute !important;
        top: 12px !important;
    }

    .mean-container .mean-nav {
        float: left !important;
        width: 100% !important;
        background: #0c1923 !important;
    }

    .mean-container .mean-nav ul {
        padding: 0 !important;
        margin: 0 !important;
        width: 100% !important;
        list-style-type: none !important;
    }

    .mean-container .mean-nav ul li {
        background: #f8f8f8 none repeat scroll 0 0 !important;
        float: left !important;
        position: relative !important;
        width: 100% !important;
        border-top: 1px solid#ddd !important;
    }
    .mean-nav ul li {
        display: list-item !important;
        text-align: -webkit-match-parent !important;
    }

    

    /* .row, .row-nm {
        box-sizing: border-box !important ;
        display: -ms-flexbox;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex !important;
        -ms-flex: 0 1 auto;
        -webkit-box-flex: 0 !important;
        -webkit-flex: 0 1 auto;
        flex: 0 1 auto !important;
        -ms-flex-direction: row;
        -webkit-box-orient: horizontal !important;
        -webkit-box-direction: normal !important;
        -webkit-flex-direction: row;
        flex-direction: row !important;
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap !important;
        margin-left: 0px !important;
        margin-right: 0px !important;
    } */
    .row:before, .row:after {
        content: " " !important;
        display: table !important;
    }

    .col-md-12, .col-lg-12 {
        position: relative !important;
        min-height: 1px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .mean-container .mean-push {
        float: left !important;
        width: 100% !important;
        padding: 0 !important;
        margin: 0;
        clear: both !important;
    }

    li {
        display: list-item;
        text-align: -webkit-match-parent;
    }


    .container:before, .container:after {
        content: " ";
        display: table;
    }



}












/* 3rd Navbar */


.nav-for-practice{
    // margin-left: 18%;
    display: flex;
    flex-direction: row !important;
    justify-content: space-between !important;
  }

  .navbar-wow-holiday-center{
      display: flex;
      justify-content: center;
  }
  
  .mr-auto{
    width: 100% !important;
  }
  

  /* .kux-bhi-container-hai-ye{
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;

  } */
  .nav-practice{
    background-color: #ffffff;
    border-top: 1px solid white;
    margin-bottom: 1px ;
    left: 0 !important;
    right: 0 !important;
    display: flex !important;
    flex-direction: row !important;
  


  }
  
  .text-written-in-navbar-of-wow-holiday{
    color: #f87370 !important;
    font-size: 18px;
    font-family: wow-regular;
    font-weight: 700;
    margin-top: 52px !important;
  }

  .text-written-in-navbar-of-wow-holiday-button{
    color: #f87370 !important;
    font-size: 18px;
    font-family: wow-regular;
    font-weight: 700;
    margin-top: 0px !important;
  }

  .text-written-in-navbar-of-wow-holiday-practices{
    color: #f87370 !important;
    font-size: 18px;
    font-family: wow-regular;
    font-weight: 700;
    margin-top: -7px !important;
  }
  
  .home-icon{
    color: #f87370;
    font-size: 20px;
    margin-top: 4px;
  }
  
  
  
  
  #nav-dropdown{
    color: #fff;
  }
  
  .navbar.navbar-light .navbar-toggler {
    color: #fff !important;
  }
  
  .navbar-light .navbar-toggler {
    color: #fff !important;
    border-color: #fff !important;
  }
  
  /* .navbar.navbar-light .navbar-toggler-icon {
    cursor: pointer;
    background-image: url(data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://ww…p='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E');
  } */


  .navbar .nav-flex-icons {
    flex-direction: row;
}



.wow-holiday-logo{
    padding: 2px 3px 4px 4px;
    height: 100px;
    width: auto;
}

.display-icons-of-right-side-in-header{
    display: flex;
    flex-direction: row !important;
    width: 14% !important;
    flex-wrap: nowrap !important;
    padding: 0 !important;
    

}

.nav-item-wow-holiday{
    color: #fff ;
    font-size: 20px;
    margin-right: 0px;
    width: 69% !important;
    backface-visibility: hidden !important;
    
  }
  .nav-item-practices{
    color: #fff !important ;
    font-size: 20px;
    
  }



.google-icon-hover:hover{
    color: white;
}


.btn-primary.dropdown-toggle {
    background-color: #f87370 ;
    color: white;
}








@media(max-width:700px){
    .display-icons-of-right-side-in-header{
        display: flex;
        flex-direction: row !important;
        width: 40% !important;
        flex-wrap: nowrap !important;
    
    }
    
    .nav-item-wow-holiday{
        color: #fff ;
        font-size: 20px;
        margin-right: 0px;
        
        backface-visibility: hidden !important;
        
      }

      .navbar-wow-holiday-center{
        display: flex;
        justify-content: flex-start;
    }

    
}










































@media (max-width: 700px){

    .wow-holiday-logo{
        padding: 2px 3px 5px 1px;
        height: 70px;
        width: 70px;
    }

    .text-written-in-navbar-of-wow-holiday{
        font-size: 24px;
        color: #f87370;

    }
    .text-written-in-navbar-of-wow-holiday-practices{
        font-size: 24px;
        color: #f87370;
    }
    .home-icon{
        font-size: 28px;
        color: #f87370;
    }
    .mr-auto{
        padding: 0;

    }
    .nav-for-practice{
        margin-left: -3%;
        display: flex;
        flex-direction: column !important;
        width: 32%;
      }
      .slash-in-mobile-view-in-nav-wow-holiday{
          display: none;
      }


      #scrollup{
        color: #ffffff;
        position: fixed;
        right: 1.8rem !important;
        bottom: 2rem !important;
        width: 40px !important;
        height: 40px !important;
        border: none;
        outline: none;
        cursor: pointer;
        border-radius: 50%;
        background-color: #f54441;
        opacity: 1;
        display: none !important;
    }

}


@media (max-width: 1132px) and (min-width: 992px){

    .mr-auto{
        width: 100% !important;
      }
      .text-written-in-navbar-of-wow-holiday{
        font-size: 14px;
        font-weight: 100 !important;

    }
    .text-written-in-navbar-of-wow-holiday-practices{
        font-size: 14px;
        font-weight: 100 !important;
    }
    .nav-for-practice{
        margin-left: 5% !important;
        display: flex;
        flex-direction: row !important;
      }
      .nav-item-wow-holiday{
        color: #fff;
        font-size: 20px;
        margin-right: 5px;
      }

      .home-icon {
        color: #f87370;
        font-size: 16px;
        margin-top: 4px;
    }
     

}


@media (max-width: 992px) and (min-width: 700px){

.mr-auto{
    padding: 0;

}
.nav-for-practice{
    margin-left: 0%;
    display: flex;
    flex-direction: column !important;
    /* width: .9rem; */
    left: 0 !important;
  }
  .slash-in-mobile-view-in-nav-wow-holiday{
    display: none;
}

.client-contact-size{
    font-size: 27px !important;
}
}









// @media (max-width: 1000px) and (min-width:759px){
// .home-slider-in-wow-holiday-below-nav{
//     height: 45vh !important;
   
// }
// }












/* 
.dropdown-mnu{
    display: none;
} */

/* .dropdown-mnu:hover{
    display: none !important;
    background-color: red !important 
} */


.color-of-user-icon-sign-in-nav-holiday{
    color: #fff !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    font-family: wow-holiday-bold !important;
}

.color-of-user-icon-sign-in-nav-holiday:hover{
    background-color: #f87370 !important;
    opacity: 1;
    color: #666c84 !important ;
}








 div.dasdad {
    position: absolute;
    left: -2rem !important;
    z-index: 999;
    width: 120px;
    padding: 0px 10px;
    background: #f87370;
    height: 110px;
    margin-top: 10%;
    
    
    text-align: left;
    box-shadow: 0 2px 5px rgba(0,0,0,.05);
    /* -webkit-transform: scaleY(0);
    transform: scaleY(0); */
    /* -webkit-transform-origin: top;
    transform-origin: top; */
    /* -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s; */
    text-transform: capitalize;
}





div.dasdad-practice {
    position: absolute;
    /* left: 0;
    top: 0px;
    z-index: 999; */
    width: 206vh;
    left: -402px;
    padding: 15px 0px;
    background: #f87370;
    height: 210px;
    margin-top: 30% !important;
    
    box-shadow: 0 2px 5px rgba(0,0,0,.05);
    z-index: 10002;
    display: block;
    border-top: 1px solid black;
    /* -webkit-transform: scaleY(0);
    transform: scaleY(0); */
    /* -webkit-transform-origin: top;
    transform-origin: top; */
    /* -webkit-transition: all .3s ease 0s;
    transition: all .3s ease 0s; */
    text-transform: capitalize;
}


@media (max-width: 700px){
    div.dasdad-practice {
        position: absolute;
        /* left: 0;
        top: 0px;
        z-index: 999; */
        width: 0vh;
        left: 0px;
        padding: 0px 0px;
        background: #f87370;
        height: 0px;
        margin-top: 0% !important;
        margin-bottom: 510px !important;
        
        box-shadow: 0 2px 5px rgba(0,0,0,.05);
        z-index: 10002;
        display: block;
        border-top: 0px solid black;
        /* -webkit-transform: scaleY(0);
        transform: scaleY(0); */
        /* -webkit-transform-origin: top;
        transform-origin: top; */
        /* -webkit-transition: all .3s ease 0s;
        transition: all .3s ease 0s; */
        text-transform: capitalize;
    }
   

}









#scrollup{
    color: #ffffff;
    position: fixed;
    right: 2.8rem;
    bottom: 5rem;
    width: 60px;
    height: 60px;
    font-size: 20px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    background-color: #f54441ce;
    opacity: 1;
    overflow-block: block;
    z-index: 12030 !important;
}


#scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 20000;

    button {
    background-color: #fa0400ce !important;
    background: #ee110dce !important;
    z-index: 1000 !important;
    width: 50px;
    height: 50px;
    text-decoration: none;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: all 0.3s linear;
    border: 0;
    cursor: pointer;

    #scroll-to-top button:hover, #scroll-to-top button:focus {
        background-color: #f54441ce !important;
        background: #f54441ce !important;
        outline-color: 0px solid #fff !important;

    }
    
}
button:focus {
    outline: 1px dotted;
    outline: 0px auto -webkit-focus-ring-color !important;
}

}









/*
 li a.dropdown-toggle {
    display: block;
    font-size: 18px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 0;
    padding: 0px 0;
    text-transform: uppercase;
    cursor: pointer !important;
    margin-top: 52px;
}

li {
    text-align: -webkit-match-parent !important;
}


li a.dropdown-toggle:hover div.dasdad {
    -webkit-transform: scaleY(1) ;
    transform: scaleY(1) !important;
} */





.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "" ;
    border-top: .3em solid;
    border-right: .3em solid transparent !important;
    border-bottom: 0;
    border-left: .3em solid transparent;
    color: #f87370;
}











































// Header Top LE


        .Header-top-main-area-wow-holiday{
            border-bottom: 1px solid rgba(255,255,255,.07);
            padding: 0px 0;
            .main-header-Menu-Wrap-holiday{
            @media (min-width:900px) {
                display: flex;
                justify-content: flex-end !important;
                flex-wrap: wrap; 
                margin-bottom: 0rem ;
            }
            @media (max-width:900px) {
                display: flex;
                justify-content: center !important;
                flex-wrap: wrap; 
            }
      
                li{
              
                    display: inline-block;
                    position: relative;
                    &:first-child{
                        padding-left: 0;
                    }
                    &:last-child{
                        padding-right: 0;
                    }
                    @media (max-width:1200px) {
                        padding: 0px 2px !important;
                        margin: 2px 0px 2px 0px;
                    }
                    a{
                        display: block;
                        font-size: 14px !important;
                        font-weight: 400;
                        color: #fff;
                        letter-spacing: 0;
                        padding: 5px 5px !important;
                     margin-top: 1px;
                     margin-bottom: 0px;
                        cursor: pointer;

                        @media (max-width:700px) {
                            display: block;
                        font-size: 12px !important;
                        font-weight: 400;
                        color: #fff;
                        letter-spacing: 0;
                        padding: 5px 5px !important;
                     margin-top: 1px;
                     margin-bottom: 0px;
                        cursor: pointer;
                        }
                        i{

                        }
                        @media (max-width:1200px) {
                            font-size: 16px;
                        }
                        &.active{
                            color: #c0b596;
                        }
                    }
                    
                    &:hover{
                        a{
                            display: block;
                            font-size: 14px !important;
                            font-weight: 500;
                            color: #fff;
                            letter-spacing: 0;
                            padding: 5px 5px;
                         margin-top: 1px;
                         margin-bottom: 0px;
                            cursor: pointer;
                            i{ 

                            }
                            @media (max-width:1200px) {
                                font-size: 16px;
                            }
                            &.active{
                                color: #c0b596;
                            }
                        }
                        span{
                            color: #c0b596;
                        }
                    }
                    ul.subMenu-wow-holiday-top-header{
                        position: absolute;
                        left: 0;
                        top: 50px;
                        z-index: 999;
                        width: 200px;
                        padding: 5px 5px;
                        background: #fff;
                        text-align: left;
                        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
                        -webkit-transform: scaleY(0);
                        -ms-transform: scaleY(0);
                        -o-transform: scaleY(0);
                        transform: scaleY(0);
                        transform-origin: top;
                        -webkit-transition: all 0.3s ease 0s;
                        -o-transition: all 0.3s ease 0s;
                        transition: all 0.3s ease 0s;
                        text-transform: capitalize;
                        font-size: 20px !important;
                        li{
                            font-size: 20px !important;
                            display: block;
                            padding: 0;
                            border-bottom: 1px solid #f2f2f2;
                            &:last-child{
                                border-bottom: none;
    
                            }
                            a{
                                @media (min-width:1000px) {
                                    font-size: 12px !important;
                                    font-weight: 550;
                                }
                                  
                               
                                color: #06163a;
                                display: block;
                                padding: 5px 15px;
                                text-transform: capitalize;
                                &:hover,
                                &.active{
                                    color: #000;
                                }
                              i{
                                  font-size: 16px;
                                  color: rgb(209, 159, 159) !important;
                              }  
                            }
                        }
                    }
                    &:hover{
                        ul.subMenu-wow-holiday-top-header{
                            -webkit-transform: scaleY(1);
                            -ms-transform: scaleY(1);
                            -o-transform: scaleY(1);
                            transform: scaleY(1);
                        }
                    }


            .responsiveWrapper{
                @media (max-width:800px) {
              
                    position: absolute;
                    left: 0;
                    top: 100%;
                    width: 100%;
                    background: #f87370;
                    transition: all .3s;
                    z-index: 99;
                    padding: 20px;
                    &.active{
                        display: block;
                    }
                    .main-header-Menu-Wrap-holiday{
                        text-align: left;
                        li{
                            display: block;
                            padding: 0;
                            margin-bottom: 15px;
                            a,
                            span{
                                padding: 0px;
                                position: relative;
                                i{
                                    display: block;
                                    position: absolute;
                                    right: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }
                            }
                            ul.subMenu-wow-holiday-top-header{
                                top: 25px;
                                li{
                                    margin-bottom: 0;
                                    a{
                                        padding: 10px 0px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        background: #f87370;
        ul{
            text-align: left;
            @media (max-width:576px) {
                text-align: center;
            }
            li{
                display: inline-block;
                padding: 6px 4px 3px 0px;
                color: #fff;
                font-size: 11px !important;
                color: #e0e0e0;
                &:first-child{
                 /*    position: relative;
                    padding-left: 0;
                    &::before{
                        position: absolute;
                        content: "";
                        right: 0;
                        top: 14px;
                        width: 1px;
                        height: 25px;
                        background: rgba(255,255,255,.07);
                    } */
                    
                }
                &:last-child{
                    padding-right: 0;
                }
                i{
                    padding-right: 2px;
                    color: #000;
                    
                }
            }
            &.accountLoginArea{
                @media (max-width:576px) {
                    justify-content: center!important;
                }
                li{
                    padding-left: 0;
                    @media (max-width:768px) {
                       padding: 0;
                    }
                    &::before{
                   
                    }
                }
                
            }
        }
        .btnStyle {
            @media (max-width:576px) {
                text-align: center!important;
            }
        }
        .btnStyle{
            a{
                background: transparent;
                font-size: 14px;
                font-weight: 700;
                color: #fff;
                padding: 9px 15px;
                border: 2px solid #c0b596;
                border-radius: 27px;
                text-transform: capitalize;
                @media (max-width:1024px) {
                    font-size: 11px;
                    padding: 9px;
                }
            }
        }
    }
 
    &.headerAreaStyleThree{
        .headerTop{
            background: transparent;
            padding: 0;
            @media (max-width:768px) {
                background: #f87370;
            }
            .Header-top-main-area-wow-holiday{
                padding: 10px 15px;
                background: #f87370;
                @media (max-width:768px) {
                    padding: 10px 0;
                }
                .btnStyle {
                    a{
                        padding: 9px 13px;
                    }
                }
            }
        }
    }
}
.live-meeting-wow-holiday-top-header a i{
 color: #ffffff;   
}
@media (max-width:7000px) and (min-width:1200px){
    .live-meeting-wow-holiday-top-header{
        a{
            background: transparent;
            font-size: 11px !important;
            font-weight: 700;
            color: #fff;
            padding: 5px 11px;
            border: 2px solid #ffffff;
            margin: 0px 5px !important;
            border-radius: 27px;
            text-transform: capitalize;
            @media (max-width:1024px) {
                font-size: 11px;
                padding: 9px;
            }  
        }
    }
    .live-meeting-wow-holiday-top-header1{
      
    }
}
@media (max-width:1200px) and (min-width:515px){
    .live-meeting-wow-holiday-top-header{
        a{
            background: transparent;
            font-size: 10px !important;
            font-weight: 700;
            color: #fff;
            padding: 9px 15px;
            border: 2px solid #fff;
            border-radius: 47px;
            text-transform: capitalize;
            @media (max-width:1024px) {
                font-size: 12px;
                padding: 9px;
            }  
        }
    }
    .live-meeting-wow-holiday-top-header1{
      
    }
}
@media (max-width:515px) and (min-width:0px){
    .mymeeting{
        margin-top: 5px;
    }
    .live-meeting-wow-holiday-top-header{
       
    }
    .my-class-number-space{
        margin-left: 20px !important;
    }
    .my-class-time{
        display: none !important;
    }
    .live-meeting-wow-holiday-top-header{
        a{
            background: transparent;
            font-size: 10px !important;
            font-weight: 700;
            color: #fff;
            padding: 9px 15px;
            border: 2px solid #ffffff;
            border-radius: 27px;
            text-transform: capitalize;
            @media (max-width:1024px) {
                font-size: 12px;
                padding: 9px;
            }  
        }
    }
    .meetingClass{
       /*  display: flex;
        justify-content: space-between;
        flex-wrap: wrap; */
    }  
}

.heading-modal{
    font-family: 'Playfair Display', serif;
}
.close{
    font-size: 2.5rem !important;
    font-weight: 600 !important;
}
.modal-header{
    border-bottom: 3px solid #D4C291 !important;
}
.google-captcha-additionarea{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.sent-message-area{
    padding: 50px 0px 50px 0px;
}
.sent-message-area h2{

}
.sent-message-area p i{
    font-size: 200px;
    color:#D4C291 ;
}
.sent-button-des{
    color: white;
    background-color: #D4C291;
    font-size: 20px;
    padding: 5px 50px 5px 50px;
    border-radius: 5px;
}
/* @media (min-width: 992px){
.container, .container-lg, .container-md, .container-sm {
    max-width: 1410px !important;
}
} */
.upload-photo-area{
    width: 100%;
    height: 82%;
    background-color: #F3F0E9;
    color: #D0C4AC;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #D0C4AC;
}
.inputfile-box18 {
    position: relative;
    border: 1px solid #D0C4AC;
    background-color: #F3F0E9;
    color: #666666 !important;
    padding: 12px;
    height: 50px;
  }
  
  .inputfile18 {
    display: none;
  }
  
  .container18 {
    display: inline-block;
    width: 100%;
  }
  
  .file-box18 {
    display: inline-block;
    width: 100%;
    border: 1px solid;
    
    padding:6px 15px;
    box-sizing: border-box;
    height: 50px;
  }
  
  .file-button18 {
    background: #D0C4AC;
    color: white;
    padding: 12px;
    cursor: pointer;
    position: absolute;
    border: 1px solid;
    top: 0px;
    right: 0px;
    height: 50px;
  }
  .image-upload > input {
    visibility:hidden;
    width:0;
    height:0
  }
  .image-upload{
      cursor: pointer;
  }
  
  @media (max-width:7000px) and (min-width:991px){
    .myaddressphoneclass{
        margin-top: 8px !important;
        display: flex !important;
        justify-content: flex-start !important;
    }
  }
  @media (max-width:991px) and (min-width:0px){
    .myaddressphoneclass{
        margin-top: 8px !important;
        display: flex !important;
        justify-content: center !important;
    } 
}