@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }





















 .td-post-header-holder-card2 {
    position: relative;
    min-height: 400px;
    background-color: #444;
    margin-bottom: 36px;
    overflow: hidden;
    margin-top: 36px;
}


 .td-image-gradient-newscard2:before {
    z-index: 1;
}

.td-image-gradient-newscard2:before {
    bottom: 0;
    content: "";
    display: block;
    height: 50%;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.8)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
}

.td-post-featured-image-newscard2 {
    position: relative;
    visibility: visible !important;
}

.td-post-featured-image-newscard2 img {
    margin-bottom: 0;
}

.td-post-featured-image-newscard2 img {
    display: block;
    width: 100%;
    height: auto;
}

.td-animation-stack-type0-2-newscard2 {
    opacity: 1 !important;
    transition: opacity 0.3s;
    transition-timing-function: cubic-bezier(0.39, 0.76, 0.51, 0.56);
}


.td-post-title-newscard2 {
    position: absolute;
    bottom: 0;
    padding: 0 30px 9px 30px;
    width: 80%;
    z-index: 1;
}


.td-post-title-newscard2 .td-category-newscard2 {
    margin-bottom: 5px;
}

.td-category-newscard2 {
    list-style: none;
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1;
}

.td-category-newscard2 li {
    display: inline-block;
    margin: 0 5px 5px 0;
    line-height: 1;
}

.td-post-title-newscard2 ul{
    padding-inline-start: 0px !important;
}


.td-category-newscard2 a {
    border-color: var(--darkblue)!important;
    background-color: var(--darkblue);
}


.td-category-newscard2 a {
    color: #fff;
    background-color: #222222;
    padding: 3px 6px 4px 6px;
    white-space: nowrap;
    display: inline-block;
}

.post .td-category-newscard2 a {
    text-transform: none;
}


 .entry-title-newscard2{
    font-family: wow-bold;
    font-weight: 700;
}

.entry-title-newscard2 {
    font-size: 38px;
    line-height: 54px;
    font-weight: 500;
    margin-bottom: 14px;
    margin-top: 0;
    color: #fff;
}


header .td-module-meta-info {
    margin-bottom: 16px;
}

.td-module-meta-info {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 11px;
    margin-bottom: 7px;
    line-height: 1;
    min-height: 17px;
}


 .td-post-title-newscard2 .td-post-date-newscard2, .td-post-title-newscard2 .td-post-comments a, .td-post-title-newscard2 .td-post-views-newscard2 {
    color: #fff;
}

header .td-post-date-newscard2 {
    margin-left: 4px;
    color: #444;
    float: left;
}

.td-post-date-newscard2 {
    color: #aaa;
    display: inline-block;
    position: relative;
    top: 2px;
}

header .td-module-meta-info-newscard2 {
    margin-bottom: 16px;
}

.td-module-meta-info-newscard2 {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 11px;
    margin-bottom: 7px;
    line-height: 1;
    min-height: 17px;
}

.td-post-date-newscard2 .entry-date-newscard2 {
    text-transform: none;
}

.td-post-title-newscard2 .td-post-views-newscard2 {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    margin-right: 0;
}

.td-post-template-3 .td-post-title-newscard2 .td-post-comments, .td-post-template-3 .td-post-title-newscard2 .td-post-views-newscard2 {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    margin-right: 0;
}

header .view-icon-views-newscard2 {
    position: relative;
    line-height: 17px;
    font-size: 14px;
    margin-right: 5px;
}

[class*="td-icon-"] {
    line-height: 1;
    text-align: center;
    display: inline-block;
}

.view-icon-views-newscard2:before {
    content: '\e805';
}

[class^="td-icon-"]:before, [class*=" td-icon-"]:before {
    font-family: 'newspaper';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    text-align: center;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


header .td-post-views-newscard2 span {
    line-height: 15px;
    vertical-align: top;
}
































/* description news cards */

.description_of_card_of_newsroom-card2 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 5rem;
    font-size: 600;
    color: #8d8c8c;
}

@media (max-width: 7000px) and (min-width: 500px){
.description_of_card_of_newsroom_area-card2 {
    width: 75%;
}
}

.blogs-description-area-of-main--text-card2 {
    font-size: 14px;
    font-family: wow-regular;
    color: #565656;
    margin-top: 0;
    margin-bottom: 1rem;
}

.blogs-description-area-of-main-headings-card2 {
    font-size: 24px;
    font-family: wow-bold;
    color: #565656;
    text-align: left;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}


.description_of_card_of_newsroom_bottom_area {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
}

.description_of_card_of_newsroom_bottom_area_previous {
    cursor: pointer;
}

.description_of_card_of_newsroom_bottom_area_previous h5 {
    color: #565656;
    font-family: wow-bold;
    font-size: 1.25rem;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}


.description_of_card_of_newsroom_bottom_area_previous p {
    color: #565656;
    font-family: wow-regular;
    margin-top: 0;
    margin-bottom: 1rem;
}

.description_of_card_of_newsroom_bottom_area_next {
    text-align: right;
    cursor: pointer;
}

.description_of_card_of_newsroom_bottom_area_next h3{
    font-size: 1.75rem;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.description_of_card_of_newsroom_bottom_area_next h5 {
    color: #565656;
    font-family: wow-bold;
    font-size: 1.25rem;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}


.description_of_card_of_newsroom_bottom_area_next p {
    color: #565656;
    font-family: wow-regular;
    margin-top: 0;
    margin-bottom: 1rem;
}







.wow-news-card1-work-page-top-image-card2{
    margin: 2rem 0 2rem 0;
  }




















  .u-margin-bottom-second-heading-wow-newscard2{
    margin-bottom: 1rem;
  }


  .heading-secondary-section-wow-newscard2{
    font-size: 3rem;
    font-weight: 700;
    margin-top: 2rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#565656 , #565656b4);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;
    /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat; */
  
  
    
  }



.text-section-corona-safest-beaches-newscard2 p{
    color: #565656;
    font-family: wow-regular;
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.2rem;


}












/* gradient gird section */

.gradient-back-travel-newscard2{
    background-color: #f3f3f3;
    padding: 50px 0;
    
  }

  .padding-in-travel-newscard2-grid-text{
    padding: 5rem 0;
  }


  .travel-newscard2-page-araea-sub-headings110 {
    font-size: 24px;
}

.travel-newscard2-page-araea-sub-headings110 {
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
    border-image-slice: 1;
    font-family: wow-bold;
    color: #565656;
    padding-bottom: 5px;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.travel-newscard2-page-page-text {
    font-size: 14px;
    font-family: wow-regular;
  }













  /* media queries */

  @media(max-width:1125px) and (min-width:700px){
    .heading-secondary-section-wow-newscard2{
        font-size: 2rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#565656 , #565656b4);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 2px;
        transition: all .2s;
        /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
        display: inline-block;
        background-size: 100% 5px;
        background-position: 0 100%;
        background-repeat: no-repeat; */
      
      
        
      }

      .text-section-corona-safest-beaches-newscard2 p{
        color: #565656;
        font-family: wow-regular;
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 1rem;
    
    
    }

    .padding-in-travel-newscard2-grid-text{
        padding: 0rem 0;
      }



      .travel-newscard2-page-araea-sub-headings110 {
        font-size: 18px;
    }
    
    .travel-newscard2-page-araea-sub-headings110 {
        border-bottom: 4px solid #f87370;
        -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
        -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
        border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
        border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
        border-image-slice: 1;
        font-family: wow-bold;
        color: #565656;
        padding-bottom: 2px;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    
    .travel-newscard2-page-page-text {
        font-size: 12px;
        font-family: wow-regular;
      }
  }




  @media(max-width:700px){
    .heading-secondary-section-wow-newscard2{
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#565656 , #565656b4);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 2px;
        transition: all .2s;
        /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
        display: inline-block;
        background-size: 100% 5px;
        background-position: 0 100%;
        background-repeat: no-repeat; */
      
      
        
      }

      .text-section-corona-safest-beaches-newscard2 p{
        color: #565656;
        font-family: wow-regular;
        margin-top: 0;
        margin-bottom: 1rem;
        font-size: 1rem;
    
    
    }

    .padding-in-travel-newscard2-grid-text{
        padding: 0rem 0;
      }



      .travel-newscard2-page-araea-sub-headings110 {
        font-size: 21px;
    }
    
    .travel-newscard2-page-araea-sub-headings110 {
        border-bottom: 4px solid #f87370;
        -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
        -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
        border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
        border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
        border-image-slice: 1;
        font-family: wow-bold;
        color: #565656;
        padding-bottom: 5px;
        margin-bottom: 25px;
        text-transform: uppercase;
    }
    
    .travel-newscard2-page-page-text {
        font-size: 13px;
        font-family: wow-regular;
        margin-bottom: 2rem;
      }

      .entry-title-newscard2{
        font-family: wow-bold;
        font-weight: 700;
    }
    
    .entry-title-newscard2 {
        font-size: 24px;
        line-height: 27px;
        font-weight: 500;
        margin-bottom: 14px;
        margin-top: 0;
        color: #fff;
    }
  }