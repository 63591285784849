@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
}




























$bacgroundgradiend : #f3f3f3;

.gradient-back-travel-blogscard2{
    background-color: $bacgroundgradiend;
    padding: 50px 0;
    margin-bottom: 1rem;
    
  }
















































/* header image */

.td-post-header-holder-blogcard2 {
    position: relative;
    min-height: 100px;
    background-color: #444;
    margin-bottom: 36px;
    overflow: hidden;
}


 .td-image-gradient-blogcard2:before {
    z-index: 1;
}

.td-image-gradient-blogcard2:before {
    bottom: 0;
    content: "";
    display: block;
    height: 70%;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.8)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
}

.td-post-featured-image-blogcard2 {
    position: relative;
    visibility: visible !important;
}

.td-post-featured-image-blogcard2 img {
    margin-bottom: 0;
}

.td-post-featured-image-blogcard2 img {
    display: block;
    width: 100%;
    height: auto;
}

.td-animation-stack-type0-2-blogcard2 {
    opacity: 1 !important;
    transition: opacity 0.3s;
    transition-timing-function: cubic-bezier(0.39, 0.76, 0.51, 0.56);
}


.td-post-title-blogcard2 {
    position: absolute;
    bottom: 0;
    padding: 0 0px 9px 250px;
    width: 80%;
    z-index: 1;
}


.td-post-title-blogcard2 .td-category-blogcard2 {
    margin-bottom: 5px;
}

.td-category-blogcard2 {
    list-style: none;
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1;
}

.td-category-blogcard2 li {
    display: inline-block;
    margin: 0 5px 5px 0;
    line-height: 1;
}

.td-post-title-blogcard2 ul{
    padding-inline-start: 0px !important;
}


.td-category-blogcard2 a {
    border-color: var(--darkblue)!important;
    background-color: var(--darkblue);
}


.td-category-blogcard2 a {
    color: #fff;
    background-color: #222222;
    padding: 3px 6px 4px 6px;
    white-space: nowrap;
    display: inline-block;
}

.post .td-category-blogcard2 a {
    text-transform: none;
}


 .entry-title-blogcard2{
    font-family: wow-bold;
    font-weight: 700;
}

.entry-title-blogcard2 {
    font-size: 32px;
    line-height: 38px;
    font-weight: 500;
    margin-bottom: 14px;
    margin-top: 0;
    color: #fff;
}

.entry-title-two-blogcard2{
    font-family: wow-medium;
    font-weight: 500;
}

.entry-title-two-blogcard2 {
    font-size: 24px;
    line-height: 20px;
    font-weight: 500;
    margin-bottom: 14px;
    margin-top: 0;
    color: rgb(190, 186, 186);
}

.entry-category-blogcard2{
    font-size: 12px;
    line-height: 34px;
    font-family: wow-regular;

}


header .td-module-meta-info {
    margin-bottom: 16px;
}

.td-module-meta-info {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 11px;
    margin-bottom: 7px;
    line-height: 1;
    min-height: 17px;
}


 .td-post-title-blogcard2 .td-post-date-blogcard2, .td-post-title-blogcard2 .td-post-comments a, .td-post-title-blogcard2 .td-post-views-blogcard2 {
    color: #fff;
}

header .td-post-date-blogcard2 {
    margin-left: 4px;
    color: #444;
}

.td-post-date-blogcard2 {
    color: #aaa;
    display: inline-block;
    position: relative;
    top: 2px;
}

header .td-module-meta-info-blogcard2 {
    margin-bottom: 16px;
}

.td-module-meta-info-blogcard2 {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 11px;
    margin-bottom: 7px;
    line-height: 1;
    min-height: 17px;
}

.td-post-date-blogcard2 .entry-date-blogcard2 {
    text-transform: none;
}

.td-post-title-blogcard2 .td-post-views-blogcard2 {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    margin-right: 0;
}

.td-post-template-3 .td-post-title-blogcard2 .td-post-comments, .td-post-template-3 .td-post-title-blogcard2 .td-post-views-blogcard2 {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    margin-right: 0;
}

 .view-icon-views-blogcard2 {
    line-height: 17px;
    font-size: 14px;
    margin-right: 5px;
}






header .td-post-views-blogcard2 span {
    line-height: 15px;
    vertical-align: top;
}















































/* description text section */


.description_of_card_of_blogscard2 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 70px;
    font-size: 600;
    color: #8d8c8c;
}

@media (max-width: 7000px) and (min-width: 500px){
.description_of_card_of_blogscard2_area {
    width: 100%;
}
}

.blogscard2-description-area-of-main--text {
    font-size: 14px;
    font-family: wow-regular;
    color: #565656;
}

.blogscard2-description-area-of-main-headings {
    font-size: 24px;
    font-family: wow-bold;
    color: #565656;
    text-align: left;
    
}


.blogscard2-description-area-of-main-headings {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}


.blogscard2-description-area-of-sub-headings {
    font-size: 18px;
    font-family: wow-bold;
    color: #565656;
    text-align: left;
}

.description_of_card_of_blogscard2_bottom_area {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
}

.description_of_card_of_blogscard2_bottom_area_previous {
    cursor: pointer;
}

.description_of_card_of_blogscard2_bottom_area_next {
    text-align: right;
    cursor: pointer;
}

.description_of_card_of_blogscard2_bottom_area_previous h3 {
    font-size: 1.75rem;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.description_of_card_of_blogscard2_bottom_area_next h3 {
    font-size: 1.75rem;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.description_of_card_of_blogscard2_bottom_area_next h5, .description_of_card_of_blogscard2_bottom_area_previous h5 {
    color: #565656;
    font-family: wow-bold;
    font-size: 1.25rem;
}

.description_of_card_of_blogscard2_bottom_area_previous p {
    color: #565656;
    font-family: wow-regular;
}

.description_of_card_of_blogscard2_bottom_area_next p {
    color: #565656;
    font-family: wow-regular;
}


.list-circle-type-blogscard li{
    list-style-type: circle !important;

}

.list-number-type-blogscard li{
    list-style-type: decimal !important;
    

}


















.blogscard2-description-area-of-main--text-above {
    font-size: 16px;
    font-family: wow-regular;
    color: #565656;
}































/* gradient gird section */



.gradient-back-travel-blogscard2{
    background-color: #f3f3f3;
    padding: 50px 0;
    margin-bottom: 1rem;
    
  }

  .padding-in-travel-blogscard2-grid-text{
    padding: 4rem 0;
  }


  .travel-blogscard2-page-araea-sub-headings110 {
    font-size: 24px;
}

.travel-blogscard2-page-araea-sub-headings110 {
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
    border-image-slice: 1;
    font-family: wow-bold;
    color: #565656;
    padding-bottom: 5px;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.travel-blogscard2-page-page-text {
    font-size: 14px;
    font-family: wow-regular;
}





















.u-margin-bottom-second-heading-wow-blogscard2{
    margin-bottom: 1rem;
  }


  .heading-secondary-uk-section-wow-blogscard2{
    font-size: 2.8rem;
    font-weight: 700;
    margin-top: 2rem;
    font-family: wow-bold;
    display: inline-block;
    /* background-image: linear-gradient(to right,#f87370 , #f872708c);
    -webkit-background-clip: text;
    color: transparent; */
    letter-spacing: 2px;
    transition: all .2s;
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    padding-bottom: 5px;
  
  
    
}





.wow-news-card1-work-page-top-image-blogscard2{
    margin: 2rem 0 2rem 0;
}




.wow-news-card1-work-page-top-image-blogscard2-aboveimage{
    margin: 2rem 0 0rem 0;
}


























/* description news cards */

.description_of_card_of_blogpost2 {
    display: flex;
    justify-content: center !important;
    margin-top: 30px;
    margin-bottom: 2rem;
    font-size: 600;
    color: #8d8c8c;
}

@media (max-width: 7000px) and (min-width: 500px){
.description_of_card_of_newsroom_area-blogpost2 {
    width: 70%;
}
}

.blogs-description-area-of-main--text-blogpost2 {
    font-size: 14px;
    font-family: wow-regular;
    color: #565656;
    margin-top: 0;
    margin-bottom: 1rem;
}

.blogs-description-area-of-main-headings-blogpost2 {
    font-size: 24px;
    font-family: wow-bold;
    color: #565656;
    text-align: left;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.wow-news-card1-work-page-top-image-blogpost2{
    margin: 2rem 0 2rem 0;
}




























/* media queries */

@media(max-width:1125px) and (min-width:700px){
    .td-post-title-blogcard2 {
        position: absolute;
        bottom: 0;
        padding: 0 0px 9px 20px;
        width: 100%;
        z-index: 1;
    }
    .entry-title-blogcard2{
        font-family: wow-bold;
        font-weight: 700;
    }
    
    .entry-title-blogcard2 {
        font-size: 22px;
        line-height: 28px;
        font-weight: 500;
        margin-bottom: 14px;
        margin-top: 0;
        color: #fff;
    }
    
    .entry-title-two-blogcard2{
        font-family: wow-medium;
        font-weight: 500;
    }
    
    .entry-title-two-blogcard2 {
        font-size: 15px;
        line-height: 15px;
        font-weight: 500;
        margin-bottom: 14px;
        margin-top: 0;
        color: rgb(190, 186, 186);
    }
    .padding-in-travel-blogscard2-grid-text {
        padding: 0rem 0;
    }
    
    .travel-blogscard2-page-araea-sub-headings110 {
        border-bottom: 4px solid #f87370;
        border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
        border-image-slice: 1;
        font-family: wow-bold;
        color: #565656;
        padding-bottom: 5px;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    
    .travel-blogscard2-page-araea-sub-headings110 {
        font-size: 18px;
    }
    
    .travel-blogscard2-page-page-text {
        font-size: 13px;
        font-family: wow-regular;
    }
    
    
    }
    
    
    @media(max-width:700px){
      .td-post-title-blogcard2 {
        position: absolute;
        bottom: 0;
        padding: 0 0px 9px 0px;
        width: 100%;
        z-index: 1;
    }
    
    
    .entry-title-blogcard2{
        font-family: wow-bold;
        font-weight: 700;
    }
    
    .entry-title-blogcard2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 3px;
        margin-top: 0;
        color: #fff;
    }
    
    header .td-module-meta-info-blogcard2 {
        margin-bottom: 0px;
    }
    
    .entry-title-two-blogcard2{
        font-family: wow-medium;
        font-weight: 500;
    }
    
    .entry-title-two-blogcard2 {
        font-size: 13px;
        line-height: 10px;
        font-weight: 500;
        margin-bottom: 5px;
        margin-top: 0;
        color: rgb(190, 186, 186);
    }
    .padding-in-travel-blogscard1-grid-text {
        padding: 0rem 0;
    }
    
    .entry-category-blogcard2{
        display: none !important; 
    
    }
    
    
    
    }