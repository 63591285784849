@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }






















.td-post-header-holder-card4 {
    position: relative;
    min-height: 400px;
    background-color: #444;
    margin-bottom: 36px;
    overflow: hidden;
    margin-top: 36px;
}


 .td-image-gradient-newscard4:before {
    z-index: 1;
}

.td-image-gradient-newscard4:before {
    bottom: 0;
    content: "";
    display: block;
    height: 50%;
    width: 100%;
    position: absolute;
    z-index: 1;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.8)));
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
}

.td-post-featured-image-newscard4 {
    position: relative;
    visibility: visible !important;
}

.td-post-featured-image-newscard4 img {
    margin-bottom: 0;
}

.td-post-featured-image-newscard4 img {
    display: block;
    width: 100%;
    height: auto;
}

.td-animation-stack-type0-2-newscard4 {
    opacity: 1 !important;
    transition: opacity 0.3s;
    transition-timing-function: cubic-bezier(0.39, 0.76, 0.51, 0.56);
}


.td-post-title-newscard4 {
    position: absolute;
    bottom: 0;
    padding: 0 30px 9px 30px;
    width: 80%;
    z-index: 1;
}


.td-post-title-newscard4 .td-category-newscard4 {
    margin-bottom: 5px;
}

.td-category-newscard4 {
    list-style: none;
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 10px;
    margin-top: 0;
    margin-bottom: 10px;
    line-height: 1;
}

.td-category-newscard4 li {
    display: inline-block;
    margin: 0 5px 5px 0;
    line-height: 1;
}

.td-post-title-newscard4 ul{
    padding-inline-start: 0px !important;
}


.td-category-newscard4 a {
    border-color: var(--darkblue)!important;
    background-color: var(--darkblue);
}


.td-category-newscard4 a {
    color: #fff;
    background-color: #222222;
    padding: 3px 6px 4px 6px;
    white-space: nowrap;
    display: inline-block;
}

.post .td-category-newscard4 a {
    text-transform: none;
}


 .entry-title-newscard4{
    font-family: wow-bold;
    font-weight: 700;
}

.entry-title-newscard4 {
    font-size: 38px;
    line-height: 54px;
    font-weight: 500;
    margin-bottom: 14px;
    margin-top: 0;
    color: #fff;
}


header .td-module-meta-info {
    margin-bottom: 16px;
}

.td-module-meta-info {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 11px;
    margin-bottom: 7px;
    line-height: 1;
    min-height: 17px;
}


 .td-post-title-newscard4 .td-post-date-newscard4, .td-post-title-newscard4 .td-post-comments a, .td-post-title-newscard4 .td-post-views-newscard4 {
    color: #fff;
}

header .td-post-date-newscard4 {
    margin-left: 4px;
    color: #444;
    float: left;
}

.td-post-date-newscard4 {
    color: #aaa;
    display: inline-block;
    position: relative;
    top: 2px;
}

header .td-module-meta-info-newscard4 {
    margin-bottom: 16px;
}

.td-module-meta-info-newscard4 {
    font-family: 'Open Sans', arial, sans-serif;
    font-size: 11px;
    margin-bottom: 7px;
    line-height: 1;
    min-height: 17px;
}

.td-post-date-newscard4 .entry-date-newscard4 {
    text-transform: none;
}

.td-post-title-newscard4 .td-post-views-newscard4 {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    margin-right: 0;
}

.td-post-template-3 .td-post-title-newscard4 .td-post-comments, .td-post-template-3 .td-post-title-newscard4 .td-post-views-newscard4 {
    float: none;
    display: inline-block;
    vertical-align: top;
    margin-left: 22px;
    margin-right: 0;
}

 .view-icon-views-newscard4 {
    line-height: 17px;
    font-size: 14px;
    margin-right: 5px;
}






header .td-post-views-newscard4 span {
    line-height: 15px;
    vertical-align: top;
}

























.u-margin-bottom-second-heading-expert-wow-newscard4{
    margin-bottom: 1rem;
  }


  .heading-secondary-expert-section-wow-newscard4{
    font-size: 2.7rem;
    font-weight: 700;
    margin-top: 2rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87370 , #f87270a1);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;
    width: 81%;
    /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat; */
  
  
    
  }
















   /* description news cards */

.description_of_card_of_newsroom-card4 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 5rem;
    font-size: 600;
    color: #8d8c8c;
}

@media (max-width: 7000px) and (min-width: 500px){
.description_of_card_of_newsroom_area-card4 {
    width: 75%;
}
}

.blogs-description-area-of-main--text-card4 {
    font-size: 14px;
    font-family: wow-regular;
    color: #565656;
    margin-top: 0;
    margin-bottom: 1rem;
}

.blogs-description-area-of-main-headings-card4 {
    font-size: 24px;
    font-family: wow-bold;
    color: #565656;
    text-align: left;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.wow-news-card1-work-page-top-image-card4{
    margin: 2rem 0 2rem 0;
  }


.description_of_card_of_newsroom_bottom_area {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;
}











.wow-news-card1-work-page-top-image-card3{
    margin: 2rem 0 2rem 0;
  }


























  /* gradient gird section */

.gradient-back-travel-newscard4{
    background-color: #f3f3f3;
    padding: 50px 0;
    margin-bottom: 1rem;
    
  }

  .padding-in-travel-newscard4-grid-text{
    padding: 5rem 0;
  }


  .travel-newscard4-page-araea-sub-headings110 {
    font-size: 24px;
}

.travel-newscard4-page-araea-sub-headings110 {
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
    border-image-slice: 1;
    font-family: wow-bold;
    color: #565656;
    padding-bottom: 5px;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.travel-newscard4-page-page-text {
    font-size: 14px;
    font-family: wow-regular;
  }
















.u-margin-bottom-second-heading-wow-newscard4{
    margin-bottom: 1rem;
}


.heading-secondary-section-wow-newscard4{
    font-size: 3rem;
    font-weight: 700;
    margin-top: 2rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#565656 , #565656b4);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;
    /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat; */
  
  
    
}
































/* collapse css */

.mb-3 {
    margin-bottom: 1rem!important;
}

.accordion>.card-newsroom4:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion>.card-newsroom4:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.accordion .card-newsroom4 {
    border: 0;
}
.accordion>.card-newsroom4 {
    overflow: hidden;
}


.card-newsroom4 {
    position: relative;
    display: -ms-flexbox;
    margin-bottom: .5rem;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: .25rem;
}

.card-newsroom4 .card-newsroom4 {
    background-color: transparent!important;
    border: none;
    height: auto;
}

.accordion .card-header.collapsed:hover {
    padding-right: 50px;
    border-left: 4px solid;
    border-left-color: #f87370;
    color: #f87370;
}

.accordion .card-header.collapsed {
    border-left-color: transparent;
    color: inherit;
    border-left: 4px solid;
    border-left-color: #f87370;
    background-color: #aaaaaa44 !important;
}

.accordion.accordion-white .card-header {
    background-color: #fff;
}

.accordion .card-header {
    padding-right: 50px;
    border-left: 4px solid;
    border-left-color: #f87370;
    transition: border-left-color .5s;
    transition-timing-function: ease;
}

.card-newsroom4 .card-header {
    padding-top: .5rem;
    padding-bottom: .5rem;
}


.card-newsroom4 .card-header {
    padding: 1rem;
}



.accordion .card-header {
    cursor: pointer;
    position: relative;
    background-color: #f5f5f5;
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0;
}


.card-header {
    padding: .75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0,0,0,.03);
    border-bottom: 1px solid rgba(0,0,0,.125);
}


.accordion .card-header .btn-link.btn-link, .accordion .card-header .btn.btn-link {
    text-decoration: none;
    transition: color .5s;
}

.card-header .btn.btn-link {
    padding: 0;
}

.accordion .card-header .card-title {
    padding-right: 50px;
    font-weight: 700;
    white-space: normal;
    text-align: left;
}

.card-header .btn-link {
    font-weight: inherit;
    color: inherit;
}

.accordion .card-title {
    margin-bottom: 0;
}

.newsroom4-button-collapsible:not(:disabled) {
    cursor: pointer;
}


.newsroom4-button-collapsible {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem !important;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.newsroom4-button-collapsible {
    -webkit-appearance: button;
}

.newsroom4-button-collapsible {
    overflow: visible;
}

.newsroom4-button-collapsible {
    margin: 0;
    font-family: wow-medium;
    font-size: inherit !important;
    line-height: inherit;
}


.accordion.indicator-plus .card-header.collapsed::after {
    content: " ";
    background-image: url(/IMAGES/city-images/newsroom/additional-cards/news_card3/plus-svg.png);
    background-size: 18px 18px;
    height: 18px;
    width: 18px;
    float: right;
    margin-top: 4px;
    transition: transform .25s linear;
    transform: rotate(90deg);
    position: absolute;
    right: 1rem;
    top: 25%;
}

.accordion.indicator-plus .card-header::after {
    content: " ";
    background-image: url(/IMAGES/city-images/newsroom/additional-cards/news_card3/minus.png);
    background-size: 18px 18px;
    height: 18px;
    width: 18px;
    float: right;
    margin-top: 4px;
    transition: transform .25s linear;
    transform: rotate(180deg);
    position: absolute;
    right: 1rem;
    top: 25%;
}



.collapse:not(.show) {
    display: none;
}

.card-newsroom4 .card-body-newscard4, .card-newsroom4 .card-footer, .card-newsroom4 .card-header {
    padding: 1rem;
}

.card-body-newscard4 {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.card-newsroom4 .card-body-newscard4>p:last-child {
    margin-bottom: 0;
}

.card-body-newscard4 p {
    font-size: 15px;
    font-family: wow-regular;
    
}







.card-body-newscard4 ul li {
    font-size: 15px;
    font-family: wow-regular;
}

.card-body-newscard4 ul li{
    list-style-type: disc !important;
}













.theme-cyan .accordion .card-header {
    border-left-color: #007c91;
    color: #007c91;
}








.theme-cyan .accordion .card-header:hover {
    border-left-color: #f87370!important;
    color: #f87370!important;
}

.accordion.accordion-white .card-header:hover {
    background-color: #f5f5f5;
}





















@media (max-width:1125px) and (min-width:700px){
    .entry-title-newscard4{
        font-family: wow-bold;
        font-weight: 700;
    }
    
    .entry-title-newscard4 {
        font-size: 33px;
        line-height: 41px;
        font-weight: 500;
        margin-bottom: 14px;
        margin-top: 0;
        color: #fff;
    }

    header .td-module-meta-info-newscard4 {
        margin-bottom: 0px;
    }

    .padding-in-travel-newscard4-grid-text {
        padding: 0rem 0;
    }

    .travel-newscard4-page-araea-sub-headings110 {
        border-bottom: 4px solid #f87370;
        border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
        border-image-slice: 1;
        font-family: wow-bold;
        color: #565656;
        padding-bottom: 5px;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    .travel-newscard4-page-araea-sub-headings110 {
        font-size: 20px;
    }

    .travel-newscard4-page-page-text {
        font-size: 12px;
        font-family: wow-regular;
    }

    .heading-secondary-expert-section-wow-newscard4 {
        font-size: 2rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87370 , #f87270a1);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 2px;
        transition: all .2s;
        width: 81%;
    }
}














@media (max-width: 700px){
    .entry-title-newscard4{
        font-family: wow-bold;
        font-weight: 700;
    }
    
    .entry-title-newscard4 {
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 14px;
        margin-top: 0;
        color: #fff;
    }

    .entry-category-newscard4{
        display: none !important;
    }

    .td-post-title-newscard4 {
        position: absolute;
        bottom: 0;
        padding: 0 30px 9px 30px;
        width: 100%;
        z-index: 1;
    }

    .td-post-featured-image-newscard4 img {
        display: block;
        width: 100%;
        height: 40vh;
    }

    .td-post-header-holder-card4 {
        position: relative;
        min-height: 100px;
        background-color: #444;
        margin-bottom: 36px;
        overflow: hidden;
        margin-top: 36px;
    }

    header .td-module-meta-info-newscard4 {
        margin-bottom: 0px;
    }


    .heading-secondary-section-wow-newscard4 {
        font-size: 2rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#565656 , #565656b4);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 2px;
        transition: all .2s;
    }

    .padding-in-travel-newscard4-grid-text {
        padding: 0rem 0;
    }


    .travel-newscard4-page-araea-sub-headings110 {
        border-bottom: 4px solid #f87370;
        border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
        border-image-slice: 1;
        font-family: wow-bold;
        color: #565656;
        padding-bottom: 5px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
    .travel-newscard4-page-araea-sub-headings110 {
        font-size: 24px;
    }

    .travel-newscard4-page-page-text {
        font-size: 13px;
        font-family: wow-regular;
        margin-bottom: 1rem;
    }

    .heading-secondary-expert-section-wow-newscard4 {
        font-size: 2rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87370 , #f87270a1);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 0px;
        transition: all .2s;
        width: 81%;
    }
}






































