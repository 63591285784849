/* * {
margin: 0;
padding: 0;
box-sizing: border-box;
} */

/* body{
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.7;
    color: #565656;
    padding: 20px;
} */


.header-image-in-new-design{
    height: 95vh;
    background-image: linear-gradient(to right bottom, #7ed56f8c, #28b485a8), url('/IMAGES/wallpaper.jpg');
    background-size: cover;
    background-position: center;
    clip-path: polygon(0 0,100% 0, 100% 550px,0 100%);
    position: relative;
   
   
    
   

}

.logo-inside-header-of-new-design-page{
    position: absolute;
    top: 30px;
    left: 30px;

    
}

.image-logo-inside-header-of-new-design-page{
    height: 40px;
    
}

.image-logo-inside-header-of-new-design-page:hover {
    height: 80px;
    animation-name: moveInleft;
    animation-duration: 1s;
    
}

.text-written{
    font-size: 60px;
    color: ivory;
    left: 40%;
    margin-top: 300px;
    position: absolute;
}


.text-box-inside-background-image-of-new-design{
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);

}


.heading-primary{
    color: #fff;
    text-transform: uppercase;
    backface-visibility: hidden;

}

.heading-primary-main{
    display: block;
    font-size: 60px;
    font-weight: 400;
    letter-spacing: 35px;
    animation-name: moveInleft;
    animation-duration: 1s;

}

.heading-primary-sub{
    display: block;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 17.4px;
    animation-name: moveInright;
    animation-duration: 1s;



}

.button-in-new-design{
    width: 30%;
    height: 50px;
    margin-left: 30%;
    margin-top: 13px;
    animation-name: moveInup;
    animation-duration: 1s;

    /* 
    animation-delay: 2s; 
    */
}

.button-in-new-design:hover{
    opacity: 0.5;
    /* animation-name: moveInright;
    animation-duration: 1s; */
}

@keyframes moveInleft {
    0%{
        opacity: 0;
        transform: translate(-100px);
    }

    80%{
        transform: translate(10px);
    }

    100%{
        opacity: 1;
        transform: translate(0);


    }
}

@keyframes moveInright{
    0%{
        opacity: 0;
        transform: translate(100px);

    }
    80%{
        transform: translate(-10px);
    }
    100%{
        opacity: 1;
        transform: translate(0);

    }
}

@keyframes moveInup{
    0%{
        opacity: 0;
        transform: translate(0 , 60px);

    }
    80%{
        transform: translate(0 , -20px);

    }
    100%{
        opacity: 1;
        transform: translate(0);

    }
}



/* body {
    font-family: 'PT Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    color: #4f5d6e;
    position: relative;
    background: rgb(26, 49, 95);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(26, 49, 95, 1)), color-stop(10%, rgba(26, 49, 95, 1)), color-stop(24%, rgba(29, 108, 141, 1)), color-stop(37%, rgba(41, 136, 151, 1)), color-stop(77%, rgba(39, 45, 100, 1)), color-stop(90%, rgba(26, 49, 95, 1)), color-stop(100%, rgba(26, 49, 95, 1)));
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1a315f', endColorstr='#1a315f', GradientType=0);
  }
  
  .body-wrap {
    min-height: 700px;
  }
  
  .body-wrap {
    position: relative;
    z-index: 0;
  }
  
  .body-wrap:before,
  .body-wrap:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: -1;
    height: 260px;
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(26, 49, 95, 1)), color-stop(100%, rgba(26, 49, 95, 0)));
    background: linear-gradient(to bottom, rgba(26, 49, 95, 1) 0%, rgba(26, 49, 95, 0) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#1a315f', endColorstr='#001a315f', GradientType=0);
  }
  
  .body-wrap:after {
    top: auto;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(26, 49, 95, 0) 0%, rgba(26, 49, 95, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#001a315f', endColorstr='#1a315f', GradientType=0);
  }
  
  nav {
    margin-top: 60px;
    box-shadow: 5px 4px 5px  #000;
  } */

  