@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  @font-face {
    font-family: wow-medium;
    src: url("../fonts/poppins/Poppins-Medium.otf");
  }
  @font-face {
    font-family: wow-regular;
    src: url("../fonts/poppins/Poppins-Regular.otf");
  }
  @font-face {
    font-family: wow-black;
    src: url("../fonts/poppins/Poppins-Black.otf");
  }
  @font-face {
    font-family: wow-holiday-bold;
    src: url("../fonts/poppins/Poppins-Bold.otf");
  }
  @font-face {
    font-family: wow-boldItalic;
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
  }
  @font-face {
    font-family: wow-semiBold;
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
  }
  @font-face {
    font-family: wow-italic;
    src: url("../fonts/poppins/Poppins-Italic.otf");
  }
  @font-face {
    font-family: wow-thin;
    src: url("../fonts/poppins/Poppins-Thin.otf");
  }
  
  

  














//   2nd cars section show of 1-3 passengers

.cars-section-passenger13-taxi-practice{
    @media (min-width: 981px){
.et_pb_gutters3 .et_pb_column_1_3, .et_pb_gutters3.et_pb_row .et_pb_column_1_3 {
    width: 29.6667%;
}
}

@media (min-width: 981px){
.et_pb_gutters3 .et_pb_column, .et_pb_gutters3.et_pb_row .et_pb_column {
    margin-right: 5.5%;
}
}

.et_pb_css_mix_blend_mode_passthrough {
    mix-blend-mode: unset!important;
}
.et_pb_column {
    position: relative;
    z-index: 2;
    min-height: 1px;
}
.et_pb_blurb_8.et_pb_blurb {
    font-family: 'Teko',Helvetica,Arial,Lucida,sans-serif;
    font-size: 26px;
    color: #c10f1b!important;
    line-height: 1.8em;
    margin-bottom: 0px!important;
}
.et_pb_module {
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
}
.et_pb_text_align_center {
    text-align: center;
}
.et_pb_blurb_content {
    max-width: 550px;
    margin: 0 auto;
    position: relative;
    text-align: center;
}
.et_pb_blurb_content {
    word-wrap: break-word;
}
.et_pb_main_blurb_image {
    display: inline-block;
    margin-bottom: 30px;
    line-height: 0;
    max-width: 100%;
}
.et_pb_blurb .et_pb_image_wrap {
    display: block;
    margin: auto;
}
.et-waypoint.et_pb_animation_off, .et_pb_animation_off {
    opacity: 1;
}

.et_pb_blurb.et_pb_text_align_center .et_pb_blurb_content .et_pb_blurb_container {
    text-align: center;
}

.et_pb_blurb_8.et_pb_blurb .et_pb_module_header {
    font-family: 'Teko',Helvetica,Arial,Lucida,sans-serif;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 26px;
    letter-spacing: 4px;
    line-height: 1.6em;
}

.et_pb_blurb_8.et_pb_blurb p {
    line-height: 1.8em;
}
.et_pb_blurb_content p:last-of-type {
    padding-bottom: 0;
}

@media (min-width: 981px){
.et_pb_section .et_pb_row .et_pb_column .et_pb_module.et-last-child{
    margin-bottom: 0;
}
}
.et_pb_toggle_1.et_pb_toggle.et_pb_toggle_close {
    background-color: rgba(0,0,0,0.02);
}
.et_pb_module.et_pb_toggle_1.et_pb_toggle {
    border-width: 0px;
}
.et_pb_toggle_1.et_pb_module {
    margin-left: auto!important;
    margin-right: auto!important;
}
.et_pb_toggle_1.et_pb_toggle {
    line-height: 2em;
    padding-top: 11px!important;
    padding-bottom: 11px!important;
}
.et_pb_toggle_1 {
    max-width: 400px;
}
.et_pb_text_align_left {
    text-align: left;
}
.et_pb_toggle_close {
    background-color: #f4f4f4;
    padding: 0px;
}
.et_pb_toggle {
    border: 1px solid #d9d9d9;
}
.et_pb_text_align_left .et_pb_toggle_title, .et_pb_toggle.et_pb_text_align_left h5.et_pb_toggle_title, .et_pb_toggle .et_pb_toggle_title, .et_pb_toggle h5.et_pb_toggle_title 
{
    cursor: pointer;
    position: relative;
    padding: 0 50px 0 0;
}
.et_pb_toggle_1.et_pb_toggle h5{
    font-weight: 600;
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
}
.et_pb_toggle_close .et_pb_toggle_title, .et_pb_toggle_close h5.et_pb_toggle_title {
    color: #666;
}
.et_pb_toggle .et_pb_toggle_title:before {
    right: 0;
    left: auto;
}

.et_pb_toggle_title:before {
    content: "\e050";
    position: absolute;
    right: 0;
    font-size: 16px;
    color: #ccc;
    top: 50%;
    margin-top: -.5em;
}
.et_pb_toggle_title:before{
    font-family: ETmodules!important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    -webkit-font-feature-settings: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 0 0;
}
// .et_pb_toggle_close .et_pb_toggle_content {
//     display: none;
// }
// .et_pb_toggle_content {
//     padding-top: 20px;
//     position: relative;
// }
.entry-content ul {
    list-style-type: disc;
    padding: 0 0 23px 1em;
    line-height: 26px;
}


ul.subMenu-airporttaxi-holiday-cars-details-section{
    position: absolute;
    z-index: 999;
    width: 500px;
    padding: 5px 5px;
    background: #444;
    text-align: left;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
    -webkit-transform: scaleY(0);
    -ms-transform: scaleY(0);
    -o-transform: scaleY(0);
    transform: scaleY(0);
    transform-origin: top;
    -webkit-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    text-transform: capitalize;
    font-size: 20px !important;
    display: block;
        
    li{
        font-size: 20px !important;
        display: block;
        padding: 0;
        color: #000 !important;
        border-bottom: 1px solid #f2f2f2;
        &:last-child{
        border-bottom: none;
        }
    }
}
.et_pb_toggle_title:hover .subMenu-airporttaxi-holiday-cars-details-section:hover{
    -webkit-transform: scaleY(1);
-ms-transform: scaleY(1);
-o-transform: scaleY(1);
transform: scaleY(1);
}


[data-theme='compact'] .site-collapse-custom-collapse .site-collapse-custom-panel,
.site-collapse-custom-collapse .site-collapse-custom-panel {
  background: #f7f7f7;
  border-radius: 2px solid #000 !important;
  margin-bottom: 0px;
  border: 0px;
  overflow: hidden;
}






}