@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
}




















.new-email-invoice-div-body-le{
    display: flex;
    margin: 24px auto;
    flex-direction: column;
    width: 600px;
    background-color: #252525;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.199);
    position: relative;
    padding:  50px 0px;
    min-height: 524px;
    font-size: 14px;
    border: 1px solid #e1e1e1;
    box-sizing: border-box;
}
    
   

    .invoice-contemplary-body-inside-new-noone .le-text {
        font-size: 11px;
        color: #cdb45c;
        
    }
    
   
    .le-divider {
        display: block;
        height: 0;
        padding: 0;
        border: none;
        border-bottom: 1px solid #d4dde3;
        overflow: hidden;
        margin: 24px auto;
}
    
    
    
    

.email-invoice-body-inside__header__logo {
    text-align: center;
    

}


.email-invoice-body-inside__header__logo img {
    max-height: 220px;
    max-width: 220px;

}

.bg-color-invoice-email{
    background-color: rgba(173, 161, 161, 0.486);
}


.email-text-le-inoice13{
    font-size: 20px;
    font-family: wow-bold;
    margin-top: 20px;
    color: #ffffff;
    text-align: center;
    
}

.invoice13-email-text{
    font-size: 20px;
    font-family: wow-regular;
    margin-top: 20px;
    color: #ffffff;
    text-align: center;

}

.invoice13-email-text-le{
    font-size: 18px;
    font-family: wow-medium;
    margin-top: 20px;
    color: #ffffff;
    text-align: center;
    margin-top: 40px;

}


.invoice13-email-text-phoneemail{
    font-size: 20px;
    font-family: wow-regular;
    color: #ffffff;
    text-align: center;

}

.email-invoice13__divider--full-width .le-divider-email-inoice13 {
    margin-left: 86px;
    margin-right: 86px;
}


.le-divider-email-inoice13 {
    display: block;
    height: 0;
    padding: 0;
    border: none;
    border-bottom: 2px solid #ffffff;
    overflow: hidden;
    margin: 10px auto;
}


.set-anchor-link-email-invoice13{
    text-align: center;
    margin-top: 40px;
    

}

.anchor-link-email-invoice13{
    font-size: 20px;
    font-family: wow-regular;
    background-color: #ffffff;
    color: #000000;
    padding: 10px 20px;
    border-radius: 30px;
    

}