@font-face {
  font-family: wow-bold;
  src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
}
@font-face {
  font-family: wow-regular;
  src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
    format("otf");
}


@font-face {
    font-family: wow-medium;
    src: url('../fonts/poppins/Poppins-Medium.otf');
  }  
  @font-face {
    font-family: wow-regular;
    src: url('../fonts/poppins/Poppins-Regular.otf');
  }  
  @font-face {
    font-family: wow-black;
    src: url('../fonts/poppins/Poppins-Black.otf');
  }  
  @font-face {
    font-family: wow-holiday-bold;
    src: url('../fonts/poppins/Poppins-Bold.otf');
  }  
      @font-face {
    font-family: wow-boldItalic;
    src: url('../fonts/poppins/Poppins-BoldItalic.otf');
  } 
  @font-face {
    font-family: wow-semiBold;
    src: url('../fonts/poppins/Poppins-SemiBold.otf');
  } 
  @font-face {
    font-family: wow-italic;
    src: url('../fonts/poppins/Poppins-Italic.otf');
  }
  @font-face {
    font-family: wow-thin;
    src: url('../fonts/poppins/Poppins-Thin.otf');
  }



















.card-dest:hover{
  transform: translateY(-10px);
}













.ha .ha-featured {
    color: #333;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 1.42857;
    /* margin: 0;
    padding: 0; */
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    text-align: center;
    /* position: relative;
    min-height: 1px; */
    padding-left: 15px;
    padding-right: 15px;
    /* float: left;
    width: 50%; */
  }
  .card-holiday-inspiration-aukland {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px ;
    line-height: 1.42857;
    text-align: center;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background-color: transparent;
    color: #333;
  }
  .card-dest {
    font-size: 14px;
    line-height: 1.42857;
    color: #333;
    -webkit-font-smoothing: antialiased;
    box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
    font-family: helvetica, arial, sans-serif;
    text-align: center;
    background: #fff;
    padding: 0 0 20px;
    margin: 20px auto;
    display: inline-table !important;
    
    height: 366px ;
  }
  .card-dest-image {
    font-size: 14px;
    line-height: 1.42857;
    color: #333;
    font-family: helvetica, arial, sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    position: relative;
    margin-bottom: 20px;
  }
  .card-tag {
    line-height: 1.42857;
    font-family: helvetica, arial, sans-serif;
    text-align: center;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    position: absolute;
    background-color: #d61920;
    font-weight: 700;
    top: 18.43px;
    padding: 7px 7px 7px 15px;
    color: #fff;
    font-size: 14px;
  }
  .deffer-image {
    font-size: 14px;
    line-height: 1.42857;
    color: #333;
    font-family: helvetica, arial, sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    border: 0;
    vertical-align: middle;
    width: 100%;
    height: 180px;
    background-size: cover;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .card-tag .text {
    line-height: 1.42857;
    font-family: helvetica, arial, sans-serif;
    text-align: center;
    font-weight: 700;
    color: #fff;
    font-size: 14px;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
  }
  .card-dest .card-tag:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(213, 11, 11, 0);
    border-left-color: #d61920;
    border-right-color: transparent\9;
    border-width: 16px;
    margin-top: -16px;
    border-left-color: #d61920;
  }
  .card-dest-heading {
    line-height: 1.42857;
    color: #333;
    text-align: center;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-size: 50px ;
    
  }
  .card-dest-heading h3 {
    text-align: center;
    -webkit-font-smoothing: antialiased;
    
    letter-spacing: 0.5px !important;
    font-weight: 600 !important;
    box-sizing: border-box;
    font-family: wow-bold !important;
    line-height: 1.1;
    font-size: 30px;
    color: #333;
    padding: 10px 0;
    margin: 0;
  }
  .card-dest-info {
    line-height: 1.42857;
    text-align: center;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    font-family: helvetica, arial, sans-serif !important;
    font-size: 13px;
    color: #666;
    letter-spacing: 0;
    padding: 0 10px;
    min-height: 25px;
    overflow: hidden;
  }


  .card-dest-lable{
    text-transform: uppercase !important;
    color: #565656;
    font-family: wow-regular;
    font-weight: 400;
      

  }






  .faisla-kr-be{
      display: none;
  }



  .button-featured-holiday-inspiration{
    width: 31%;
    background-color: #fff !important;
    color: black !important;
    font-size: 22px !important;
    padding: 0 !important;
    border-radius: 15px !important;
    
    border-color: #f87370 !important;
    outline-color: #f87370 !important;

  }


  .button-featured-holiday-inspiration:hover{
    background-color: #f87370 !important;
    color: white !important;
  }




  @media(max-width: 700px){
    .button-featured-holiday-inspiration{
      width: 100%;
      background-color: #fff !important;
      color: black !important;
      font-size: 22px !important;
      padding: 0 !important;
      border-radius: 15px !important;
      
      border-color: #f87370 !important;
      outline-color: #f87370 !important;
  
    }

    .ha {
      color: #333;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 1.42857;
      /* margin: 0;
      padding: 0; */
      -webkit-font-smoothing: antialiased;
      box-sizing: border-box;
      text-align: center;
      /* position: relative;
      min-height: 1px; */
      padding-left: 15px !important;
      padding-right: 15px !important;
      /* float: left;
      width: 94% !important; */
    }


    .ha-featured {
      color: #333;
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 1.42857;
      /* margin: 0;
      padding: 0; */
      -webkit-font-smoothing: antialiased;
      box-sizing: border-box;
      text-align: center;
      /* position: relative;
      min-height: 1px; */
      padding-left: 15px !important;
      padding-right: 0px !important;
      /* float: left; */
      width: 94% !important;
    }








    .card-tag {
      line-height: 1.42857;
      font-family: helvetica, arial, sans-serif;
      text-align: center;
      margin: 0;
      -webkit-font-smoothing: antialiased;
      box-sizing: border-box;
      position: absolute;
      background-color: #d61920;
      font-weight: 700;
      top: 18.43px;
      padding: 7px 7px 7px 15px;
      color: #fff;
      font-size: 14px;
    }

  }
