@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }













/* carousel image banner */

.carousel-wow-travel-works {
  position: relative;
  width: 100%;
}


.slide-wow-travel-works {
  height: auto!important;
}

.slide-wow-travel-works {
  width: 100%;
  height: auto;
  overflow: hidden;
}





.carousel-indicators-wow-travel-works {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
}


ol.carousel-indicators-wow-travel-works {
  margin-top: 0;
  margin-bottom: 1rem;
}

.carousel-indicators-wow-travel-works .active-wow-travel-works {
  opacity: 1;
}


.carousel-indicators-wow-travel-works li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease;
  color: #fff !important;
}





.carousel-inner-wow-travel-works {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-item-wow-travel-works.active-carousel-inner-wow-travel-works {
  display: block;
}


.carousel-inner-wow-travel-works::after {
  display: block;
  clear: both;
  content: "";
}



.carousel-item-wow-travel-works {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: -webkit-transform .6s ease-in-out;
  transition: transform .6s ease-in-out;
  transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
}



.w-100-wow-travel-works {
  width: 100%!important;
}
.d-block-wow-travel-works {
  display: block!important;
}


.image-carousel-wow-travel-works img{
  vertical-align: middle;
  border-style: none;
}



.home_slider_text_area123456l-wow-travel-works {
  background-color: rgba(0,0,0,.5);
}

.home_slider_text_area123456l-wow-travel-works h3 {
  -webkit-animation: tracking-in-expand-fwd-top 1.2s cubic-bezier(.215,.61,.355,1) both;
  animation: tracking-in-expand-fwd-top 1.2s cubic-bezier(.215,.61,.355,1) both;
}


@media (max-width: 7000px) and (min-width: 400px){
  .home_slider_text_area123456l-wow-travel-works h3, .offices-heading-text {
      text-transform: uppercase;
      font-family: wow-bold;
  }
  
  .about_fdkng12154-wow-travel-works {
      font-size: 24px;
      font-family: wow-bold;
  }
  
  .about_fdkng-wow-travel-works{
      font-size: 16px;
  
  }
  
  .home_slider_text_area123456l-wow-travel-works p {
      font-family: wow-regular;
      margin-bottom: 1rem;
      margin-top: 0;
  }
}

.carousel-caption-wow-travel-works {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
}




.about_fdkng12154-wow-travel-works h3{
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}



.carousel-control-prev-wow-travel-works {
  left: 0;
}

.carousel-control-next-wow-travel-works, .carousel-control-prev-wow-travel-works {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 5;
  transition: opacity .15s ease;
}


.sr-only-wow-travel-works {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0,0,0,0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
}

.carousel-control-next-wow-travel-works {
  right: 0;
}



/* media queries for slider */


@media(max-width:700px){
  .w-100-wow-travel-works {
      width: 100%!important;
      height: 33vh;
  }


  .about_fdkng12154-wow-travel-works {
      font-size: 15px;
      font-family: wow-bold;
      margin-bottom: 0rem;
      font-weight: 100;
      line-height: 1;
  }

  .about_fdkng-wow-travel-works{
      display: none;
  
  }
}


@media(max-width:1120px) and (min-width:700px){
  .w-100-wow-travel-works {
      width: 100%!important;
      
  }


  .about_fdkng12154-wow-travel-works {
      font-size: 18px;
      font-family: wow-bold;
      margin-bottom: 0rem;
      font-weight: 100;
      line-height: 1.2;
  }

  .about_fdkng-wow-travel-works{
      font-size: 13px;
      padding: 0 2rem;
      margin-bottom: 0 !important;
  
  }
}


/* //////////////////////////////////////////////////// */
















































.padding-in-travel-how-work-grid-text{
  padding: 7rem 0;
}




.heading-top-text-image-wow-travel-how-works{
  text-align: center !important;
}
.wow_community-wow-travel-how-works {
  padding: 50px 0 0 0;
  font-family: wow-bold;
  margin-bottom: 20px;
  font-size: 30px;
  background: linear-gradient(90deg, transparent 20%, #f87370 0%, #f87370 80%, transparent 20%);
background-size: 100%  5px;
background-position: 0% 100%;
background-repeat: no-repeat;
text-transform: uppercase;
display: inline-block;
color: #565656;
padding-bottom: 5px; 
  line-height: 1.2;
  
}

.wow_community-wow-travel-how-works-heading-text{
  font-family: wow-regular;
  font-size: 14px;
  text-align: center;
  padding: 0 5rem;

}



.wow-travel-how-works-page-top-image{
  background-image: url();
  background-image: linear-gradient(to left bottom, #00000054, #00000000),
  url(/IMAGES/city-images/wow-travel-how-works-card/place3.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 80%;
  position: relative;
  width: 100%;
  padding-bottom: 38%;
  margin: 1.5rem 0;
}


.wow-travel-how-works-page-top-image-inside-text{
  font-size: 26px;
  color: #fff;
  position: absolute;
  bottom: 30px;
  /* width: 40%; */
  left: 50%;
  font-weight: 500;
  right: auto;
  transform: translateX(-50%);
  line-height: 1.15em;
  font-style: oblique;
  font-family: -apple-system,BlinkMacSystemFont,avenir next,avenir,segoe ui,lucida grande,helvetica neue,helvetica,fira sans,roboto,noto,droid sans,cantarell,oxygen,ubuntu,franklin gothic medium,century gothic,liberation sans,sans-serif;
}


.wow-travel-how-works-page-top-image-inside-text-author-name{
  margin-top: 10px;
    text-shadow: 0 2px 8px rgba(0,0,0,.5);
    font-size: 19px;
}


@media screen and (min-width: 62em){
  .w-banner-quote-icon {
      left: -10px;
      top: -50px;
      width: 120px;
      height: 100px;
  }
  }
  
  .w-banner-quote-icon {
    position: absolute;
    top: -20px;
    left: -30px;
    width: 90px;
    height: 90px;
  }












































  .travel-how-work-page-araea-sub-headings110 {
    font-size: 24px;
}

.travel-how-work-page-araea-sub-headings110 {
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
    border-image-slice: 1;
    font-family: wow-bold;
    color: #565656;
    padding-bottom: 5px;
    margin-bottom: 25px;
    text-transform: uppercase;  
}


.travel-how-work-page-page-text {
  font-size: 14px;
  font-family: wow-regular;
}











.gradient-back-travel-how-work{
  background-color: #f3f3f3;
  padding: 50px 0;
  
}
  
  
@media (max-width: 7000px) and (min-width: 768px){
.wow-travel-work-top-area-second-area {
  padding: 50px 0 10px;
}


.wow-travel-work-top-area-second-area-left-area img {
  width: 170px;
  margin-bottom: 20px;
}


.wow-travel-work-top-area-second-area-left-area h3 {
  font-size: 24px;
  font-family: wow-bold;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.wow-travel-work-top-area-second-area-left-area p {
  font-size: 14px;
  font-family: wow-regular;
  margin-top: 0;
  margin-bottom: 1rem;
}
}
  






/* safety icons */


.lg-icon-wow-travel-work, 
.circle-icon-wow-travel-work {
    width: 100px !important;
    height: 100px !important;
    line-height: 95px !important;
    font-size: 50px !important;
    margin-bottom: 1.5rem ;
   
}


.primary-icon-filled-wow-travel-work {
    background: #f87370;
    color: #fff;
border: none;
}

.circle-icon-wow-travel-work{
    border-radius: 50%;
}


























.wow-travel-work-page-top-image{
  margin: 2rem 0 0 0;
}




.u-margin-bottom-second-heading-wow-travel-work{
  margin-bottom: 1rem;
}


.heading-secondary-section-wow-travel-work{
  font-size: 3.5rem;
  font-weight: 700;
  margin-top: 2rem;
  font-family: wow-bold;
  display: inline-block;
  background-image: linear-gradient(to right,#f87270 , #ff615ea9);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 2px;
  transition: all .2s;
  /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
  display: inline-block;
  background-size: 100% 5px;
  background-position: 0 100%;
  background-repeat: no-repeat; */


  
}
















.wow-travel-work-top-area-third-area-contet{
  margin-bottom: 5rem;
}


.wow-travel-work-top-area-third-area-contetn p, .wow-travel-work-top-area-third-area-contetn ul li {
  font-size: 16px;
  font-family: wow-regular;
}

.wow-travel-work-top-area-third-area-contetn ul li{
  list-style-type: disc !important;
}


.wow-travel-work-top-area-third-area-contetn p{
margin-top: 0;
  margin-bottom: 1rem;
}


.wow-travel-work-top-area-third-area-contetn h3 {
  font-size: 26px;
  font-family: wow-bold;
}

































@media (max-width:1125px) and (min-width:700px){
  .wow_community-wow-travel-how-works {
    padding: 40px 0 0 0;
    font-family: wow-bold;
    margin-bottom: 20px;
    font-size: 26px;
    background: linear-gradient(90deg, transparent 20%, #f87370 0%, #f87370 80%, transparent 20%);
  background-size: 100%  5px;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  text-transform: uppercase;
  display: inline-block;
  color: #565656;
  padding-bottom: 5px; 
    line-height: 1.2;
    
  }
  
  .wow_community-wow-travel-how-works-heading-text{
    font-family: wow-regular;
    font-size: 13px;
    text-align: center;
    padding: 0 3rem;
  
  }


  .padding-in-travel-how-work-grid-text{
    padding: 3rem 0;
}



  .travel-how-work-page-araea-sub-headings110 {
    font-size: 16px;
}

.travel-how-work-page-araea-sub-headings110 {
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
    border-image-slice: 1;
    font-family: wow-bold;
    color: #565656;
    padding-bottom: 5px;
    margin-bottom: 10px;
    text-transform: uppercase;
}


.travel-how-work-page-page-text {
  font-size: 13px;
  font-family: wow-regular;
}

}













@media(max-width:700px){

  .wow_community-wow-travel-how-works {
    padding: 30px 0 0 0;
    font-family: wow-bold;
    margin-bottom: 20px;
    font-size: 20px;
    background: linear-gradient(90deg, transparent 20%, #f87370 0%, #f87370 80%, transparent 20%);
  background-size: 100%  5px;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  text-transform: uppercase;
  display: inline-block;
  color: #565656;
  padding-bottom: 5px; 
    line-height: 1.2;
    
  }
  
  .wow_community-wow-travel-how-works-heading-text{
    font-family: wow-regular;
    font-size: 13px;
    text-align: center;
    padding: 0 0rem;
  
  }

  .wow-travel-how-works-page-top-image-inside-text{
    font-size: 16px;
    color: #fff;
    position: absolute;
    bottom: 0px;
    /* width: 40%; */
    left: 42%;
    font-weight: 500;
    right: auto;
    transform: translateX(-50%);
    line-height: 1em;
    font-style: oblique;
    font-family: -apple-system,BlinkMacSystemFont,avenir next,avenir,segoe ui,lucida grande,helvetica neue,helvetica,fira sans,roboto,noto,droid sans,cantarell,oxygen,ubuntu,franklin gothic medium,century gothic,liberation sans,sans-serif;
  }

  .wow-travel-how-works-page-top-image-inside-text-author-name{
    margin-top: 0px;
      text-shadow: 0 2px 8px rgba(0,0,0,.5);
      font-size: 14px;
  }

  .padding-in-travel-how-work-grid-text{
    padding: 0rem 0;
}



  .travel-how-work-page-araea-sub-headings110 {
    font-size: 16px;
}

.travel-how-work-page-araea-sub-headings110 {
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
    border-image-slice: 1;
    font-family: wow-bold;
    color: #565656;
    padding-bottom: 5px;
    margin-bottom: 10px;
    text-transform: uppercase;
}


.travel-how-work-page-page-text {
  font-size: 13px;
  font-family: wow-regular;
}



.wow-travel-work-top-area-second-area-left-area h3 {
  font-size: 24px;
  font-family: wow-bold;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.wow-travel-work-top-area-second-area-left-area p {
  font-size: 14px;
  font-family: wow-regular;
  margin-top: 0;
  margin-bottom: 1rem;
}


.heading-secondary-section-wow-travel-work{
  font-size: 2.3rem;
  font-weight: 700;
  margin-top: 2rem;
  font-family: wow-bold;
  display: inline-block;
  background-image: linear-gradient(to right,#f87270 , #ff615ea9);
  -webkit-background-clip: text;
  color: transparent;
  letter-spacing: 0px;
  transition: all .2s;
  /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
  display: inline-block;
  background-size: 100% 5px;
  background-position: 0 100%;
  background-repeat: no-repeat; */


  
}
}