@font-face {
  font-family: wow-bold;
  src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
}
@font-face {
  font-family: wow-regular;
  src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
    format("otf");
}

@font-face {
  font-family: wow-medium;
  src: url("../fonts/poppins/Poppins-Medium.otf");
}
@font-face {
  font-family: wow-regular;
  src: url("../fonts/poppins/Poppins-Regular.otf");
}
@font-face {
  font-family: wow-black;
  src: url("../fonts/poppins/Poppins-Black.otf");
}
@font-face {
  font-family: wow-holiday-bold;
  src: url("../fonts/poppins/Poppins-Bold.otf");
}
@font-face {
  font-family: wow-boldItalic;
  src: url("../fonts/poppins/Poppins-BoldItalic.otf");
}
@font-face {
  font-family: wow-semiBold;
  src: url("../fonts/poppins/Poppins-SemiBold.otf");
}
@font-face {
  font-family: wow-italic;
  src: url("../fonts/poppins/Poppins-Italic.otf");
}
@font-face {
  font-family: wow-thin;
  src: url("../fonts/poppins/Poppins-Thin.otf");
}


























.news-room-top-content {
  background-image: url(/IMAGES/city-images/newsroom/news-bg1.jpg);
  min-height: 450px;
    padding-top: 92px;
    position: relative;
    background-position: 30%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
 
}

.news-room-top-content:before {
  content: "";
  position: absolute;
background: linear-gradient(90deg,rgba(21,26,48,.79) 8%,rgba(21,26,48,.79) 46%,rgba(21,26,48,.07) 95%,rgba(21,26,48,0));
width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.news-room-container-top {
  position: relative;
  width: 100%;
  padding-top: 39.25%;
}

.news-room-top-content-area {
  position: absolute;
  top: 35%;
  transform: translateY(-50%);
  left: 7.5%;
  margin-top: 56px;
}

@media(max-width:7000px) and (min-width:2500px){
  .news-room-top-content-area {
    position: absolute;
    top: 40%;
    transform: translateY(-50%);
    left: 7.5%;
    margin-top: 56px;
  }
}

.news-room-grid {
  margin-right: 7px !important;
  margin-left: 6px !important;
}


@media (max-width: 1100px) and (min-width: 580px){
  .news-room12 {
      padding: 20px 0 0 30px;
  }
  }


  @media (max-width: 7000px) and (min-width: 800px){
    .news-room-banner-top-heading {
        font-size: 50px;
        font-family: wow-bold;
        width: 30ch;
    }
    }


    @media (max-width: 7000px) and (min-width: 800px){
      .news-room-banner-top-co {
          width: 56ch;
      }
      }



      .text-in-h3-news-room h3{
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
      }


      @media(max-width:1200px) and (min-width:700px){
        .simplify-news-room-text-col-tab{
          display: none;
        }

      }







.news-room-header-text{
  font-size: 42px;
  font-family: wow-bold;
  text-align: center;
  margin-top: 50px;
  
  padding-bottom: 5px;  
  /* bottom padding should be higher to make up for pseudo border height  */
background: linear-gradient(90deg, transparent 20%, #f87370 20%, #f87370 80%, transparent 60%);
background-size: 100%  5px;
background-position: 0% 100%;
background-repeat: no-repeat;
text-transform: uppercase;
display: inline-block;
color: #565656;
  
}


.news-room-header-press-media{
  font-size: 42px;
  font-family: wow-bold;
  text-align: center;
  margin-top: 50px;
  
  padding-bottom: 5px;  
  /* bottom padding should be higher to make up for pseudo border height  */
background: linear-gradient(90deg, transparent 20%, #f87370 20%, #f87370 80%, transparent 60%);
background-size: 100%  5px;
background-position: 0% 100%;
background-repeat: no-repeat;
text-transform: uppercase;
display: inline-block;
color: #565656;
  
}


.news-room-header-press-media-text{
  font-size: 14px;
  font-family: wow-regular;
  text-align: center;
  margin-top: 30px;
color: #565656;
padding: 0 18rem;

}



.news_room {
  padding: 50px 0 0 0;
  /* text-align: left; */
  font-family: wow-bold;
  margin-bottom: 14px;
  font-size: 42px;
  background: linear-gradient(90deg, transparent 20%, #f87370 20%, #f87370 80%, transparent 60%);
background-size: 100%  5px;
background-position: 0% 100%;
background-repeat: no-repeat;
text-transform: uppercase;
display: inline-block;
color: #565656;
padding-bottom: 5px; 
  line-height: 1.2;
}

.news_room-heading-text{
  font-family: wow-regular;
  font-size: 14px;

}






.sidebarTitle {
  background: #ddd;
  padding: 10px 20px 20px;
  position: relative;
  font-size: 34px;
  margin-bottom: 0;
  color: #565656;
  font-family: wow-bold;
}


.sidebarTitle:before {
  content: "";
  position: absolute;
  left: 30px;
  top: 100%;
  border: 15px solid transparent;
  border-top: 13px solid rgba(0,0,0,.1);
}


.MuiTabs-root-news-room {
  display: flex;
  overflow: hidden;
  min-height: 48px;
  /* max-height: 208px; */
  -webkit-overflow-scrolling: touch;
  background: #f5f5f5;
  border: 3px solid #0000003b;
}

.jss9-news-room {
  border-right: 1px solid rgba(0, 0, 0, 0.178);
}

.MuiTabs-vertical-news-room {
  flex-direction: column !important;
}

.MuiTabs-scrollable-help-center {
  /* overflow-y: scroll ; */
  scrollbar-width: none;
}



.MuiTabs-scroller-news-room {
  flex: 1 1 auto;
  display: inline-block;
  position: relative;
  white-space: nowrap;
}

.MuiTabs-flexContainerVertical-news-room {
  flex-direction: column !important;
}

.MuiTabs-flexContainer-news-room {
  display: flex;
  
}

.MuiTabs-scroller-news-room {
  white-space: nowrap ;
  
}

.MuiButtonBase-root-news-room {
  color: inherit;
  border: 0;
  border-bottom: 2px solid #ddd;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}


.on-focus-button-news-room:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

.on-focus-button-news-room:focus{
  opacity: 1 !important;
}

@media (min-width: 600px){
  .MuiTab-root-news-room {
      font-size: 0.8125rem;
      min-width: 160px !important;
      
  }
  
  }



.MuiTab-root-news-room {
  padding: 15px 40px 25px !important;
  overflow: hidden;
  position: relative;
  font-size: 0.875rem;
  max-width: 564px;
  min-width: 72px;
  box-sizing: border-box;
  min-height: 48px;
  text-align: center;
  flex-shrink: 0;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  white-space: normal;
  letter-spacing: 0.07857em !important;
  text-transform: uppercase;
}

.MuiTab-textColorInherit-news-room {
  color: inherit !important;
  opacity: 0.8 !important;
  
}

.news-room-tab-name {
  font-family: wow-medium!important;
  text-align: left!important;
  font-size: 17px!important;
  color: #333 !important;
  transition: all .3s;
}

.news-room-tab-name:hover{
  color: #f8727081 !important;
}


.MuiTab-wrapper-news-room {
  width: 100%;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.MuiTouchRipple-root-news-room {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  overflow: hidden;
  position: absolute;
  border-radius: inherit;
  pointer-events: none;
}























/* recent news cards */


.card-news-room{
  height: auto;
    max-width: 360px;
  

}


.card-img-top-carousel-new{
  height: 100%;
  width: 100%;
  max-width: 360px;
  /* border: 1px solid #d3d3d3; */
}

.card-img-top-news-room{
  height: 100%;
  max-height: 200px;
  width: 100%;
  max-width: 350px;
}

.card-img-top-news-room-dubai{
  height: auto;
  width: 100%;

}


  .card-text {
            
            line-height: 1.3;
            font-size: 14px;
    font-weight: 500;
    text-align: left;
    display: inline-block;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
        } 



        .carousel-inside-link-card-news-room{
          color: #f87370;
          font-family: wow-bold;
          font-size: 18px;
          text-align: right !important;
          justify-content: right;
          
          
      }


      .date-card-news-room{
        font-size: 15px;
        font-family: wow-medium;
        text-align: left;
        margin-top: 65px;
    }

.card-title-news-room{

  font-family: wow-bold;
  font-size: 17px;
  color: #565656;

}


.card-text-news-room{

  font-family: wow-regular;
  font-size: 15px;
  color: #aaaaaa;
  text-transform: initial;

}







@media(max-width:700px){

  .news-room-top-content {
    background-image: url(/IMAGES/city-images/newsroom/news-bg1.jpg);
    min-height: 450px;
      padding-top: 92px;
      position: relative;
      background-position: 35%;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
   
  }
  .news-room-header-press-media-text{
    font-size: 14px;
    font-family: wow-regular;
    text-align: center;
    margin-top: 30px;
  color: #565656;
  padding: 0 1rem;
  
  }

  .news-room-header-press-media{
    font-size: 34px;
    font-family: wow-bold;
    text-align: center;
    margin-top: 50px;
    
    padding-bottom: 5px;  
    /* bottom padding should be higher to make up for pseudo border height  */
  background: linear-gradient(90deg, transparent 20%, #f87370 20%, #f87370 80%, transparent 60%);
  background-size: 100%  5px;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  text-transform: uppercase;
  display: inline-block;
  color: #565656;
    
  }

}


@media (max-width: 700px) {
  .muitab-in-pc-view-news-room{
  display: none !important;
  }
  }


  @media (min-width: 700px) {
    .sidenav-newsroom-mobile-view{
      display: none !important;
    }
    }
    
    @media (max-width: 700px) {
    .sidenav-newsroom-mobile-view{
      display: "";
    }
    }



    @media screen and (max-height: 450px) {
      .sidenav-newsroom {
        padding-top: 15px;
      }
    
    
      .sidenav-newsroom a {
        font-size: 18px;
      }
    }



@media(max-width:700px){
  .sidenav-newsroom {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(15, 15, 15);
    overflow-x: hidden;
    transition: 0.4s;
    padding-top: 0px;
  }
  
  .sidenav-newsroom button {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181 ;
    display: block;
    transition: 0.3s;
  }
  
  .sidenav-newsroom button:hover {
    color: #f1f1f1;
  }
  
  .sidenav-newsroom .closebtn-newsroom {
    position: absolute;
    top: 10px;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .closebtn-newsroom {
    color: #565656 !important;
  }


  .newsroom-menu-button-border{
    border-bottom: 5px solid #e7e7e7;
    padding-bottom: 1rem;
    }
    
    .newsroom-menu-button{
    font-size: 32px;
    font-family: wow-medium;
    cursor: pointer;
    margin-left: 1rem;
    }



    .sidebarTitle {
      background: #ddd;
      padding: 10px 20px 20px;
      position: relative;
      font-size: 34px;
      margin-bottom: 0;
      color: #565656;
      font-family: wow-bold;
    }
    
    
    .sidebarTitle:before {
      content: "";
      position: absolute;
      left: 30px;
      top: 100%;
      border: 15px solid transparent;
      border-top: 13px solid rgba(0,0,0,.1);
    }

    .MuiButtonBase-root-news-room {
      color: inherit;
      border-bottom: 1px solid #ddd;
      cursor: pointer;
      margin: 0;
      display: inline-flex;
      outline: 0;
      padding: 0;
      position: relative;
      align-items: center;
      user-select: none;
      border-radius: 1;
      vertical-align: middle;
      -moz-appearance: none;
      justify-content: center;
      text-decoration: none;
      background-color: transparent;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
    }

    .MuiTab-root-news-room {
      padding: 15px 0px 25px 45px !important;
      overflow: hidden;
      position: relative;
      font-size: 0.875rem;
      max-width: 100%;
      min-width: 72px;
      box-sizing: border-box;
      min-height: 48px;
      text-align: center;
      flex-shrink: 0;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 500;
      line-height: 1.75;
      white-space: normal;
      letter-spacing: 0.00057em !important;
      text-transform: uppercase;
    }
    
    .MuiTab-textColorInherit-news-room {
      color: inherit !important;
      opacity: 0.9 !important;
      
    }
    
    .news-room-tab-name {
      font-family: wow-medium!important;
      text-align: left!important;
      font-size: 19px!important;
      color: #fff !important;
      transition: all .3s;
    }
}



@media(max-width:1125px) and (min-width:700px){
  .news-room-tab-name {
    font-family: wow-medium!important;
    text-align: left!important;
    font-size: 15px!important;
    color: #565656 !important;
    transition: all .3s;
  }

  .MuiTab-root-news-room {
    padding: 15px 0px 25px 5px !important;
    overflow: hidden;
    position: relative;
    font-size: 0.875rem;
    max-width: 100% !important;
    min-width: 72px;
    box-sizing: border-box;
    min-height: 48px;
    text-align: center;
    flex-shrink: 0;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    white-space: normal;
    letter-spacing: 0.00007em !important;
    text-transform: uppercase;
  }

  .news-room-header-press-media-text{
    font-size: 14px;
    font-family: wow-regular;
    text-align: center;
    margin-top: 30px;
  color: #565656;
  padding: 0 5rem;
  
  }




  
  
  
  .card-img-top-news-room{
    height: 100%;
    max-height: 200px !important;
    width: 100%;
    max-width: 200px;
  }
  
  
    .card-text {
              
              line-height: 1.3;
              font-size: 14px;
      font-weight: 500;
      text-align: left;
      display: inline-block;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
          } 
  
  
  
          .carousel-inside-link-card-news-room{
            color: #f87370;
            font-family: wow-bold;
            font-size: 18px;
            text-align: right !important;
            justify-content: right;
            
            
        }
  
  
        .date-card-news-room{
          font-size: 15px;
          font-family: wow-medium;
          text-align: left;
          margin-top: 65px;
      }
  
  .card-title-news-room{
  
    font-family: wow-bold;
    font-size: 17px;
    color: #565656;
  
  }
  
  
  .card-text-news-room{
  
    font-family: wow-regular;
    font-size: 14px;
    color: #aaaaaa;
  
  }

  .card-news-room{
    height: auto;
      max-width: 360px;
    
  
  }
}

