@font-face {
  font-family: wow-bold;
  src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
}
@font-face {
  font-family: wow-regular;
  src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
    format("otf");
}


@font-face {
    font-family: wow-medium;
    src: url('../fonts/poppins/Poppins-Medium.otf');
  }  
  @font-face {
    font-family: wow-regular;
    src: url('../fonts/poppins/Poppins-Regular.otf');
  }  
  @font-face {
    font-family: wow-black;
    src: url('../fonts/poppins/Poppins-Black.otf');
  }  
  @font-face {
    font-family: wow-holiday-bold;
    src: url('../fonts/poppins/Poppins-Bold.otf');
  }  
      @font-face {
    font-family: wow-boldItalic;
    src: url('../fonts/poppins/Poppins-BoldItalic.otf');
  } 
  @font-face {
    font-family: wow-semiBold;
    src: url('../fonts/poppins/Poppins-SemiBold.otf');
  } 
  @font-face {
    font-family: wow-italic;
    src: url('../fonts/poppins/Poppins-Italic.otf');
  }
  @font-face {
    font-family: wow-thin;
    src: url('../fonts/poppins/Poppins-Thin.otf');
  }
























.icons-in-about-us-wow-holiday{
    color: #f87370;
    font-size: 42px;
    text-align: left;
    


}

.text-written-after-icons-in-about-us-wow-holiday{
    font-size: 20px;
    font-family: wow-bold;
    font-weight: 700;
    color: #565656;
    

}

.image-our-values-in-about-us-wow-holiday{
    max-height: 270px;
    height: 100%;
   width: 100%;
   max-width: 400px;
    
}


.padding-in-our-values-icons-about-wow-holiday{
    padding: 7% 0;
}








/* .bg-displaced-above-about-us {
  position: absolute;
  top: 50px;
  right: 0;
  bottom: 50px;
  left: 0;
  z-index: 0;
}

.bg-image-above-about-us {
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
} */



.about-us-wow-holiday-our-container {
  background-image: url(/IMAGES/city-images/Airport-taxi/bg-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  position: relative;
  width: 100%;
  padding-top: 0%;
  padding-bottom: 36%;
  /* margin-top: 20px; */
  bottom: 0;
  margin-bottom: 20px;
}


.about-us-wow-holiday-our-content {
  font-size: 120%;
  color: #fff;
}

.about-us-wow-holiday-our-content {
  display: -moz-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 60px;
  right: 30px;
}


.about-us-wow-holiday-home-our-story {
  padding: 2% 7%;
}


.about-us-wow-holiday-our-team-heading {
  font-family: wow-bold !important;
font-size: 56px !important;
color: #ffffff;
text-align: left !important;
margin-left: -25px !important;
letter-spacing: 15px;
background-color: rgba(0,0,0,0.3);
width: 40%;
line-height: 1.2 !important;
}




.bgcolor-our-mission-context-about-us-wow-holiday{
  background-color: #fff;
  border-bottom: 1px solid #fff;
}

.padding-mission-context-about-wow-holiday{
  padding: 1% 0% 10% 0%;
}

.headings-of-our-mission-mission-about-us-holiday{
  color: #000000;
  font-size: 2.5rem;
  font-family: wow-bold;
  font-weight: 700;
  letter-spacing: 3px;
  display: inline-block;

}


.headings-of-cols-below-mission-about-us-holiday{
  color: #000000;
  font-size: 2.2rem;
  font-family: wow-bold;
  font-weight: 900;
  letter-spacing: 2px;
  display: inline-block;

}


.context-written-behind-col-headings-wow-holiday-about{
  color: #565656;
  font-size: 15px;
  font-family: wow-regular;
  font-weight: 300;
  
 display: flex;

}


.context-below-mission-about-us-wow-holiday{
  color: #565656;
  font-size: 19px;
  font-family: wow-regular;
  font-weight: 600;
  display: block;
}

.borderline-bw-mission-vision{
  border: 0.5px solid #000000;
  margin: 2% 0;

}


.color-of-font-icon-below-vision-context{
  font-size: 36px;
  color: #000000;
  
}


















@media (max-width: 700px){

  .about-us-wow-holiday-our-team-heading {
    font-family: wow-bold !important;
font-size: 26px !important;
color: #ffffff;
text-align: left !important;
margin-left: -50px !important;
letter-spacing: 5px;
background-color: rgba(0,0,0,0.3);
width: 100%;
line-height: 1.2 !important;
  }

  .about-us-wow-holiday-our-container {
    background-image: url(/IMAGES/city-images/Airport-taxi/bg-image.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: relative;
    width: 100%;
    padding-top: 45%;
    padding-bottom: 0%;
    /* margin-top: 20px; */
    bottom: 0;
    margin-bottom: 20px;
  }

  .icons-in-about-us-wow-holiday{
    color: #f87370;
    font-size: 62px;
    
}
.display-icons-about-us-in-mobile-view{
  text-align: center !important;
}

.context-below-mission-about-us-wow-holiday{
  color: #565656;
  font-size: 18px;
  /* font-family: wow-regular;
  font-weight: 0 !important; */
  display: block;
}





}






@media (max-width:1000px) and (min-width:700px){

  .context-written-behind-col-headings-wow-holiday-about{
    color: #565656;
    font-size: 15px;
    font-family: wow-regular;
    font-weight: 300;
    
   display: flex;
  
  }

  .headings-of-cols-below-mission-about-us-holiday{
    color: #000000;
    font-size: 2.5rem;
    font-family: wow-holiday-bold;
    /* font-weight: 700; */
    letter-spacing: 2px;
    display: inline-block;
  
  }







}













































/* what makes us differeny */

.makes-us-different-section {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.wpb_row {
  position: relative;
  margin: 0 auto;
}

.makes-us-different-section{
  border-color: #ebebeb;
}

.makes-us-different-section:before {
  content: '';
  display: table;
}


@media (max-width: 1024px){
.makes-us-different-section.height_large>.makes-us-different-section-h {
    padding: 4rem 0;
}

}




.makes-us-different-section-h {
  max-width: 1200px;
}

.makes-us-different-section-h {
  position: relative;
  margin: 0 auto;
  width: 100%;
}

.i-cf:before, .i-cf:after {
  content: ' ';
  display: table;
}

.g-cols.type_default {
  margin: 0 -1.5rem;
}

.vc_row {
  position: relative;
}

.g-cols {
  display: flex;
  flex-wrap: wrap;
}

.wpb_column {
  position: relative;
}

.vc_column_container{
  border-color: #ebebeb;
}

@media (min-width: 768px){
.vc_col-sm-12 {
    width: 100%;
}

}

.vc_column_container {
  display: flex;
  flex-direction: column;
}

.g-cols.type_default>div>.vc_column-inner {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.vc_column-inner {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}

.vc_column-inner{
  border-color: #ebebeb;
}

.vc_column-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
}

.l-canvas {
  color: #666666;
}

.makes-us-different-section-wpb_wrapper h2:last-child{
  margin-bottom: 0;
}

.makes-us-different-section-wpb_wrapper h2:first-child{
  padding-top: 0;
}

.makes-us-different-section-wpb_wrapper h2 {
  color: #000000;
  font-family: wow-bold;
    font-weight: 300;
  color: #f87370;
    font-size: 30px;
    line-height: 1.4;
    letter-spacing: 0em;
    margin: 0 0 1.5rem;
}

.w-separator.size_medium:empty {
  height: 3rem;
}

.w-separator:not(.with_content), .w-separator.with_content .w-separator-h {
  position: relative;
}

.w-separator {
  clear: both;
  overflow: hidden;
  line-height: 0;
  height: 1.5rem;
}

.g-cols.type_default {
  margin: 0 -1.5rem;
}


@media (min-width: 768px){
.vc_col-sm-6 {
    width: 50%;
}

}

.makes-us-different-section-wpb_wrapper h3:first-child {
  padding-top: 0;
}

.makes-us-different-section-wpb_wrapper h3 {
  color: #565656;
  font-family: wow-medium;
    font-weight: 300;
    font-size: 24px;
    line-height: 1.4;
    letter-spacing: 0em;
    margin-bottom: 1.5rem;
    margin: 0 0 1.5rem;
}

.makes-us-different-section-wpb_wrapper h5:last-child {
  margin-bottom: 0;
}

.makes-us-different-section-wpb_wrapper h5 {
  color: #000000;
  font-family: wow-regular;
    font-weight: 300;
    font-size: 17px;
    line-height: 1.4;
    letter-spacing: 0em;
    margin: 0 0 1.5rem;
}

.w-separator.size_medium.with_line {
  height: 6rem;
}

.w-separator.color_border {
  color: #ebebeb;
}

.w-separator.with_content {
  line-height: 1.3;
}

.w-separator.color_primary{
  color: #000000;
}

.w-separator.with_content {
  line-height: 1.3;
}

.w-separator.width_30 {
  width: 30%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.w-separator.with_content .w-separator-h {
  position: relative;
}

.w-separator-h {
  display: inline-flex;
  align-items: center;
  height: inherit;
  border-color: inherit !important;
  color: inherit;
}

.w-separator-h:before {
  right: 100%;
  margin-right: 0.6em;
}

.w-separator-h:after {
  left: 100%;
  margin-left: 0.6em;
}

.w-separator-h:before, .w-separator-h:after {
  content: '';
  position: absolute;
  top: 50%;
  height: 0;
  width: 200rem;
  border-top-width: 1px;
  border-top-style: solid;
  border-color: inherit;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}














