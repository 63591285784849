@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }
















/* header image */

.td-post-header-holder-card5 {
        position: relative;
        min-height: 400px;
        background-color: #444;
        margin-bottom: 36px;
        overflow: hidden;
        margin-top: 36px;
}
    
    
     .td-image-gradient-newscard5:before {
        z-index: 1;
    }
    
    .td-image-gradient-newscard5:before {
        bottom: 0;
        content: "";
        display: block;
        height: 50%;
        width: 100%;
        position: absolute;
        z-index: 1;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
        background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.8)));
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
        background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
        background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#b3000000', GradientType=0);
    }
    
    .td-post-featured-image-newscard5 {
        position: relative;
        visibility: visible !important;
    }
    
    .td-post-featured-image-newscard5 img {
        margin-bottom: 0;
    }
    
    .td-post-featured-image-newscard5 img {
        display: block;
        width: 100%;
        height: auto;
    }
    
    .td-animation-stack-type0-2-newscard5 {
        opacity: 1 !important;
        transition: opacity 0.3s;
        transition-timing-function: cubic-bezier(0.39, 0.76, 0.51, 0.56);
    }
    
    
    .td-post-title-newscard5 {
        position: absolute;
        bottom: 0;
        padding: 0 30px 9px 30px;
        width: 80%;
        z-index: 1;
    }
    
    
    .td-post-title-newscard5 .td-category-newscard5 {
        margin-bottom: 5px;
    }
    
    .td-category-newscard5 {
        list-style: none;
        font-family: 'Open Sans', arial, sans-serif;
        font-size: 10px;
        margin-top: 0;
        margin-bottom: 10px;
        line-height: 1;
    }
    
    .td-category-newscard5 li {
        display: inline-block;
        margin: 0 5px 5px 0;
        line-height: 1;
    }
    
    .td-post-title-newscard5 ul{
        padding-inline-start: 0px !important;
    }
    
    
    .td-category-newscard5 a {
        border-color: var(--darkblue)!important;
        background-color: var(--darkblue);
    }
    
    
    .td-category-newscard5 a {
        color: #fff;
        background-color: #222222;
        padding: 3px 6px 4px 6px;
        white-space: nowrap;
        display: inline-block;
    }
    
    .post .td-category-newscard5 a {
        text-transform: none;
    }
    
    
     .entry-title-newscard5{
        font-family: wow-bold;
        font-weight: 700;
    }
    
    .entry-title-newscard5 {
        font-size: 38px;
        line-height: 54px;
        font-weight: 500;
        margin-bottom: 14px;
        margin-top: 0;
        color: #fff;
    }
    
    
    header .td-module-meta-info {
        margin-bottom: 16px;
    }
    
    .td-module-meta-info {
        font-family: 'Open Sans', arial, sans-serif;
        font-size: 11px;
        margin-bottom: 7px;
        line-height: 1;
        min-height: 17px;
    }
    
    
     .td-post-title-newscard5 .td-post-date-newscard5, .td-post-title-newscard5 .td-post-comments a, .td-post-title-newscard5 .td-post-views-newscard5 {
        color: #fff;
    }
    
    header .td-post-date-newscard5 {
        margin-left: 4px;
        color: #444;
        float: left;
    }
    
    .td-post-date-newscard5 {
        color: #aaa;
        display: inline-block;
        position: relative;
        top: 2px;
    }
    
    header .td-module-meta-info-newscard5 {
        margin-bottom: 16px;
    }
    
    .td-module-meta-info-newscard5 {
        font-family: 'Open Sans', arial, sans-serif;
        font-size: 11px;
        margin-bottom: 7px;
        line-height: 1;
        min-height: 17px;
    }
    
    .td-post-date-newscard5 .entry-date-newscard5 {
        text-transform: none;
    }
    
    .td-post-title-newscard5 .td-post-views-newscard5 {
        float: none;
        display: inline-block;
        vertical-align: top;
        margin-left: 22px;
        margin-right: 0;
    }
    
    .td-post-template-3 .td-post-title-newscard5 .td-post-comments, .td-post-template-3 .td-post-title-newscard5 .td-post-views-newscard5 {
        float: none;
        display: inline-block;
        vertical-align: top;
        margin-left: 22px;
        margin-right: 0;
    }
    
     .view-icon-views-newscard5 {
        line-height: 17px;
        font-size: 14px;
        margin-right: 5px;
    }
    
    
    
    
    
    
    header .td-post-views-newscard5 span {
        line-height: 15px;
        vertical-align: top;
}



















.u-margin-bottom-second-heading-wow-newscard5{
    margin-bottom: 1rem;
}


.heading-secondary-section-wow-newscard5{
    font-size: 3rem;
    font-weight: 700;
    margin-top: 2rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#565656 , #565656b4);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;
    /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat; */
  
  
    
}

.text-written-top-heading-newscard5{
    font-size: 16px;
    font-family: wow-regular;
    text-align: left !important;
    margin-bottom: 1rem;
    padding: 0 .5rem;
}




























/* gradient gird section */



.gradient-back-travel-newscard5{
    background-color: #f3f3f3;
    padding: 50px 0;
    margin-bottom: 1rem;
    
  }

  .padding-in-travel-newscard5-grid-text{
    padding: 4rem 0;
  }


  .travel-newscard5-page-araea-sub-headings110 {
    font-size: 24px;
}

.travel-newscard5-page-araea-sub-headings110 {
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
    border-image-slice: 1;
    font-family: wow-bold;
    color: #565656;
    padding-bottom: 5px;
    margin-bottom: 25px;
    text-transform: uppercase;
}

.travel-newscard5-page-page-text {
    font-size: 14px;
    font-family: wow-regular;
}


















.u-margin-bottom-second-heading-expert-wow-newscard5{
    margin-bottom: 1rem;
  }


  .heading-secondary-expert-section-wow-newscard5{
    font-size: 2.7rem;
    font-weight: 700;
    margin-top: 2rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87370 , #f87270a1);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;
    width: 81%;
    /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat; */
  
  
    
}

























/* description news cards */

.description_of_card_of_newsroom-card5 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 5rem;
    font-size: 600;
    color: #8d8c8c;
}

@media (max-width: 7000px) and (min-width: 500px){
.description_of_card_of_newsroom_area-card5 {
    width: 75%;
}
}

.blogs-description-area-of-main--text-card5 {
    font-size: 14px;
    font-family: wow-regular;
    color: #565656;
    margin-top: 0;
    margin-bottom: 1rem;
}

.blogs-description-area-of-main-headings-card5 {
    font-size: 24px;
    font-family: wow-bold;
    color: #565656;
    text-align: left;
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.wow-news-card1-work-page-top-image-card5{
    margin: 2rem 0 2rem 0;
}


















/* media queriesn */

@media(max-width:1125px) and (min-width:700px){
    .entry-title-newscard5{
        font-family: wow-bold;
        font-weight: 700;
    }
    
    .entry-title-newscard5 {
        font-size: 30px;
        line-height: 34px;
        font-weight: 500;
        margin-bottom: 14px;
        margin-top: 0;
        color: #fff;
    }

    .td-post-header-holder-card5 {
        position: relative;
        min-height: 300px;
        background-color: #444;
        margin-bottom: 36px;
        overflow: hidden;
        margin-top: 36px;
    }

    .heading-secondary-section-wow-newscard5 {
        font-size: 2.3rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#565656 , #565656b4);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 2px;
        transition: all .2s;
    }

    .padding-in-travel-newscard5-grid-text {
        padding: 0rem 0;
    }

    .travel-newscard5-page-araea-sub-headings110 {
        border-bottom: 4px solid #f87370;
        border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
        border-image-slice: 1;
        font-family: wow-bold;
        color: #565656;
        padding-bottom: 5px;
        margin-bottom: 5px;
        text-transform: uppercase;
    }
    
    .travel-newscard5-page-araea-sub-headings110 {
        font-size: 20px;
    }

    .travel-newscard5-page-page-text {
        font-size: 12px;
        font-family: wow-regular;
    }

    .heading-secondary-expert-section-wow-newscard5 {
        font-size: 2rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87370 , #f87270a1);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 2px;
        transition: all .2s;
        width: 101%;
    }









}





@media(max-width:700px){
    .entry-title-newscard5{
        font-family: wow-bold;
        font-weight: 700;
    }
    
    .entry-title-newscard5 {
        font-size: 20px;
        line-height: 20px;
        font-weight: 500;
        margin-bottom: 14px;
        margin-top: 0;
        color: #fff;
    }

    .td-post-header-holder-card5 {
        position: relative;
        min-height: 100px;
        background-color: #444;
        margin-bottom: 36px;
        overflow: hidden;
        margin-top: 36px;
    }

    .td-post-title-newscard5 {
        position: absolute;
        bottom: 0;
        padding: 0 30px 9px 30px;
        width: 100%;
        z-index: 1;
    }

    .td-post-featured-image-newscard5 img {
        display: block;
        width: 100%;
        height: 40vh;
    }

    header .td-module-meta-info-newscard5 {
        margin-bottom: 0px;
    }

    
    .heading-secondary-section-wow-newscard5 {
        font-size: 1.7rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#565656 , #565656b4);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 2px;
        transition: all .2s;
    }

    .padding-in-travel-newscard5-grid-text {
        padding: 1rem 0;
    }

    .travel-newscard5-page-araea-sub-headings110 {
        border-bottom: 4px solid #f87370;
        border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
        border-image-slice: 1;
        font-family: wow-bold;
        color: #565656;
        padding-bottom: 5px;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
    
    .travel-newscard5-page-araea-sub-headings110 {
        font-size: 22px;
    }

    .travel-newscard5-page-page-text {
        font-size: 12px;
        font-family: wow-regular;
    }

    .heading-secondary-expert-section-wow-newscard5 {
        font-size: 1.7rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87370 , #f87270a1);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 2px;
        transition: all .2s;
        width: 101%;
    }









}