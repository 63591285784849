@font-face {
  font-family: wow-bold;
  src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
}
@font-face {
  font-family: wow-regular;
  src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
    format("otf");
}


@font-face {
    font-family: wow-medium;
    src: url('../fonts/poppins/Poppins-Medium.otf');
  }  
  @font-face {
    font-family: wow-regular;
    src: url('../fonts/poppins/Poppins-Regular.otf');
  }  
  @font-face {
    font-family: wow-black;
    src: url('../fonts/poppins/Poppins-Black.otf');
  }  
  @font-face {
    font-family: wow-holiday-bold;
    src: url('../fonts/poppins/Poppins-Bold.otf');
  }  
      @font-face {
    font-family: wow-boldItalic;
    src: url('../fonts/poppins/Poppins-BoldItalic.otf');
  } 
  @font-face {
    font-family: wow-semiBold;
    src: url('../fonts/poppins/Poppins-SemiBold.otf');
  } 
  @font-face {
    font-family: wow-italic;
    src: url('../fonts/poppins/Poppins-Italic.otf');
  }
  @font-face {
    font-family: wow-thin;
    src: url('../fonts/poppins/Poppins-Thin.otf');
  }





































.holiday-credit-wow-holiday-our-container {
    background-image: url(/IMAGES/city-images/city-home-images/home-card3slider.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0% !important;
    position: relative;
    width: 100%;
    padding-bottom: 27%;
  margin-top: 0px;
  bottom: 0;
    margin-bottom: 20px;
    background-attachment: fixed;
    
  }


  .holiday-credit-wow-holiday-home-our-story {
    padding: 2% 17%;
    
  }

  .holiday-credit-wow-holiday-our-team-heading {
    font-family: wow-bold !important;
  font-size: 39px !important;
  color: #ffffff;
  text-align: center !important;
  font-weight: 700;
  text-shadow: 2px 2px 0 rgba(0,0,0,.5);
  
  margin-left: -65px !important;
  letter-spacing: 3px;
  line-height: 1 !important;
  }







  .holiday-credit-wow-holiday-our-team-button {
    font-family: wow-bold !important;
  font-size: 22px !important;
  color: #ffffff !important;
  text-align: center !important;
  font-weight: 700;
  
 
 
  
  line-height: 0 !important;
  }






  .holiday-credit-wow-holiday-our-team-heading-below-text{
    font-family: wow-regular !important;
    font-size: 21px !important;
    color: #ffffff;
    text-align: center !important;
    font-weight: 300;
    padding: 20px 180px;
    
    margin-left: -65px !important;
    letter-spacing: 1px;
    line-height: 1.2 !important;

  }




  .holiday-credit-our-content {
    font-size: 120% !important;
    color: #fff;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    left: 0 !important;
    
  }
  
  .holiday-credit-our-content {
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 60px;
    right: 30px;
  }


 












  .heading-primary-in-wow-holiday-credits{
    /* color: #fff; */
    
    backface-visibility: hidden;
  }



  .text-box-inside-background-image-of-new-design-credits {
    position: relative !important;
    top: 0% !important;
    left: 0% !important;
    transform: translate(0%, 0%) !important;
}



.text-box-inside-background-image-of-new-design-credits {
 
  text-align: center;
  padding-left: 0%;
  margin: 70px 0;
}

.heading-primary-main-credits {
    display: block;
    font-size: 38px;
    text-transform: uppercase;
    font-weight: 900;
    font-family: wow-holiday-bold;
    letter-spacing: 2px;
    animation-name: moveInleft;
    animation-duration: 1s;
    color: #565656 !important;
    margin-bottom: 30px;
    margin-left: 0 !important;
    line-height: 1.5;
    text-shadow: 2px 2px 0 rgba(0,0,0,.2);
    
  }


  .border-line-credit-headings{
    /* border-bottom: 4px solid #f87370; */
    /* border-image: linear-gradient(70deg,#f87370 65%,transparent 0); */
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    
    /* border-image-slice: 11; */

  }

  .heading-primary-sub-credits {
    display: block;
    font-size: 20px;
    font-weight: 400;
    color: #565656;
    letter-spacing: 1.4px;
    animation-name: moveInright;
    animation-duration: 1s;
    margin-top: 10px;
    
  }


.credit-equal-dollar-wow-credit{
    color: #f87370;
    
}


.heading-primary-main-credits-how-get{
    display: block;
    font-size: 36px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: wow-holiday-bold;
    letter-spacing: 2px;
    animation-name: moveInleft;
    animation-duration: 1s;
    color: #fff !important;
    margin-bottom: 40px;

}


.activity-heading-below-img-holiday{

    display: block;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: 700;
    font-family: wow-holiday-bold;
    letter-spacing: 2px;
    animation-name: moveInleft;
    animation-duration: 1s;
    color: #fff !important;
    margin: 10px 0;

}


.activity-heading-context-below-img-holiday{

    display: block;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 100;
    /* text-align: left; */
    font-family: wow-regular;
    letter-spacing: 2px;
    animation-name: moveInleft;
    animation-duration: 1s;
    color: #fff !important;
    margin: 20px 0;

}




.background-color-in-wow-credits{
    background-color: #f87070e8;
    padding: 1px 0 1px 0;

}



.activity-images-wow-credits{
    width: 98%;

}




.arrow{
    background-image: url('/IMAGES/city-images/city-home-images/arrow-right-activity.png');
    
}


.g_v_mid {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}


.arrow_box {
    display: block;
    top: 65px;
}


 .g_v_mid{
    position: absolute;
}

.g_c_mid {
    left: 50%;
    margin-top: 30%;
    /* -webkit-transform: translateX(-50%) !important; */
    transform: translateX(0%);
}



 .arrow_box .line {
    height: 1px;
    width: 100%;
    display: inline-block;
    border-bottom: 3px dashed #fff;
}


.g_rel {
    position: relative;
}


 .arrow_box .line .arrow {
    display: block;
    right: -10px;
    width: 20px;
    height: 20px;
    background-size: cover;
    top: 2px;
    
}





.website-text {
    font-family: wow-regular;
    color: #565656;
    font-size: 21px;
    text-align: left;
}

.color-of-icon-in-credit-holiday{
    color: #f87370;
}

















/* mobile view */

@media (max-width: 700px){

  .holiday-credit-wow-holiday-our-container {
    background-image: url(/IMAGES/city-images/city-home-images/home-card3slider.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position:45% !important;
    position: relative;
    width: 100%;
    padding-bottom: 36%;
  margin-top: 0px;
  bottom: 0;
    margin-bottom: 20px;
    
  }



  .holiday-credit-our-content {
    font-size: 120% !important;
    color: #fff;
    background-color: rgba(0,0,0,0.2);
    width: 100%;
    left: 0 !important;
    
  }
  
  .holiday-credit-our-content {
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 60px;
    right: 30px;
  }

  .holiday-credit-wow-holiday-home-our-story {
    padding: 2% 7%;
    
  }

  .holiday-credit-wow-holiday-our-team-heading {
    font-family: wow-bold !important;
  font-size: 15px !important;
  color: #ffffff;
  text-align: center !important;
  font-weight: 700;
  text-shadow: 2px 2px 0 rgba(0,0,0,.5);
  
  margin-left: -5px !important;
  letter-spacing: 0px;
  line-height: 1 !important;
  }

  .holiday-credit-wow-holiday-our-team-button {
    font-family: wow-bold !important;
  font-size: 12px !important;
  color: #ffffff !important;
  text-align: center !important;
  font-weight: 700;
  display: none !important;
 
 
  
  line-height: 0 !important;
  }

  .holiday-credit-wow-holiday-our-team-heading-below-text{
    font-family: wow-regular !important;
    font-size: 10px !important;
    color: #ffffff;
    text-align: center !important;
    font-weight: 300;
    padding: 5px 50px;
    
    margin-left: -5px !important;
    letter-spacing: 1px;
    line-height: 1.2 !important;

  }



  .text-box-inside-background-image-of-new-design-credits {
 
    text-align: left;
    padding-left: 0%;
    margin: 20px 0;
  }
  
  .heading-primary-main-credits {
      display: block;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 900;
      font-family: wow-holiday-bold;
      letter-spacing: 0px;
      animation-name: moveInleft;
      animation-duration: 1s;
      color: #565656 !important;
      margin-bottom: 30px;
      margin-left: 0 !important;
      line-height: 1.5;
      
    }









}



