@font-face {
    font-family: wow-bold;
    src: url('https://wowets.com/static/media/Poppins-Bold.otf') format(opentype) ;
  } 
  
  @font-face {
    font-family: wow-holiday-bold;
    src: url('/src/fonts/poppins/Poppins-Bold.otf');
  }
@font-face {
    font-family: wow-regular;
    src: url('https://wowets.com/static/media/Poppins-Regular.de2dd933.otf' )  format('otf');
  }   


.date-ride-tracking{
    margin-left: 200px;
}

.map-content{
    width: 600px !important;
}



.car-number-written-in-ride-details{
  border: 1px solid #888;
  width: 75%;
  padding: 3px;
  text-align: center;
  font-family: wow-bold;
  font-weight: bolder;
  background-color: #FFFFFF;

}


.icons-on-right-of-phone-ride-details{
  padding-right: 10px ;
  margin-top: 10px;

}



.ride-logo-header{
    width: 100%;
    height: 100%;

    max-height: 120px;
    
    max-width: 350px;
    text-align: center !important;
   
    
}

.icon-below-trip-details{
    
    max-width: 31px;
    max-height: 380px;

    
}

.icon-below-leader-details{
    max-height: 55px;
    max-width: 60px;
    /* margin-top: 2px; */
}
.icon-below-leader-details-car{
  max-height: 100% !important;
  max-width: 60px;
  /* margin-top: 2px; */
  margin-left: 0 !important;
}

/* .trip-details-column-flex{
    display: flex;
    width: 1px;

} */

.leader-details-column-flex{
    margin-top: 10px;
}

/* .name-trip-detail{
    font-size: 20px;
    font-weight: bolder;
    font-family: wow-regular;
    text-align: left;
} */


.logo-header{
    background-color: #1036fc;
}


.rider-header-text{
    font-size: 30px;
    font-family: wow-bold;
    /* text-align: left; */
    /* justify-content: left; */
    margin-top: 50px;
    margin-bottom: 20px;
    
    padding-bottom: 5px;  
    /* bottom padding should be higher to make up for pseudo border height  */
  background: linear-gradient(90deg, transparent 20%, #1036fc 20%, #1036fc 80%, transparent 60%);
  background-size: 100%  5px;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  text-transform: uppercase;
  display: inline-block;
  color: #565656;
}

.rider-header-text-inside{
    font-size: 30px !important;
    font-weight: bolder !important;
    font-family: wow-regular;
}


.phone-no-in-rider-details{
  margin-top: 19px !important;
}
.Vehicles-in-rider-details{
  margin-top: 1px !important;
  margin-bottom: 3px;
}



/* flex css */

.ride-header-data-flex{
    display: flex;
    margin-left: 15px;
    
  }

  .pick-up-context{
      font-size: 12px;
      font-family: wow-regular;
      margin-top: 48px;
      color: #565656;
      margin-left: 15px;
  }

  .pick-up-context-dropoff{
    font-size: 12px;
    font-family: wow-regular;
    margin-top: 30px;
    color: #565656;
    margin-left: 15px;

  }


  .ride-name{
    font-family: wow-regular;
    font-size: 18px;
    text-align: left;
    
    color: #565656;
    
  }

  .ride-leader-name{
    font-family: wow-bold;
    font-size: 14px;
    text-align: left;
    margin-left: 15px;
    margin-top: 10px;
    
    color: #565656;

  }

  .ride-leader-name-icons-context{
    font-family: wow-regular;
    font-size: 14px;
    text-align: left;
    margin-left: 20px;
    margin-top: 10px;
    
    color: #565656;

  }

  .pick-up-contexts{
      font-size: 16px;
      font-family: wow-regular;
      color: #565656;
      margin-left: 15px;

  }

  .ride-name-data{
    font-size: 12px;
    margin-left: 1px;
  }



  @media (max-width:8000px) and (min-width:1130px){

    .map-in-mobile-view-in-ride-details{
      height: 100vh;
      width: 100%;
      max-height: 650px;
    }
    

    

    
    

}
@media (max-width: 1129px) and (min-width: 701px){

  .map-in-mobile-view-in-ride-details{
    height: 100vh;
    width: 100%;
    max-height: 650px;
  }

  .icon-below-trip-details{
    
    max-width: 27px;
    height: 100%;
    max-height: 300px;

    
}

}



@media (max-width:700px) and (min-width:0px){
    .logo-header{
        background-color: #1036fc;
    }

    .ride-logo-header{
        display: flex;
        width: 100%;
        height: 100%;
        max-width: 200px;
        padding: 15px 0;
    
        
        
        
        text-align: center !important;
       
        
    }

    .icon-below-trip-details{
    
        max-width: 34px;
        max-height: 380px;
    
        
    }

    .ride-name{
        font-family: wow-regular;
        font-size: 18px;
        text-align: left;
        
        color: #565656;
        
      }

      .date-ride-tracking{
        margin-left: 120px;
        font-size: 13px;
    }

    .rider-header-text{
        font-size: 25px;
        font-family: wow-bold;
        /* text-align: left; */
        /* justify-content: left; */
        margin-top: 10px;
        margin-bottom: 8px;
        
        padding-bottom: 5px;  
        /* bottom padding should be higher to make up for pseudo border height  */
      background: linear-gradient(90deg, transparent 20%, #1036fc 20%, #1036fc 80%, transparent 60%);
      background-size: 100%  5px;
      background-position: 0% 100%;
      background-repeat: no-repeat;
      text-transform: uppercase;
      display: inline-block;
      color: #565656;
    }
    .pick-up-context-dropoff{
    font-size: 13px;
    font-family: wow-regular;
    margin-top: 20px;
    color: #565656;
    margin-left: 15px;

  }

  .pick-up-contexts{
    font-size: 15px;
    font-family: wow-regular;
    color: #565656;
    margin-left: 15px;

}

.pick-up-context{
    font-size: 13px;
    font-family: wow-regular;
    margin-top: 48px;
    color: #565656;
    margin-left: 15px;
}
   
.icon-below-leader-details{
    max-height: 50px;
    max-width: 50px;
}

.ride-leader-name-icons-context{
    font-family: wow-regular;
    font-size: 12px;
    
    
    margin-top: 5px;
    
    color: #565656;

  }

  .map-content{
    width:100%;
    max-width: 340px !important;
    display: flexbox;
    height: 100%;
    
}
    
.map-in-mobile-view-in-ride-details{
  height: 100vh;
  width: 100%;
  max-height: 350px;
}

    

    
    

}