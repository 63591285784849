@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('/fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('/fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('/fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('/fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('/fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('/fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('/fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('/fonts/poppins/Poppins-Thin.otf');
    }





































.headings-written-in-contact-us-wow-holiday{
    color: #565656;
    font-size: 32px;
    font-family: wow-holiday-bold;
    font-weight: 700;
}




.form-group {
    position: relative;
    margin-bottom: 0;
}


 .form-group label {
    font-size: 20px;
    font-family: wow-holiday-bold;
    color: #565656;
   
}


.border-behind-contact-us-form-wow-holiday{
    border-bottom: 1px solid #D3D3D3;
    margin-top: 20px;
}


.context-under-contact-us-form-wow-holiday{
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 20px;
    font-family: wow-regular;
    font-weight: 700;
    color: #565656;
}



textarea.form-control {
    height: 166px !important;
    min-height: 52px !important;
    max-height: 249px !important;
    resize: vertical !important;
}

.button-contact-us-wow-holiday{
    width: 20%;
    background-color: #f87370;
}

.btn-primary {
    color: #fff;
    background-color: #f87370 !important;
}

#contact {
    width: 130px !important;
    height: 52px;
    line-height: 42px;
    border-radius: 0 2px 2px 0;
    
}


.contact-us-button-in-wow-holiday  {
    width: 101px !important;
    height: 42px !important;
    line-height: 42px !important;
    border-radius: 0 2px 2px 0 !important;
    float: left;
}




.m_btn_main-contact {
    background-color: #f87370;
    color: white;
    border-radius: 5px !important;
}

.t_mid-contact {
    text-align: center;
}

.t14-contact {
    font-size: 19px !important ;
    color: #fff !important;
   
}

.form-control {
    height: auto;
    min-height: 52px;
    padding: 12.1px 19px 16.1px !important;
    border: 1px solid #e3e3e3;
    -webkit-appearance: none;
    line-height: 23.8px;
    margin-bottom: 12px;
    color: #565656;
}



 button.submit-buttom {
    background: #f87370;
    padding: 10px 25px;
    color: #ffffff;
    font-size: 20px;
    width: 100%;
    height: 50px;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    transition: all 0.3s ease 0s;
}


.icon-below-leader-details{
    max-height: 55px;
    max-width: 60px;
    /* margin-top: 2px; */
}


 .clock-icon-wow-holiday-contact {
    background: #f87370;
    font-size: 15px;
    color: #ffffff;
    padding: 15px;
    width: 45px;
    height: 45px;
    text-align: center;
    margin-right: 20px;
    transition: all 0.3s ease 0s;
    float: left;
}


.icon-clock-contact-context{
    font-family: wow-medium;
    font-size: 17px;
    text-align: left;
    padding: 2% 0;
    margin-top: 0px;
    
    color: #565656;

  }

  .icon-clock-contact-context-address{
    font-family: wow-medium;
    font-size: 14px;
    text-align: left;
    margin-top: 0px;
    
    color: #565656;

  }


  .margin-icons-in-contact-wow-holiday{
      padding: 2% 0;
  }


  .timing-contact-us-wow-holiday{
      font-size: 15px;
      margin-top: 10px;
      color: #565656;
  }


  .google-maps-in-contact-us-wow-holiday{
    font-size: 18px;
    color: black;
    margin: 5px 0;
    
  }
  .google-maps-in-contact-us-wow-holiday:hover{
    color: #f87370;
    font-size: 19px;
  }



  .map-in-mobile-view-in-contact-us-wow-holiday{
    height: 100vh;
    width: 100%;
    max-height: 370px;
  }


  /* .wp-block-column{
    position: relative;
    width: 33.33%;
    flex-basis: unset;
    float: left;
} */



.marker-in-contact-us-wow-holiday{
  font-size: 52px;
    float: left;
    color: #fff;
    margin-right: 25px;

}

.icon-padding-in-wow-holiday-contact{
  padding: 6% 0 5% 5%;
}


.text-written-below-contact-icon-form-wow-holiday{
  font-family: wow-holiday-bold;
  font-weight: 700;
  font-size: 19px;
  color: #fff;
  
}


.social-icons-in-contact-us-wow-holiday{
  color: white;
  font-size: 20px;
  padding: 4%;
}

.backgroung-color-below-contact-form-section-holiday{
  background-color: #f87370;
  border-bottom: 1px solid #fff;
}




.contact-us-wow-holiday-our-container {
  background-image: url(/IMAGES/city-images/wow-contactus/contactus.png);
  background-repeat: no-repeat;
  background-size: cover;
  /* background-position: 100%; */
  position: relative;
  width: 100%;
  padding-top: 36%;
  padding-bottom: 0%;
  /* margin-top: 20px; */
  bottom: 0;
  margin-bottom: 20px;
  
}

.contact-us-wow-holiday-home-our-story {
  padding: 2% 7%;
}




.contact-us-wow-holiday-our-team-heading {
  font-family: wow-bold !important;
font-size: 46px !important;
color: #ffffff;
text-align: left !important;
margin-left: -25px !important;
letter-spacing: 15px;
line-height: 1.2 !important;
}




.padding-in-form-behind-contact-wow-holiday{
  padding: 1% 0;
}









@media (max-width: 700px) and (min-width:0){
  .input-name-contact-wow-holiday{
    margin-left: 0 !important;

  }

  .contact-us-wow-holiday-our-container {
    background-image: url(/IMAGES/city-images/wow-contactus/contactus.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
    position: relative;
    width: 100%;
    padding-top: 45%;
    padding-bottom: 0%;
    /* margin-top: 20px; */
    bottom: 0;
    margin-bottom: 20px;
  }

  button.submit-buttom {
    background: #f87370;
    padding: 10px 25px;
    color: #ffffff;
    font-size: 20px;
    width: 100%;
    height: 50px;
    border: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    transition: all 0.3s ease 0s;
    margin-left: 0 !important;
}

.marker-in-contact-us-wow-holiday{
  font-size: 42px;
    float: left;
    color: #fff;
    margin-right: 25px;
    margin-left: 15px;

}

.text-written-below-contact-icon-form-wow-holiday{
  font-family: wow-holiday-bold;
  font-weight: 700;
  font-size: 19px;
  color: #fff;
  
}
.context-in-icons-above-footer-contact-us-holiday-page{
  font-size: 12px !important;
}


}









.wow-logo-in-contact-below-forms-holiday{
  height: 100%;
  max-height: 62px;
  margin-left: 10px;
}








@media (max-width:1000px) and (min-width:700px){

  
  .icon-padding-in-wow-holiday-contact{
    padding: 6% 0 5% 2%;
    
  }

  .wow-logo-in-contact-below-forms-holiday{
    height: 100%;
    max-height: 52px;
    margin-left: 10px;
    margin: 5px 0;
  }





}


