@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  @font-face {
    font-family: wow-medium;
    src: url("../fonts/poppins/Poppins-Medium.otf");
  }
  @font-face {
    font-family: wow-regular;
    src: url("../fonts/poppins/Poppins-Regular.otf");
  }
  @font-face {
    font-family: wow-black;
    src: url("../fonts/poppins/Poppins-Black.otf");
  }
  @font-face {
    font-family: wow-holiday-bold;
    src: url("../fonts/poppins/Poppins-Bold.otf");
  }
  @font-face {
    font-family: wow-boldItalic;
    src: url("../fonts/poppins/Poppins-BoldItalic.otf");
  }
  @font-face {
    font-family: wow-semiBold;
    src: url("../fonts/poppins/Poppins-SemiBold.otf");
  }
  @font-face {
    font-family: wow-italic;
    src: url("../fonts/poppins/Poppins-Italic.otf");
  }
  @font-face {
    font-family: wow-thin;
    src: url("../fonts/poppins/Poppins-Thin.otf");
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  

  







  .wow-community-top-content {
    background-image: url(/IMAGES/city-images/wow-community/community-bg.jpg);
    min-height: 450px;
      padding-top: 72px;
      position: relative;
      background-position: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
   
  }
  
  .wow-community-top-content:before {
    content: "";
    position: absolute;
  background: linear-gradient(90deg,rgba(21, 26, 48, 0.664) 8%,rgba(21, 26, 48, 0.692) 46%,rgba(21,26,48,.07) 95%,rgba(21,26,48,0));
  width: 90%;
    height: 100%;
    left: 0;
    top: 0;
  }


  .wow-community-container-top {
    position: relative;
    width: 100%;
    padding-top: 39.25%;
  }

  @media (max-width: 2000px) and (min-width: 800px){
  .wow-community-top-content-area {
    position: absolute;
    top: 45%;
    transform: translateY(-50%);
    left: 7.5%;
  }
}

@media (max-width: 7000px) and (min-width: 2300px){
  .wow-community-top-content-area {
    position: absolute;
    top: 35%;
    transform: translateY(-50%);
    left: 7.5%;
    margin-top: 56px;
}
}
  .wow-community-grid {
    margin-right: 7px !important;
    margin-left: 6px !important;
  }


  @media (max-width: 1100px) and (min-width: 580px){
    .wow-community12 {
        padding: 20px 0 0 30px;
    }
    }



@media (max-width: 2300px) and (min-width: 1100px){
        .wow-community-banner-top-heading {
            font-size: 50px;
            font-family: wow-bold;
            width: 30ch;
        }
}

@media (max-width: 1100px) and (min-width: 900px){
  .wow-community-banner-top-heading {
      font-size: 50px;
      /* width: 100% !important; */
      font-family: wow-bold;
      width: 20ch;
  }
}

@media (max-width: 7000px) and (min-width: 2300px){
.wow-community-banner-top-heading {
    font-size: 70px;
    font-family: wow-bold;
    width: 30ch;
}
.text-in-h3-wow-community{
  font-size: 30px !important;
}
.wow-community-banner-top-co {
  width: 56ch;
  font-size: 30px !important;
}
}

.text-in-h3-wow-community h3{
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
  }



  @media (max-width: 7000px) and (min-width: 800px){
    .wow-community-banner-top-co {
        width: 56ch;
    }
    }


@media(max-width:1200px) and (min-width:700px){
        .simplify-wow-community-text-col-tab{
          display: none;
        }

}



.community-page-top-image{
    margin: 2rem 0 0 0;
}






.wowcommunity-page-right-back-gradiant {
    background: #fff;
    background: -webkit-gradient(left top,right bottom,color-stop(0,#fff),color-stop(60%,#fff),color-stop(79%,#fff),color-stop(79%,#cfd7fe),color-stop(82%,#cfd7fe),color-stop(100%,#cfd7fe));
    background: -webkit-linear-gradient(-45deg,#fff,#fff 60%,#fff 79%,#d87b79a4 0,#d87b7985 82rgba(216, 123, 121, 0.527)79);
    background: -webkit-linear-gradient(315deg,#fff,#fff 60%,#fff 79%,#d87b799a 0,#d87b7991 82rgba(216, 123, 121, 0.555)79);
    background: linear-gradient(135deg,#fff,#fff 60%,#fff 79%,#d87b79 0,#d87b79b2 82%,#d87b798a);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#d87b79",GradientType=1);
    padding: 90px 0;
}


@media (max-width: 1700px) and (min-width: 500px){
    .wowcommunity-page-left-back-gradiant {
        background: #cfd7fe;
        background: -webkit-gradient(left bottom,right top,color-stop(0,#cfd7fe),color-stop(18%,#cfd7fe),color-stop(21%,#cfd7fe),color-stop(21%,#fff),color-stop(40%,#fff),color-stop(100%,#fff));
        background: -webkit-linear-gradient(45deg,#f8727081,#f8727096 18%,#f87370 21%,#fff 0,#fff 40%,#fff);
        background: linear-gradient(45deg,#f872709c,#f8727081 18%,#f87370 21%,#fff 0,#fff 40%,#fff);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f87370",endColorstr="#ffffff",GradientType=1);
        padding: 60px 0;
        margin-bottom: 2rem;
    }
}


.padding-in-wowcommunity-grid-text{
    padding: 7rem 0;
}



.wowcommunity-page-araea-sub-headings110 {
    font-size: 24px;
}

.wowcommunity-page-araea-sub-headings110 {
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
    border-image-slice: 1;
    font-family: wow-bold;
    color: #565656;
    padding-bottom: 5px;
    margin-bottom: 25px;
    text-transform: uppercase;
}


.wowcommunity-page-text {
    font-size: 14px;
    font-family: wow-regular;
}





.wow_community {
    padding: 50px 0 0 0;
    /* text-align: left; */
    font-family: wow-bold;
    margin-bottom: 14px;
    font-size: 42px;
    background: linear-gradient(90deg, transparent 20%, #f87370 20%, #f87370 80%, transparent 60%);
  background-size: 100%  5px;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  text-transform: uppercase;
  display: inline-block;
  color: #565656;
  padding-bottom: 5px; 
    line-height: 1.2;
  }
  
  .wow_community-heading-text{
    font-family: wow-regular;
    font-size: 14px;
  
  }




  .wow_community-our-people {
    padding: 50px 0 0 0;
    /* text-align: left; */
    font-family: wow-bold;
    margin-bottom: 14px;
    font-size: 30px;
    background: linear-gradient(90deg, transparent 0%, #f87370 0%, #f87370 60%, transparent 20%);
  background-size: 100%  5px;
  background-position: 0% 100%;
  background-repeat: no-repeat;
  text-transform: uppercase;
  display: inline-block;
  color: #565656;
  padding-bottom: 5px; 
    line-height: 1.2;
  }
  
  .wow_community-our-people-heading-text{
    font-family: wow-regular;
    font-size: 14px;
  
  }






  .image-community-grid-section{
    border: 4px solid #f87270c4;
    padding: 15px;
  }


















  /* section features wow community*/

.section-features-nature-wow-community-holiday{
    padding: 10rem 0;
    background-image: 
    linear-gradient(to left bottom, #f872708a, #f872707c),
     url('/IMAGES/city-images/wow-community/travel-business.jpg');
    background-size: cover;
    transform: skewY(-4deg);
    margin-top: -2rem;
    margin-bottom: 5rem;
    
    
}








.feature-box-in-wow-community-holiday{
    background-color: rgb(255, 255, 255);
    font-size: 5.2rem;
    padding: 1.8rem;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 1.5rem 4rem #000000;
    transition: transform .3s;
    height: 350px;
    cursor: pointer;
    

}


.feature-box-in-wow-community-holiday:hover{
    transform: translateY(-1.5rem) scale(1.01) ;

}




.section-features-nature-wow-community-holiday > *{
    transform: skewY(4deg);
}



.feature-box__icon-wow-community{
    font-size: 5rem;
    margin-bottom: .5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #f87370, #f87370);
    -webkit-background-clip: text;
    color: transparent;
}


.header-inside-grid-wow-community-features-box{
    font-size: 1.4rem !important;
    font-weight: 700;
    text-transform: uppercase;
    font-family: wow-bold;
    color: #565656;
    /* background-image: linear-gradient(to right,#000000 , #0000009c);
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block; */
}


.feature__box-text-wow-community{
        font-size: 1.1rem !important;
        font-family: wow-regular;
     

}



@media(max-width:1125px)and (min-width:700px){

  .section-features-nature-wow-community-holiday{
    padding: 10rem 0;
    background-image: 
    linear-gradient(to left bottom, #f872708a, #f872707c),
     url('/IMAGES/city-images/wow-community/travel-business.jpg');
    background-size: cover;
    transform: skewY(-4deg);
    margin-top: -2rem;
    margin-bottom: -9rem;
    
    
}
  .feature-box-in-wow-community-holiday{
    background-color: rgb(255, 255, 255);
    font-size: 5.2rem;
    padding: 1.8rem;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 1.5rem 4rem #000000;
    transition: transform .3s;
    height: 100%;
    


}


.feature-box__icon-wow-community{
  font-size: 4rem;
  margin-bottom: .5rem;
  display: inline-block;
  background-image: linear-gradient(to right, #f87370, #f87370);
  -webkit-background-clip: text;
  color: transparent;
}


.header-inside-grid-wow-community-features-box{
  font-size: 1.2rem !important;
  font-weight: 700;
  text-transform: uppercase;
  font-family: wow-bold;
  color: #565656;
  /* background-image: linear-gradient(to right,#000000 , #0000009c);
  -webkit-background-clip: text;
  color: transparent;
  display: inline-block; */
}


.feature__box-text-wow-community{
      font-size: 1rem !important;
      font-family: wow-regular;
   

}
}



































.wow_community-collaborating {
  padding: 50px 0 0 0;
  /* text-align: left; */
  font-family: wow-bold;
  margin-bottom: 14px;
  font-size: 36px;
  background: linear-gradient(90deg, transparent 20%, #f87370 20%, #f87370 80%, transparent 60%);
background-size: 100%  5px;
background-position: 0% 100%;
background-repeat: no-repeat;
text-transform: uppercase;
display: inline-block;
color: #565656;
padding-bottom: 5px; 
  line-height: 1.2;
}

.wow_community-collaborating-heading-text{
  font-family: wow-regular;
  font-size: 16px;
  padding: .5rem 10rem;

}






































/* icon grid section */


.communtiy-page-additional-features-area-items-image img {
  width: 130px;
}



.how-to-get-started-communtiy-area-heading4 {
  font-size: 20px;
  font-family: wow-medium;
  color: #565656;
}


.how-to-get-started-communtiy-area-text4 {
    font-size: 14px;
    font-family: wow-regular;
    color: #565656;
    margin-bottom: 1rem !important;
}
































@media(max-width:700px){
  .wow-community-top-content {
    background-image: url(/IMAGES/city-images/wow-community/community-bg.jpg);
    min-height: 450px;
      padding-top: 22px;
      position: relative;
      background-position: 78%;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
   
  }
  
  .wow-community-banner-top-heading{
    font-family: wow-bold;
  }
  .text-in-h3-wow-community{
    font-family: wow-medium;
  }
  
  .wow-community-top-content:before {
    content: "";
    position: absolute;
  background: linear-gradient(90deg,rgba(21, 26, 48, 0.664) 8%,rgba(21, 26, 48, 0.692) 46%,rgba(21,26,48,.07) 95%,rgba(21,26,48,0));
  width: 90%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .wow-community-top-content-area {
    position: absolute;
    top: 50px;
    left: 1.5%;
    margin-top: 56px;
  }




  
.wow_community {
  padding: 30px 0 0 0;
  /* text-align: left; */
  font-family: wow-bold;
  margin-bottom: 0px;
  font-size: 30px;
  background: linear-gradient(90deg, transparent 20%, #f87370 20%, #f87370 80%, transparent 60%);
background-size: 100%  5px;
background-position: 0% 100%;
background-repeat: no-repeat;
text-transform: uppercase;
display: inline-block;
color: #565656;
padding-bottom: 5px; 
  line-height: 1.2;
}

.wow_community-heading-text{
  font-family: wow-regular;
  font-size: 14px;
  margin-top: 16px !important;

}




.wow_community-our-people {
  padding: 30px 0 0 0;
  /* text-align: left; */
  font-family: wow-bold;
  margin-bottom: 14px;
  font-size: 24px;
  background: linear-gradient(90deg, transparent 0%, #f87370 0%, #f87370 60%, transparent 20%);
background-size: 100%  5px;
background-position: 0% 100%;
background-repeat: no-repeat;
text-transform: uppercase;
display: inline-block;
color: #565656;
padding-bottom: 5px; 
  line-height: 1.2;
}

.wow_community-our-people-heading-text{
  font-family: wow-regular;
  font-size: 14px;
  margin-top: 16px !important;

}



.wowcommunity-page-right-back-gradiant {
  background: #fff;
  background: none !important;
  background: -webkit-linear-gradient(-45deg,#fff,#fff 60%,#fff 79%,#d87b79a4 0,#d87b7985 82rgba(216, 123, 121, 0.527)79);
  background: -webkit-linear-gradient(315deg,#fff,#fff 60%,#fff 79%,#d87b799a 0,#d87b7991 82rgba(216, 123, 121, 0.555)79);
  background: linear-gradient(135deg,#fff,#fff 60%,#fff 79%,#d87b79 0,#d87b79b2 82%,#d87b798a);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#d87b79",GradientType=1);
  padding: 30px 0;
}



.wowcommunity-page-left-back-gradiant {
  background: #cfd7fe;
  background: none !important;
  background: -webkit-linear-gradient(45deg,#f8727081,#f8727096 18%,#f87370 21%,#fff 0,#fff 40%,#fff);
  background: linear-gradient(45deg,#f872709c,#f8727081 18%,#f87370 21%,#fff 0,#fff 40%,#fff);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f87370",endColorstr="#ffffff",GradientType=1);
  padding: 30px 0;
  margin-bottom: 0rem;
}



.padding-in-wowcommunity-grid-text{
padding: 1.5rem 0;
}


.feature-box-in-wow-community-holiday{
  background-color: rgb(255, 255, 255);
  font-size: 5.2rem;
  padding: 1.8rem;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 1.5rem 4rem #000000;
  transition: transform .3s;
  height: 350px;
  margin-bottom: 1rem;
  

}







.wow_community-collaborating {
  padding: 50px 0 0 0;
  /* text-align: left; */
  font-family: wow-bold;
  margin-bottom: 0px;
  font-size: 28px;
  background: linear-gradient(90deg, transparent 20%, #f87370 20%, #f87370 80%, transparent 60%);
background-size: 100%  5px;
background-position: 0% 100%;
background-repeat: no-repeat;
text-transform: uppercase;
display: inline-block;
color: #565656;
padding-bottom: 5px; 
  line-height: 1.2;
}

.wow_community-collaborating-heading-text{
  font-family: wow-regular;
  font-size: 16px;
  padding: 0rem 1rem;

}



}




@media(max-width:1125px) and (min-width:700px){
  .wow-community-top-content {
    background-image: url(/IMAGES/city-images/wow-community/community-bg.jpg);
    min-height: 450px;
      padding-top: 22px;
      position: relative;
      background-position: 68%;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      align-items: center;
   
  }
  
  .wow-community-top-content:before {
    content: "";
    position: absolute;
  background: linear-gradient(90deg,rgba(21, 26, 48, 0.664) 8%,rgba(21, 26, 48, 0.692) 46%,rgba(21,26,48,.07) 95%,rgba(21,26,48,0));
  width: 95%;
    height: 100%;
    left: 0;
    top: 0;
  }

  .wow-community-top-content-area {
    position: absolute;
    top: 30%;
    transform: translateY(-50%);  
    left: 1.5%;
    margin-top: 56px;
  }
  .wow-community-banner-top-heading{
    font-family: wow-bold;
  }
  .text-in-h3-wow-community{
    font-family: wow-medium;
  }
}