@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }


























    .carousel-slider-in-wow-holiday-trust-safety{
        height: auto ;
        /* max-height: 80vh !important; */
        width: 100% ;
    }


    


    .trust_slider_text_area123456l {
        background-color: rgba(0,0,0,.5);
    }


    .slide-trust-safety {
        height: auto!important;
    }

    .carousel-trust-safety {
        position: relative;
        width: 100%;
    }

    .slide-trust-safety {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
    }

    .carousel-indicators-trust-safety {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 15;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;
    }


    ol.carousel-indicators-trust-safety {
        margin-top: 0;
        margin-bottom: 1rem;
    }

    .carousel-indicators-trust-safety .active-trust-safety {
        opacity: 1;
    }


    .carousel-indicators-trust-safety li {
        box-sizing: content-box;
        -ms-flex: 0 1 auto;
        flex: 0 1 auto;
        width: 30px;
        height: 3px;
        margin-right: 3px;
        margin-left: 3px;
        text-indent: -999px;
        cursor: pointer;
        background-color: #fff;
        background-clip: padding-box;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        opacity: .5;
        transition: opacity .6s ease;
        color: #fff !important;
    }

  


    .carousel-inner-trust-safety {
        position: relative;
        width: 100%;
        overflow: hidden;
    }

    .carousel-item-trust-safety.active-carousel-inner-trust-safety {
        display: block;
    }

    .carousel-item-trust-safety {
        position: relative;
        display: none;
        float: left;
        width: 100%;
        margin-right: -100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: -webkit-transform .6s ease-in-out;
        transition: transform .6s ease-in-out;
        transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out;
    }

    .carousel img {
        width: 100%;
        display: inline-block;
        pointer-events: none;
        
    }


    .w-100-trust-safety {
        width: 100%!important;
    }
    .d-block-trust-safety {
        display: block!important;
    }

    .image-carousel-trust-safety img{
        vertical-align: middle;
        border-style: none;
    }

    .home_slider_text_area123456l-trust-safety {
        background-color: rgba(0,0,0,.5);
    }

    .carousel-caption-trust-safety {
        position: absolute;
        right: 15%;
        bottom: 20px;
        left: 15%;
        z-index: 10;
        padding-top: 20px;
        padding-bottom: 20px;
        color: #fff;
        text-align: center;
    }

    .home_slider_text_area123456l-trust-safety h3 {
        -webkit-animation: tracking-in-expand-fwd-top 1.2s cubic-bezier(.215,.61,.355,1) both;
        animation: tracking-in-expand-fwd-top 1.2s cubic-bezier(.215,.61,.355,1) both;
    }


    @media (max-width: 7000px) and (min-width: 400px){
.home_slider_text_area123456l-trust-safety h3, .offices-heading-text {
    text-transform: uppercase;
    font-family: wow-bold;
}

.about_fdkng12154-trust-safety {
    font-size: 24px;
    font-family: wow-bold;
}

.about_fdkng-trust-safety{
    font-size: 16px;

}

.home_slider_text_area123456l-trust-safety p {
    font-family: wow-regular;
    margin-bottom: 1rem;
    margin-top: 0;
}
    }


    @media (max-width: 700px){
        .home_slider_text_area123456l-trust-safety h3, .offices-heading-text {
            text-transform: uppercase;
            font-family: wow-bold;
        }
        
        .about_fdkng12154-trust-safety {
            font-size: 14px;
            font-family: wow-bold;
        }
        
        .about_fdkng-trust-safety{
            font-size: 10px;
            display: none;
        
        }
        
        .home_slider_text_area123456l-trust-safety p {
            font-family: wow-regular;
            margin-bottom: 0rem;
            margin-top: 0;
        }


        .carousel-caption-trust-safety {
            position: absolute;
            right: 15%;
            bottom: 10px;
            left: 15%;
            z-index: 10;
            padding-top: 10px;
            padding-bottom: 10px;
            color: #fff;
            text-align: center;
        }
        .w-100-trust-safety {
            width: 100%!important;
            height: 33vh;
        }
       
    
       
    
       
        
    
    
    }







.about_fdkng12154-trust-safety h3{
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.home_slider_text_area123456l p {
    -webkit-animation: focus-in-expand-fwd 1.3s cubic-bezier(.25,.46,.45,.94) both;
    animation: focus-in-expand-fwd 1.3s cubic-bezier(.25,.46,.45,.94) both;
}

.carousel-inner-trust-safety::after {
    display: block;
    clear: both;
    content: "";
}

.carousel-control-prev-trust-safety {
    left: 0;
}

.carousel-control-next-trust-safety, .carousel-control-prev-trust-safety {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: 5;
    transition: opacity .15s ease;
}

.sr-only-trust-safety {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
    margin: -1px;
}


.carousel-control-next-trust-safety {
    right: 0;
}









.u-left-text{
    text-align: left;
}

.u-margin-bottom-second-heading-trust-safety{
    margin-bottom: 2rem;
}


.heading-secondary-section-trust-safety{
    font-size: 3.5rem;
    font-weight: 700;
    margin-top: 2rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270 , #ff615ea9);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;
    /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat; */


    
}

.u-margin-bottom-heading-trust-safety{
    margin-bottom: 2rem;
}


.heading-secondary-trust-safety{
    font-size: 2rem;
    font-weight: 700;
    margin-top: 2rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: #565656;
    letter-spacing: 2px;
    transition: all .2s;
    background: -webkit-gradient(linear,left top,right top,color-stop(55%,transparent),color-stop(54.5%,#f87370),color-stop(0%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    padding-bottom: .5rem;


    
}

.padding-in-trustsafety-grid-text{
    padding: 7rem 0;
}


.trust-and-saefuty-page-bold-text {
    font-size: 16px;
    font-family: wow-medium;
    margin-bottom: 1rem;
}

.trust-and-safety-behind-bold-text{
    font-size: 14px;
    font-family: wow-regular;
    margin-bottom: 1rem;
    padding: 0 13rem 0 .2rem;

}

.trust-and-safety-planning-text{
    font-size: 20px;
    font-family: wow-medium;
    margin-bottom: 1rem;
    text-align: center;

    color: #565656;

}

.trust-and-saefuty-page-classic-text{
    font-size: 14px;
    font-family: wow-regular;
    margin-bottom: 1rem;
    padding: 0 2rem 0 .2rem;
}








.heading-safety-action-rider-section-trust-safety{
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: 3rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: #565656;
    letter-spacing: 2px;
    transition: all .2s;
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;


    
}



@media (max-width: 1700px) and (min-width: 500px){
.safety-page-left-back-gradiant {
    background: #cfd7fe;
    background: -webkit-gradient(left bottom,right top,color-stop(0,#cfd7fe),color-stop(18%,#cfd7fe),color-stop(21%,#cfd7fe),color-stop(21%,#fff),color-stop(40%,#fff),color-stop(100%,#fff));
    background: -webkit-linear-gradient(45deg,#f8727081,#f8727096 18%,#f87370 21%,#fff 0,#fff 40%,#fff);
    background: linear-gradient(45deg,#f872709c,#f8727081 18%,#f87370 21%,#fff 0,#fff 40%,#fff);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f87370",endColorstr="#ffffff",GradientType=1);
    padding: 60px 0;
}






.safety-page-right-back-gradiant {
    background: #fff;
    background: -webkit-gradient(left top,right bottom,color-stop(0,#fff),color-stop(60%,#fff),color-stop(79%,#fff),color-stop(79%,#cfd7fe),color-stop(82%,#cfd7fe),color-stop(100%,#cfd7fe));
    background: -webkit-linear-gradient(-45deg,#fff,#fff 60%,#fff 79%,#d87b79a4 0,#d87b7985 82rgba(216, 123, 121, 0.527)79);
    background: -webkit-linear-gradient(315deg,#fff,#fff 60%,#fff 79%,#d87b799a 0,#d87b7991 82rgba(216, 123, 121, 0.555)79);
    background: linear-gradient(135deg,#fff,#fff 60%,#fff 79%,#d87b79 0,#d87b79b2 82%,#d87b798a);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#d87b79",GradientType=1);
    padding: 90px 0;
}


.safety-page-right-back-gradiant-3 {
    background: #fff;
    background: -webkit-gradient(left top,right bottom,color-stop(0,#fff),color-stop(0%,#fff),color-stop(0%,#fff),color-stop(0%,#cfd7fe),color-stop(0%,#cfd7fe),color-stop(100%,#cfd7fe));
    background: -webkit-linear-gradient(-45deg,#d87b79a4,#d87b79a4 60%,#d87b79a4 79%,#d87b79a4 0,#d87b7985 82rgba(216, 123, 121, 0.527)79);
    background: -webkit-linear-gradient(315deg,#d87b79a4,#d87b79a4 60%,#d87b79a4 79%,#d87b799a 0,#d87b7991 82rgba(216, 123, 121, 0.555)79);
    background: linear-gradient(135deg,#d87b79a4,#d87b79a4 60%,#d87b79a4 79%,#d87b799a 0,#d87b798c 82%,#d87b798a);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#d87b79",GradientType=1);
    padding: 90px 0;
}


}



.safety-page-araea-sub-headings110 {
    font-size: 24px;
}

.safety-page-araea-sub-headings110 {
    border-bottom: 4px solid #f87370;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
    border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
    border-image-slice: 1;
    font-family: wow-bold;
    color: #565656;
    padding-bottom: 5px;
    margin-bottom: 25px;
    text-transform: uppercase;
}


.safety-page-araea-sub-headings110-3 {
    font-size: 24px;
}

.safety-page-araea-sub-headings110-3 {
    border-bottom: 4px solid #fff;
    -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#fff),color-stop(28%,transparent));
    -webkit-border-image: -webkit-linear-gradient(left,#fff 28%,transparent 0);
    border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#fff),color-stop(28%,transparent));
    border-image: linear-gradient(90deg,#fff 28%,transparent 0);
    border-image-slice: 1;
    font-family: wow-bold;
    color: #ffffff !important;
    padding-bottom: 5px;
    margin-bottom: 25px;
    text-transform: uppercase;
}




.trust-and-saefuty-page-text {
    font-size: 14px;
    font-family: wow-regular;
}


.trust-and-saefuty-page-text-3 {
    font-size: 14px;
    font-family: wow-regular;
    color: #ffffff;
}


/* .collapsible {
    background-color: #777;
    color: white;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }


  .content {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #f1f1f1;
  }

  .active, .collapsible:hover {
    background-color: red;
  } */










  .heading-safety-action-choose-us-trust-safety{
    font-size: 2.5rem;
    font-weight: 700;
    margin-top: 3rem;
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: #565656;
    letter-spacing: 2px;
    transition: all .2s;
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    padding-bottom: 5px;


    
}

.text-written-below-why-choose-us-safety-holiday{
    font-size: 20px;
    font-family: wow-medium;
    padding: 0 10rem;
    color: #aaaaaa;
    margin-top: 1rem;
    margin-bottom: 1rem;

}






/* services features safety */


.services-box-safety{
    background-color: #aaaaaa36;
    font-size: 5.2rem;
    padding: 1.8rem;
    height: 100%;
    text-align: center;
    border-radius: 4px;
    /* box-shadow: 0 .3rem .7rem #000000; */
    transition: transform .3s;
    margin-top: 0rem;
    
    

}




 /* safety icons */


 .lg-icon-safety, 
 .circle-icon-safety {
     width: 90px !important;
     height: 90px !important;
     line-height: 85px !important;
     font-size: 50px !important;
     margin-bottom: 1rem ;
    
 }


 .primary-icon-filled-safety {
     background: #f87370;
     color: #fff;
 border: none;
 }

 .circle-icon-safety{
     border-radius: 50%;
 }
 
 
 /* .feature-box-in-wow-transport-our-services-holiday:hover{
     transform: translateY(-1.5rem) scale(1.01) ;
 
 } */
 
 
 
 
 
 .header-inside-grid-wow-trust-our-safety-features-box{
     font-size: 1.3rem !important;
     font-weight: 700;
     text-transform: uppercase;
     font-family: wow-bold;
     color: #000000;
     /* background-image: linear-gradient(to right,#000000 , #0000009c);
     -webkit-background-clip: text;
     color: transparent;
     display: inline-block; */
 }


 .header-heading1-inside-grid-wow-trust-our-safety-features-box{
    font-size: 1.2rem !important;
    font-weight: 700;
    text-transform: uppercase;
    font-family: wow-bold;
    color: #000000;
    /* background-image: linear-gradient(to right,#000000 , #0000009c);
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block; */
}
 
 
 .feature__box-text-wow-trust-our-safety{
         font-size: 1.1rem !important;
         font-family: wow-regular;
         color: #000000;
      
 
 }


















 @media (max-width:700px){

    .heading-secondary-section-trust-safety{
        font-size: 2rem;
        font-weight: 700;
        margin-top: 2rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87270 , #ff615ea9);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 2px;
        transition: all .2s;
        /* background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
        display: inline-block;
        background-size: 100% 5px;
        background-position: 0 100%;
        background-repeat: no-repeat; */
    
    
        
    }
    
    
    .heading-secondary-trust-safety{
        font-size: 1.5rem;
        font-weight: 700;
        margin-top: 0rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87270a8 , #ff615e);
        -webkit-background-clip: text;
        color: #565656;
        letter-spacing: 2px;
        transition: all .2s;
        background: -webkit-gradient(linear,left top,right top,color-stop(55%,transparent),color-stop(54.5%,#f87370),color-stop(0%,#f87370),color-stop(75%,transparent));
        display: inline-block;
        background-size: 100% 5px;
        background-position: 0 100%;
        background-repeat: no-repeat;
        padding-bottom: .5rem;
    
    
        
    }
    
    
   
    
    .trust-and-safety-behind-bold-text{
        font-size: 14px;
        font-family: wow-regular;
        margin-bottom: 1rem;
        padding: 0 3rem 0 2.5rem;
    
    }

    .trust-and-saefuty-page-classic-text{
        font-size: 13px;
        font-family: wow-regular;
        margin-bottom: 1rem;
        padding: 0 2rem 0 .5rem;
    }
    
    .trust-and-safety-planning-text{
        font-size: 18px;
        font-family: wow-medium;
        margin-bottom: 1rem;
        text-align: center;
    
        color: #565656;
    
    }


    .heading-safety-action-choose-us-trust-safety{
        font-size: 2rem;
        font-weight: 700;
        margin-top: 3rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87270a8 , #ff615e);
        -webkit-background-clip: text;
        color: #565656;
        letter-spacing: 2px;
        transition: all .2s;
        background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
        display: inline-block;
        background-size: 100% 5px;
        background-position: 0 100%;
        background-repeat: no-repeat;
        padding-bottom: 5px;
    
    
        
    }
    
    .text-written-below-why-choose-us-safety-holiday{
        font-size: 16px;
        font-family: wow-medium;
        padding: 0 2rem;
        color: #aaaaaa;
        margin-top: 1rem;
        margin-bottom: 2rem;
    
    }



    .service-box-safety-margin{
        margin-bottom: 1.5rem;
    
    }


    .heading-safety-action-rider-section-trust-safety{
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 1rem;
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87270a8 , #ff615e);
        -webkit-background-clip: text;
        color: #565656;
        letter-spacing: 2px;
        transition: all .2s;
        background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
        display: inline-block;
        background-size: 100% 5px;
        background-position: 0 100%;
        background-repeat: no-repeat;
    
    
        
    }
    .u-margin-bottom-second-heading-trust-safety{
        margin-bottom: 0rem;
    }


    
    .safety-page-araea-sub-headings110 {
        border-bottom: 4px solid #f87370;
        -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
        -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
        border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
        border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
        border-image-slice: 1;
        font-family: wow-bold;
        color: #565656;
        padding-bottom: 5px;
        margin-bottom: 25px;
        text-transform: uppercase;
    }
    
    
    .safety-page-araea-sub-headings110-3 {
        border-bottom: 4px solid #f87370;
        -webkit-border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
        -webkit-border-image: -webkit-linear-gradient(left,#f87370 28%,transparent 0);
        border-image: -webkit-gradient(linear,left top,right top,color-stop(28%,#f87370),color-stop(28%,transparent));
        border-image: linear-gradient(90deg,#f87370 28%,transparent 0);
        border-image-slice: 1;
        font-family: wow-bold;
        color: #565656 !important;
        padding-bottom: 5px;
        margin-bottom: 25px;
        text-transform: uppercase;
    }

    .padding-in-trustsafety-grid-text{
        padding: 3rem 0;
    }

    .trust-and-saefuty-page-text-3 {
        font-size: 14px;
        font-family: wow-regular;
        color: #000000;
    }




 }






















