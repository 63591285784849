@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }


























*, *::before, *::after {
    box-sizing: inherit;
}

*, *::before, *::after {
    box-sizing: inherit;
}

/* footer-above-section-in-wow-holiday-background */

.footer-above-section-in-wow-holiday-background{
    background-color: #f87370;
    border-bottom: 3px solid rgba(255,255,255,0.1) !important;
}

.padding-in-footer-section{
    padding: 30px 30px;
   
    
}


.footer-in-wow-holiday input {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    padding: 7px 12px;
    width: 259px;
    height: 42px;
}

 


 .footer-in-wow-holiday a,  .footer-in-wow-holiday input {
    border-radius: 0;
}


button, input[type="text"], input[type="password"], select, textarea {
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 2px;
    height: 40px;
    box-sizing: border-box;
    outline: none;
    
}

input{
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}


.wow-logo-in-footer{
    width: 110px;
    margin-left: 0;
}


input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark-color(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-appearance: textfield;
    background-color: -internal-light-dark-color(white, black) !important;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 0px;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
}

.padding-between-contact-in-wow-holiday{
    margin-right: 0px !important;
    color: #f87370 !important;
}


input, textarea {
    outline: none;
}


/* button subscribe */
 .footer-in-wow-holiday-subscribe input {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    padding: 7px 12px;
    width: 249px !important;
    height: 42px;
}

.subscribe{
    color: #fff !important;
    font-family: wow-regular;
}
.footer-in-wow-holiday-subscribe  {
    width: 101px !important;
    height: 42px !important;
    line-height: 42px !important;
    border-radius: 0 2px 2px 0 !important;
}

.g_left {
    float: left;
}

.m_btn_main, .m_btn_gray {
    background-color: #ff5722;
    color: white;
    border-radius: 5px;
}

.t_mid {
    text-align: center;
}

.t14-footer-above {
    font-size: 13px ;
    font-family: wow-regular !important;

   
}



.t12-footer-above{
    color: #fff;
    font-family: wow-regular !important;
}






















/* .m_footer .footer-in-wow-holiday {
    padding: 30px 0 30px;
    background-color: black !important;
    border-bottom: 1px solid rgba(255,255,255,0.1);
} */

 .footer-in-wow-holiday {
    padding: 30px 0 30px;
    background-color: #f87370 !important;
    border-bottom: 3px solid rgba(255,255,255,0.1);
}





div {
    display: block;
}


/* .klook-icon-fb:before {
    content: "\E059";
}

.klook-icon-twi:before {
    content: "\E0C9";
}

.klook-icon-wc:before {
    content: "\E0DB";
}

.klook-icon-ins2:before {
    content: "\E06F";
} */

 .footer-in-wow-holiday>div.m-grid-in-wow-holiday-leader>div.m_justify_list_footer:nth-of-type(1) .m-grid-in-wow-holiday-leader .m_justify_list_footer [class*="klook-icon"] {
    color: #888;
}

.t22 {
    font-size: 22px;
    padding-left: 20px;
    color: #fff !important;
    margin-top: 8px;
    
}

.t23 {
    
    padding-right: 12px;
    
}

[class^="klook-icon-"], [class*=" klook-icon-"]  {
    font-family: "klookfont";
    /* speak: none; */
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    color: #d5d5d5;
    /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

.m-grid-in-wow-holiday-leader {
    text-align: justify;
    line-height: 0;
}



.m-grid-in-wow-holiday-leader .m_justify_list_footer {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    line-height: 1;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    box-sizing: border-box;
}

.t_white {
    color: #ffffff;
}
/* .m_footer */
 

.g-main-in-wow-holiday-leader {
    width: 1160px !important;
}

.g_rel, .t_v_mid_box, .m_header, .m_bg, .m_star {
    position: relative;
    
}

.g-main-in-wow-holiday-leader {
    margin: 0 auto !important;
    width: 1000px;
    
}

 .footer-in-wow-holiday>div.m-grid-in-wow-holiday-leader>div.m_justify_list_footer:nth-of-type(2)>div.m-grid-in-wow-holiday-leader>div.m_justify_list_footer:first-of-type>div {
    height: 42px;
    display: table-cell;
    vertical-align: middle;
}

 .footer-in-wow-holiday>div.m-grid-in-wow-holiday-leader>div.m_justify_list_footer:nth-of-type(2)>div.m-grid-in-wow-holiday-leader>div.m_justify_list_footer:first-of-type {
    height: 42px;
    margin-right: 15px;
    /* color: #888; */
}


 .footer-in-wow-holiday input {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    padding: 7px 12px;
    width: 259px;
    height: 42px;
}

 


 .footer-in-wow-holiday a,  .footer-in-wow-holiday input {
    border-radius: 0;
}


button, input[type="text"], input[type="password"], select, textarea {
    margin: 0;
    border: 1px solid #ccc;
    border-radius: 2px;
    height: 40px;
    box-sizing: border-box;
    outline: none;
}





.input-color-in-wow-holiday{
    color: black;
    width: 250px ;
    padding: 19px;
    margin-top: 1px !important;
}




/* button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
} */


 /* input{
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
}


input {
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark-color(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    -webkit-appearance: textfield;
    background-color: -internal-light-dark-color(white, black) !important;
    -webkit-rtl-ordering: logical;
    cursor: text;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 0px;
    border-width: 2px;
    border-style: inset;
    border-color: initial;
    border-image: initial;
} */


.m-grid-in-wow-holiday-leader .m_justify_list_footer {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    line-height: 1;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    box-sizing: border-box;
}




/* .m_footer .a input {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    padding: 7px 12px;
    width: 259px;
    height: 42px;
} */


 .footer-in-wow-holiday .subscription {
    width: 101px;
    height: 42px;
    line-height: 42px;
    border-radius: 0 2px 2px 0;
}

/* .m_btn_main, .m_btn_gray {
    background-color: #ff5722;
    color: white;
    border-radius: 5px;
} */




.m-grid-in-wow-holiday-leader {
    text-align: justify !important;
    line-height: 0;
}

.links-written-footer-in-wow-holiday{
    color: #fff;
    font-family: wow-bold;
    text-decoration: underline;
}



















/* footer section */



 .b {
    padding: 42px 0 11px;
    background-color: #f87370;
    border-bottom: 1px solid rgba(255,255,255,0.1);
    opacity: 1;
}


 .g_main {
    width: 1160px;
}

.g_main {
    margin: 0 auto;
    width: 1080px;
}

.m_grid {
    text-align: justify;
    line-height: 0;
}

div, section, article {
    box-sizing: border-box;
}

div {
    display: block;
}

ul, ol, li {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul, p {
    margin: 0;
    padding: 0;
}

ul, p {
    margin: 0;
    padding: 0;
}


ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}

.m_grid .m_justify_list_footer {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    line-height: 1;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    box-sizing: border-box;
}

.m_grid {
    text-align: justify !important;
    line-height: 0;
}

 .b .g_main>.m_justify_list_footer:last-of-type>ul:first-of-type>li:last-of-type>a {
    display: block;
    width: 100%;
    height: 100%;
}

.b ul li a,  .b  a {
    color: #888;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

.copyright  {
    color: #fff;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
}

 .b ul li {
    margin-bottom: 8px;
    line-height: 17px;
}


.foot-askus-btn {
    width: 214px;
    border-radius: 2px;
    border: 2px solid #fff;
    height: 42px;
    line-height: 42px !important;
    box-sizing: border-box;
    outline: none;
    color: white;
}


.t16 {
    font-size: 16px;
}

ul li {
    list-style: none;
}

/* ul, ol, li {
    margin: 0;
    padding: 0;
    list-style: none;
} */

/* ul, ol {
    padding-left: 0;
    list-style-type: none;
} */

 .b ul li:first-of-type {
    margin-bottom: 20px;
}

.b .g_main>.m_justify_list_footer:last-of-type>ul:first-of-type>li:last-of-type {
    margin-bottom: 30px;
}


.color-of-footer-content {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
    cursor: pointer;
    text-decoration: none;
    color: #fff !important;
}


 .b .g_main>.m_justify_list_footer:last-of-type>ul:last-of-type>li:last-of-type {
    width: 100%;
}

.view_mobile {
    border: 1px solid #fff;
    border-radius: 5px;
    width: 158px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    /* display: none; */
}


.bold-content{
    font-weight: bold;

}

.m_footer .b .g_main>.m_justify_list_footer:first-of-type {
    width: 220px;
}

.i_unionpay {
    display: inline-block;
    /* background-image: url(/IMAGES/city-images/Visa-icon.png); */
    /* background-position: 0px -487px; */
    width: 140px;
    height: 74px;
}

.b .g_main>.m_justify_list_footer:last-of-type>ul:last-of-type>li:last-of-type span:not(:nth-of-type(4n)) {
    margin-right: 13px;
}
 .b .g_main>.m_justify_list_footer:last-of-type>ul:last-of-type>li:last-of-type span {
    margin-bottom: 18px;
}

 .m_grid .m_justify_list_footer {
    overflow: visible !important;
}

.m_grid .m_justify_list_footer {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    display: inline-block;
    vertical-align: top;
    overflow: hidden;
    line-height: 1;
    -webkit-transition: all .2s ease;
    transition: all .2s ease;
    box-sizing: border-box;
}

[class^="i_"] {
    display: inline-block;
    vertical-align: top;
}















.shadow_box__1, .a_sd_move, .klook-icon-Top {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 -1px 3px 0 rgba(0, 0, 0, 0.08);
}

.klook-icon-Top {
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 9px;
    box-sizing: border-box;
    color: #ff5722;
    background-color: white;
    right: 40px;
    bottom: 7.5%;
    cursor: pointer;
    font-size: 36px;
    z-index: 10;
    opacity: 0.6;
}

.klook-icon-Top:before {
    content: "\E004";
}


.t_gray {
    color: #fff;
    font-weight: bold;
    font-size: 16px;
}
























































































/* 2nd footer */


footer.bg-color--black {
    padding-bottom: 0!important;
}

.bg-color--black {
    background-color: #ffffff;
    
}

.pt-5, .py-5 {
    padding-top: 3rem!important;
}


article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}



.text-right {
    text-align: right!important;
}

/* .col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
} */

footer .navbar-brand img {
    width: auto;
    height: 130px;
    margin-top: 3rem;
    
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
  
}

footer a {
    color: #f87370;
    font-family: wow-regular;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 1px;
}
.mb-3, .my-3 {
    margin-bottom: 1rem;
}
footer h4.mb-3 {
    font-family: wow-medium;
    font-size: 17px;
    font-weight: bold;
    line-height: 1.5;
    letter-spacing: 1.14px;
    color: #f87370;
    border-image: linear-gradient(90deg,#f87370 40%,transparent 0);
    border-image-slice: 2;
    border-bottom: 4px solid #f87370;
    padding-bottom: 3px;
}

.copyright-footer{
    font-size: 13px;
    font-family: wow-regular;
    color: #f87370;
}

.copyright-links{
    font-family: wow-medium !important;

}
/* .mb-3, .my-3 {
    margin-bottom: 1rem!important;
} */

h1, h2, h4 {
   
    font-weight: 700;
    color: #f87370;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

h4 {
    display: block;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-weight: bold;
}

.list-unstyled {
    padding-left: 0;
    margin-top: -.1rem;
    list-style: none;
}

dl, ol, ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
}


ul.list--chevron li {
    position: relative;
    padding-left: 15px;
}

.mb-2, .my-2 {
    margin-bottom: .5rem!important;
}


ul.list--chevron li:before {
    content: '';
    background-image: url(/IMAGES/city-images/arrow-rightpink.png);
    background-size: 8px 14px;
    width: 8px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 4px;
    transition: transform 250ms cubic-bezier(.1,.6,.6,1);
}

.footer__bottom i {
    font-size: 22px;
}

.icons-in-footer-of-wow-holiday {
    display: inline-block;
    /* font: normal normal normal 14px/1 FontAwesome; */
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding-right: 30px;
}

.pl-4, .px-4, .pl-4-4, .pl-4-3, .pl-4-4-uae {
    padding-left: 1.5rem!important;
    
    
}

.pl-4{
    font-family: wow-medium;
    font-size: 16px;
    color: #f87370 !important; 
}

.mt-2 i{
    color: #f87370 !important;
}


.pl-4-4{
    font-family: wow-regular;
    font-size: 14px;
    color: #f87370;
}

.pl-4-4-uae{
    font-family: wow-regular;
    font-size: 16px;
    color: #f87370 !important;
}

.pl-4-3{
    font-family: wow-medium;
    font-size: 13px;
    color: #f87370 !important; 
    
}

.pl-4-3-qrcode{
    width: 60%;
    
}

.padding-between-contact-in-wow-holiday-qrcode{
    text-align: center;
}





footer address {
    color: #f87370;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

address {
    display: block;
}

.fa-envelope:before {
    content: "";
}

footer ul.list--icons li i {
    width: 16px;
    text-align: center;
    font-size: 16px;
    /* padding-right: 220px !important; */
}

ul.list--icons li i {
    color: #fff;
    font-size: 18px;
    position: absolute;
    margin-top: 4px;
    
}

.whatever{
    color: #a3af03;
    font: bold;
    font-size: 19px;

}

.content-written-in-footer-of-wow-holiday-page{
    font-family: wow-regular;
    line-height: 15px ;
}


li {
    display: list-item;
    /* text-align: -webkit-match-parent; */
}

/* .footer__bottom {
    padding: 5px 0!important;
} */

.footer__bottom {
    border-top: 1px solid #f87370;
    font-size: 12px;
    color: #fff;
}

.flex-column {
    flex-direction: column!important;
}
.d-flex {
    display: flex!important;
}















@media (max-width: 700px) and (min-width:0){
    .subscription {
        width: 70px !important;
        height: 42px;
        line-height: 42px;
        border-radius: 0 2px 2px 0;
        
    }

    .footer-in-wow-holiday-subscribe input {
        border-bottom-left-radius: 2px;
        border-top-left-radius: 2px;
        padding: 7px 22px;
        width: 209px !important;
        
        height: 42px;
    }
    
    .subscribe{
        color: #fff !important;
    }
    .footer-in-wow-holiday-subscribe  {
        width: 70px !important;
        height: 40px !important;
        line-height: 40px !important;
        border-radius: 0 2px 2px 0 !important;
        margin-top: 10px !important;
    }

    .input-color-in-wow-holiday{
        margin-top: 10px !important;
        color: black;
        width: 215px ;
        padding: 19px;
    }
    .m_justify_list_footer{
        margin-top: 20px;
    }

    button, input[type="text"], input[type="password"], select {
        margin: 0;
        border: 1px solid #ccc;
        border-radius: 2px;
        height: 40px;
        box-sizing: border-box;
        outline: none;
        margin-left: 10px ;
    }


    .t14-footer-above-button-subscribe {
        font-size: 12px ;
    
    }


}















.content-written-in-footer-of-wow-holiday-page:hover{
    color: #ff0000;
}


.padding-between-contact-in-wow-holiday:hover{
    color: #fff;
}




































@media (min-width: 992px){
.text-lg-left {
    text-align: left!important;
}
/* .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
} */

.mb-lg-0, .my-lg-0 {
    margin-bottom: 0!important;
}
/* .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
} */
.align-items-lg-center {
    align-items: center!important;
}

/* .icons-in-footer-below-section-of-wow-holiday{
    padding-left: 27%;
    width: 50%;
} */

/* .col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 30.666667% !important;
} */



}


 .icons-in-footer-below-section-of-wow-holiday{
    color: #f87370;
}



@media (min-width: 992px){
.order-lg-first {
    order: -1;
}
}

@media (min-width: 768px){
/* .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
} */
.flex-md-row {
    flex-direction: row!important;
}
.class-for-mobile-view-wow-holiday-footer-section{
    margin-bottom: 20px;
    
}


}


























/* form */

.form-in-wow-holiday-footer{
    height: 350px;
    width: 21rem;
    
    margin-top: 20%;
    margin-bottom: 20%;
    border-image: linear-gradient(90deg,black 40%,transparent 0);
    /* border-image-slice:; */
    
}






























@media (max-width: 1130px) and (min-width:709px){
    .icons-in-footer-of-wow-holiday {
        display: inline-block;
        /* font: normal normal normal 14px/1 FontAwesome; */
        font-size: 16px !important;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding-right: 7px;
    }

    .tac{
        font-size: 12px !important;
    }

    

    .t22 {
        font-size: 15px;
        padding-left: 0px;
        color: #fff !important;
        margin-top: 8px;
        
    }
    .padding-in-icon-in-footer-aboe-section-wow-holiday{
        padding-left: 14px;

    }

    .input-color-in-wow-holiday{
        color: black;
        width: 210px ;
        padding: 19px !important;
        
    }

    .footer-in-wow-holiday-subscribe  {
        width: 80px !important;
        height: 40px !important;
        line-height: 40px !important;
        border-radius: 0 2px 2px 0 !important;
       
    }
    
    .t69 {
        font-size: 13px !important;
       
    }


    .wow-logo-in-footer {
        width: 90px;
        margin-left: 0;
    }

    footer h4.mb-3 {
        
        font-size: 18px !important;
        font-family: wow-regular;
        line-height: 1.5;
        letter-spacing: 1.14px;
        color: #fff;
    }

    footer .navbar-brand img {
        width: auto;
        height: 130px;
        margin-top: 0rem;
        
    }
    
    .navbar-brand {
        display: inline-block;
        padding-top: .3125rem;
        padding-bottom: .3125rem;
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: inherit;
        white-space: nowrap;
      
    }




}
























.asdas{
    display: flex;
    
}





/* 
.active-button-terms{
    color: black !important;
    background-color: #fff !important;
} */










