@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }   





.intimation-email-wow-header{
    background-color: #1036fc;
    

}

.wow-image-in-intimation-email{
    width: 100%;
height: 100%;


max-height: 120px;

max-width: 300px;
text-align: center !important;
padding: 20px;
}

.text-written-in-intimation-email{
    margin-left: 300px;
    margin-top: 30px;
    font-family: wow-regular;
    /*display: block;
*/		font-size: 20px;
    
    color: #565656;
}
/* .text-thank-you{
    font-family: wow-bold;
    
    color: #1036fc;
} */

.statement-header-line-in-intimation-email{
width: 100%;
border-top: 2px solid blue;
margin-top: 3%;

}











.intimation-email-footer-content{
margin-top: 10px;
}

.infoCons-in-intimation-email{
display: flex;
flex-direction: column;
/* max-width: 70%; */
margin: auto;
padding: 1%;


}
.horizontalRow-in-intimation-email{
display: flex;
justify-content: space-between;
align-items: left;




}

.intimation-email-ones{
width: 30%;
}
.intimation-email-twos{
width: 20%;
flex-grow: 0.5;
}
.intimation-email-threes{
padding-left: 10%;
width: 23%;

}
.intimation-email-four{
width: 27%;
}

.intimation-email-ones, .intimation-email-twos, .intimation-email-threes{
padding: 5px 1%;
overflow: hidden;
padding-right: 5%;
text-align: left;
}



/* .leader-texts{


font-size: 12px;
text-align: left;


}

.regard-text{
font-size: 14px;


} */

.intimation-email-inside-texts{

font-size: 19px;
font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";


}
.itemCons-in-intimation-email {
display: flex;

align-items: center;
}

/* .iconCons,
.iconDescs {
display: inline-block;
font-weight: bold;
font-size: 12px;
}

.iconCon-in-intimation-email,
.iconDesc {
display: inline-block;
font-weight: bold;
} */

.iconCon-in-intimation-email-img {
height: 25px;
width: 25px;
margin-right: 15px;
}
.footer-baseline-image-intimation-email{
width: 25px;

}

.iconConss,
.iconDescss-in-intimation-email {
display: inline-block;
font-weight: bold;
font-size: 12px;
margin-left: 15px;
}

.iconConss,
.iconDescsss {
display: inline-block;
font-weight: bold;
font-size: 13px;
margin-left: 10px;
}






@media only screen and (min-width: 992px){
.text-written-in-intimation-email{
    margin-left: 300px;
    margin-top: 30px;
    font-family: wow-regular;
    /*display: block;
*/		font-size: 20px;
    
    color: #565656;
}
.text-thank-you{
    font-family: wow-bold;
    font-size: 29px;
    color: #1036fc;
}

.statement-header-line-in-intimation-email{
width: 100%;
border-top: 2px solid blue;
margin-top: 3%;

}

.text-this-was-intimation-page{
    font-family: wow-bold;
}









.intimation-email-footer-content{
margin-top: 10px;
}

.infoCons-in-intimation-email{
display: flex;
flex-direction: column;
/* max-width: 70%; */
margin: auto;


}
.horizontalRow-in-intimation-email{
display: flex;
justify-content: space-between;
align-items: left;



}

.text-below-intimation-footer-page{
    font-size: 14px;
    font-weight: bolder;
    color: #565656;
    margin-top: 15px;
    padding: 1%;

}



.intimation-email-ones{
width: 30%;
}

.intimation-email-twos{
width: 20%;
flex-grow: 0.5;
}
.intimation-email-threes{
padding-left: 10%;
width: 20%;

}
.intimation-email-four{
width: 30%;
}

.intimation-email-ones,.intimation-email-twos,.intimation-email-threes{
padding: 5px 1%;
overflow: hidden;
padding-right: 5%;
text-align: left;
}



.leader-texts{


font-size: 12px;
text-align: left;


}

.regard-text{
font-size: 14px;


}

.intimation-email-inside-texts{

font-size: 19px;
font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";


}
.itemCons-in-intimation-email {
display: flex;

align-items: center;
}

.iconCons,
.iconDescs {
display: inline-block;
font-weight: bold;
font-size: 12px;
}

.iconCon-in-intimation-email,
.iconDesc {
display: inline-block;
font-weight: bold;
}

.iconCon-in-intimation-email-img {
height: 25px;
width: 25px;
margin-right: 15px;
}
.footer-baseline-image-intimation-email{
width: 25px;

}

.iconConss,
.iconDescss-in-intimation-email {
display: inline-block;
font-weight: bold;
font-size: 12px;
margin-left: 15px;
}

.iconConss,
.iconDescsss {
display: inline-block;
font-weight: bold;
font-size: 13px;
margin-left: 10px;
}


}



@media (max-width:991px) and (min-width: 699px){

    .text-written-in-intimation-email{
        margin-left: 100px;
        
    
        
        margin-top: 50px;
        font-family: wow-regular;
        
        font-size: 12px;
        
        /*color: #565656;*/
    }


    .text-thank-you{
        font-family: wow-bold;
        font-size: 20px;
        color: #1036fc;
    }


    .intimation-email-ones{
        width: 27%;
        }
        .intimation-email-twos{
        width: 20%;
        flex-grow: 0.5;
        }
        .intimation-email-threes{
        padding-left: 10%;
        width: 20%;
        
        }
        .intimation-email-four{
        width: 40%;
        }
        
        .intimation-email-ones,.intimation-email-twos,.intimation-email-threes{
        padding: 5px 1%;
        overflow: hidden;
        padding-right: 5%;
        text-align: left;
        }






}







@media (max-width:700px) and (min-width:0px){

.text-written-in-intimation-email{
    margin-left: 0px;
    

    
    margin-top: 50px;
    font-family: wow-regular;
    
    font-size: 12px;
    
    /*color: #565656;*/
}
.div text{
    text-align: left;
}

.wow-image-in-intimation-email{
    width: 100%;
height: 100%;


max-height: 90px;

max-width: 220px;
text-align: center !important;
padding: 20px;
}

.statement-header-line-in-intimation-email{
width: 100%;
border-top: 2px solid blue;
margin-top: 3%;
margin-top: 50px


}


.text-thank-you{
    font-family: wow-bold;
    font-size: 19px;
    color: #1036fc;
}





.intimation-email-ones{
    width: 22%;
    }
    .intimation-email-twos{
    width: 22%;
    flex-grow: 0.5;
    }
    .intimation-email-threes{
    padding-left: 10%;
    width: 22%;
    
    }
    .intimation-email-four{
    width: 46%;
    }
    
    .intimation-email-ones,.intimation-email-twos,.intimation-email-threes{
    padding: 5px 1%;
    overflow: hidden;
    padding-right: 5%;
    text-align: left;
    }


    .footer-baseline-image-intimation-email{
        width: 10px;
        
        }


        .iconConss,
.iconDescss-in-intimation-email {
display: inline-block;
font-weight: bold;
font-size: 8px;
margin-left: 5px;
}

.intimation-email-inside-texts{

    font-size: 12px;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    
    
    }



}















































/* legal emirates verify email */


.verify-email-emirates-header{
    display: flex;
    /* display:-webkit-flex;
    display:-ms-flexbox;
    justify-content: space-between !important; */
    flex-direction: row;
}

.image-logo-verify-email-emirates{
    width: 170px;
    margin: 1rem 0rem 0 2rem;
    border: 1px solid #00000077;
    border-radius: 50%;
    padding: .5rem;
    z-index: 1000 !important;
    background-color: white;
}

.border-line-verify-email-emirates15{
    margin-top: -95px;
    z-index: -1 !important;
}


.heading-text-verify-email-emirates{
    font-size: 32px;
    font-family: wow-bold;
    color: #e4cd72 ;
    padding: 3rem 2rem;
    position: absolute;
    right: 0px;
}


.image-verify-email-emirates{
    text-align: center;
    width: 100%;
}











.content-section-verify-email-emirates{
    padding: 2rem 3rem;
}




.content-section-text-verify-email-emirates p {
    font-size: 16px;
    font-family: wow-regular;
}




.content-section-text-verify-email-emirates p{
margin-top: 0;
    margin-bottom: 1rem;
    padding: 0rem 1.3rem;
}


.content-section-text-verify-email-emirates h2 {
    font-size: 26px;
    font-family: wow-medium;
}

.content-section-text-verify-email-emirates h3 {
    font-size: 24px;
    font-family: wow-medium;
    padding: 0rem 1rem;
}


.content-section-text-verify-email-emirates button{
    background-color: #2d2b2b !important;
    color: #e4cd72 ;
    padding: 1rem;
    width: auto;
    border-radius: 5px;
    margin: 2rem 0;
    height: auto;
    font-size: 17px;
    font-family: wow-medium;



}


.border-line-verify-email-emirates{
    border: 1px solid #56565671;
    margin-bottom: 1rem;
}

.border-line-bottom-verify-email-emirates{
    border: 1px solid #56565662;
    margin: 1.5rem 0;

}


@media(max-width:700px){

    .image-logo-verify-email-emirates{
        width: 100px;
        padding: 0rem 0rem 0 0rem;
    }

    .border-line-verify-email-emirates15{
        margin-top: -45px;
        z-index: -1 !important;
    }
    
    
    .heading-text-verify-email-emirates{
        font-size: 16px;
        font-family: wow-bold;
        color: #e4cd72 ;
        padding: 3rem 1rem;
    }
    
    
    .image-verify-email-emirates{
        text-align: center;
        width: 100%;
    }




    .content-section-text-verify-email-emirates p{
        margin-top: 0;
            margin-bottom: 1rem;
            padding: 0rem 1rem;
        }

        .content-section-text-verify-email-emirates p {
            font-size: 14px;
            font-family: wow-regular;
        }
        
        
        .content-section-text-verify-email-emirates h2 {
            font-size: 20px;
            font-family: wow-medium;
        }
        
        .content-section-text-verify-email-emirates h3 {
            font-size: 18px;
            font-family: wow-medium;
            padding: 0rem 1rem;
        }
        
        
        .content-section-text-verify-email-emirates button{
            background-color: #2d2b2b !important;
            color: #e4cd72 ;
            padding: 1rem;
            width: auto;
            border-radius: 5px;
            margin: 2rem 0;
            height: auto;
            font-size: 14px;
            font-family: wow-regular;
        
        
        
        }

}






















/* .verify-email-emirates-header {
    line-height: 0.5;
    text-align: center;
  }
  .verify-email-emirates-header span {
    display: inline-block;
    position: relative;  
  }
  .verify-email-emirates-header span:before,
  .verify-email-emirates-header span:after {
    content: "";
    position: absolute;
    height: 5px;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    top: 0;
    width: 600px;
  }
  .verify-email-emirates-header span:before {
    right: 100%;
    margin-right: 15px;
  }
  .verify-email-emirates-header span:after {
    left: 100%;
    margin-left: 15px;
  } */




