@font-face {
    font-family: wow-bold;
    src: url("https://wowets.com/static/media/Poppins-Bold.otf") format(opentype);
  }
  @font-face {
    font-family: wow-regular;
    src: url("https://wowets.com/static/media/Poppins-Regular.de2dd933.otf")
      format("otf");
  }
  
  
  @font-face {
      font-family: wow-medium;
      src: url('../fonts/poppins/Poppins-Medium.otf');
    }  
    @font-face {
      font-family: wow-regular;
      src: url('../fonts/poppins/Poppins-Regular.otf');
    }  
    @font-face {
      font-family: wow-black;
      src: url('../fonts/poppins/Poppins-Black.otf');
    }  
    @font-face {
      font-family: wow-holiday-bold;
      src: url('../fonts/poppins/Poppins-Bold.otf');
    }  
        @font-face {
      font-family: wow-boldItalic;
      src: url('../fonts/poppins/Poppins-BoldItalic.otf');
    } 
    @font-face {
      font-family: wow-semiBold;
      src: url('../fonts/poppins/Poppins-SemiBold.otf');
    } 
    @font-face {
      font-family: wow-italic;
      src: url('../fonts/poppins/Poppins-Italic.otf');
    }
    @font-face {
      font-family: wow-thin;
      src: url('../fonts/poppins/Poppins-Thin.otf');
    }





















.gaib-ho-gallery-grid-section-pictures{
    display: none;
}
























  
  .holiday-inspiration-our-content {
    display: -moz-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 60px;
    right: 30px;
  }



 
































  * {
margin: 0;
padding: 0;
box-sizing: border-box;
}






  .header-image-in-new-design-gallery{
    /* width: 100% !important;
    
    max-height: 400px !important; */
    background-image: linear-gradient(to left bottom, #7ed56f17, #f872701f), url('/IMAGES/Newdesign/hero-small.jpg');
    background-size: cover;
    background-position: center;
    
    clip-path: polygon(0 0,100% 0, 100% 430px,0 100%);
    position: relative;



    /* background-repeat: no-repeat; */
    
    background-position: 23% !important;
    
    width: 100%;
    padding-bottom: 36% !important;
  
  
    margin-bottom: 20px;
    background-attachment: fixed;
   
   
    
   

}

.logo-inside-header-of-new-design-page-gallery{
    position: absolute;
    top: 30px;
    left: 30px;

    
}

.logo-inside-header-of-new-design-page--wow-gallery{
    position: absolute;
    top: 30px !important;
    right: 30px !important;
    text-align: right !important; 
}
.image-logo-inside-header-of-new-design-page-wow-gallery{
    height: 50px;
    text-align: right;
}




.image-logo-inside-header-of-new-design-page-gallery{
    height: 60px;
    
}

/* .image-logo-inside-header-of-new-design-page-gallery:hover {
    height: 60px;
    animation-name: moveInright;
    animation-duration: 1s;
    
} */

/* .text-written{
    font-size: 60px;
    color: ivory;
    left: 40%;
    margin-top: 300px !important;
    position: absolute;
} */


.text-box-inside-background-image-of-new-design-gallery{
    position: absolute;
    top: 45% !important;
    left: 50%;
    transform: translate(-50%, -50%);

}


.heading-primary-gallery{
    color: #fff;
    text-transform: uppercase;
    backface-visibility: hidden;
    

}

.heading-primary-main-gallery{
    display: block;
    text-shadow: 2px 2px 0 rgba(0,0,0,.69);
    
    font-size: 60px;
    font-weight: 400;
    letter-spacing: 35px;
    animation-name: moveInleft;
    animation-duration: 1s;

}

.heading-primary-main-wow-gallery{

    display: block;
    text-align: center;
    font-size: 50px;
    
    font-weight: 400;
    letter-spacing: 15px;
    animation-name: moveInleft;
    animation-duration: 1s;

}



.heading-primary-sub-gallery{
    display: block;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 17.4px;
    animation-name: moveInright;
    animation-duration: 1s;
    text-shadow: 2px 2px 0 rgba(0,0,0,.4);


}

.button-in-new-design-gallery{
    /* width: 30%; */
    height: 50px; 
    margin-left: 32% !important;
    margin-top: 13px;
    font-size: 14px !important;
    animation-name: moveInup;
    animation-duration: 1s;
    border-radius: 10px !important;
    text-shadow: 2px 2px 0 rgba(0,0,0,.39);

    /* 
    animation-delay: 2s; 
    */
}

.button-in-new-design-gallery:hover{
    opacity: 1;
    border-radius: 0px !important;
    
    /* animation-name: moveInright;
    animation-duration: 1s; */
}

@keyframes moveInleft {
    0%{
        opacity: 0;
        transform: translate(-100px);
    }

    80%{
        transform: translate(10px);
    }

    100%{
        opacity: 1;
        transform: translate(0);


    }
}

@keyframes moveInright{
    0%{
        opacity: 0;
        transform: translate(100px);

    }
    80%{
        transform: translate(-10px);
    }
    100%{
        opacity: 1;
        transform: translate(0);

    }
}

@keyframes moveInup{
    0%{
        opacity: 0;
        transform: translate(0 , 60px);

    }
    80%{
        transform: translate(0 , -20px);

    }
    100%{
        opacity: 1;
        transform: translate(0);

    }
}















































/* section gallery */

.section-gallery-wow-holiday{
    background-color: #f7f7f7;
    padding: 12rem 0;
    margin-top: -9vh ;
    /* text-align: center; */
}


.heading-secondary-section-gallery-holiday{
    font-size: 3.5rem;
    font-weight: 700;
    
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;


    
}

.heading-secondary-section-gallery-holiday:hover{
    transform: skewY(1deg) skewX(15deg) scale(1.1);
    text-shadow: .5rem 1rem 2rem #565656;
}






.u-center-text{
    text-align: center;
}

.u-margin-bottom-second-heading-gallery{
    margin-bottom: 5rem;
}


.header-inside-grid-wow-gallery{
    font-size: 1.4rem !important;
    font-weight: 700;
    text-transform: uppercase;
    font-family: wow-holiday-bold;
    color: #565656;
    /* background-image: linear-gradient(to right,#000000 , #0000009c);
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block; */
}








.header-context-inside-grid-wow-gallery{
    font-size: 1.2rem !important;
    font-family: wow-regular;
    color: #000000;
}

.header-context-inside-grid-wow-gallery:not(:last-child){
    margin-bottom: 3rem;
}


.u-margin-bottom-small{
    margin-bottom: 1.5rem;
}




.button-text-wow-gallery-grid:link,
.button-text-wow-gallery-grid:visited{
    font-size: 1.2rem;
    color: #f87370 !important;
    display: inline-block ;
    text-decoration: none;
    border-bottom: 1px solid #f87370 !important;
    padding: 2px;
    font-family: wow-regular;


}




.button-text-wow-gallery-grid:hover{
    background-color: #f87370 !important;
    color: #ffffff !important;
    box-shadow: 0 1rem 2rem #000000;
    transform: translateY(-3px);
    padding: 4px;

}

.button-text-wow-gallery-grid:active {
    box-shadow: 0 .5rem 1rem #000000;
    transform: translateY(3px);
    padding: 4px;


}



.composition{

position: relative;



}


.composition__photo{
    width: 55%;
    box-shadow:0 1.5rem 4rem #0000002c ;
    position: absolute;
    border-radius: 2px;
    z-index: 10;
    transition: all .2s;
    outline-offset: 1.2rem;
}



.composition__photo:hover{
    outline: 1rem solid #f87370;
    transform: scale(1.05) translate(-0.5rem);
    box-shadow: 0 2.5rem 4rem #00000077;
    z-index: 20;
}




.composition__photo--p1{
    left: 0;
    top: -2rem;

}

.composition__photo--p2{
    right: 0;
    top: 2rem;
    
}

.composition__photo--p3{
    left: 20%;
    top: 10rem;

    
}






.composition:hover .composition__photo:not(:hover){
    transform: scale(.95);
    
}















/* section features */

.section-features-nature-wow-gallery-holiday{
    padding: 10rem 0;
    background-image: 
    linear-gradient(to left bottom, #f87270b6, #f872708a),
     url('/IMAGES/Newdesign/hero-small.jpg');
    background-size: cover;
    transform: skewY(-4deg);
    margin-top: -2rem;
    margin-bottom: 5rem;
    
    
}







.feature-box-in-wow-gallery-holiday{
    background-color: rgba(255, 255, 255, 0.808);
    font-size: 5.2rem;
    padding: 1.8rem;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 1.5rem 4rem #000000;
    transition: transform .3s;
    

}


.feature-box-in-wow-gallery-holiday:hover{
    transform: translateY(-1.5rem) scale(1.01) ;

}




.section-features-nature-wow-gallery-holiday > *{
    transform: skewY(4deg);
}



.feature-box__icon-wow-gallery{
    font-size: 5rem;
    margin-bottom: .5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #f87370, #f87370);
    -webkit-background-clip: text;
    color: transparent;
}


.header-inside-grid-wow-gallery-features-box{
    font-size: 1.2rem !important;
    font-weight: 700;
    text-transform: uppercase;
    font-family: wow-holiday-bold;
    color: #565656;
    /* background-image: linear-gradient(to right,#000000 , #0000009c);
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block; */
}


.feature__box-text-wow-gallery{
        font-size: 1.1rem !important;
        font-family: wow-regular;
     

}


































/* component under features of grid section pictures */




.heading-secondary-section-gallery-holiday-grid-section-pictures{
    font-size: 3.5rem;
    font-weight: 700;
    
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;


    
}


.u-margin-bottom-first-heading-gallery-grid-section-pictures{
    margin-bottom: 5rem;
}


.button-text-wow-gallery-grid-grid-section-pictures:link,
.button-text-wow-gallery-grid-grid-section-pictures:visited{
    font-size: 1.5rem;
    color: #565656 !important;
    display: inline-block ;
    text-decoration: none;
    border-bottom: 1px solid #565656 !important;
    padding: 2px;
    font-family: wow-regular;
    margin-bottom: 2rem;
    margin-right: .5rem;


}




.button-text-wow-gallery-grid-grid-section-pictures:hover{
    background-color: #f87370 !important;
    color: #ffffff !important;
    box-shadow: 0 1rem 2rem #000000;
    transform: translateY(3px);
    padding: 4px;

}

.button-text-wow-gallery-grid-grid-section-pictures:active {
    box-shadow: 0 .5rem 1rem #000000;
    transform: translateY(3px);
    padding: 4px;


}




.slash-in-gallery-grid-section-pictures{
    font-size: 2rem;
    color: #aaaaaa;
    margin-right: .5rem;
}




.images-in-grid-section-pictures-gallery{
    position: relative;
  
}

.margin-bw-pics-grid-section-pictures-gallery{
    margin-top: 4%;
}

.athens-bg-image{
    background-image: 
    linear-gradient(to  bottom, #00000023, #00000042),
     url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/city/athens.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    z-index: 10 !important;
    /* width: 55%; */
    background-position: 12%;
   
}



.athens-bg-image:hover{
     z-index: 20 !important;
   
}


.porto-bg-image{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/city/porto.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
   
}

.barca-bg-image{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/city/barcelona.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
   
}

.sebastian-bg-image{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/city/sebastian.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 32%;
   
}

.metz-bg-image{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/city/metz.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 10%;
   
}


.paris-bg-image{
    background-image:
     linear-gradient(to  bottom, #00000000, #0000003f),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/city/paris.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 100%;
   
}















/* beaches bg image */


.krk-bg-image-beaches{
    background-image: 
    linear-gradient(to  bottom, #00000023, #00000042),
     url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/beaches/krk.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    z-index: 10 !important;
    /* width: 55%; */
    background-position:42%;
   
}



.athens-bg-image:hover{
     z-index: 20 !important;
   
}


.larnaca-bg-image-beaches{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/beaches/larnaca.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 90%;
}

.malaga-bg-image-beaches{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/beaches/malaga.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
   
}

.sebastian-bg-image-beaches{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/beaches/sebastian.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 40rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 32%;
   
}

.algrave-bg-image-beaches{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/beaches/algrave.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 40rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 10%;
   
}









/* nature bg image */


.Cascadia-bg-image-nature{
    background-image: 
    linear-gradient(to  bottom, #00000023, #00000070),
     url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/nature/cascadia.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    z-index: 10 !important;
    /* width: 55%; */
    background-position:35%;
   
}



.athens-bg-image:hover{
     z-index: 20 !important;
   
}


.hawaii-bg-image-nature{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000075),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/nature/hawaii.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 0%;
}

.malaga-bg-image-beaches{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/beaches/malaga.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
   
}

.reef-bg-image-nature{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/nature/reef.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-bottom: 40rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 50%;
   
}

.pltivice-bg-image-nature{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/nature/pltivice.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 40rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 10%;
   
}
























/* ski bg image */



.ski-desinations-heading{
    text-align: center !important;
    font-size: 2.8rem;
    background-image: linear-gradient(to right,#f87270 , #ff615ea8);
    border-bottom: 3px solid #f87370;
    display: inline-block;
    margin-bottom: 2rem;
    font-weight: 700;
    font-family: wow-bold;
    -webkit-background-clip: text;
    color: transparent;
    
}

.ski-desinations-heading-below-text{
    padding: 0 8rem 3rem 8rem;
    font-size: 1.3rem;
    font-weight: 500;
    font-family: wow-regular;
    color: #565656;

}


.kitzbuhel-bg-image-ski{
    background-image: 
    linear-gradient(to  bottom, #00000023, #00000042),
     url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/ski/kitzbuhel.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    z-index: 10 !important;
    /* width: 55%; */
    background-position:42%;
   
}



.athens-bg-image:hover{
     z-index: 20 !important;
   
}


.cortina-bg-image-ski{
    background-image:
     linear-gradient(to  bottom, #00000000, #0000009f),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/ski/cortina.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 90%;
}

.crans-bg-image-ski{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000049),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/ski/crans.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
   
}

.livingo-bg-image-ski{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/ski/livingo.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 32%;
   
}

.gardena-bg-image-ski{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/ski/gardena.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 10%;
   
}

.valloire-bg-image-ski{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/ski/valloire.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 10%;
   
}




















/* events bg image */



.events-desinations-heading{
    text-align: center !important;
    font-size: 2.8rem;
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    margin-bottom: 3rem;
    font-weight: 700;
    font-family: wow-bold;
    color: #f87370;
    padding-bottom: 7px;
    z-index: 10;
    
}


.events-desinations-heading:hover{
    
    z-index: 20;
    
}





.uefa-bg-image-events{
    background-image: 
    linear-gradient(to  bottom, #00000023, #00000042),
     url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/events/uefa.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    z-index: 10 !important;
    /* width: 55%; */
    background-position:42%;
   
}



.athens-bg-image:hover{
     z-index: 20 !important;
   
}


.augusta-bg-image-events{
    background-image:
     linear-gradient(to  bottom, #00000000, #0000009f),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/events/augusta.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 60%;
}

.fifa-bg-image-events{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000049),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/events/fifa.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 0%;
}

.venice-bg-image-events{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000073),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/events/venice.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 36%;
   
}

.chinese-bg-image-events{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/events/chinese.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 10%;
   
}

.cricket-bg-image-events{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/events/cricket.png');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 30rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 50%;
   
}


















/* year bg image */



.year-desinations-heading{
    text-align: center !important;
    font-size: 2.8rem;
    background: -webkit-gradient(linear,left top,right top,color-stop(25%,transparent),color-stop(25%,#f87370),color-stop(75%,#f87370),color-stop(75%,transparent));
    display: inline-block;
    background-size: 100% 5px;
    background-position: 0 100%;
    background-repeat: no-repeat;
    margin-bottom: 3rem;
    font-weight: 700;
    font-family: wow-bold;
    color: #f87370;
    padding-bottom: 7px;
    z-index: 10;
    
}


.year-desinations-heading:hover{
    
    z-index: 20;
    
}

.text-written-inside-year-images-grid-section-pictures-gallery{
    font-size: 1.2rem;
    font-weight: 700;
    font-family: wow-regular;
    color: #fff;
    padding: 0 17px 0 0;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}










.music-bg-image-year{
    background-image: 
    linear-gradient(to  bottom, #00000041, #f8727073),
     url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/year/music.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 40rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    z-index: 10 !important;
    /* width: 55%; */
    background-position:42%;
   
}



.music-bg-image:hover{
     z-index: 20 !important;
   
}


.easter-bg-image-year{
    background-image:
     linear-gradient(to  bottom, #0000003a, #f8727075),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/year/easter.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 40rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 60%;
}

.eve-bg-image-year{
    background-image:
     linear-gradient(to  bottom, #00000038, #f8727052),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/year/eve.jpg');
    background-size: cover;
    background-repeat: no-repeat;
   
    padding-bottom: 50rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 0%;
}

.spring-bg-image-year{
    background-image:
     linear-gradient(to  bottom, #0000003a, #f8727056),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/year/spring.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 40rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 26%;
   
}

.halloween-bg-image-year{
    background-image:
     linear-gradient(to  bottom, #00000000, #00000070),
      url('/IMAGES/city-images/wow-gallery/gallery-grid-pics/year/halloween.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 0;
    padding-bottom: 40rem;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    background-position: 10%;
   
}





















.images-in-grid-section-pictures-gallery:hover{
    /* -webkit-transition: opacity .2s linear; */
   
    opacity: 0.9;
    
    /* padding-top: 0;
    padding-bottom: 0rem; */
    
   
}





@media (min-width:992px){

.text-area-inside-images-grid-section-pictures-gallery{
    position: absolute;
    bottom: 5px;
  left: 3%;
 
  width: 100%;
}


.text-heading-written-inside-images-grid-section-pictures-gallery{
    font-size: 1.7rem;
    font-weight: 700;
    font-family: wow-bold;
    color: #fff;
    text-transform: uppercase;
}

.map-pin-icon-grid-section-pictures-gallery{
    font-size: 1.5rem;
}


.text-written-inside-images-grid-section-pictures-gallery{
    font-size: 1.2rem;
    font-weight: 700;
    font-family: wow-regular;
    color: #fff;
    padding: 0 17px 0 0;
    display: flex;
    justify-content: space-between;
}

.price-written-grid-section-pictures-gallery{
    font-size: 1.8rem;
    font-weight: 700;
    font-family: wow-bold;
    color: #fff;
}





}








@media (max-width: 700px){
    .text-area-inside-images-grid-section-pictures-gallery{
        position: absolute;
        bottom: 5px;
      left: 3%;
     
      width: 100%;
    }
    
    
    .text-heading-written-inside-images-grid-section-pictures-gallery{
        font-size: 1.7rem;
        font-weight: 700;
        font-family: wow-bold;
        color: #fff;
        text-transform: uppercase;
    }
    
    .map-pin-icon-grid-section-pictures-gallery{
        font-size: 1.5rem;
    }
    
    
    .text-written-inside-images-grid-section-pictures-gallery{
        font-size: 1.2rem;
        font-weight: 700;
        font-family: wow-regular;
        color: #fff;
        padding: 0 17px 0 0;
        display: flex;
        justify-content: space-between;
    }
    
    .price-written-grid-section-pictures-gallery{
        font-size: 1.8rem;
        font-weight: 700;
        font-family: wow-bold;
        color: #fff;
    }



}



















.button-ski-resort-holiday-gallery{
    width: 31%;
    background-color: #fff !important;
    color: black !important;
    font-size: 22px !important;
    padding: 0 !important;
    border-radius: 115px !important;
    text-align: center !important;
    margin-top: 2rem;
    

  }
.button-ski-resort-holiday-gallery-center{
    text-align: center;
    
}

.border-line-gallery-holiday{
    border-bottom: 2px solid #aaaaaab2;
    margin-bottom: 2rem;
}














@media (max-width: 700px){


    .heading-primary-main-gallery{
        display: block;
        
        font-size: 26px;
        font-weight: 400;
        letter-spacing: 10px;
        animation-name: moveInleft;
        animation-duration: 1s;
    
    }
    
    .heading-primary-sub-gallery{
        display: block;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.4px;
        animation-name: moveInright;
        animation-duration: 1s;
    
    }





    .header-image-in-new-design-gallery{
        /* width: 100% !important;
        
        max-height: 400px !important; */
        background-image: linear-gradient(to right bottom, #f8727054, #f872705e), url('/IMAGES/Newdesign/hero-small.jpg');
        background-size: cover;
        /* background-position: center; */
        
        clip-path: polygon(0 0,100% 0, 100% 750px,0 100%);
        position: relative;
    
    
    
        /* background-repeat: no-repeat; */
        
        background-position: 43% !important;
        
        width: 100%;
        padding-bottom: 55% !important;
      
      
        margin-bottom: 0px;
        background-attachment: scroll;
       
       
        
       
    
    }



    .logo-inside-header-of-new-design-page-gallery{
        position: absolute;
        top: 10px;
        left: 10px;
    
        
    }

    .image-logo-inside-header-of-new-design-page-wow-gallery{
        height: 30px;
        
    }


    .logo-inside-header-of-new-design-page--wow-gallery{
        position: absolute;
        top: 10px !important;
        right: 10px !important;
        text-align: right !important; 
    }
    
    .image-logo-inside-header-of-new-design-page-gallery{
        height: 25px;
        
    }



    .text-box-inside-background-image-of-new-design-gallery{
        position: absolute;
        top: 55% !important;
        left: 52%;
        transform: translate(-50%, -50%);
    
    }


    .button-in-new-design-gallery{
       display: none !important;
    }

   




}






/* section gallery media queries */

@media (max-width:700px){
    .section-gallery-wow-holiday{
        background-color: #f7f7f7;
        padding: 7rem 0;
        margin-top: -9vh ;
        /* text-align: center; */
    }


    .u-margin-bottom-second-heading-gallery{
        margin-bottom: 3rem;
    }
    
    
    .header-inside-grid-wow-gallery{
        font-size: 1.3rem !important;
        font-weight: 700;
        text-transform: uppercase;
        font-family: wow-holiday-bold;
        color: #565656;
        /* background-image: linear-gradient(to right,#000000 , #0000009c);
        -webkit-background-clip: text;
        color: transparent;
        display: inline-block; */
    }

    .heading-secondary-section-gallery-holiday{
        font-size: 2.5rem;
        font-weight: 700;
        
        font-family: wow-bold;
        display: inline-block;
        background-image: linear-gradient(to right,#f87270a8 , #ff615e);
        -webkit-background-clip: text;
        color: transparent;
        letter-spacing: 2px;
        transition: all .2s;
    
    
        
    }

    .composition{
        margin-bottom: 12rem !important;
    }


    .composition__photo{
        width: 55%;
        box-shadow:0 1.5rem 4rem #0000002c ;
        position: absolute;
        border-radius: 2px;
        z-index: 10;
        transition: all .2s;
        outline-offset: 1.2rem;
    }
    
    
    
    .composition__photo:hover{
        outline: 1rem solid #f87370;
        transform: scale(1.05) translate(-0.5rem);
        box-shadow: 0 2.5rem 4rem #00000077;
        z-index: 20;
    }
    
    
    
    
    .composition__photo--p1{
        left: 0;
        top: -2rem;
    
    }
    
    .composition__photo--p2{
        right: 0;
        top: 1rem;
        
    }
    
    .composition__photo--p3{
        left: 15%;
        top: 5rem;
    
        
    }
    



}






















/* component under stories section of  pictures */




.heading-secondary-section-gallery-holiday-stories-section-pictures{
    font-size: 3.3rem;
    font-weight: 700;
    /* margin: 3rem 0; */
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270 , #ff615ebd);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;
    margin-bottom: 2.5rem;

}


.section-stories{
    padding: 3rem 0;
    /* margin:0 8.5rem ; */
    background-color: rgba(128, 128, 128, 0.199);
    margin-top: 2rem !important;
    
}



.story-gallery-holiday{
    width: 70%;
    margin: 0 auto;
    box-shadow: 0 2rem 3rem #000000 ;
    background-color: #fff;
    border-radius: 3px;
    padding: 3rem 4rem 2.7rem 7rem;
    backface-visibility: hidden;
   transform: skewX(-12deg);
}


.story-gallery-holiday-two{
    width: 70%;
    margin: 0 auto;
    box-shadow: 0 2rem 3rem #000000 ;
    background-color: #fff;
    border-radius: 3px;
    padding: 3rem 4rem 2.7rem 7rem;
   margin-top: 5rem;
   transform: skewX(-12deg);
   
}


.story__image-caption{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    color: #fff;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-align: center;
    background-color: #00000065;
    opacity: 0;
    transition: all 1s;
    /* backface-visibility: hidden; */

}

.story__image-caption-two{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    color: #fff;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-align: center;
    opacity: 0;
    transition: all .5s;
    backface-visibility: hidden;
}

.story-gallery-holiday:hover .story__image-caption{
    opacity: 1;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
}



.story-gallery-holiday-two:hover .story__image-caption-two{
    opacity: 1;
    transform: translate(-50%, -50%);
}

.story__shape{
    width: 14rem;
    height: 14rem;
    float: left;
    shape-outside: circle(50% at 50% 50%);
    -webkit-shape-outside: circle(50% at 50% 50%);
    clip-path: circle(50% at 50% 50%);
    -webkit-clip-path: circle(50% at 50% 50%);
    transform: translate(-2.5rem) skewX(12deg);
    position: relative;
    
}

.story__text{
    transform:skewX(12deg) ;
}


.heading-tertiary-story-section-gallery-holiday{
    font-size: 1.6rem;
    font-weight: 700;
    font-family: wow-bold;
    margin-bottom: 1rem;
    text-transform: uppercase;

}


.heading-tertiary-text-story-section-gallery-holiday{
    font-size: 1.4rem;
    font-weight: 300;
    font-family: wow-regular;
    margin-bottom: 1rem;

}

.story-gallery-holiday-image{
    height: 100%;
    transform: translateX(0rem) scale(1.4);
    backface-visibility: hidden !important;
    transition: all .3s;
    
}

.story-gallery-holiday-image-two{
    height: 100%;
    transform: translateY(2.6rem) scale(1.4);
    transition: all .3s;
    
}


.story-gallery-holiday:hover .story-gallery-holiday-image{
    transform: translateX(.3rem) scale(1);

    backface-visibility: hidden !important; 
    
}

.story-gallery-holiday-two:hover .story-gallery-holiday-image-two{
    transform: translateX(1rem) scale(1);
    filter: blur(2px) brightness(90%);
}





.button-text-wow-story-section:link,
.button-text-wow-story-section:visited{
    font-size: 1.6rem;
    color: #f87370 !important;
    display: inline-block ;
    text-decoration: none;
    border-bottom: 1px solid #f87370 !important;
    padding: 2px;
    font-family: wow-regular;
    font-weight: 700;
    margin-top: 5.4rem;
}




.button-text-wow-story-section:hover{
    background-color: #f87370 !important;
    color: #ffffff !important;
    box-shadow: 0 1rem 2rem #000000;
    transform: translateY(-3px);
    padding: 4px;

}

.button-text-wow-story-section:active {
    box-shadow: 0 .5rem 1rem #000000;
    transform: translateY(3px);
    padding: 4px;


}







/* media queries for stories section */


@media(max-width:700px){
    
.heading-secondary-section-gallery-holiday-stories-section-pictures{
    font-size: 2.3rem;
    font-weight: 700;
    /* margin: 3rem 0; */
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270 , #ff615ebd);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 1px;
    transition: all .2s;
    margin-bottom: 2.5rem;

}


.section-stories{
    padding: 1rem 0;
    /* margin:0 8.5rem ; */
    background-color: rgba(128, 128, 128, 0.199);
    margin-top: 2rem !important;
}



.story-gallery-holiday{
    width: 80%;
    margin: 0 auto;
    box-shadow: 0 2rem 3rem #000000 ;
    background-color: #fff;
    border-radius: 3px;
    padding: 2rem 2rem 1.7rem 2rem;
    backface-visibility: hidden;
   transform: skewX(0deg);
}


.story-gallery-holiday-two{
    width: 80%;
    margin: 0 auto;
    box-shadow: 0 2rem 3rem #000000 ;
    background-color: #fff;
    border-radius: 3px;
    padding: 2rem 2rem 1.7rem 2rem;
   margin-top: 5rem;
   transform: skewX(0deg);
   
}


.story__image-caption{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    color: #fff;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-align: center;
    opacity: 0;
    transition: all .5s;
    backface-visibility: hidden;

}

.story__image-caption-two{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 25%);
    color: #fff;
    text-transform: uppercase;
    font-size: 1.6rem;
    text-align: center;
    opacity: 0;
    transition: all .5s;
    backface-visibility: hidden;
}

.story-gallery-holiday:hover .story__image-caption{
    opacity: 1;
    transform: translate(-50%, -50%);
    backface-visibility: hidden;
}



.story-gallery-holiday-two:hover .story__image-caption-two{
    opacity: 1;
    transform: translate(-50%, -50%);
}

.story__shape{
    width: 11rem;
    height: 11rem;
    float: none;
    shape-outside: circle(0);
    -webkit-shape-outside: circle(0);
    clip-path: circle(50% at 50% 50%);
    -webkit-clip-path: circle(50% at 50% 50%);
    transform: translate(0rem) skewX(0deg);
    position: relative;
    
}




.story__text{
    transform:skewX(0deg) ;
}


.heading-tertiary-story-section-gallery-holiday{
    font-size: 1.6rem;
    font-weight: 700;
    font-family: wow-bold;
    margin-bottom: 1rem;
    text-transform: uppercase;

}


.heading-tertiary-text-story-section-gallery-holiday{
    font-size: 1.4rem;
    font-weight: 300;
    font-family: wow-regular;
    margin-bottom: 1rem;

}

.story-gallery-holiday-image{
    height: 100%;
    transform: translateX(-2.6rem) scale(1.4);
    backface-visibility: hidden !important;
    transition: all .3s;
    text-align: center !important;
    
}

.story-gallery-holiday-image-two{
    height: 100%;
    transform: translateY(-2.6rem) scale(1.4);
    transition: all .3s;
    text-align: center !important;
    
}


.story-gallery-holiday:hover .story-gallery-holiday-image{
    transform: translateX(-2.6rem) scale(1);

    backface-visibility: hidden !important; 
    
}

.story-gallery-holiday-two:hover .story-gallery-holiday-image-two{
    transform: translateX(1rem) scale(1);
    filter: blur(2px) brightness(90%);
}

.row-in-stories-section-wow-gallery{
    margin-right: 0 !important;
}
}















































/* section features media queries */

@media (max-width:700px){


.section-features-nature-wow-gallery-holiday{
    padding: 7rem 1rem;
    background-image: 
    linear-gradient(to left bottom, #f87270b6, #f872708a),
     url('/IMAGES/Newdesign/hero-small.jpg');
    background-size: cover;
    transform: skewY(0deg);
    margin-top: 0rem;
    margin-bottom: 5rem;
    
    
}







.feature-box-in-wow-gallery-holiday{
    background-color: rgba(255, 255, 255, 0.808);
    font-size: 5.2rem;
    padding: 1.8rem;
    text-align: center;
    border-radius: 4px;
    box-shadow: 0 1.5rem 4rem #000000;
    transition: none;
    margin-bottom: 1rem;
    
    

}


.feature-box-in-wow-gallery-holiday:hover{
    transform: none ;

}




.section-features-nature-wow-gallery-holiday > *{
    transform: skewY(0deg);
}



.feature-box__icon-wow-gallery{
    font-size: 5rem;
    margin-bottom: .5rem;
    display: inline-block;
    background-image: linear-gradient(to right, #f87370, #f87370);
    -webkit-background-clip: text;
    color: transparent;
}


.header-inside-grid-wow-gallery-features-box{
    font-size: 1.2rem !important;
    font-weight: 700;
    text-transform: uppercase;
    font-family: wow-holiday-bold;
    color: #565656;
    /* background-image: linear-gradient(to right,#000000 , #0000009c);
    -webkit-background-clip: text;
    color: transparent;
    display: inline-block; */
}


.feature__box-text-wow-gallery{
        font-size: 1.1rem !important;
        font-family: wow-regular;
     

}


}



@media (max-width: 1100px) and (min-width:700px){
    .feature-box-in-wow-gallery-holiday{
        background-color: rgba(255, 255, 255, 0.808);
        font-size: 5rem;
        padding: 1rem;
        text-align: center;
        border-radius: 4px;
        box-shadow: 0 1.5rem 4rem #000000;
        transition: none;
        margin-bottom: 1rem;
        width: 100% !important;
        height: 27rem;
        margin-right: 0 !important;
        
        
    
    }

    /* .any-class-feature-gallery{
        

    } */


    .feature-box__icon-wow-gallery{
        font-size: 4.8rem;
        margin-bottom: .5rem;
        display: inline-block;
        background-image: linear-gradient(to right, #f87370, #f87370);
        -webkit-background-clip: text;
        color: transparent;
    }
    
    
    .header-inside-grid-wow-gallery-features-box{
        font-size: 1rem !important;
        font-weight: 700;
        text-transform: uppercase;
        font-family: wow-holiday-bold;
        color: #565656;
        /* background-image: linear-gradient(to right,#000000 , #0000009c);
        -webkit-background-clip: text;
        color: transparent;
        display: inline-block; */
    }
    
    
    .feature__box-text-wow-gallery{
            font-size: 1rem !important;
            font-family: wow-regular;
         
    
    }
    
}





































/* component under features of grid section pictures media queries */


@media (max-width:700px){

.heading-secondary-section-gallery-holiday-grid-section-pictures{
    font-size: 2rem;
    font-weight: 700;
    
    font-family: wow-bold;
    display: inline-block;
    background-image: linear-gradient(to right,#f87270a8 , #ff615e);
    -webkit-background-clip: text;
    color: transparent;
    letter-spacing: 2px;
    transition: all .2s;
    border-bottom: 2px solid #f87370;
    margin-bottom: 1rem;


    
}



.button-text-wow-gallery-grid-grid-section-pictures:link,
.button-text-wow-gallery-grid-grid-section-pictures:visited{
    font-size: 1.2rem;
    color: #565656 !important;
    display: inline-block ;
    text-decoration: none;
    border-bottom: 1px solid #565656 !important;
    padding: 2px;
    font-family: wow-regular;
    margin-bottom: 1rem;
    margin-right: .5rem;
    


}


.slash-in-gallery-grid-section-pictures{
    font-size: 1rem;
    color: #aaaaaa;
    margin-right: 0.2rem;
}



}